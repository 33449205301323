import { IonRow, IonCol } from '@ionic/react';
import { FC } from 'react';

interface MenuUserInfoProps {
  aliasName?: string;
  isProUser: boolean;
}

const MenuUserInfo: FC<MenuUserInfoProps> = ({ aliasName, isProUser }) => (
  <IonRow className="sidemenu-userinfo ion-no-padding">
    {aliasName ? (
      <IonCol size="12" className="ion-no-padding username">
        {aliasName}
      </IonCol>
    ) : (
      <IonCol size="12" className="ion-no-padding username">
        Anonymous
      </IonCol>
    )}
    {!isProUser && <IonCol className="side-menu-plan-name ion-no-padding">Slot Check</IonCol>}

    {isProUser && <IonCol className="side-menu-plan-name ion-no-padding">Slot Pro</IonCol>}
  </IonRow>
);

export default MenuUserInfo;
