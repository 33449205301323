import { IonPage } from '@ionic/react';
import CasinoNavBar from 'components/CasinoNavBar';
import DropdownBar from 'components/DropdownBar/DropdownBar';
import React from 'react';
import Leaderboard from './views/Leaderboard/Leaderboard';
import { useSelector } from 'react-redux';
import { IOrientationInfo, IState } from 'appRedux/createStore';
import { FilterTypeEnum } from 'common/common';

const LeaderBoardTab: React.FC = () => {
  const isLandscape = useSelector<IState>(
    (state) => state.app.persistedState.orientationState?.isLandscape
  ) as IOrientationInfo;

  return (
    <IonPage>
      {!isLandscape && (
        <>
          <CasinoNavBar hideSearch hideNotify />
          <div className="draw-box">
            <div className="title">SLOT CHECK LEADERBOARD</div>
          </div>
        </>
      )}
      <DropdownBar id={FilterTypeEnum.leaderboard} isShowShortName={true} />
      <Leaderboard />
    </IonPage>
  );
};

export default React.memo(LeaderBoardTab);
