import React from 'react';

const UpArrow: React.FC = () => {
  return (
    <svg
      id="Group_4054"
      data-name="Group 4054"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="20"
      viewBox="0 0 30 20"
    >
      <path
        id="Path_2152"
        data-name="Path 2152"
        d="M13.251,19.161a2.285,2.285,0,0,0,3.538,0L29.379,4.468a2.955,2.955,0,0,0,.379-3.046A2.258,2.258,0,0,0,27.61,0H2.43C.307,0-.791,2.773.661,4.468Z"
        transform="translate(30 20) rotate(180)"
        className='Path_2152'
      />
      <path
        id="Path_2156"
        data-name="Path 2156"
        d="M2.183,4.7,5.367,7.863a.87.87,0,0,1,0,1.265.883.883,0,0,1-1.273,0l-3.82-3.8h0a.87.87,0,0,1,0-1.265l3.82-3.8A.828.828,0,0,1,4.73,0h0a.828.828,0,0,1,.637.271.87.87,0,0,1,0,1.265Z"
        transform="translate(19.52 8.48) rotate(90)"
        className='Path_2156'

      />
    </svg>
  );
};

export default UpArrow;
