import { useState } from 'react';

export type SortOrder = 'asc' | 'desc' | false;
export type SortField = 'interval' | 'amount' | 'hasSubscription';

type SortOrderState = {
  [key in SortField]: SortOrder;
};

const useSort = () => {
  const initialSortOrder: SortOrderState = {
    interval: false,
    amount: false,
    hasSubscription: false,
  };

  const [sortOrder, setSortOrder] = useState<{ [key in SortField]: SortOrder }>({
    interval: false,
    amount: false,
    hasSubscription: false,
  });

  const getOrderValue = (order: SortOrder) => {
    return order === 'desc' ? -1 : 1;
  };

  const handleSort = (fieldParam: SortField) => {
    setSortOrder((prev) => {
      const newSortOrder: SortOrderState = {
        ...initialSortOrder,
        [fieldParam]: prev[fieldParam] === 'asc' ? 'desc' : 'asc',
      };
      return newSortOrder;
    });
  };

  return { sortOrder, handleSort, getOrderValue };
};

export default useSort;
