import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonPage,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { IState } from 'appRedux/createStore';
import { useSelector } from 'react-redux';
import { onPromise } from '../CreateAccount';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import CountdownTimer from 'components/CountdownTimer/CountdownTimer';
import useToast from 'common/reactHooks/useToast';
import { Auth } from 'aws-amplify';
import { getResetPasswordErrorMessage } from 'common/common';

interface FormData {
  user: string;
  otp_number: string;
  password: string;
  passwordConfirmation: string;
}

export const login_schema = yup.object().shape({
  otp_number: yup
    .string()
    .required('OTP number is required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(6, 'Must be exactly 6 digits')
    .max(6, 'Must be exactly 6 digits'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must include at least 8 characters')
    .max(24, 'Please use a shorter password (max: 24 characters)'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const ResetPassword = () => {
  const history = useHistory();
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(login_schema),
  });
  const phoneNumber = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.phone
  ) as string;
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const showToast = useToast();
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const onResendCodeBtnClick = () => {
    OTPsend(phoneNumber);
    showToast({ message: 'New code has been sent' });
    setIsTimerExpired(false);
  };

  const OTPsend = (phone: string) => {
    const transformedPhoneNumber = phone
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('-', '')
      .replaceAll(' ', '');

    Auth.forgotPassword(`+1${transformedPhoneNumber?.replaceAll('-', '')}`)
      .then(() => {
        showToast({ message: 'Ok we’re sending a new code. It should arrive shortly.' });
      })
      .catch((err) => {
        showToast({ message: err.message, type: 'danger' });
      });
  };

  const onSubmit = async (data: FormData) => {
    if (phoneNumber) {
      const phnoUsername = `+1${phoneNumber}`;
      await Auth.forgotPasswordSubmit(phnoUsername, data.otp_number, data.password)
        .then(() => {
          showToast({ message: 'Your password has been changed' });
          history.push('/');
        })
        .catch((e) => {
          showToast({ type: 'danger', message: getResetPasswordErrorMessage(e?.message) });
        });
    } else {
      showToast({ type: 'danger', message: 'Cell number is required' });
    }
  };

  return (
    <IonPage className="app-parent">
      <IonHeader>
        <IonToolbar className="toolbar-background">
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="scroll-disable">
        <div className="container">
          <div className="content">
            <div className="m-signup m-signup__login">
              <div className="modal-container">
                <form onSubmit={onPromise(handleSubmit((data: FormData) => void onSubmit(data)))}>
                  <IonRow>
                    <IonCol>
                      <div className="header-bar">
                        <span className="heading4 signup-box-title primary-color-text">
                          Verify & Reset Password
                        </span>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <span className="body primary-color-text">
                        We have sent the 6 digit verification code to your registered mobile number
                      </span>
                      <span className="body-bold accent-color"> +1 {phoneNumber}</span>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>
                      <span className="button-text primary-color-text">Code</span>
                      <div className="input-group">
                        <Controller
                          name="otp_number"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange } }) => (
                            <div className="input-row">
                              <IonInput maxlength={6} placeholder="" onIonChange={onChange} />
                              <div className="input-icon-box">
                                {isTimerExpired ? (
                                  <button className="resend-otp-btn" onClick={onResendCodeBtnClick}>
                                    Resend
                                  </button>
                                ) : (
                                  <CountdownTimer
                                    minutes="1"
                                    setResendTimerExpired={setIsTimerExpired}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                          rules={{ required: true }}
                        />
                        {errors.otp_number && errors.otp_number.message && (
                          <div className="field-validation-message">
                            {errors.otp_number.message}
                          </div>
                        )}
                      </div>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>
                      <span className="button-text primary-color-text">New Password *</span>
                      <div className="input-group cy-password-field-group">
                        <Controller
                          name="password"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange } }) => (
                            <div className="input-row">
                              <IonInput
                                type={showPasswordNew ? 'text' : 'password'}
                                placeholder=""
                                onIonChange={onChange}
                                autocomplete="current-password"
                                data-cy="password-text-field"
                              />

                              <div
                                className="input-icon-box"
                                onClick={() => {
                                  setShowPasswordNew((showPasswordNew) => !showPasswordNew);
                                }}
                              >
                                <IonIcon icon={showPasswordNew ? eyeOffOutline : eyeOutline} />
                              </div>
                            </div>
                          )}
                          rules={{
                            required: true,
                          }}
                        />
                        {errors.password && errors.password.message && (
                          <div className="field-validation-message">{errors.password.message}</div>
                        )}
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <span className="button-text primary-color-text">Confirm Password *</span>
                      <div className="input-group cy-password-field-group">
                        <Controller
                          name="passwordConfirmation"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange } }) => (
                            <div className="input-row">
                              <IonInput
                                type={showConfirmPassword ? 'text' : 'password'}
                                placeholder=""
                                onIonChange={onChange}
                                autocomplete="new-password"
                              />
                              <div
                                className="input-icon-box"
                                onClick={() => {
                                  setShowConfirmPassword(
                                    (showConfirmPassword) => !showConfirmPassword
                                  );
                                }}
                              >
                                <IonIcon icon={showConfirmPassword ? eyeOffOutline : eyeOutline} />
                              </div>
                            </div>
                          )}
                          rules={{
                            required: true,
                          }}
                        />
                        {errors.passwordConfirmation && errors.passwordConfirmation.message && (
                          <div className="field-validation-message">
                            {errors.passwordConfirmation.message}
                          </div>
                        )}
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonButton type="submit" data-cy="login-submit" className="submit-btn">
                        Submit
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </form>
              </div>
              <div className="footer-container">
                <div className="logo">
                  <IonImg src="assets/images/SLOTcheckTM.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
