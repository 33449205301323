import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonImg,
  IonMenuButton,
  IonToolbar,
  useIonModal,
} from '@ionic/react';
import { useSelector } from 'react-redux';
import { IState } from 'appRedux/createStore';
import {
  arrowBackOutline,
  menuOutline,
  notificationsOutline,
  searchCircleOutline,
} from 'ionicons/icons';
import useWhiteLabelStyles, { WHITE_LABEL_TYPE_ENUM } from '../../hooks/useWhiteLabelStyles';

import { useHistory } from 'react-router';
import { SearchModal } from './searchModal';
import useSlotDetailHistory from 'globalServices/useSlotDetailHistory';

interface ICasinoNavBar {
  showBack?: boolean;
  searchBorder?: boolean;
  hideSearch?: boolean;
  hideNotify?: boolean;
  styles?: object;
  hideLogo?: boolean;
}

const CasinoNavBar = ({
  showBack,
  hideSearch,
  hideNotify,
  styles,
  hideLogo = false,
}: ICasinoNavBar) => {
  const btnstyles = useWhiteLabelStyles(WHITE_LABEL_TYPE_ENUM.BUTTON);
  const casinoNavBarImage = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema?.logos?.navBar
  ) as string;
  const history = useHistory();
  const { addToSlotHistory } = useSlotDetailHistory();

  const [showSearchModal, hideSearchModal] = useIonModal(SearchModal, {
    title: 'Slot Search',
    onClickIndividualItem: (slotNumber: number) => {
      hideSearchModal();
      addToSlotHistory(slotNumber);
      history.push('/detail');
    },
    closeHandler: () => hideSearchModal(),
  });

  return (
    <div className="slotcheck-header" style={styles}>
      <IonHeader className="app-header ion-no-border">
        <div className="header-container">
          <IonToolbar className="ion-no-border no-border">
            <IonButtons slot="start">
              {showBack && (
                <IonButton
                  fill="clear"
                  onClick={() => {
                    history.goBack();
                  }}
                  className="icon-back"
                  style={btnstyles}
                  slot="start"
                >
                  <IonIcon style={btnstyles} icon={arrowBackOutline} />
                </IonButton>
              )}
              {!showBack && (
                <IonMenuButton id="menu-drawer">
                  <IonIcon style={btnstyles} icon={menuOutline} className="side-menu-icon" />
                </IonMenuButton>
              )}
            </IonButtons>
            {!hideNotify && (
              <IonButtons slot="end">
                <IonButton routerLink="/notifications">
                  <IonIcon
                    icon={notificationsOutline}
                    color="light"
                    className="notification-icon"
                  />
                </IonButton>
              </IonButtons>
            )}
          </IonToolbar>
          <div className="casino-logo-header">
            {!hideLogo && casinoNavBarImage && (
              <IonImg src={casinoNavBarImage} className="casino-logo" />
            )}
          </div>
          <div>
            {!hideSearch && (
              <div className="search-container">
                <div
                  home-page-tour-step="search"
                  className="header-search-icon"
                  onClick={() => {
                    showSearchModal();
                  }}
                >
                  <IonIcon
                    slot="icon-only"
                    id="search-icon"
                    icon={searchCircleOutline}
                    className={'menu-icon'}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </IonHeader>
    </div>
  );
};

export default CasinoNavBar;
