import { IonImg } from '@ionic/react';

interface IProfileMenuOption {
  optionLabel: string;
  optionImageSrc: string;
  onClick?: () => void;
}

const ProfileMenuOption: React.FC<IProfileMenuOption> = ({
  optionLabel,
  optionImageSrc,
  onClick,
}: IProfileMenuOption) => {
  return (
    <div className="manage-profile-menu-box" onClick={onClick}>
      <div className="manage-profile-menu-box-icon">
        <IonImg src={optionImageSrc} />
      </div>
      <div className="manage-profile-menu-box-text">{optionLabel}</div>
    </div>
  );
};

export default ProfileMenuOption;
