import { FC, useEffect, useRef, useState } from 'react';
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonGrid, IonRow, useIonToast } from '@ionic/react';
import './trends.scss';
import { getInsightDetails } from '../../config/config';
import { kFormatter } from '../../common/include';
import { getTrendLine } from './utils';
import SmartLoading from '../SmartLoading';
import TrendRow from './TrendRow';
import { useSelector } from 'react-redux';
import { IState } from 'appRedux/createStore';
import { CasinoService, IRequestTrendsParam } from 'services/casino';
import { ICasinoReport, ITrendsReport } from 'appRedux/models/casinoModels';

const TrendsSlots: FC<{
  slotInfo: ICasinoReport;
  isAdvancedDisplayState?: boolean;
  onClickpopUp: (id: string) => void;
}> = ({ slotInfo, isAdvancedDisplayState, onClickpopUp }) => {
  const casinoSvc = new CasinoService();
  const ref = useRef<any>(null);
  const [presentToast] = useIonToast();
  const [isTrendsLoading, setTrendsLoading] = useState(false);
  const [trendsData, setTrendsData] = useState<ITrendsReport[]>();
  const [collapse, setCollapse] = useState(true);
  const [screenWidth, setScreenWidth] = useState(ref?.current?.clientWidth);
  const casinoId = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema?.kpCasinoPk
  ) as number;
  const toggleCollapse = () => {
    setCollapse(!collapse);
  };
  const insightFilter = '{1,2,3,4,5,6,7,8,10,11}';
  const orderByFilter = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const fetchTrendsGraph = (queryParams: IRequestTrendsParam) => {
    setTrendsLoading(true);
    casinoSvc.GetTrendsData(queryParams).subscribe({
      next(casinoResponse) {
        if (casinoResponse && casinoResponse?.DataSet) {
          const filteredTrends = casinoResponse?.DataSet.filter((trendsData) =>
            orderByFilter.includes(trendsData.order_by)
          );
          setTrendsData(filteredTrends);
        }
      },
      error(err) {
        presentToast({
          position: 'top',
          duration: 5000,
          color: 'danger',
          message: 'Failed to retrieve casino report.',
        }).catch(() => null);
      },
      complete() {
        setTrendsLoading(false);
      },
    });
  };

  useEffect(() => {
    const graphRequestParam = {
      slotNumber: slotInfo.slot_number,
      insightFilter,
      casinoFk: casinoId,
    } as IRequestTrendsParam;

    fetchTrendsGraph(graphRequestParam);
  }, [insightFilter, slotInfo.slot_number]);

  useEffect(() => {
    if (ref?.current) {
      setScreenWidth(ref?.current?.clientWidth);
    }
  });

  useEffect(() => {
    if (ref?.current) {
      setTimeout(() => {
        setScreenWidth(ref?.current?.clientWidth);
      }, 1000);
    }
  }, [isAdvancedDisplayState]);

  return (
    <div className="trends">
      <div className="trends-header">
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol>
              <div className="section-header">
                <div className="section-header-icon section-header-icon-collapse">
                  <FontAwesomeIcon
                    icon={collapse ? faAngleDoubleUp : faAngleDoubleDown}
                    onClick={toggleCollapse}
                  />
                </div>
                <div className="section-header-title trends-section-title">
                  <h2>30 Day trends</h2>
                </div>
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className="info-icon"
                  onClick={() => onClickpopUp('trends_section_info')}
                />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      {isTrendsLoading ? (
        <SmartLoading loading={isTrendsLoading} customCls="trends-loader" />
      ) : (
        <div className={collapse ? 'trends-content' : 'content-hide'}>
          <div className="trends-table-header">
            <IonRow>
              <IonCol className="trend-column" ref={ref}>
                30 days
              </IonCol>
              <IonCol className="trend-column">7 days</IonCol>
              <IonCol className="trend-column">24 hours</IonCol>
              {!isAdvancedDisplayState && <IonCol className="trend-column" />}
              {isAdvancedDisplayState && (
                <>
                  <IonCol className="trend-column">8 hours</IonCol>
                  <IonCol className="trend-column">4 hours</IonCol>
                  <IonCol className="trend-column">1 hour</IonCol>
                  <IonCol className="trend-column" />
                </>
              )}
            </IonRow>
          </div>
          {trendsData &&
            trendsData.length > 0 &&
            trendsData.map((eachPlan: ITrendsReport, index: number) => {
              const { insight } = eachPlan;
              const showInsightModal = () => onClickpopUp(insight);
              const snapshotLabel = '';
              const trendLabel = getInsightDetails(eachPlan.insight)?.trendLabel;
              const monthTrendValue = eachPlan['m_insight_value'];
              const weekTrendValue = eachPlan['w_insight_value'];
              const dayTrendValue = eachPlan['d_insight_value'];
              const eightHoursTrendValue = eachPlan['h8_insight_value'];
              const fourHoursTrendValue = eachPlan['h4_insight_value'];
              const oneHourTrendValue = eachPlan['h1_insight_value'];
              const isVolatilityInsight = insight === 'volatility_rating';
              const enableDarkBg = [
                'avg_money_won_per_spin',
                'win_rate',
                'jackpot_value',
                'jackpots',
              ].includes(insight);
              let lastJackpotAge = eachPlan.long_jackpot_age;
              if (lastJackpotAge === null) {
                lastJackpotAge = 'Jackpot data not found';
              }
              const {
                monthToWeekTrend,
                weekToDayTrend,
                dayToEightHoursTrend,
                eightHoursToFourHoursTrend,
                fourHoursTrendToOneHourTrend,
              } = getTrendLine(
                monthTrendValue,
                weekTrendValue,
                dayTrendValue,
                eightHoursTrendValue,
                fourHoursTrendValue,
                oneHourTrendValue
              );

              const trendValues = {
                monthTrendValue: isVolatilityInsight
                  ? eachPlan['m_insight_ntile']
                  : monthTrendValue,
                weekTrendValue: isVolatilityInsight ? eachPlan['w_insight_ntile'] : weekTrendValue,
                dayTrendValue: isVolatilityInsight ? eachPlan['d_insight_ntile'] : dayTrendValue,
                eightHoursTrendValue: isVolatilityInsight
                  ? eachPlan['h8_insight_ntile']
                  : eightHoursTrendValue,
                fourHoursTrendValue: isVolatilityInsight
                  ? eachPlan['h4_insight_ntile']
                  : fourHoursTrendValue,
                oneHourTrendValue: isVolatilityInsight
                  ? eachPlan['h1_insight_ntile']
                  : oneHourTrendValue,
              };
              const heatLevel = {
                monthHeat: eachPlan['m_insight_ntile'],
                weekHeat: eachPlan['w_insight_ntile'],
                dayHeat: eachPlan['d_insight_ntile'],
                eightHoursHeat: eachPlan['h8_insight_ntile'],
                fourHoursHeat: eachPlan['h4_insight_ntile'],
                oneHourHeat: eachPlan['h1_insight_ntile'],
              };

              const trendLine = {
                monthToWeekTrend,
                weekToDayTrend,
                dayToEightHoursTrend,
                eightHoursToFourHoursTrend,
                fourHoursTrendToOneHourTrend,
              };
              const noJackpotsWithinMonth = !monthTrendValue && !weekTrendValue && !dayTrendValue;
              return (
                <TrendRow
                  key={index}
                  lastJackpotAge={lastJackpotAge}
                  snapshotLabel={snapshotLabel}
                  insightId={insight}
                  enableDarkBg={enableDarkBg}
                  trendLine={trendLine}
                  trendValues={trendValues}
                  trendLabel={trendLabel!}
                  heatLevel={heatLevel}
                  showInsightModal={showInsightModal}
                  noJackpotsWithinMonth={noJackpotsWithinMonth}
                  isAdvancedDisplayState={isAdvancedDisplayState}
                  screenWidth={screenWidth}
                />
              );
            })}

          {trendsData && trendsData.length === 0 && <div className="no-data">No Data Found</div>}
        </div>
      )}
    </div>
  );
};

export default TrendsSlots;
