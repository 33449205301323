import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonInput,
  IonPage,
  IonRow,
  useIonLoading,
  useIonToast,
} from '@ionic/react';
import * as yup from 'yup';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import CountdownTimer from 'components/CountdownTimer/CountdownTimer';
import { Auth } from 'aws-amplify';

import { publish } from 'common/PubSub';
import { IState } from 'appRedux/createStore';
import { useDispatch, useSelector } from 'react-redux';
import { onPromise } from '../CreateAccount';
import { ReduxCommandCreator } from 'appRedux/actions';
import { useHistory } from 'react-router';

interface FormData {
  otp_number: string;
}

export const verification_schema = yup.object().shape({
  otp_number: yup
    .string()
    .required('OTP number is required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(6, 'Must be exactly 6 digits')
    .max(6, 'Must be exactly 6 digits'),
});

export const VerifyAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const commands = ReduxCommandCreator(dispatch);
  const phone = useSelector<IState>((state) => state.app.persistedState.userInfo?.phone) as string;
  const [resendTimerExpired, setResendTimerExpired] = useState(false);
  const [presentToast] = useIonToast();
  const [present, dismiss] = useIonLoading();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(verification_schema),
  });

  async function confirmSignUp(username: string, code: string) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const results = await Auth.confirmSignUp(username, code);
      console.log(results);
      if (results) {
        console.log('SUCCESS');
        void presentToast({
          position: 'top',
          duration: 3000,
          color: 'success',
          message: 'Phone number verified!',
        });
        commands.SetUserLevel({ hasCompletedOnboarding: false });

        history.push('/');
      } else {
        void presentToast({
          position: 'top',
          duration: 5000,
          color: 'error',
          message: 'Invalid Code',
        });
        setResendTimerExpired(false);
        setTimeout(() => {
          publish(false);
        }, 3000);
      }
    } catch (error) {
      console.log('error confirming sign up', error);
      void presentToast({
        position: 'top',
        duration: 5000,
        color: 'danger',
        message: 'Invalid Code',
      });
      setResendTimerExpired(false);
      setTimeout(() => {
        publish(false);
      }, 3000);
      setResendTimerExpired(true);
    }
  }

  async function resendConfirmationCode(username: string) {
    try {
      await present({
        message: 'Loading...',
        cssClass: 'custom-loading',
      });
      await Auth.resendSignUp(username);
      console.log('code resent successfully');
      dismiss()
        .then(() => {
          return;
        })
        .catch(() => {
          return;
        });
    } catch (err) {
      console.log('error resending code: ', err);
      dismiss()
        .then(() => {
          return;
        })
        .catch(() => {
          return;
        });
    }
  }

  const onSubmit = async (data: FormData) => {
    // send({
    //   otpNumber: data.otp_number,
    //   type: 'updateOtpNumber',
    // });
    // send('next');
    await present({
      message: 'Loading...',
      cssClass: 'custom-loading',
    });
    const userName = `+1${phone.replaceAll('-', '')}`;
    await confirmSignUp(userName, data.otp_number);
    dismiss()
      .then(() => {
        return;
      })
      .catch(() => {
        return;
      });
  };

  const onResendCodeBtnClick = async () => {
    const userName = `+1${phone.replaceAll('-', '')}`;
    // send('resendOtpNumber');
    await resendConfirmationCode(userName);
    publish(true);
    void presentToast({
      position: 'top',
      duration: 5000,
      color: 'success',
      message: 'New code has been sent',
    });
    setResendTimerExpired(false);
    setTimeout(() => {
      publish(false);
    }, 3000);
  };

  // useEffect(() => {
  //   let isIgnore = false;
  //   if (!phone) {
  //     if (!isIgnore) {
  //       void presentToast({
  //         position: 'top',
  //         duration: 5000,
  //         color: 'error',
  //         message: 'Error registering, please contact support',
  //       });
  //       history.push('/auth/createaccount');
  //     }
  //   }
  //   return () => {
  //     isIgnore = true;
  //   };
  // }, [phone]);

  return (
    <IonPage className="app-parent">
      <IonContent className="scroll-disable">
        <div className="container">
          <div className="content">
            <div className="m-signup m-signup__login">
              <div className="modal-container">
                <div className="m-signup__verify-account">
                  <div>
                    <form
                      id="confirm-page"
                      className="form-modal"
                      onSubmit={onPromise(handleSubmit((data: FormData) => void onSubmit(data)))}
                    >
                      <IonGrid>
                        <IonRow>
                          <IonCol>
                            <div className="header-bar">
                              <span className="heading4 signup-box-title primary-color-text">
                                Verify Account
                              </span>
                            </div>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol>
                            <span className="body primary-color-text">
                              We have sent the 6 digit verification code to your registered mobile
                              number
                            </span>
                            <span className="body-bold accent-color"> +1 {phone}</span>
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol>
                            <span className="button-text primary-color-text">Code</span>
                            <div className="input-group">
                              <Controller
                                name="otp_number"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange } }) => (
                                  <IonInput maxlength={6} placeholder="" onIonChange={onChange} />
                                )}
                                rules={{ required: true }}
                              />
                              {errors.otp_number && errors.otp_number.message && (
                                <div className="field-validation-message">
                                  {errors.otp_number.message}
                                </div>
                              )}
                            </div>
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol>
                            <IonButton type="submit" className="submit-btn">
                              Verify
                            </IonButton>
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol>
                            <div className="resend-container">
                              {resendTimerExpired && (
                                <span
                                  className="caption-text primary-color-text"
                                  onClick={onResendCodeBtnClick}
                                >
                                  Resend Code
                                </span>
                              )}
                              {!resendTimerExpired && (
                                <div className="create-account-link ion-text-center">
                                  <CountdownTimer
                                    setResendTimerExpired={setResendTimerExpired}
                                    minutes="3"
                                  />
                                </div>
                              )}
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </form>
                  </div>
                </div>
              </div>
              <div className="footer-container">
                <div className="logo">
                  <IonImg src="assets/images/SLOTcheckTM.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
