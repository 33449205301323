import { IonButton, IonIcon, IonSpinner } from '@ionic/react';
import { heart, heartOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useFavorites from 'common/reactHooks/useFavorites';
import { IUserFavorite } from 'appRedux/models/playerModels';
import { selectUserId, selectFavoritesList } from 'appRedux/selectors';

interface ILikeButtonProps {
  slotNumber: string;
}

export const LikeButton: React.FC<ILikeButtonProps> = ({ slotNumber }: ILikeButtonProps) => {
  const { isFavoritesLoading, handleDeleteFavorite, handleInsertFavorite } = useFavorites({});
  const userId = useSelector(selectUserId);
  const favoritesList = useSelector(selectFavoritesList);

  const isIncludedInFavorites = (slotNumber: string, userFavorites: IUserFavorite[] = []) => {
    const slotNumberNumeric = Number(slotNumber);

    return userFavorites.some((favorite) => favorite.slotNumber === slotNumberNumeric);
  };

  const isFavorite = isIncludedInFavorites(slotNumber, favoritesList);

  const handleClick = (event: React.MouseEvent<HTMLIonButtonElement>) => {
    event.stopPropagation();

    if (userId) {
      if (isFavorite) {
        handleDeleteFavorite(slotNumber, userId);
      } else {
        handleInsertFavorite(slotNumber, userId);
      }
    }
  };

  return (
    <IonButton
      id="likeButton"
      className={isFavorite ? 'is-favorite-true' : 'is-favorite-false'}
      fill="clear"
      size="small"
      onClick={handleClick}
    >
      {isFavoritesLoading ? (
        <IonSpinner name="crescent" />
      ) : (
        <>
          {isFavorite ? (
            <IonIcon slot="icon-only" icon={heart} color="favorite" />
          ) : (
            <IonIcon slot="icon-only" icon={heartOutline} color="medium" />
          )}
        </>
      )}
    </IonButton>
  );
};
