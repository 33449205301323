import { IonLoading, useIonToast } from '@ionic/react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { StripeService } from 'services/stripe';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'appRedux/createStore';
import moment from 'moment';
import { ReduxCommandCreator } from 'appRedux/actions';
import { AuthService, ISubscriptionInfo } from 'services/auth';
import { playerLevel } from 'common/commonEnum';

interface IProcessParams {
  sessionId: string;
}

export const ProcessSuccess = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const commands = ReduxCommandCreator(dispatch);
  const service = new StripeService();
  const authService = new AuthService();
  const params = useParams<IProcessParams>();
  const [presentToast] = useIonToast();

  const appUserPk = useSelector<IState>((state) => state.app.persistedState.userInfo?.appUserPk);
  const subscriptionSuccess = async (planDuration: string) => {
    if (appUserPk) {
      const timeType = planDuration === 'month' ? 'M' : 'y';
      const jsonReqPro: ISubscriptionInfo = {
        appUserFk: appUserPk as string,
        subscriptionPlanFk: playerLevel.slotCheckPro,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, timeType).format('YYYY-MM-DD'),
      };

      authService.UpdateUserSubscription(jsonReqPro).subscribe({
        next(_loginResponse) {},
        error(_err) {},
      });
    }
  };

  useEffect(() => {
    service.ProcessCheckout(params.sessionId).subscribe({
      next(data) {
        if (data) {
          const { subscriptionData, customerData } = data.response;
          const customerId = customerData.id;
          const planId = subscriptionData?.plan?.id;
          const subscriptionId = subscriptionData?.id;
          const currentPeriodEnd = subscriptionData.current_period_end;
          const planDuration = subscriptionData?.plan.interval;
          commands.SetUserLevel({
            level: planId,
            customer_id: customerId,
            subscription_id: subscriptionId,
            current_period_end: currentPeriodEnd,
            isProUser: true,
          });
          void presentToast({
            position: 'top',
            duration: 3000,
            color: 'success',
            message: 'Your transaction has been completed successfully!!',
          });
          void subscriptionSuccess(planDuration);
          history.push('/tabs/home');
        } else {
          void presentToast({
            position: 'top',
            duration: 3000,
            color: 'failed',
            message: 'your transaction has been declined!!',
          });
        }
      },
      error(err) {
        console.log(err);
        void presentToast({
          position: 'top',
          duration: 3000,
          color: 'failed',
          message: 'your transaction has been declined!!',
        });
        window.location.replace('/tabs/home');
      },
    });
  }, []);
  return (
    <div>
      <div className="loader-container">
        <IonLoading message="Wait for it" isOpen={true} />
      </div>
    </div>
  );
};
