import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonMenu } from '@ionic/react';
import { close } from 'ionicons/icons';
import { useRef } from 'react';
import { routes } from '../../config/config';
import MenuAvatar from './MenuAvatar';
import MenuListSection from './MenuListSection';
import MenuUserInfo from './MenuUserInfo';
import './side-menu.scss';
import CopyRight from '../CopyRight';
import MenuNotAuthenticated from './MenuNotAuthenticated';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxCommandCreator } from 'appRedux/actions';
import { IState, IUserInfo } from 'appRedux/createStore';
import { AUTH_STATE } from 'appRedux/types/reduxTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompass, faSplotch } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'services/auth';
import { take, tap } from 'rxjs';
import { selectAuthStateTransformed } from 'appRedux/selectors';

const SideMenu = () => {
  const dispatch = useDispatch();
  const commands = ReduxCommandCreator(dispatch);
  const history = useHistory();
  const authService = new AuthService();
  const authState = useSelector(selectAuthStateTransformed);

  const isProUser = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.isProUser
  ) as boolean;
  const isSlotcheckInternal = useSelector<IState>((state) =>
    state.app.persistedState.userInfo?.groups?.includes('SlotcheckInternal')
  );
  const isCasinoAdmin = useSelector<IState>(
    (state) =>
      state.app.persistedState.userInfo?.groups?.includes('LOTCAdmin') ||
      state.app.persistedState.userInfo?.groups?.includes('EurekaAdmin')
  );
  const { aliasInitial2, aliasName } = useSelector<IState>(
    (state) => state.app.persistedState.userInfo
  ) as IUserInfo;

  const sideMenuRef = useRef<HTMLIonMenuElement | null>(null);

  const signOut = () => {
    history.push('/logout');
  };

  const signIn = async () => {
    await sideMenuRef.current?.toggle();
  };

  const handleCloseMenu = async () => {
    await sideMenuRef?.current?.toggle();
  };

  const handleResetTour = () => {
    authService
      .UpdateMe({ hasCompletedOnboarding: false })
      .pipe(
        take(1),
        tap(() => {
          commands.SetTourState(false);
          handleCloseMenu();

          if (history.location.pathname !== '/tabs/home') {
            history.push('/tabs/home');
          } else {
            history.go(0);
          }
        })
      )
      .subscribe();
  };

  return (
    <>
      <IonMenu
        type="overlay"
        contentId="main"
        className="bg-menu"
        swipeGesture={false}
        menuId="main"
        ref={sideMenuRef}
      >
        <div className="sidemenu-container">
          <div className="close-side-menu-btn" onClick={handleCloseMenu}>
            <IonIcon icon={close} />
          </div>
          <div className="sidemenu-header">
            {!authState && <MenuNotAuthenticated signIn={signIn} />}
            {authState && (
              <>
                <MenuAvatar aliasInitial={aliasInitial2} />
                <MenuUserInfo aliasName={aliasName} isProUser={isProUser} />
              </>
            )}
          </div>

          <IonList lines="none" className="menu-list menu-content">
            <IonItem onClick={handleResetTour} className="action-container">
              <IonLabel>
                <FontAwesomeIcon className="sidemenu-icon fa" icon={faCompass} color="gold" />
                Start App Tour
              </IonLabel>
            </IonItem>
          </IonList>

          {(isSlotcheckInternal || isCasinoAdmin) && (
            <IonList lines="none" className="menu-list menu-content">
              {isSlotcheckInternal && (
                <IonItem
                  routerLink="/admin/dashboard"
                  onClick={handleCloseMenu}
                  className="action-container"
                >
                  <IonLabel>
                    <FontAwesomeIcon className="sidemenu-icon fa" icon={faSplotch} color="gold" />
                    Admin Dashboard
                  </IonLabel>
                </IonItem>
              )}

              {isSlotcheckInternal && (
                <IonItem
                  routerLink="/admin/analytics"
                  onClick={handleCloseMenu}
                  className="action-container"
                >
                  <IonLabel>
                    <FontAwesomeIcon className="sidemenu-icon fa" icon={faSplotch} color="gold" />
                    Analytics Dashboard
                  </IonLabel>
                </IonItem>
              )}

              {isCasinoAdmin && (
                <IonItem
                  routerLink="/admin/casino"
                  onClick={handleCloseMenu}
                  className="action-container"
                >
                  <IonLabel>
                    <FontAwesomeIcon className="sidemenu-icon fa" icon={faSplotch} color="gold" />
                    Casino Admin
                  </IonLabel>
                </IonItem>
              )}

              {isSlotcheckInternal && (
                <IonItem
                  routerLink="/admin/testEveriLogin"
                  onClick={handleCloseMenu}
                  className="action-container"
                >
                  <IonLabel>
                    <FontAwesomeIcon className="sidemenu-icon fa" icon={faSplotch} color="gold" />
                    Test Everi Login
                  </IonLabel>
                </IonItem>
              )}
            </IonList>
          )}

          <IonContent forceOverscroll={false} className="menu-content">
            {authState && (
              <MenuListSection
                className="user-pages"
                pages={routes.userPages}
                signOut={signOut}
                onRouteChange={handleCloseMenu}
              />
            )}
            <MenuListSection
              className="info-pages"
              pages={routes.infoPages}
              signOut={signOut}
              onRouteChange={handleCloseMenu}
            />

            {authState && (
              <MenuListSection
                id="logout-button"
                className="info-pages"
                pages={routes.logoutPages}
                signOut={() => {
                  handleCloseMenu().catch(() => null);
                  signOut();
                }}
                onRouteChange={handleCloseMenu}
              />
            )}
          </IonContent>
          <CopyRight isShowVersion={true} />
        </div>
      </IonMenu>
    </>
  );
};

export default SideMenu;
