import { SlotHistoryReduxCommandCreator } from 'appRedux/actions/slotHistoryCommandCreator';
import { IState } from 'appRedux/createStore';
import { IHistorySlotInfo } from 'appRedux/models/casinoModels';
import { useDispatch, useSelector } from 'react-redux';

export default function useSlotDetailHistory() {
  const dispatch = useDispatch();
  const historyCommands = SlotHistoryReduxCommandCreator(dispatch);
  const { slotNumbers, activeSlotNumber } = useSelector<IState>(
    (state) => state.app.persistedState.historyState
  ) as IHistorySlotInfo;

  const addToSlotHistory = (slotNumber: number) => {
    historyCommands.AddToSlotHistory({
      slotNumbers: [slotNumber],
      activeSlotNumber: slotNumber,
    });
  };

  return {
    addToSlotHistory,
    activeSlotNumber,
    slotNumbers,
  };
}
