import { createSelector } from 'reselect';
import { IState } from '../createStore';
import { AUTH_STATE } from 'appRedux/types/reduxTypes';

const getAuthState = (state: IState) => state.app.persistedState?.authState;

export const selectAuthState = createSelector([getAuthState], (authState) => authState);

export const selectAuthStateTransformed = createSelector([getAuthState], (authState) => {
  return authState === AUTH_STATE.AUTHENTICATED ? true : false;
});
