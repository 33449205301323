import { IonIcon } from '@ionic/react';
import { card, chevronForward } from 'ionicons/icons';
import { noop } from 'lodash';
import React from 'react';

interface LinkedCasinoCardProps {
  name: string;
  address: string;
  playerId?: string;
  onActionClick?: () => void;
}

export const LinkedCasinoCard: React.FC<LinkedCasinoCardProps> = ({
  name,
  address,
  playerId,
  onActionClick = () => noop,
}) => {
  return (
    <div className="linked-casino-card">
      <div className="info">
        <h3 className="remove-margins body-bold heading">{name}</h3>
        <p className="remove-margins body-small description">{address}</p>
      </div>

      {playerId ? (
        <div className="card-info body-small-bold">
          <IonIcon className="icon" icon={card} />
          {playerId}
        </div>
      ) : (
        <a
          href="javascript:void(0)"
          onClick={() => onActionClick()}
          className="body-small-bold link-player-id "
          data-testid="casinoCardAction"
        >
          Link Player ID
          <IonIcon className="icon" icon={chevronForward} />
        </a>
      )}
    </div>
  );
};

export default LinkedCasinoCard;
