import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface DivideIconProps {
  color: string;
  isOpen: boolean;
}

const DivideIcon: React.FC<DivideIconProps> = ({ color, isOpen }: DivideIconProps) => {
  return (
    <div style={{ color: color }}>
      {!isOpen && (
        <div>
          <span style={{ textTransform: 'capitalize' }}>Show less </span>
          <FontAwesomeIcon
            icon={faCaretUp}
            size="1x"
            color={color}
            className="sort-arrow-insights insight-name-sort-icon"
          />
        </div>
      )}
      {isOpen && (
        <div>
          <span style={{ textTransform: 'capitalize' }}>Show more </span>
          <FontAwesomeIcon
            icon={faCaretDown}
            size="1x"
            className="sort-arrow-insights insight-name-sort-icon"
          />
        </div>
      )}
    </div>
  );
};

export default DivideIcon;
