import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  useIonToast,
} from '@ionic/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { differenceInYears, format, parseISO } from 'date-fns';
import { useForm } from 'react-hook-form';
import { lastValueFrom } from 'rxjs';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { ICasinoSchema } from 'appRedux/models/casinoModels';
import { AuthService } from 'services/auth';
import { IState } from 'appRedux/createStore';
import { CasinoReduxCommandCreator } from 'appRedux/actions/casinoCommandCreator';
import { PlayerService } from 'services/player';
import SmartLoading from 'components/SmartLoading';
import InfoWarning, { InfoWarningProps } from 'components/InfoWarning/InfoWarning';

interface LinkPlayerCardModalProps {
  casinoInfo: ICasinoSchema;
  onSubmitSuccess: () => void;
  onCancel: () => void;
}

interface FormData {
  playerId: string;
  birthday: string;
}

export const validatePlayerId = yup.object().shape({
  playerId: yup.string().required('Player ID is required'),
  birthday: yup
    .string()
    .test('dateOfBirth', 'You must be 18 years or older', (value) => {
      if (!value) {
        return false; // Fail validation if value is undefined or empty
      }

      const formattedDate = format(parseISO(value), 'yyyy-MM-dd');
      return differenceInYears(new Date(), new Date(formattedDate)) >= 18;
    })
    .required('Birthday is required'),
});

export const LinkPlayerCardModal: React.FC<LinkPlayerCardModalProps> = ({
  casinoInfo,
  onSubmitSuccess,
  onCancel,
}: LinkPlayerCardModalProps) => {
  const authService = new AuthService();
  const playerService = new PlayerService();

  const dispatch = useDispatch();
  const casinoCommands = CasinoReduxCommandCreator(dispatch);

  const [linkPlayerLoading, setLinkPlayerLoading] = useState(false);
  const [errorWarning, setErrorWarning] = useState<InfoWarningProps | null>(null);

  const userId = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.appUserPk
  ) as string;

  const {
    handleSubmit,
    register,
    formState: { errors },
    trigger,
  } = useForm<FormData>({
    resolver: yupResolver(validatePlayerId),
    defaultValues: {
      birthday: format(new Date(), 'yyyy-MM-dd'),
    },
  });
  const [presentToast] = useIonToast();

  const onSubmit = async (data: FormData): Promise<void> => {
    setErrorWarning(null);

    try {
      const isValid = await trigger();

      if (isValid) {
        setLinkPlayerLoading(true);
        const formattedDate = format(parseISO(data.birthday), 'yyyy-MM-dd').replace(/-/g, '-');

        await playerService
          .LinkPlayerCard(casinoInfo.casinoId, userId, data.playerId, formattedDate)
          .toPromise()
          .then(async (response) => {
            if (response && response?.errorMsg) {
              setErrorWarning({
                status: 'info',
                heading: response.errorMsg,
                description: '',
              });

              return;
            }

            const linkedResponse = await lastValueFrom(authService.GetLinkedAccounts(userId));

            const linkedAccounts = linkedResponse?.user.linkedAccounts;
            if (linkedAccounts) {
              casinoCommands.SetLinkedCasinos({
                casinoLinkedAccounts: linkedAccounts,
              });

              void presentToast({
                position: 'top',
                duration: 5000,
                color: 'success',
                message: `Successfully added your player ID for ${casinoInfo.displayName}.`,
              });
            }

            onSubmitSuccess();
          });
      } else {
        setErrorWarning({
          status: 'error',
          heading: 'Form is invalid',
          description: 'Please correct form errors.',
        });
      }
    } catch (error) {
      setErrorWarning({
        status: 'info',
        heading: 'Player ID and birthdate do not match',
        description:
          'Please try again. If the problem continues, visit the player desk for assistance.',
      });
    } finally {
      setLinkPlayerLoading(false);
    }
  };

  return (
    <>
      <SmartLoading loading={linkPlayerLoading} />

      <IonCard
        id="link-player-card-modal"
        data-testid="linkCardModal"
        className="modal-container link-player-card-modal"
      >
        <IonCardContent>
          <div className="heading-container">
            <div role="heading" className="heading5 heading">
              Link Player ID
            </div>
            <div role="heading" className="subtitle2 subtitle">
              {casinoInfo.displayName}
            </div>
          </div>

          {errorWarning?.status && (
            <div className="error-card">
              <InfoWarning
                status={errorWarning.status}
                heading={errorWarning.heading}
                description={errorWarning.description}
              />
            </div>
          )}

          <form
            onSubmit={(event) => {
              event.preventDefault();

              handleSubmit(onSubmit)().catch((e) => {
                console.error(e);
              });
            }}
            className="form"
          >
            <div className="fields-container">
              <IonRow>
                <IonCol className="ion-no-padding form-field player-id">
                  <div className="field">
                    <IonLabel className="body label">Player ID</IonLabel>

                    <IonItem className="input" fill="outline">
                      <IonInput
                        placeholder="Enter Player ID"
                        {...register('playerId', {
                          required: 'This is a required field',
                        })}
                      />
                    </IonItem>
                  </div>

                  {errors.playerId?.message && (
                    <div className="field-validation-message body" data-testid="playerId-error">
                      {errors.playerId.message}
                    </div>
                  )}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-no-padding form-field">
                  <div className="field">
                    <IonLabel className="body label">Birthday</IonLabel>
                    <IonItem className="input" fill="outline">
                      <IonInput
                        type="date"
                        {...register('birthday', {
                          required: 'Birthday is required',
                        })}
                      />
                    </IonItem>
                  </div>

                  {errors.birthday?.message && (
                    <div className="field-validation-message body" data-testid="dob-error">
                      {errors.birthday.message}
                    </div>
                  )}
                </IonCol>
              </IonRow>
            </div>

            <IonRow>
              <IonCol>
                <IonButton expand="block" color="primary" type="submit" data-testid="submitButton">
                  Link Player ID
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton expand="block" color="medium" onClick={onCancel}>
                  Maybe Later
                </IonButton>
              </IonCol>
            </IonRow>
          </form>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default LinkPlayerCardModal;
