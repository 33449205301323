import {
  IonButton,
  IonContent,
  IonHeader,
  IonImg,
  IonInput,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import { KeyboardEvent } from 'react';
import SmartLoading from '../../components/SmartLoading';
import useEditProfileForm from './hooks/useEditProfileForm';
import './edit-profile.scss';
import FormFieldError from '../../components/FormFieldError/FormFieldError';

const EditProfile: React.FC = () => {
  const {
    firstName,
    lastName,
    cellNumber,
    birthday,
    email,
    formErrors,
    isSubmitDisabled,
    isUpdatingUserData,
    handleFirstNameChange,
    handleLastNameChange,
    handleEmailChange,
    handleEditProfile,
  } = useEditProfileForm();

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleEditProfile();
    }
  };

  return (
    <IonPage className="app-parent">
      <div className="slotcheck-header">
        <IonHeader className="small-header">
          <IonToolbar>
            <IonButton
              fill="clear"
              onClick={() => {
                history.back();
              }}
              className="icon-back"
              slot="start"
            >
              <IonImg src="assets/images/back-icon.svg" />
            </IonButton>
            <div className="logo header-title">Edit Profile</div>
          </IonToolbar>
        </IonHeader>
      </div>
      <IonContent>
        <div className="edit-profile" onKeyDown={handleKeyDown}>
          <SmartLoading loading={isUpdatingUserData} />
          <div className="app-form">
            <div className="app-form-field">
              <div className="app-form-label">
                <div className="app-form-label-name">First Name *</div>
              </div>
              <div className="app-form-input">
                <IonInput value={firstName} onIonChange={handleFirstNameChange} />
                <FormFieldError message={formErrors.firstNameError} />
              </div>
            </div>

            <div className="app-form-field">
              <div className="app-form-label">
                <div className="app-form-label-name">Last Name *</div>
              </div>
              <div className="app-form-input">
                <IonInput value={lastName} onIonChange={handleLastNameChange} />
                <FormFieldError message={formErrors.lastNameError} />
              </div>
            </div>

            <div className="app-form-field">
              <div className="app-form-label">
                <div className="app-form-label-name">Mobile Number</div>
              </div>
              <div className="app-form-input">
                <IonInput value={cellNumber} disabled />
              </div>
            </div>

            <div className="app-form-field">
              <div className="app-form-label">
                <div className="app-form-label-name">Email Address </div>
                {/* <div className="app-form-label-message verify-text">Verify</div> */}
              </div>
              <div className="app-form-input">
                <IonInput className="email-input" value={email} onIonChange={handleEmailChange} />
                {/* <FormFieldError message={formErrors.emailAddressError} /> */}
              </div>
            </div>

            <div className="app-form-field">
              <div className="app-form-label">
                <div className="app-form-label-name">Birthday</div>
              </div>
              <div className="app-form-input">
                <IonInput value={birthday} disabled />
              </div>
            </div>

            <div className="app-form-button">
              <IonButton disabled={isSubmitDisabled} onClick={handleEditProfile}>
                Update
              </IonButton>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EditProfile;
