/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useIonModal } from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
import { components } from 'react-select';
import { Timeframe } from '../../../config/Timeframes';
import SlotProComingSoon from '../../SlotProComingSoon/SlotProComingSoon';
import { useSelector } from 'react-redux';
import { IState } from 'appRedux/createStore';
import { playerTypeEnum } from 'common/commonEnum';

const { Option } = components;

const getOptionIcon = (data: Timeframe | any, playerType: playerTypeEnum) => {
  const { playerTypeAccess } = data;
  if (playerType === playerTypeEnum.slotCheckPro) return;
  if (playerTypeAccess.includes(playerType)) return;

  switch (playerTypeAccess[0]) {
    case playerTypeEnum.slotCheckPlus:
      return <i className="slot-check-plus-option" />;
    case playerTypeEnum.slotCheckPro:
      return <i className="slot-check-pro-option" />;
    default:
      break;
  }
};

const FilterDropdownSelectOption = ({ data, ...rest }: any) => {
  const isProUser = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.isProUser
  ) as boolean;

  const { playerTypeAccess } = data;
  const { innerProps } = rest;
  const { onClick: reactSelectOnClick } = innerProps || {};

  const history = useHistory();
  const location = useLocation();
  const playerType = isProUser ? playerTypeEnum.slotCheckPro : playerTypeEnum.slotCheck;
  const isOptionAvailable = playerTypeAccess?.includes(playerType);
  const optionLabelCls = `dropdown__text dropdown__label filters-selector-option ${
    isOptionAvailable ? 'active-select-option' : ''
  }`;

  const slotProSubscription = {
    enabled: true,
  };

  const [presentSlotPro, dismissSlotPro] = useIonModal(SlotProComingSoon, {
    // subscriptionPlanData: subscriptionPlanData,
    onClosePopUp: () => dismissSlotPro(),
    onDismiss: (data: string, role: string) => dismissSlotPro(data, role),
  });

  const handleOptionClick = (e: Event) => {
    if (!isOptionAvailable) {
      if (data?.playerTypeAccess?.includes(playerTypeEnum.slotCheckPlus)) {
        history.push('/auth/stripe', { previousPage: location.pathname });
      } else {
        if (slotProSubscription?.enabled === true) {
          history.push('/auth/stripe', { previousPage: location.pathname });
        }
      }
    } else {
      reactSelectOnClick(e);
    }
  };

  return (
    <Option {...rest} innerProps={{ onClick: handleOptionClick }}>
      <span className={optionLabelCls}>
        <div className="dropdown-option-label">{data.label}</div>
        <div className={['dropdown-label-icon ', isProUser ? ' dropdown-icon-none' : ''].join('')}>
          {getOptionIcon(data, playerType)}
        </div>
      </span>
    </Option>
  );
};

export default FilterDropdownSelectOption;
