import { IonContent, IonPage } from '@ionic/react';
import { IState, ITabProperties } from 'appRedux/createStore';
import { IFilterType, ListReportEnum, ListReportType } from 'common/common';
import CasinoNavBar from 'components/CasinoNavBar';
import DropdownBar from 'components/DropdownBar/DropdownBar';
import useWhiteLabelStyles, { WHITE_LABEL_TYPE_ENUM } from 'hooks/useWhiteLabelStyles';
import { FavoritesLinkBar } from 'pages/Favorites/components/FavoritesLinkBar';
import ListTabNew from 'pages/ListTab/New_ListTab';
import { useState } from 'react';
import { useSelector } from 'react-redux';

interface IHotListTab {
  selectedPage: ListReportEnum;
}

const HotListTab: React.FC<IHotListTab> = ({ selectedPage }: IHotListTab) => {
  const isLandscape = useSelector<IState>(
    (state) => state.app.persistedState.orientationState?.isLandscape
  ) as boolean;

  const [currentTab, setCurrentTab] = useState<ListReportType>(selectedPage);

  const tabFilters = useSelector<IState>(
    (state) => state.app.persistedState.filterState[currentTab as IFilterType]
  ) as ITabProperties;

  const { advancedViewInsights } = tabFilters;

  const fieldSelectorValues = advancedViewInsights?.map((insight: string) => ({
    value: insight,
    label: insight,
  }));
  const styles = useWhiteLabelStyles(WHITE_LABEL_TYPE_ENUM.CANVAS);
  return (
    <IonPage>
      {!isLandscape && <CasinoNavBar hideSearch hideNotify />}
      <IonContent>
        <div className="sticky-top bg-primary" style={styles}>
          <FavoritesLinkBar />
          <DropdownBar
            id={currentTab}
            isShowShortName={true}
            fieldSelectorValues={fieldSelectorValues}
          />
        </div>
        <ListTabNew reportType={currentTab} />
      </IonContent>
    </IonPage>
  );
};

export default HotListTab;
