import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonPage,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';
import { KeyboardEvent } from 'react';
import { toastConfig, ToastType } from '../../config/ToastConfig';
import usePasswords from './hooks/usePasswords';
import { updatePassword } from './updatePassword';
import './change-password.scss';

const ChangePassword = () => {
  const {
    currentPassword,
    newPassword,
    confirmPassword,
    showCurrentPassword,
    showNewPassword,
    showConfirmPassword,
    handleShowCurrentPassword,
    handleShowNewPassword,
    handleShowConfirmPassword,
    handleCurrentPasswordChange,
    handleNewPasswordChange,
    handleConfirmPasswordChange,
    clearFields,
    isPasswordValid,
  } = usePasswords();

  const [presentToast] = useIonToast();
  const showToast = ({ type = 'success', message }: { type?: ToastType; message: string }) => {
    toastConfig.color = type;
    toastConfig['message'] = message;
    void presentToast(toastConfig);
  };

  const handleUpdatePassword = async () => {
    if (newPassword !== confirmPassword) {
      showToast({ type: 'danger', message: 'Failed to match new password' });
      return;
    }

    if (!isPasswordValid()) {
      showToast({ type: 'danger', message: "New password doesn't match requirements" });
      return;
    }

    await updatePassword(currentPassword, newPassword)
      .then(() => {
        showToast({ message: 'Your password has been changed' });
        clearFields();
      })
      .catch((e) => {
        console.error(e);
        showToast({ type: 'danger', message: 'Wrong current password' });
      });
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      void handleUpdatePassword();
    }
  };

  return (
    <IonPage className="app-parent">
      <div className="slotcheck-header">
        <IonHeader className="small-header">
          <IonToolbar>
            <IonButton
              fill="clear"
              onClick={() => {
                history.back();
              }}
              className="icon-back"
              slot="start"
            >
              <IonImg src="assets/images/back-icon.svg" />
            </IonButton>
            <div className="logo header-title">Change Password</div>
          </IonToolbar>
        </IonHeader>
      </div>
      <IonContent>
        <div className="change-password" onKeyDown={handleKeyDown}>
          <div className="change-password-label">Current Password</div>
          <div className="change-password-input">
            <input
              type={showCurrentPassword ? 'text' : 'password'}
              value={currentPassword}
              onChange={handleCurrentPasswordChange}
            />
            <div className="input-icon-box" onClick={handleShowCurrentPassword}>
              <IonIcon icon={showCurrentPassword ? eyeOutline : eyeOffOutline} />
            </div>
          </div>

          <div className="change-password-label">New Password</div>
          <div className="change-password-input">
            <input
              type={showNewPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={handleNewPasswordChange}
            />
            <div className="input-icon-box" onClick={handleShowNewPassword}>
              <IonIcon icon={showNewPassword ? eyeOutline : eyeOffOutline} />
            </div>
          </div>

          <div className="change-password-label">Confirm Password</div>
          <div className="change-password-input">
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            <div className="input-icon-box" onClick={handleShowConfirmPassword}>
              <IonIcon icon={showConfirmPassword ? eyeOutline : eyeOffOutline} />
            </div>
          </div>
          <div className="change-password-label">
            Password must include a number, an uppercase letter, a lowercase letter, and a special
            character, and be at least 8 characters long
          </div>
          <div className="password-update-button">
            <IonButton
              disabled={!currentPassword || !newPassword}
              onClick={() => {
                void handleUpdatePassword();
              }}
            >
              Update
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ChangePassword;
