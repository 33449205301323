import { config } from '../../../config/config';
export const numberRegex = /^[0-9]+$/;
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
export const passwordRequirements =
  'Password must include a number, an uppercase letter, a lowercase letter, and a special character (= + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ `)';
export const passwordSubtitle =
  'Password must include a number, an uppercase letter, a lowercase letter, and a special character, and be at least 8 characters long';
export const nameRegex = /^([A-Za-z/g]+\s)*[A-Za-z/g]+$/;
export const nameRequirement = 'Invalid characters';
export const zipcodeRegex = /^(\d{5}(\d{4})?)?$/;

export const getCurrentYear = () => {
  return new Date().getFullYear().toString();
};

export const formatPhoneNumber = (value: any) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const titleCase = (str: string | null): string => {
  if (str === null || str === '') return '';
  else str = str.toString();

  str = str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  return str.replace(/\/[a-z]*/g, function (txt) {
    return txt.charAt(0) + txt.charAt(1).toUpperCase() + txt.substr(2);
  });
};

export const formatNumber: any = (value: number | string) => {
  let returnValue: string = '0';
  if (Number.isFinite(value)) {
    returnValue = value.toString();
  }
  if (typeof value === 'string') {
    returnValue = value;
  }
  // Add commas
  return returnValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatRoundOffNumber: any = (value: number) => {
  let returnValue: string = '0';
  if (value) {
    returnValue = Math.round(value).toString();
  }
  // Add commas
  return returnValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const percentage: any = (percent: any, total: any) => {
  return ((percent / total) * 100).toFixed(2) + '%';
};

export const formatPercentage: any = (value: number) => {
  let returnValue: string = '0';

  if (value) {
    returnValue = (value * 100).toFixed(0);
  }

  // Add commas
  returnValue = returnValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%';
  // Remove trailing zero
  returnValue = returnValue.replace('.0%', '%');

  return returnValue;
};

export const formatCurrency: any = (value: string) => {
  let numDecimals = 2;
  let returnValue: string = '0';
  if (value !== undefined && value !== null) {
    if (parseFloat(value) >= 10) {
      numDecimals = 0;
    }
    returnValue = parseFloat(value).toFixed(numDecimals);
  }

  // Add commas
  return '$' + returnValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getColorInsights: any = (insightValue: string) => {
  const insightColor: any = config.slotInsights.find(
    (insight: any) => insight.value === insightValue
  )?.backgroundColor;
  const inlineStyle = { ['color' as any]: insightColor };
  return inlineStyle;
};

export const kFormatter: any = (num: number) => {
  if (num > 999 && num < 1000000) {
    return Math.round(num / 1000) + 'K'; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return Math.round(num / 1000000) + 'M'; // convert to M for number from > 1 million
  } else if (num < 900) {
    return Math.round(num); // if value < 1000, nothing to do
  }
};

export const formatPercentageWithOutPercent: any = (value: number) => {
  let returnValue: string = '0';

  if (value) {
    returnValue = value.toFixed(0);
  }

  // Add commas
  returnValue = returnValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%';
  // Remove trailing zero
  returnValue = returnValue.replace('.0%', '%');

  return returnValue;
};

export const centDollarConversion: any = (value: string) => {
  let returnValue: string = '0';
  if (value !== undefined && value !== null) {
    returnValue = (parseFloat(value) / 100 || 0).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }

  return returnValue;
};
