import { IonRow, IonCheckbox, IonIcon } from '@ionic/react';
import { Options, components } from 'react-select';
import shallowEqual from 'shallowequal';
import { Component } from 'react';

const { Option } = components;

// TODO this likely can be refactored to a functional component
export default class DropDownMultiselectOption extends Component<any> {
  shouldComponentUpdate(nextProps: any) {
    if (this.props.cx !== nextProps.cx && this.props.cx() === nextProps.cx()) {
      // console.warn("cx prop has changed, but it's return value hasn't");
    }
    if (
      this.props.getValue !== nextProps.getValue &&
      this.props.getValue() === nextProps.getValue()
    ) {
      // console.warn("getValue prop has changed, but it's return value hasn't");
    }
    return !shallowEqual(this.props, nextProps);
  }

  render() {
    const insightSymbolTextStyle = {
      color: this.props.data.color,
    };
    return (
      <Option
        label={''}
        type={'option'}
        hasValue={false}
        isMulti={false}
        isRtl={false}
        options={[]}
        {...this.props}
      >
        <div className="multi-select-option dropdown">
          <IonRow class="ion-align-items-center">
            <IonCheckbox className="checkbox" mode="md" checked={this.props.isSelected} />
            {this.props.data.asset_type === 'text' && (
              <span style={insightSymbolTextStyle}>{this.props.data.icon_text}</span>
            )}
            {this.props.data.asset_type === 'image' && (
              <IonIcon icon={this.props.data.icon} className="custom-option" />
            )}
            <span className="dropdown__text">{this.props.data.label}</span>
          </IonRow>
        </div>
      </Option>
    );
  }
}
