import { heatBeanRatings } from 'config/HeatBeanRatings';
import { HeatBeanObjectKey } from 'config/config';
import React from 'react';

export interface IHeatTile {
  heatRating: number | null;
  isLabel?: boolean;
  isVolatilityLabel?: boolean;
  isLight?: boolean;
  size?: string;
}

export const HeatTile: React.FC<IHeatTile> = ({
  heatRating,
  isLabel = false,
  isVolatilityLabel = false,
  isLight = false,
  size = '20px',
}) => {
  if (!heatRating) return <></>;

  const heatInfo = heatBeanRatings[heatRating as HeatBeanObjectKey];

  if (!heatInfo?.is_show) {
    return <></>;
  }

  if (isLabel) {
    return <span>{heatInfo.label}</span>;
  }

  if (isVolatilityLabel) {
    return (
      <>
        <span>{heatInfo.text_lbl_volatility}</span>
        <div
          role="img"
          aria-label={heatInfo.text_lbl_volatility}
          style={{
            width: size,
            height: size,
            backgroundColor: isLight ? heatInfo.light_color : heatInfo.color,
            borderRadius: '4px',
          }}
        ></div>
      </>
    );
  }

  return (
    <div
      role="img"
      aria-label={heatInfo.text_lbl_volatility}
      style={{
        width: size,
        height: size,
        backgroundColor: isLight ? heatInfo.light_color : heatInfo.color,
        borderRadius: '4px',
      }}
    ></div>
  );
};
