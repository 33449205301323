import { IonIcon } from '@ionic/react';
import clsx from 'clsx';
import { chevronForward, globe } from 'ionicons/icons';
import React from 'react';

interface CasinoCardProps {
  logoIcon: string;
  name: string;
  address: string;
  website: string;
  actionText: string;
  onActionClick: () => void;
  isComingSoon?: boolean;
  isPlayerLinked?: boolean;
  hasAccess?: boolean;
  timeLeft?: number;
}

export const CasinoCard: React.FC<CasinoCardProps> = ({
  isPlayerLinked = false,
  isComingSoon = false,
  hasAccess = false,
  timeLeft = 0,
  name,
  address,
  website,
  logoIcon,
  actionText,
  onActionClick,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.open(website, '_blank');
  };

  const onActionClickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onActionClick();
  };

  const formatTimeLeft = (remainingTime: number | null): string => {
    if (remainingTime === null) {
      return '';
    }

    if (remainingTime <= 0) {
      return 'Link Player Card';
    } else if (remainingTime <= 24) {
      return `${Math.ceil(remainingTime)} hour${remainingTime > 1 ? 's' : ''} remaining`;
    } else if (remainingTime <= 48) {
      return '1 day left';
    } else if (remainingTime < 72) {
      return '2 days left';
    } else if (remainingTime == 72) {
      return '';
    }

    return 'Link Player Card';
  };

  return (
    <div className="casino-card">
      <div className="casino-logo">
        <img src={logoIcon} />
      </div>

      <div className="card-info">
        <div className="casino-info">
          <div>
            <h3 className="caption-text heading">{name}</h3>

            <p className="remove-margins location body-small">{address}</p>
          </div>

          <a
            onClick={handleClick}
            target="_blank"
            rel="noreferrer"
            className="link-website body-small"
          >
            <IonIcon className="icon" icon={globe} />
            Visit Website
          </a>
        </div>

        {isComingSoon ? (
          <div className="body-small action coming-soon">Coming Soon</div>
        ) : (
          <div className="action">
            <div className="link">
              <span
                onClick={onActionClickHandler}
                className="body-small-bold action action-link"
                data-testid="casinoCardAction"
              >
                {actionText}
                <IonIcon className="icon" icon={chevronForward} />
              </span>
            </div>
            {!isPlayerLinked && (
              <div
                className={clsx('body-tiny', 'info-text', {
                  warning: hasAccess,
                })}
              >
                {formatTimeLeft(timeLeft)}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
