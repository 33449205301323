import { IonIcon } from '@ionic/react';
import { chevronBack, chevronForward } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { useDispatch, useSelector } from 'react-redux';

import { IState } from 'appRedux/createStore';
import { useDynamicCSS } from 'hooks/useDynamicCSS';
import useWhiteLabelStyles, { WHITE_LABEL_TYPE_ENUM } from 'hooks/useWhiteLabelStyles';
import { ICasinoReport, IHistorySlotInfo } from 'appRedux/models/casinoModels';
import { SlotHistoryReduxCommandCreator } from 'appRedux/actions/slotHistoryCommandCreator';
import useFavorites from 'common/reactHooks/useFavorites';

import './machine-name-header.scss';
import { IUserFavorite } from 'appRedux/models/playerModels';
import { LikeButton } from 'pages/Favorites/components/LikeButton';

interface MachineNavProps {
  selectedSlotData: ICasinoReport;
}
const MachineNameHeader: React.FC<MachineNavProps> = ({ selectedSlotData }: MachineNavProps) => {
  const dispatch = useDispatch();
  const historyCommands = SlotHistoryReduxCommandCreator(dispatch);

  const slotsNavRef = useRef(null);
  const [showSearchField, setShowSearchField] = useState(false);

  const [isFavoriteSlot, setIsFavoriteSlot] = useState(false);

  const [marqueeRun, setMarqueeRun] = useState(true);
  const [marqueePause, setMarqueePause] = useState(false);
  const [marqueeDelay, setMarqueeDelay] = useState(1);

  const { slotNumbers, activeSlotNumber } = useSelector<IState>(
    (state) => state.app.persistedState.historyState
  ) as IHistorySlotInfo;

  const casinoId = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema?.kpCasinoPk
  ) as number;

  const {
    handleInsertFavorite,
    handleDeleteFavorite,
    fetchUserFavorites,
    fetchSlotFavorites,
    slotFavoriteCount,
  } = useFavorites({});

  const userId = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.appUserPk
  ) as string;
  const favorites = useSelector<IState>(
    (state) => state.app.persistedState.favorites.favoritesList
  ) as IUserFavorite[];

  const styles = useWhiteLabelStyles(WHITE_LABEL_TYPE_ENUM.CANVAS);
  const btnstyles = useWhiteLabelStyles(WHITE_LABEL_TYPE_ENUM.BUTTON);

  const css = `
  .header-machine .machine-nav .machine-nav-likes .like-btn {
        filter : ${casinoId === 1 ? 'brightness(0) invert(1)' : 'brightness(1) invert(1)'}
    }
  .header-machine .machine-nav .machine-nav-likes .like-active {
    filter: unset
   }
  `;
  useDynamicCSS(css);

  useEffect(() => {
    if (userId) {
      fetchUserFavorites(userId);
    }
  }, [fetchUserFavorites, userId]);

  useEffect(() => {
    if (favorites && favorites.length > 0 && activeSlotNumber) {
      const isFavorite = favorites.some(
        (favorite) => favorite.slotNumber === Number(activeSlotNumber)
      );

      setIsFavoriteSlot(isFavorite);
      fetchSlotFavorites(activeSlotNumber.toString());
    }
  }, [favorites, activeSlotNumber]);
  const goForward = () => {
    const currentIndex = slotNumbers.indexOf(activeSlotNumber);
    const nextIndex = (currentIndex + 1) % slotNumbers.length;
    if (nextIndex === 0 || slotNumbers.length <= 1) {
      return undefined;
    }
    return slotNumbers[nextIndex];
  };

  const goPrevious = () => {
    const currentIndex = slotNumbers.indexOf(activeSlotNumber);
    const previousIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : slotNumbers.length - 1;
    if (currentIndex === 0 || slotNumbers.length <= 1) {
      return undefined;
    }
    return slotNumbers[previousIndex];
  };

  const goToNextSlot = () => {
    const forwardNumber = goForward();
    if (forwardNumber) {
      historyCommands.UpdateActiveSlotNumber({
        activeSlotNumber: forwardNumber,
      });
    }
  };

  const goToPreviousSlot = () => {
    const previousNumber = goPrevious();
    if (previousNumber) {
      historyCommands.UpdateActiveSlotNumber({
        activeSlotNumber: previousNumber,
      });
    }
  };

  const handleFavoriteBtnClick = () => {
    if (userId) {
      if (!isFavoriteSlot) {
        handleInsertFavorite(String(activeSlotNumber), userId);
      } else {
        handleDeleteFavorite(String(activeSlotNumber), userId);
      }
    }
  };

  useEffect(() => {
    const observerd = new IntersectionObserver((entries) => {
      entries.forEach(({ isIntersecting }) => {
        if (!isIntersecting) setShowSearchField(false);
      });
    });
    observerd.observe(slotsNavRef?.current!);

    const el = document.querySelector('.machine-nav')!;
    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1),
      { threshold: [1] }
    );
    observer.observe(el);
    return () => {
      observerd.disconnect();
      observer.disconnect();
    };
  }, []);

  const onclickMarquee = () => {
    setMarqueeRun(true);
    setMarqueePause(!marqueePause);
    setMarqueeDelay(0);
  };

  const onCompleteMarquee = () => {
    setMarqueeRun(false);
    setMarqueePause(true);
  };

  return (
    <div className="header-machine">
      <div className="machine-nav" style={styles} ref={slotsNavRef}>
        <div className="machine-nav-next-prev">
          <button
            onClick={goToPreviousSlot}
            className="machine-nav-next-prev-btn"
            disabled={goPrevious() === undefined}
          >
            <IonIcon
              style={goPrevious() ? btnstyles : {}}
              icon={chevronBack}
              className="machine-nav-next-prev-icon"
            />
          </button>
          <button
            onClick={goToNextSlot}
            className="machine-nav-next-prev-btn"
            disabled={goForward() === undefined}
          >
            <IonIcon
              style={goForward() ? btnstyles : {}}
              icon={chevronForward}
              className="machine-nav-next-prev-icon"
            />
          </button>
        </div>
        <div className="machine-name-header">
          <div className="machine-name-header-box">
            <div className="machine-name-header-number">{selectedSlotData?.slot_number}</div>
            <div
              className="machine-name-header-name"
              onClick={() => {
                onclickMarquee();
              }}
            >
              <Marquee
                play={marqueeRun && !marqueePause}
                delay={marqueeDelay}
                gradient={false}
                speed={60}
                onCycleComplete={() => {
                  onCompleteMarquee();
                }}
              >
                <span className="gamename-text">{selectedSlotData?.game_name}</span>
              </Marquee>
            </div>
            <div className="machine-name-header-number">{selectedSlotData?.denom}</div>
          </div>
        </div>

        <div className="machine-nav-likes">
          <span className="like-button">
            <LikeButton slotNumber={String(selectedSlotData?.slot_number)} />
          </span>
          <span style={btnstyles} className="machine-nav-likes-text">
            {slotFavoriteCount}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MachineNameHeader;
