import { IonIcon } from '@ionic/react';
import { FC } from 'react';
import { DropDownType } from './DropDownTypes';
import { FilterTypeEnum } from 'common/common';

interface DropDownSelectValueProps {
  data: {
    asset_type?: string;
    color?: string;
    icon?: any;
    icon_text?: string;
    label?: string;
    description?: string;
    value: string;
  };
  displayDescription?: boolean;
  dropDownType: DropDownType;
  styleClass: string;
  id: string;
}

const DropDownSelectValue: FC<DropDownSelectValueProps> = ({
  data,
  displayDescription = false,
  dropDownType,
  styleClass,
  id
}) => {

  const insightSymbolTextStyle = {
    color: data?.color,
  };
  return (
    <div
      className={[
        'dropdown ',
        dropDownType === DropDownType.insights ? ' dropdown__wrap' : '',
      ].join('')}
    >
      {id !== FilterTypeEnum.graphfilter && data?.value !== 'jackpot_value' && (
        <>
          {/* {data.asset_type === 'text' && (
            <span>{data.icon_text}</span>
          )} */}
          {data.asset_type === 'image' && (
            <IonIcon icon={data.icon} className='custom-option' />
          )}
        </>

      )}

      <span className={['dropdown__text dropdown__wrap ', styleClass].join('')}  >
        {displayDescription ? data.description : data.label}
      </span>
    </div>
  );
};

export default DropDownSelectValue;
