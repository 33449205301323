import { faChainBroken } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';

interface IErrorPage {
  message: string;
  icon?: ReactNode;
}
export const ErrorPage = ({
  message,
  icon = <FontAwesomeIcon icon={faChainBroken} />,
}: IErrorPage) => {
  return (
    <div className="m-error-page">
      <div className="content-container">
        <span className="message">{message}</span>
        <div className="icon-container">{icon}</div>
      </div>
    </div>
  );
};
