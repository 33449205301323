import { Dispatch } from 'react';
import { IAction } from 'appRedux/reducers';
import { IHotColdCollapsedState } from 'appRedux/models/slotModels';
import { SectionIdType } from 'appRedux/reducers/slotReducer';

const slotReduxKey = 'SLOT';

export const slotActionTypes = {
  SET_DEFAULT_SECTION: `${slotReduxKey}-SET_DEFAULT_SECTION`,
  MOVE_SECTION: `${slotReduxKey}-MOVE_SECTION`,
  TOGGLE_HOT_COLD_COLLAPSED: `${slotReduxKey}-TOGGLE_HOT_COLD_COLLAPSED`,
  SET_SELECTED_SLOTS_NAME: `${slotReduxKey}-SET_SELECTED_SLOTS_NAME`,
  SET_SELECTED_SLOTS: `${slotReduxKey}-SET_SELECTED_SLOTS`,
  CLEAR_SELECTED_SLOTS: `${slotReduxKey}-CLEAR_SELECTED_SLOTS`,
};

export const SlotReduxCommandCreator = (dispatch: Dispatch<IAction>) => {
  return {
    SetDefaultSection(state: { sectionId: SectionIdType }) {
      dispatch({
        type: slotActionTypes.SET_DEFAULT_SECTION,
        state,
      });
    },
    MoveSection(state: { sectionId: string; newPosition: number; sections: string[] }) {
      dispatch({
        type: slotActionTypes.MOVE_SECTION,
        state,
      });
    },
    ToggleHotColdCollapsed(state: IHotColdCollapsedState) {
      dispatch({
        type: slotActionTypes.TOGGLE_HOT_COLD_COLLAPSED,
        state,
      });
    },
    SelectedSlotsName(slotName: string) {
      dispatch({
        type: slotActionTypes.SET_SELECTED_SLOTS_NAME,
        state: slotName,
      });
    },
    SelectedSlots(selectedSlots: string[]) {
      dispatch({
        type: slotActionTypes.SET_SELECTED_SLOTS,
        state: selectedSlots,
      });
    },
    ClearSelectedSlots() {
      dispatch({
        type: slotActionTypes.CLEAR_SELECTED_SLOTS,
      });
    },
  };
};
