import { useCallback, useEffect, useState } from 'react';
import { intersectionBy } from 'lodash';
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { IDenomination } from '../../../common/types';

import './denominationSelector.scss';
import { FilterTypeEnum, IFilterType } from 'common/common';
import { IState, ITabProperties } from 'appRedux/createStore';
import { useSelector } from 'react-redux';
interface DenomSelectorProps {
  placeholder?: string;
  onSelect: (denominations: IDenomination[]) => void;
  onMultipleSelect?: (rowData: any) => void;
  orientation?: boolean;
  options: IDenomination[];
}

const DenomSelector: React.FC<DenomSelectorProps> = ({ onSelect, options }) => {
  const blockEl = 'm-multi-select';
  const tabFilters = useSelector<IState>(
    (state) => state.app.persistedState.filterState[FilterTypeEnum.hottestSlots as IFilterType]
  ) as ITabProperties;
  const { denominations } = tabFilters;
  const getMappedDenominationState = useCallback(() => {
    return denominations.map((m: IDenomination) => m.field);
  }, []);

  const [displayAll, setDisplayAll] = useState<Array<any>>(getMappedDenominationState());
  const [selectedLabel, setSelectedLabel] = useState<string>('');
  useEffect(() => {
    if (options.length === denominations.length) {
      setSelectedLabel('All Denomination');
    } else {
      setSelectedLabel(denominations?.map((denom: { label: string }) => denom.label).join(', '));
    }
  }, [denominations]);

  const changeHandler = (ev: { detail: { value: Array<number> } }) => {
    const mapped: Array<IDenomination> = [];
    if (Array.isArray(ev?.detail?.value)) {
      ev?.detail?.value.forEach((el) => {
        const mapData: IDenomination = options.find((x) => x.field === el)!;
        mapped.push(mapData);
      });
      const interSect = intersectionBy(options, mapped, 'field');
      if (interSect.length === options.length) {
        setDisplayAll(ev?.detail.value);
        onSelect(interSect);
        setSelectedLabel('All Denomination');
      } else {
        setDisplayAll(ev?.detail.value);
        onSelect(interSect);
        setSelectedLabel(mapped?.map((denom) => denom.label).join(', '));
      }
    }
  };

  return (
    <div className={blockEl}>
      <IonItem lines="none" color="transparent" className="denom-class ion-no-padding">
        <IonLabel className="lbl-denom-disable">Denomination</IonLabel>
        <IonSelect
          className={`${blockEl}__select`}
          placeholder="Denominations"
          multiple={true}
          onIonChange={changeHandler}
          value={displayAll}
          selectedText={selectedLabel}
        >
          {options?.map((opt, index) => {
            return (
              <IonSelectOption key={`option-key-${index}`} value={opt.field}>
                {opt.label}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      </IonItem>
    </div>
  );
};

export default DenomSelector;
