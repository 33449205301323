import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  useIonToast,
} from '@ionic/react';
import { catchError, finalize, map, tap } from 'rxjs';
import { close, gitCompareOutline } from 'ionicons/icons';

import { IState } from 'appRedux/createStore';
import { CasinoService } from 'services/casino';
import { OrderByEnum } from 'common/common';
import useSlotDetailHistory from 'globalServices/useSlotDetailHistory';

import SmartLoading from 'components/SmartLoading';
import { ListHeader } from './components/ListHeader';
import CasinoNavBar from 'components/CasinoNavBar';
import InsightFilter, { insightOptions } from 'components/Filters/InsightFilter';
import TimeframeFilter from 'components/Filters/TimeframeFilter';

import { ListItem } from './components/ListItem';
import { FavoritesLinkBar } from './components/FavoritesLinkBar';
import {
  InsightDisplayType,
  InsightFilterEnum,
  TimeframeFilterEnum,
} from 'appRedux/models/favoritesModels';
import { SlotReduxCommandCreator } from 'appRedux/actions/slotCommandCreator';
import { SectionID } from 'appRedux/reducers/slotReducer';
import { FavoritesReduxCommandCreator } from 'appRedux/actions/favoritesCommandCreator';

interface ISlotInformation {
  price: string;
  isActiveSlot: boolean;
  slotNumber: string;
  slotName: string;
  heatValue: number;
  heatRating: number;
}

export const Favorites: React.FC = () => {
  const casinoService = new CasinoService();

  const [slotList, setSlotList] = useState<ISlotInformation[]>([]);
  const [isSlotListLoading, setIsSlotListLoading] = useState<boolean>(false);
  const [heatPercentageSortDirection, setHeatPercentageSortDirection] = useState<OrderByEnum>(
    OrderByEnum.Desc
  );
  const history = useHistory();
  const ionInfiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null);
  const { addToSlotHistory } = useSlotDetailHistory();
  const { userId, casinoId, insightFilter, timeframeFilter, insightDisplayType, favoritesList } =
    useSelector((state: IState) => ({
      userId: state.app.persistedState.userInfo?.appUserPk,
      casinoId: state.app.persistedState.casinoSchema?.casinoId,
      insightFilter: state.app.persistedState.favorites.insightFilter,
      timeframeFilter: state.app.persistedState.favorites.timeframeFilter,
      insightDisplayType: state.app.persistedState.favorites.displayType,
      favoritesList: state.app.persistedState.favorites.favoritesList,
    }));
  const isProUser = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.isProUser
  ) as boolean;

  const [slotsToCompare, setSlotsToCompare] = useState<string[]>([]);
  const [isComparing, setIsComparing] = useState(false);

  const dispatch = useDispatch();
  const detailCommand = SlotReduxCommandCreator(dispatch);
  const favoritesCommands = FavoritesReduxCommandCreator(dispatch);

  useEffect(() => {
    if (!isProUser) {
      favoritesCommands.SetInsightFilter(InsightFilterEnum.Spins);
      favoritesCommands.SetTimeframeFilter(TimeframeFilterEnum.ThirtyDays);

      const displayType = getInsightDisplayType(InsightFilterEnum.Spins);
      if (displayType) {
        favoritesCommands.SetInsightDisplayType(displayType);
      }
    }
  }, [favoritesCommands, isProUser]);

  const getInsightDisplayType = (value: InsightFilterEnum): InsightDisplayType | undefined => {
    const option = insightOptions.find((option) => option.value === value);

    return option?.displayType;
  };

  const [presentToast] = useIonToast();
  useEffect(() => {
    if (!userId || !casinoId) return;

    setSlotList([]);

    setIsSlotListLoading(true);
    const subscription = casinoService
      .UsersListReport({
        appUserFk: userId,
        slot: 0,
        casinofk: casinoId,
        insight: insightFilter,
        orderby: heatPercentageSortDirection,
        reportType: 'favorites',
        timeframe: timeframeFilter,
        limit: 100,
        pageNumber: 0,
      })
      .pipe(
        map((slotInformationList) => {
          return (
            slotInformationList?.DataSet?.map(
              (slotInformation): ISlotInformation => ({
                price: slotInformation.denom,
                isActiveSlot: Boolean(slotInformation.is_active) ?? false,
                slotNumber: String(slotInformation.slot_number),
                slotName: slotInformation.game_name,
                heatValue: slotInformation[insightFilter],
                heatRating: slotInformation[`${insightFilter}_ntile`] || 0,
              })
            ) ?? []
          );
        }),
        tap((slotInformationList) => {
          setSlotList((prevItems) => [...prevItems, ...slotInformationList]);
        }),
        catchError(() => {
          presentToast({
            position: 'top',
            duration: 5000,
            color: 'danger',
            message: 'Failed to get Favorites list.',
          }).catch(() => null);

          return [];
        }),
        finalize(() => {
          setIsSlotListLoading(false);

          ionInfiniteScrollRef.current?.complete().catch(() => null);
        })
      )
      .subscribe();

    return () => subscription?.unsubscribe();
  }, [
    userId,
    casinoId,
    insightFilter,
    timeframeFilter,
    heatPercentageSortDirection,
    favoritesList,
  ]);

  const onGoToDetailClick = (slotNumber: string) => {
    addToSlotHistory(parseInt(slotNumber));
    detailCommand.SetDefaultSection({
      sectionId: SectionID.Graph,
    });
    history.push('/detail');
  };

  const onHeatColumnClick = () => {
    setHeatPercentageSortDirection((prevOrder) =>
      prevOrder === OrderByEnum.Asc ? OrderByEnum.Desc : OrderByEnum.Asc
    );
  };

  const onSelectRow = (newSlotNumber: string) => {
    setSlotsToCompare((currentSlots) => {
      const slotIndex = currentSlots.indexOf(newSlotNumber);

      if (slotIndex >= 0) {
        const newSlots = [...currentSlots];
        newSlots.splice(slotIndex, 1);

        return newSlots;
      } else {
        return [...currentSlots, newSlotNumber];
      }
    });
  };

  const isSlotSelected = (slotNumber: string): boolean => {
    return slotsToCompare.includes(slotNumber);
  };

  const displayedSlots = isComparing
    ? slotList.filter((slot) => slotsToCompare.includes(slot.slotNumber))
    : slotList;

  const onCompareSlot = () => {
    setIsComparing(true);
  };

  const clearComparedSlots = () => {
    setIsComparing(false);
    setSlotsToCompare([]);
  };

  return (
    <IonPage>
      <CasinoNavBar hideSearch hideNotify />

      <FavoritesLinkBar />

      <div>
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol size={insightFilter === InsightFilterEnum.PayoutToPlayer ? '12' : '6'}>
              <InsightFilter />
            </IonCol>

            {/* If POP, remove Timeframe filter from view */}
            {insightFilter !== InsightFilterEnum.PayoutToPlayer && (
              <IonCol size="6">
                <TimeframeFilter
                  removeSinceLastJackpot={insightFilter === InsightFilterEnum.Jackpot}
                />
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      </div>

      <IonContent>
        <IonGrid className="ion-no-padding favorites-container">
          <div className=" favorites-container__list">
            {isSlotListLoading && <SmartLoading loading={true} />}

            {slotList?.length > 0 && !isSlotListLoading && (
              <>
                <IonRow>
                  <IonCol size="12" className="ion-text-center">
                    <h1 className="heading6 favorites-container__heading">My Favorite Slots</h1>
                    {slotsToCompare.length > 0 && (
                      <>
                        {!isComparing && (
                          <IonButton size="small" onClick={onCompareSlot}>
                            <IonIcon slot="start" icon={gitCompareOutline}></IonIcon>
                            Compare Slots
                          </IonButton>
                        )}
                        <IonButton size="small" fill="outline" onClick={clearComparedSlots}>
                          <IonIcon slot="start" icon={close}></IonIcon>
                          Clear Slots
                        </IonButton>
                      </>
                    )}
                  </IonCol>
                </IonRow>

                <ListHeader
                  numberOfSlots={slotList.length}
                  onHeatColumnClick={onHeatColumnClick}
                  sortDirection={heatPercentageSortDirection}
                />

                {isComparing}

                {displayedSlots.map((slotInfo) => {
                  return (
                    <ListItem
                      key={slotInfo.slotNumber}
                      slotNumber={slotInfo.slotNumber}
                      slotName={slotInfo.slotName}
                      slotPrice={slotInfo.price}
                      heatValue={String(slotInfo.heatValue)}
                      iconAction={onGoToDetailClick}
                      isActiveSlot={slotInfo.isActiveSlot}
                      heatRating={slotInfo.heatRating}
                      onClick={() => onSelectRow(slotInfo.slotNumber)}
                      isSelected={isSlotSelected(slotInfo.slotNumber)}
                      heatDisplayType={insightDisplayType}
                    ></ListItem>
                  );
                })}
              </>
            )}

            {/* Empty State */}
            {slotList?.length === 0 && !isSlotListLoading && (
              <div className="favorites-container__list__empty-state-container">
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>You have no favorite slots</IonCardTitle>

                    <IonCardContent>
                      Please go to your favorite slot detail page and click on the heart to add it
                      to your favorite slots list
                    </IonCardContent>
                  </IonCardHeader>
                </IonCard>
              </div>
            )}
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
