import { IState } from 'appRedux/createStore';

import { IAction } from '.';

import { casinoReducer } from './casinoReducer';
import { slotHistoryReducer } from './slotHistoryReducer';
import { favoritesReducer } from './favoriteReducer';
import { filtersReducer } from './filtersReducer';
import { floormapReducer } from './floormapReducer';
import slotReducer from './slotReducer';

export interface IReducer {
  (draft: IState, action: IAction): void;
}

const reducerArray: Array<IReducer> = [
  casinoReducer,
  slotHistoryReducer,
  favoritesReducer,
  filtersReducer,
  floormapReducer,
  slotReducer
];

export default reducerArray;
