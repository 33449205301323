import { IonButton, IonContent, IonHeader, IonImg, IonPage, IonToolbar } from '@ionic/react';
import React from 'react';
import { privacyHtml } from './privacy-terms';

const TermsPage: React.FC = () => {
  return (
    <IonPage>
      <div className="slotcheck-header">
        <IonHeader className="small-header">
          <IonToolbar>
            <IonButton
              fill="clear"
              onClick={() => {
                history.back();
              }}
              className="icon-back"
              slot="start"
            >
              <IonImg src="assets/images/back-icon.svg" />
            </IonButton>
            <div className="logo header-title">Terms and Conditions</div>
          </IonToolbar>
        </IonHeader>
      </div>
      <IonContent className="terms">
        <iframe
          srcDoc={privacyHtml}
          referrerPolicy="same-origin"
          width="100%"
          height="100%"
          frameBorder="0"
          className="responsive-iframe"
        ></iframe>
        {/* </div>
        </div> */}
      </IonContent>
    </IonPage>
  );
};

export default TermsPage;
