import { Radio } from '@material-ui/core';
import React from 'react';

interface CasinoCardProps {
  name: string;
  address: string;
  isComingSoon?: boolean;
  radioValue: number;
  selectedRadio: number;
  onSelect: (value: number) => void;
  cssClassName?: string;
}

export const EditCasinoCard: React.FC<CasinoCardProps> = ({
  name,
  address,
  radioValue,
  selectedRadio,
  onSelect,
  cssClassName = 'bg-white',
}) => {
  const handleRadioChange = () => {
    onSelect(radioValue);
  };

  return (
    <div className={`edit-casino-radio ${cssClassName}`}>
      <Radio
        color="primary"
        checked={selectedRadio === radioValue}
        onChange={handleRadioChange}
        value={radioValue}
        name="casino-selection"
        inputProps={{ 'aria-label': `${name} casino` }}
        className="radio-input"
      />
      <div className="info">
        <div className="casino-info">
          <div>
            <div className="caption-text">{name}</div>
            <div className="remove-margins location body-small">{address}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
