import moment from 'moment';
import { parseValue } from '../../common/include';
import { config } from '../../config/config';

export const chartOptions = {
  events: [] as any[],
  onClick: (event: any, element: any) => {},
  responsive: true,
  backgroundColor: '#000',
  maintainAspectRatio: false,
  tooltips: {
    enabled: false,
  },
  scales: {
    x: {
      stacked: true,
      display: true,
      type: 'time',
      grid: {
        display: true,
      },
      time: {
        displayFormats: {
          hour: 'h a',
          minute: 'h:mm a',
        },
        minUnit: '',
      },
      max: '' as any,
    },
    y: {
      stacked: true,
      type: 'linear',
      display: true,
      position: 'left',
      grid: {
        display: true,
        color: '#6D6D6E',
        zeroLineColor: '#6D6D6E',
      },
      min: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: (context: any) => {
          const { raw, dataset, formattedValue } = context[0];
          const { label, data } = dataset;
          const jackpotDate = raw?.bucket;
          const jackpotsArr = data?.filter(({ bucket }: any) => bucket === jackpotDate);
          const tooltipBody = `${moment(label === 'jackpot_value' ? context?.[0]?.raw?.game_time : context?.[0]?.raw?.bucket).format('D MMM YYYY hh:mm a')}\n`;
          return tooltipBody;
        },
        label: (ctx: any) => {
          const { dataset, raw } = ctx;
          console.log(dataset);
          const { label: insight } = dataset || {};
          const insightValue = raw[insight];
          const insightDetails: any =
            config?.slotInsights?.find(({ value }: any) => value === insight) || {};
          return `${insightDetails?.label} = ${parseValue(insightValue, insight)}`;
        },
        footer: (context: any) => {},
      },
    },
    zoom: {
      zoom: {
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true,
        },
        mode: 'x',
      },
      pan: {
        enabled: true,
        mode: 'x',
      },
    },
  },
} as any;
