import { FC } from 'react';
import './svg-component.scss';

const SVGComponent: FC<{ message?: string; customCls?: string }> = ({
  message,
  customCls = '',
}) => (
  <div className={`svg__parent ${customCls}`}>
    <svg className="svg__child">
      <g>
        <circle cx="73.801" cy="68.263" fill="#e32d91" r="3">
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="spline"
            values="0 50 50;360 50 50"
            keySplines="0.5 0 0.5 1"
            repeatCount="indefinite"
            dur="1.4925373134328357s"
            begin="0s"
          />
        </circle>
        <circle cx="68.263" cy="73.801" fill="#ff6a4b" r="3">
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="spline"
            values="0 50 50;360 50 50"
            keySplines="0.5 0 0.5 1"
            repeatCount="indefinite"
            dur="1.4925373134328357s"
            begin="-0.062s"
          />
        </circle>
        <circle cx="61.481" cy="77.716" fill="#ffed01" r="3">
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="spline"
            values="0 50 50;360 50 50"
            keySplines="0.5 0 0.5 1"
            repeatCount="indefinite"
            dur="1.4925373134328357s"
            begin="-0.125s"
          />
        </circle>
        <circle cx="53.916" cy="79.743" fill="#00e953" r="3">
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="spline"
            values="0 50 50;360 50 50"
            keySplines="0.5 0 0.5 1"
            repeatCount="indefinite"
            dur="1.4925373134328357s"
            begin="-0.187s"
          />
        </circle>
        <circle cx="46.084" cy="79.743" fill="#8fe3e8" r="3">
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="spline"
            values="0 50 50;360 50 50"
            keySplines="0.5 0 0.5 1"
            repeatCount="indefinite"
            dur="1.4925373134328357s"
            begin="-0.25s"
          />
        </circle>
        <circle cx="38.519" cy="77.716" fill="#007ddc" r="3">
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="spline"
            values="0 50 50;360 50 50"
            keySplines="0.5 0 0.5 1"
            repeatCount="indefinite"
            dur="1.4925373134328357s"
            begin="-0.312s"
          />
        </circle>
        <circle cx="31.737" cy="73.801" fill="#e32d91" r="3">
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="spline"
            values="0 50 50;360 50 50"
            keySplines="0.5 0 0.5 1"
            repeatCount="indefinite"
            dur="1.4925373134328357s"
            begin="-0.375s"
          />
        </circle>
        <circle cx="26.199" cy="68.263" fill="#ff6a4b" r="3">
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="spline"
            values="0 50 50;360 50 50"
            keySplines="0.5 0 0.5 1"
            repeatCount="indefinite"
            dur="1.4925373134328357s"
            begin="-0.437s"
          />
        </circle>
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="spline"
          values="0 50 50;0 50 50"
          keySplines="0.5 0 0.5 1"
          repeatCount="indefinite"
          dur="1.4925373134328357s"
        />
      </g>
    </svg>
    <span className="svg_message">{message} </span>
  </div>
);

export default SVGComponent;
