export const devConfig = {
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_gDjf8Fp3V',
    userPoolWebClientId: '1c4m4t7hllunlo6ttjd3m7hagg',
    mandatorySignIn: true,
    oauth: {
      domain: 'slotcheck-dev.auth.us-east-1.amazoncognito.com',
      scope: ['email', 'openid'],
      redirectSignIn: 'https://dev.slotcheck.com',
      redirectSignOut: 'https://dev.slotcheck.com',
      responseType: 'token',
    },
  }
};
