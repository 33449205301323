import { Dispatch } from 'react';
import { IAction } from 'appRedux/reducers';

import { ICasinoSchema, ILinkPlayerInfo } from '../models/casinoModels';
import { ICasinoSearchData } from 'appRedux/createStore';

const casinoReduxKey = 'CASINO';

export const casinoActionTypes = {
  SET_CASINO_SCHEMA_STATE: `${casinoReduxKey}-SET_CASINO_SCHEMA_STATE`,
  SET_LINKED_PLAYER_STATE: `${casinoReduxKey}-SET_LINKED_PLAYER_STATE`,
  SET_CASINO_SEARCH_DATA: `${casinoReduxKey}-SET_CASINO_SEARCH_DATA`,
};

export const CasinoReduxCommandCreator = (dispatch: Dispatch<IAction>) => {
  return {
    /** Sets the casinos linked player card data */
    SetLinkedCasinos(state: ILinkPlayerInfo) {
      dispatch({
        type: casinoActionTypes.SET_LINKED_PLAYER_STATE,
        state,
      });
    },
    /** Sets the player's currently selected casino information */
    SetCasinoSchema(state: ICasinoSchema) {
      dispatch({
        type: casinoActionTypes.SET_CASINO_SCHEMA_STATE,
        state,
      });
    },
    SetCasinoSearchData(state: ICasinoSearchData) {
      dispatch({
        type: casinoActionTypes.SET_CASINO_SEARCH_DATA,
        state,
      });
    },
  };
};
