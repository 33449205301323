import { IonContent, IonImg, IonPage } from '@ionic/react';
import { SignInAccount } from './SignInAccount';

export const Login = () => {
  // const dispatch = useDispatch();
  // const commands = ReduxCommandCreator(dispatch);
  // const history = useHistory();
  // const authState = useSelector<IState>((state) => state.app.persistedState.authState);


  //TODO: review the need for this!
  // useEffect(() => {
  //   if (authState === AUTH_STATE.AUTHENTICATED) {
  //     Auth.currentAuthenticatedUser({
  //       bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  //     })
  //       .then((user) => {
  //         history.replace('/home');
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         commands.SetAuthState(AUTH_STATE.SIGN_IN);
  //       });
  //   }
  // }, [authState]);

  return (
    <IonPage className="app-parent">
      <IonContent className="scroll-disable">
        <div className="container">
          <div className="content">
            <div className="m-signup m-signup__login">
              <div className="modal-container">
                <SignInAccount />
              </div>
              <div className="footer-container">
                <div className="logo">
                  <IonImg src="assets/images/SLOTcheckTM.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
