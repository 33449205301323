import {
  IonButton,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonToolbar,
  useIonToast,
  IonRow,
  IonCol,
  IonGrid,
} from '@ionic/react';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { useSelector } from 'react-redux';
import { firstValueFrom } from 'rxjs';
import { IState } from 'appRedux/createStore';
import { PlayerService, UserAnalytics } from 'services/player';
import { SmartLoading } from 'components/SmartLoading';

interface UserAnalyticsExcel {
  'Create Date': string;
  'Cell Number': string;
  'Linked Casino IDs': string;
  'Casino Player IDs': string;
  'Subscription Status': string;
  'Last Activity': string;
}

export const AnalyticsDashboard = () => {
  const playerSvc = new PlayerService();
  const [presentToast] = useIonToast();
  const [userAnalytics, setUserAnalytics] = useState<UserAnalytics[]>([]);
  const [loading, setLoading] = useState(true);

  const casinoId = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema?.kpCasinoPk
  ) as number;

  useEffect(() => {
    let isIgnore = false;

    const fetchPlayerAnalytics = async (casinoId: number) => {
      if (!isIgnore) {
        try {
          const res = await firstValueFrom(playerSvc.GetPlayerAnalytics(casinoId));
          if (res) {
            const sortedData = res.DataSet.map((user) => ({
              ...user,
              linkedAccounts: user.linkedAccounts.sort((a, b) => a.casinoID - b.casinoID),
            }));
            setUserAnalytics(sortedData);
          }
        } catch (error) {
          presentToast({
            position: 'top',
            duration: 5000,
            color: 'danger',
            message: 'Failed to get Analytics information.',
          }).catch(() => null);
        } finally {
          setLoading(false);
        }
      }
    };

    const debouncedFetch = debounce(fetchPlayerAnalytics, 500);
    void debouncedFetch(casinoId);

    return () => {
      isIgnore = true;
      debouncedFetch.cancel();
    };
  }, [casinoId, presentToast]);

  const exportToExcel = () => {
    if (!userAnalytics.length) {
      presentToast({
        position: 'top',
        duration: 3000,
        color: 'warning',
        message: 'No data available to export.',
      }).catch(() => null);
      return;
    }

    const today = new Date().toLocaleDateString('en-CA');
    const ws = XLSX.utils.json_to_sheet(
      userAnalytics.map(
        (user: UserAnalytics): UserAnalyticsExcel => ({
          'Create Date': new Date(user.createDate).toLocaleDateString(),
          'Cell Number': user.cellNumber,
          'Linked Casino IDs': user.linkedAccounts
            .map(
              (account) =>
                `${account.casinoID} on ${new Date(
                  Number(account.createDate)
                ).toLocaleDateString()}`
            )
            .join(', '),
          'Casino Player IDs': user.linkedAccounts
            .map((account) => `${account.casinoID}: ${account.playerId}`)
            .join(', '),
          'Subscription Status':
            user.subscriptionData?.subscriptionData?.status === 'active'
              ? `${user.subscriptionData.subscriptionData.status} since ${
                  user.subscriptionData.subscriptionData.current_period_start
                    ? new Date(
                        user.subscriptionData.subscriptionData.current_period_start
                      ).toLocaleDateString()
                    : 'unknown'
                }`
              : `${user.subscriptionData?.subscriptionData?.status} since ${new Date(
                  user.subscriptionData.subscriptionData.current_period_end
                ).toLocaleDateString()}`,
          'Last Activity': new Date(user.lastActivity).toLocaleDateString(),
        })
      )
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Analytics');
    XLSX.writeFile(wb, `analyticsForCasinoId_${casinoId}_${today}.xlsx`);
  };

  return (
    <IonPage className="app-parent">
      <div className="slotcheck-header">
        <IonHeader className="small-header">
          <IonToolbar>
            <IonButton
              fill="clear"
              onClick={() => history.back()}
              className="icon-back"
              slot="start"
            >
              <IonImg src="assets/images/back-icon.svg" />
            </IonButton>
            <div className="logo header-title">Analytics Dashboard</div>
            {userAnalytics.length > 0 && (
              <IonButton color="success" slot="end" onClick={exportToExcel}>
                Export to Excel
              </IonButton>
            )}
          </IonToolbar>
        </IonHeader>
      </div>
      <IonContent className="player-analytics">
        {loading ? (
          <SmartLoading loading={loading} />
        ) : (
          <IonGrid>
            {userAnalytics.length === 0 ? (
              <div>No data available</div>
            ) : (
              <>
                <IonHeader>
                  <IonRow>
                    <IonCol>Create Date</IonCol>
                    <IonCol>Cell Number</IonCol>
                    <IonCol>Linked Casino IDs</IonCol>
                    <IonCol>Casino Player ID</IonCol>
                    <IonCol>Subscription Status</IonCol>
                    <IonCol>Last Activity Date</IonCol>
                  </IonRow>
                </IonHeader>
                {userAnalytics.map((user, index) => (
                  <IonRow className="table-data" key={index}>
                    <IonCol>{new Date(user.createDate).toLocaleDateString()}</IonCol>
                    <IonCol>{user.cellNumber}</IonCol>
                    <IonCol>
                      {user.linkedAccounts.map((account, i) => (
                        <div key={`${account.playerId}_${i}`}>
                          {account.casinoID} on{' '}
                          {new Date(Number(account.createDate)).toLocaleDateString()}
                        </div>
                      ))}
                    </IonCol>
                    <IonCol>
                      {user.linkedAccounts.map((account, i) => (
                        <div key={`${account.playerId}_${i}`}>
                          {account.casinoID}: {account.playerId}
                        </div>
                      ))}
                    </IonCol>
                    <IonCol>
                      {user.subscriptionData?.subscriptionData?.status === 'active'
                        ? `${user.subscriptionData.subscriptionData.status} since ${
                            user.subscriptionData.subscriptionData.current_period_start
                              ? new Date(
                                  user.subscriptionData.subscriptionData.current_period_start
                                ).toLocaleDateString()
                              : 'unknown'
                          }`
                        : `${user.subscriptionData?.subscriptionData?.status} since ${new Date(
                            user.subscriptionData.subscriptionData.current_period_end
                          ).toLocaleDateString()}`}
                    </IonCol>
                    <IonCol>{new Date(user.lastActivity).toLocaleString()}</IonCol>
                  </IonRow>
                ))}
              </>
            )}
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  );
};
