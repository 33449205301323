import { Dispatch } from 'react';
import { IAction } from 'appRedux/reducers';
import { ITabProperties } from 'appRedux/createStore';
import { FilterTypeEnum } from 'common/common';

const tabfilterReduxKey = 'FILTER';

export const FilterActionTypes = {
  SET_FILTER_OPTIONS: `${tabfilterReduxKey}-SET_FILTER_OPTIONS`,
  RESET_FILTERS: `${tabfilterReduxKey}-RESET_FILTERS`,
};

export type FilteredTabProperties = {
  [Key in keyof ITabProperties]: ITabProperties[Key];
};

type TabFiltersState = {
  id: string;
  filters: Partial<FilteredTabProperties>;
};

export const FilterReduxCommandCreator = (dispatch: Dispatch<IAction>) => {
  return {
    SetFilterOptions(pageName: FilterTypeEnum, updatedValues: Partial<FilteredTabProperties>) {
      const state: TabFiltersState = {
        id: pageName,
        filters: {
          ...updatedValues,
        },
      };

      dispatch({
        type: FilterActionTypes.SET_FILTER_OPTIONS,
        state,
      });
    },
    ResetAllFilters() {
      dispatch({
        type: FilterActionTypes.RESET_FILTERS,
      });
    },
  };
};
