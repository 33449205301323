import { Auth as amplifyAuth } from 'aws-amplify';
import Auth from './auth.common';
import { defer, Observable, switchMap, from } from 'rxjs';
import initializeAxios from './axiosSetup';
import { axiosRequestConfiguration } from './axiosConfig';
import { map } from 'rxjs/operators';
import { merge } from 'lodash';

const BaseUrl = '//' + __API__ + ':' + __APIPORT__ + '/api/';

const axiosInstance = initializeAxios(axiosRequestConfiguration);

interface IAuthOptions {
  headers: {
    authorization?: string;
    awstoken?: string;
    casinoId?: string;
  };
}
const buildOptions = (
  options = {},
  isSecure?: boolean,
  awstoken?: string,
  isLambda?: boolean,
  casinoId?: string
): object => {
  let retOptions = {};
  if (isSecure) {
    const token = Auth.GetToken() as string;
    const authOptions: IAuthOptions = {
      headers: {
        authorization: `Bearer ${isLambda ? awstoken : token}`,
      },
    };

    if (isLambda) {
      authOptions.headers.awstoken = awstoken;
    }

    if (casinoId) {
      authOptions.headers.casinoId = casinoId;
    }
    retOptions = merge(options, authOptions);
  } else {
    retOptions = { ...options };
  }
  return retOptions;
};

export const getAccessJwtToken = async () => {
  const session = await amplifyAuth.currentSession();
  Auth.SetJWKToken(session.getIdToken().getJwtToken());
  return session.getIdToken().getJwtToken();
};

const checkAuth = (
  options: object,
  isSecure?: boolean,
  isLambda?: boolean,
  casinoId?: string
): Promise<object> => {
  return new Promise((resolve, reject) => {
    getAccessJwtToken()
      .then((awsToken) => {
        resolve(buildOptions(options, isSecure, awsToken, isLambda, casinoId));
      })
      .catch((err) => reject(err));
  });
};

const postSecure = <T>(
  url: string,
  body: object,
  options = {},
  isLambda = false,
  casinoId?: string
): Observable<T | void> => {
  return from(checkAuth(options, true, isLambda, casinoId)).pipe(
    switchMap((builtOptions) => post<T>(url, body, builtOptions))
  );
};

const post = <T>(url: string, body: object, options = {}): Observable<T | void> => {
  return defer(() => axiosInstance.post<T>(url, body, options)).pipe(map((result) => result.data));
};

const Post = <T>(url: string, body: object, options = {}, isSecure = false, isLambda = false, casinoId?: string) => {
  if (isSecure) {
    return postSecure<T>(url, body, options, isLambda, casinoId);
  } else {
    return post<T>(url, body, options);
  }
};

const get = <T>(url: string, queryParams?: object, options?: object): Observable<T> => {
  const config = {
    params: queryParams,
    ...options,
  };
  return defer(() => axiosInstance.get<T>(url, config)).pipe(map((result) => result.data));
};

const getSecure = <T>(
  url: string,
  queryParams: object,
  options = {},
  isLambda = false,
  casinoId?: string
): Observable<T | void> => {
  return from(checkAuth(options, true, isLambda, casinoId)).pipe(
    switchMap((builtOptions) => get<T>(url, queryParams, builtOptions))
  );
};

const Get = <T>(
  url: string,
  queryParams: object,
  options = {},
  isSecure = false,
  isLambda = false,
  casinoId?: string
) => {
  if (isSecure) {
    return getSecure<T>(url, queryParams, options, isLambda, casinoId);
  } else {
    return get<T>(url, queryParams, options);
  }
};

const put = <T>(url: string, body: object, queryParams?: object): Observable<T | void> => {
  return defer(() => axiosInstance.put<T>(`${BaseUrl}${url}`, body, { params: queryParams })).pipe(
    map((result) => result.data)
  );
};

const patch = <T>(url: string, body: object, queryParams?: object): Observable<T | void> => {
  return defer(() =>
    axiosInstance.patch<T>(`${BaseUrl}${url}`, body, { params: queryParams })
  ).pipe(map((result) => result.data));
};

export default { Get, Post, put, patch };
export { Post, Get };
