import './copy-right.scss';
import { IonIcon, IonButton } from '@ionic/react';
import { logoFacebook, logoInstagram, logoYoutube } from 'ionicons/icons';
import { FC } from 'react';
import packageInfo from '../../../package.json';
interface CopyRightProps {
  isShowVersion: boolean;
}
const CopyRight: FC<CopyRightProps> = ({ isShowVersion }) => (
  <div className="copyright">
    <div className="copyright-socialmedia-icons">
      <IonButton
        className="copyright-icon-button"
        onClick={() => {
          window.open('https://www.facebook.com/SlotCheck', '_blank');
        }}
      >
        <IonIcon icon={logoFacebook} />
      </IonButton>
      <IonButton
        className="copyright-icon-button"
        onClick={() => {
          window.open('https://www.instagram.com/slotcheck', '_blank');
        }}
      >
        <IonIcon icon={logoInstagram} />
      </IonButton>
      <IonButton
        className="copyright-icon-button"
        onClick={() => {
          window.open('https://www.youtube.com/@slotcheck', '_blank');
        }}
      >
        <IonIcon icon={logoYoutube} />
      </IonButton>
    </div>
    <div
      className="copyright-url"
      onClick={() => {
        window.open('https://www.slotcheck.com', '_blank');
      }}
    >
      www.slotcheck.com
    </div>
    <div className="copy-right">
      <div className="copyright-name">Slot Check, Inc. &copy; 2023, 2022</div>
      {isShowVersion && <div className="copyright-version">Version {packageInfo.version}</div>}
    </div>
  </div>
);

export default CopyRight;
