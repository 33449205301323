/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { IonImg } from '@ionic/react';
import React, { useState } from 'react';
import './slotpro-comingsoon.scss';
import { playerTypeEnum } from 'common/commonEnum';

const SlotProComingSoon: React.FC<{ subscriptionPlanData: any; onClosePopUp: () => void }> = (
  props
) => {
  const { onClosePopUp, subscriptionPlanData } = props;
  const [readMoreSlotpro, setReadMoreSlotpro] = useState(false);

  return (
    <div className="dark-popup player-id-hint-popup soon-popup">
      <div className="dark-popup-content">
        <div className="slot-plan">
          {subscriptionPlanData?.slotcheck_user_subscription_plan_features &&
            subscriptionPlanData?.slotcheck_user_subscription_plan_features.map(
              (eachPlan: any, index: any) => (
                <div
                  key={index + 1}
                  className={`slot-plan-box ${
                    eachPlan.plan_type === 'SlotPro' &&
                    (readMoreSlotpro ? 'slot-plan-box-more' : 'slot-plan-box-less')
                  } ${eachPlan.plan_type !== 'SlotPro' && 'slot-plan-box-less'}`}
                >
                  {eachPlan.plan_type === playerTypeEnum.slotCheckPro && (
                    <div className="comming-soon-badge slot-plan-coming-pos">Coming NOV 30</div>
                  )}
                  <div className="slot-plan-box-header">
                    <div className="slot-plan-box-logo">
                      {eachPlan.plan_type === playerTypeEnum.slotCheck && (
                        <IonImg src="assets/images/Check.png" />
                      )}
                      {eachPlan.plan_type === playerTypeEnum.slotCheckPlus && (
                        <IonImg src="assets/images/SlotCheckPlus.png" />
                      )}
                      {eachPlan.plan_type === playerTypeEnum.slotCheckPro && (
                        <IonImg src="assets/images/slot-pro.png" />
                      )}
                    </div>
                    <div className="slot-plan-box-name">
                      {eachPlan.plan_type === playerTypeEnum.slotCheck && <>Slot Check</>}
                      {eachPlan.plan_type === playerTypeEnum.slotCheckPlus && <>Slot Check Plus</>}
                      {eachPlan.plan_type === playerTypeEnum.slotCheckPro && <>Slot Pro</>}
                    </div>
                  </div>
                  <div className="slot-plan-box-content">
                    <ul>
                      {eachPlan?.features?.plan_detail.map((planDesc: any, planIndex: any) => (
                        <div key={planIndex}>
                          {eachPlan.plan_type === playerTypeEnum.slotCheckPro && (
                            <li>{planDesc}</li>
                          )}
                        </div>
                      ))}
                    </ul>
                  </div>
                </div>
              )
            )}
        </div>
      </div>
      <div className="dark-popup-footer " onClick={onClosePopUp}>
        Close
      </div>
    </div>
  );
};

export default SlotProComingSoon;
