import { createSelector } from 'reselect';
import { IState } from '../createStore';

const getFloormapState = (state: IState) => state.app.persistedState.floormapState;

export const selectHeatFilterAndBearing = createSelector(
  [getFloormapState],
  (floormapState) => ({
    heatFilter: floormapState?.heatFilter,
    bearing: floormapState?.bearing,
  })
);
