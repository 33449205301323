import { favoritesActionTypes } from 'appRedux/actions/favoritesCommandCreator';
import { IUserFavorite } from 'appRedux/models/playerModels';

import {
  IFavoritesState,
  InsightDisplayType,
  InsightFilterEnum,
  TimeframeFilterEnum,
} from 'appRedux/models/favoritesModels';

import { IReducer } from './moduleReducerRegistry';

export const favoritesInitialState: IFavoritesState = {
  favoritesList: [],
  insightFilter: InsightFilterEnum.Spins,
  displayType: InsightDisplayType.Number,
  timeframeFilter: TimeframeFilterEnum.ThirtyDays,
};

export const favoritesReducer: IReducer = (draft, action) => {
  const { type, state } = action;

  switch (type) {
    case favoritesActionTypes.ADD_FAVORITE:
      if (draft.app.persistedState.favorites.favoritesList) {
        const newFavorite = state as IUserFavorite;
        const oldFavorite = draft.app.persistedState.favorites.favoritesList;

        const doesExistInFavorites = oldFavorite.some(
          (favorite) => favorite.slotNumber === newFavorite.slotNumber
        );

        if (!doesExistInFavorites) {
          draft.app.persistedState.favorites.favoritesList.push(newFavorite);
        }
      }

      break;
    case favoritesActionTypes.REMOVE_FAVORITE:
      draft.app.persistedState.favorites.favoritesList =
        draft.app.persistedState.favorites.favoritesList.filter(
          (favorite) => favorite.slotNumber !== state
        );
      break;
    case favoritesActionTypes.SET_FAVORITES:
      draft.app.persistedState.favorites.favoritesList = state as IUserFavorite[];
      break;
    case favoritesActionTypes.CLEAR_FAVORITES:
      draft.app.persistedState.favorites.favoritesList = [];
      break;
    case favoritesActionTypes.SET_INSIGHT_FILTER:
      draft.app.persistedState.favorites.insightFilter = state as InsightFilterEnum;
      break;
    case favoritesActionTypes.SET_TIMEFRAME_FILTER:
      draft.app.persistedState.favorites.timeframeFilter = state as TimeframeFilterEnum;
      break;
    case favoritesActionTypes.SET_INSIGHT_DISPLAY_TYPE:
      draft.app.persistedState.favorites.displayType = state as InsightDisplayType;
      break;
  }
};
