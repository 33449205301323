/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IonCol, IonContent, IonGrid, IonIcon, IonRow, useIonToast } from '@ionic/react';
import { trophy } from 'ionicons/icons';
import './leaderboard.scss';

import {
  config,
  getInsightDetails,
  getTimeCharacter,
  getTimeFrameDetails,
} from '../../../../config/config';
import SmartLoading from '../../../../components/SmartLoading';
import LeadersTable from './LeadersTable';
import NoPlayerIDLink, { SCREEN_TYPE } from '../../../../components/NoPlayerIDLink/NoPlayerIDLink';
import { useEffect, useMemo, useRef, useState } from 'react';
import NoDetailsNotice from '../../../ListTab/NoDetailsNotice';
import { IState, ITabProperties } from 'appRedux/createStore';
import {
  ICasinoSchema,
  ILeaderBoardReport,
  leaderBoardType,
  leaderBoardTypeOrder,
  leaderBoardTypePercent,
} from 'appRedux/models/casinoModels';
import { useSelector } from 'react-redux';
import { useDynamicCSS } from 'hooks/useDynamicCSS';
import { CasinoService, IRequestWithPaginationLeaderParams } from 'services/casino';
import {
  FilterTypeEnum,
  IFilterType,
  InsightType,
  OrderByEnum,
  OrderValueEnum,
} from 'common/common';
import { debounce } from 'lodash';
import { take, tap } from 'rxjs';
import { InsightValue, LeaderBoardInsightValue } from 'common/types';
import useLeaderboardFilters from './hooks/useLeaderboardFilters';
import { parseValue, parseValueForTrends } from 'common/include';

const Leaderboard: React.FC = () => {
  const casinoSvc = new CasinoService();
  const [presentToast] = useIonToast();
  const ionInfiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null);

  const {
    sortBy,
    activeFilter,
    toggleRankFilter,
    handleResetFilters,
    isRankFilterActive,
    isAliasFilterActive,
    getRankFilterIcon,
  } = useLeaderboardFilters();

  const casinoSchema: ICasinoSchema = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema
  ) as ICasinoSchema;
  const isPlayerLinked = useSelector<IState>(
    (state) => state.app.persistedState.linkPlayerState?.isPlayerLinked
  );
  const userId = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.appUserPk
  ) as string;
  const casinoId = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema?.kpCasinoPk
  ) as number;
  const leaderboardTabAnalytics = useSelector<IState>(
    (state) => state.app.persistedState.filterState[FilterTypeEnum.leaderboard as IFilterType]
  ) as ITabProperties;

  const { timeframe, insight } = leaderboardTabAnalytics || {};
  const insightData: InsightType | undefined = getInsightDetails(insight);
  const timeFrameData = getTimeFrameDetails(timeframe);
  const isProUser = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.isProUser
  ) as boolean;

  const itemsPerPage = 50;
  const [leadersData, setLeadersData] = useState<ILeaderBoardReport[]>([]);
  const [totalReportCount, setTotalReportCount] = useState<number>(0);
  const [playerData, setPlayerData] = useState<ILeaderBoardReport[]>([]);

  const [isEndReached, setIsEndReached] = useState<boolean>(false);
  const [leadersDataLoading, setLeadersDataLoading] = useState(false);

  const [casinoParams, setCasinoParams] = useState<IRequestWithPaginationLeaderParams>({
    appUserFk: '',
    casinofk: casinoId,
    timeframe: 5,
    insight: insightData ? insightData.id : 1,
    limit: itemsPerPage,
    pageNumber: 0,
    ordervalue: OrderValueEnum.JackpotOrder,
    orderby: OrderByEnum.Desc,
  });

  const handleInputChange = (
    param: keyof IRequestWithPaginationLeaderParams,
    value: string | number
  ) => {
    setCasinoParams((prevParams) => ({ ...prevParams, [param]: value }));
  };

  const memoizedCasinoParams = useMemo(
    () => casinoParams,
    [
      casinoParams.casinofk,
      casinoParams.insight,
      casinoParams.timeframe,
      casinoParams.appUserFk,
      casinoParams.ordervalue,
      casinoParams.orderby,
      casinoParams.pageNumber,
    ]
  );

  const handleFetchMore = (): void => {
    handleInputChange('pageNumber', casinoParams.pageNumber + 1);
  };

  const fetchListReport = (queryParams: IRequestWithPaginationLeaderParams) => {
    try {
      casinoSvc.LeaderBoardListReport(queryParams).subscribe({
        next(casinoResponse) {
          if (casinoResponse) {
            if (casinoParams.pageNumber === 0) {
              setLeadersData(casinoResponse.DataSet);
              setPlayerData(casinoResponse.PlayerDataSet);
            } else {
              setLeadersData((prevItems) => [...prevItems, ...casinoResponse.DataSet]);
            }
            if (casinoResponse.DataSet) {
              if (casinoResponse.DataSet.length < itemsPerPage) {
                setIsEndReached(true);
              } else {
                setIsEndReached(false);
              }
            }
          }
          ionInfiniteScrollRef.current?.complete();
        },
        error(_err) {
          presentToast({
            position: 'top',
            duration: 5000,
            color: 'danger',
            message: 'Failed to retrieve casino report.',
          }).catch(() => null);
          ionInfiniteScrollRef.current?.complete();
        },
        complete() {
          setLeadersDataLoading(false);
        },
      });
    } catch (error) {
      setLeadersDataLoading(false);
    }
  };

  const fetchTotalReportCount = (queryParams: IRequestWithPaginationLeaderParams) => {
    const { pageNumber, limit, ...requestParams } = queryParams;

    casinoSvc
      .LeaderBoardCountReport(requestParams)
      .pipe(
        take(1),
        tap((data) => {
          if (data) {
            if (data.DataSet) {
              setTotalReportCount(data.DataSet[0].total_count);
            }
          }
        })
      )
      .subscribe();
  };

  const debouncedGetListReport = debounce((casinoParams) => {
    fetchListReport(casinoParams as IRequestWithPaginationLeaderParams);
  }, 500);

  const debouncedGetListReportCount = debounce((casinoParams) => {
    fetchTotalReportCount(casinoParams as IRequestWithPaginationLeaderParams);
  }, 500);

  useEffect(() => {
    const insightData = getInsightDetails(insight as InsightValue);
    insightData && handleInputChange('insight', insightData.id);
    handleInputChange('orderby', OrderByEnum.Desc);
    handleInputChange('casinofk', casinoId);
    handleInputChange('pageNumber', 0);
  }, [casinoId]);

  useEffect(() => {
    const insightData = getInsightDetails(insight);
    insightData && handleInputChange('insight', insightData.id);
    if (activeFilter === 'rank') {
      handleInputChange('ordervalue', insightData?.field + '_order');
    } else {
      handleInputChange('ordervalue', `alias_${sortBy}_order`);
    }
    handleInputChange('orderby', casinoParams.orderby);
    handleInputChange('pageNumber', 0);
  }, [insight]);

  useEffect(() => {
    const filterTime = config.allTimeFrames.filter(
      (eachTimeframe) => eachTimeframe.value === timeframe
    )[0];
    handleInputChange('timeframe', filterTime.id);
    handleInputChange('pageNumber', 0);
    handleInputChange('insight', casinoParams.insight);
    handleInputChange('orderby', casinoParams.orderby);
  }, [timeframe]);

  useEffect(() => {
    if (userId) {
      handleInputChange('appUserFk', userId);
    }
  }, [userId]);

  useEffect(() => {
    if (activeFilter) {
      if (activeFilter === 'rank') {
        handleInputChange('ordervalue', insight + '_order');
        handleInputChange('orderby', sortBy);
      } else {
        handleInputChange('ordervalue', `alias_${sortBy}_order`);
        handleInputChange('orderby', OrderByEnum.Asc);
      }
    }
  }, [activeFilter, sortBy]);

  useEffect(() => {
    if (casinoParams.appUserFk && isPlayerLinked) {
      if (casinoParams.pageNumber === 0) {
        setLeadersDataLoading(true);
      }
      debouncedGetListReport(casinoParams);

      return () => {
        debouncedGetListReport.cancel();
      };
    }
  }, [memoizedCasinoParams, memoizedCasinoParams.appUserFk != '']);

  const playerRank = playerData?.[0] || {};
  const { alias_initial2: playerInitial, alias_name: playerName } = playerRank || {};
  const currentPlayerTypeIconCls = isProUser ? 'slot-pro-player-icon' : 'content-hide';

  const css = `
  .user-rank-container {
    color:${casinoSchema.styles.color};
    background-color: ${casinoSchema.styles.backgroundColor};
    & .player-name-container {
      color:${casinoSchema.styles.color};
      & .player-alias-box{
       color: #fff
      }
    }
  }`;
  useDynamicCSS(css);

  return (
    <>
      <IonContent className="table-data-leaders remain-height" no-bounce>
        <SmartLoading loading={leadersDataLoading} />

        {!leadersDataLoading && leadersData.length > 0 && isPlayerLinked && (
          <div className="table-data-leaders-wrapper">
            <div className="table-title leaderboard-title">
              {leadersData.length !== 0 && <h3>Top {leadersData.length} Players</h3>}
              {insightData && (
                <h4>
                  {insightData.label} {getTimeCharacter(timeFrameData?.value)}
                </h4>
              )}
            </div>
            <div className="table-data-leaders-table remain-height">
              <div className="table-data-leaders-header">
                <IonGrid className="ion-no-padding">
                  <IonRow className="ion-text-center leader-header-row center-flex-align">
                    <IonCol size="1.5" className="leaders-count-title center-flex-align">
                      <div className="table-data-flex-container" onClick={toggleRankFilter}>
                        <span
                          className={`table-column-label ${
                            isRankFilterActive ? 'active-filter' : ''
                          }`}
                        >
                          Rank
                        </span>
                        <IonIcon
                          icon={getRankFilterIcon()}
                          className={`table-sort-arrow ${
                            isRankFilterActive ? 'active-filter' : ''
                          }`}
                        />
                      </div>
                    </IonCol>
                    <IonCol size="1" className="ion-text-center data-percent">
                      <div className="data-percent-box">%</div>
                    </IonCol>
                    <IonCol size="6" className="center-flex-align">
                      <div className="player-name-container  table-data-flex-container padding-left-10">
                        <span className="player-alias-box-empty user-avatar-color6">
                          <div className="alias-icon content-hide"></div>
                        </span>
                        <span
                          className={`top-player-column table-column-label ${
                            isAliasFilterActive ? 'active-filter' : ''
                          }`}
                        >
                          Top Players
                        </span>
                      </div>
                    </IonCol>
                    {insight.includes('jackpot') && (
                      <IonCol size="1.5" className="table-column-label">
                        <div className="jkpt-number">#</div>
                      </IonCol>
                    )}
                    <IonCol
                      size={insight.includes('jackpot') ? '2' : '3.5'}
                      class="trophy-column center-flex-align"
                    >
                      <IonIcon
                        icon={trophy}
                        className="table-trophy-icon"
                        onClick={handleResetFilters}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>

              <div className="table-data-leaders-content">
                <LeadersTable
                  leadersData={leadersData}
                  selectedInsight={insight as LeaderBoardInsightValue}
                />
              </div>
              {/* need to be fix the pagination  */}
              {/* <IonInfiniteScroll
                threshold="100px"
                ref={ionInfiniteScrollRef}
                disabled={isEndReached}
                onIonInfinite={handleFetchMore}
              >
                <IonInfiniteScrollContent
                  loadingSpinner="bubbles"
                  loadingText="Loading more data..."
                />
              </IonInfiniteScroll> */}
            </div>
          </div>
        )}

        {!leadersDataLoading && leadersData.length === 0 && isPlayerLinked && (
          <NoDetailsNotice selectedTimeFrameValue={timeframe} />
        )}
        {!isPlayerLinked && <NoPlayerIDLink screenType={SCREEN_TYPE.Leaderboard} />}
      </IonContent>
      {/* User Rank Sticky Footer */}
      {playerName && !leadersDataLoading && isPlayerLinked && (
        <div className="user-rank-container">
          <IonGrid className="ion-no-padding">
            <IonRow className="ion-align-items-center">
              <IonCol size="1.5" className="ion-text-center leaders-count-content">
                {!playerRank[insight as leaderBoardType]
                  ? 'N/A'
                  : playerRank[`${insight}_order` as leaderBoardTypeOrder]}
              </IonCol>
              <IonCol size="1.2" className="ion-text-center data-percent">
                <span className="data-percent-box na-percentage">
                  {!playerRank[insight as leaderBoardType]
                    ? 'N/A'
                    : playerRank[`${insight}_percent` as leaderBoardTypePercent] + '%'}
                </span>
              </IonCol>
              <IonCol size="6" class="center-flex-align">
                <div className="table-data-flex-container player-name-container player-footer">
                  <span className="player-alias-box user-avatar-color5">
                    {playerInitial}
                    <div className={`alias-icon ${currentPlayerTypeIconCls}`} />
                  </span>
                  <span className="player-alias-name">{playerName}</span>
                </div>
              </IonCol>
              {insight.includes('jackpot') && (
                <IonCol size="1" class="center-flex-align">
                  <div className="table-data-flex-container rank-col jkpt-number">
                    {parseValue(playerRank['jackpots'], 'jackpots')}
                  </div>
                </IonCol>
              )}
              <IonCol size={insight.includes('jackpot') ? '2' : '3.3'} class="center-flex-align">
                <div className="table-data-flex-container rank-col">
                  {parseValueForTrends(playerRank[insight as leaderBoardType], insight)}
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      )}
    </>
  );
};

export default Leaderboard;
