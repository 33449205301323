import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { FC } from 'react';

interface NoDetailsNoticeProps {
  selectedTimeFrameValue: string;
}

const NoDetailsNotice: FC<NoDetailsNoticeProps> = ({
  selectedTimeFrameValue,
}) => (
  <IonGrid className='hot-slot-listing'>
    <IonRow>
      <IonCol className='ion-text-center'>
        {selectedTimeFrameValue === 'month1' ? (
          <span className='white-p no-data-message'>
            The slot might be out of service, as there is no data.
          </span>
        ) : (
          <span className='white-p no-data-message'>
            Try a longer time frame.
          </span>
        )}
      </IonCol>
    </IonRow>
  </IonGrid>
);

export default NoDetailsNotice;
