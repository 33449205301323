import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import useLocalStorage from 'common/reactHooks/useLocalStorage';
import React from 'react';

const LegalDisclaimer: React.FC = () => {
  const router = useIonRouter();
  const [, setAgreedToLegalDisclaimer] = useLocalStorage('legalDisclaimer', false);

  const handleDisagree = () => {
    router.push('/logout');
  };

  const handleAgree = () => {
    setAgreedToLegalDisclaimer(true);
    router.push('/auth/choosecasinos');
  };

  return (
    <IonPage className="app-parent">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Disclaimer</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding scroll-disable">
        <p className="text">
          The Slot Machine outcomes are governed by a Random Number Generator (RNG), ensuring that
          each outcome is statistically independent and unpredictable. The historical data provided
          by Slot Check is solely for informational purposes and reflects previous results. Under no
          circumstances does Slot Check purport to predict, influence, or determine future outcomes
          of slot machine games. The user acknowledges that Slot Check bears no responsibility for
          the interpretation or use of the historical data in a manner that assumes predictability
          of future slot machine results. The user accepts and agrees to this disclaimer as a
          condition of utilizing Slot Check's services.
        </p>
      </IonContent>

      <IonFooter className="ion-padding form-button-bar ">
        <IonButton fill="outline" color={'light'} onClick={handleDisagree} id="disagreeButton">
          Disagree
        </IonButton>

        <IonButton onClick={handleAgree} id="agreeButton">
          Agree
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default LegalDisclaimer;
export { LegalDisclaimer };
