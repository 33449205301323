import { IUserFavorite } from './playerModels';

export interface IFavoritesState {
  favoritesList: IUserFavorite[];
  insightFilter: InsightFilterEnum;
  displayType: InsightDisplayType;
  timeframeFilter: TimeframeFilterEnum;
}

export enum InsightFilterEnum {
  PayoutToPlayer = 'pop',
  ReturnToPlayer = 'return',
  Spins = 'spins',
  Volatility = 'volatility_rating',
  Jackpot = 'jackpot_value',
  MoneyWon = 'money_won',
  MoneyPlayed = 'money_played',
  AverageWonPerSpin = 'avg_money_won_per_spin',
  WinRate = 'win_rate',
}

export enum InsightDisplayType {
  Percentage = 'percentage',
  PercentageNoFormat = 'percentageNoFormat',
  Number = 'number',
  String = 'string',
  HeatTileLabel = 'heatTileLabel',
  Money = 'Money',
}

export enum TimeframeFilterEnum {
  OneHour = 1,
  FourHours = 2,
  EightHours = 3,
  TwentyFourHours = 4,
  SevenDays = 5,
  ThirtyDays = 6,
  SinceLastJackpot = 7,
}
