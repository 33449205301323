import { AxiosRequestConfig } from 'axios';
const BaseUrl = '//' + __API__ + ':' + __APIPORT__ + '/api/';

export const axiosRequestConfiguration: AxiosRequestConfig = {
  baseURL: BaseUrl,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
};
