import Men from './mapicons/restrooms-boy.png';
import Women from './mapicons/restrooms-girl.png';

const LblImageArr = [
  {
    name: 'Men',
    image: Men,
  },
  {
    name: 'Women',
    image: Women,
  },
];

export const loadImage = (mapbox: mapboxgl.Map, imageName: string, key: string): void => {
  mapbox.loadImage(
    imageName,
    (error?: Error | null, image?: HTMLImageElement | ImageBitmap | undefined) => {
      if (error) throw error;
      if (image) {
        mapbox.addImage(key, image);
      } 
    }
  );
};

export const addMapEurekaImage = (mapbox: mapboxgl.Map): void => {
  for (let index = 0; index < LblImageArr.length; index++) {
    const eachElement = LblImageArr[index];
    loadImage(mapbox, eachElement.image, eachElement.name);
  }
};
