import './hotListDetailRow.scss';
import { IonButton, IonCol, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react';
import { alertCircleOutline, statsChart } from 'ionicons/icons';
import moment from 'moment';
import React, { MouseEvent } from 'react';
import { kFormatter, parseValue, titleCase } from '../../../common/include';
import { InsightValue } from '../../../common/types';

const HotListDetailRow: React.FC<any> = ({
  rowData,
  isSelected,
  isFilterDataPresent,
  isFavorite,
  isLoading,
  onListSelect,
  onFavorite,
  selectedTimeFrame,
  selectedInsight,
  listType,
  advancedViewInsights,
  handleSelectItem,
  getHeatBean,
  isAdvancedDisplayState,
}) => {
  const hotListRowInsights = isAdvancedDisplayState ? advancedViewInsights : [selectedInsight];

  const renderCol: any = (rowData: any, insightKey: InsightValue) => {
    const unparsedValue = ['sleeper_slot_rating', 'volatility_rating'].includes(insightKey)
      ? rowData[`${insightKey}_ntile`]
      : rowData[insightKey];
    const parsedValue = parseValue(unparsedValue, insightKey);

    const isJackpotsInsight = insightKey === 'jackpot_value';

    return (
      <div className="hot-list-row" key={insightKey}>
        <div className="game-data-td">
          <span className="row-insight-value">
            {parsedValue}

            {isJackpotsInsight && listType !== 'myJackpot' && (
              <span className="jackpot_number">{rowData['jackpots']}</span>
            )}
          </span>
          {!isLoading && insightKey !== 'session_played' && getHeatBean(rowData, insightKey)}
          {isLoading && <div className="box-indicator"></div>}
        </div>
      </div>
    );
  };

  const handleRowClick = (e: MouseEvent<HTMLElement>) => {
    handleSelectItem(rowData.slot_number);
  };

  const onFavoriteClicked = (e: any) => {
    e.stopPropagation();
    onFavorite(rowData);
  };

  const onSlotNumberClicked = (e: any) => {
    e.stopPropagation();
    onListSelect(rowData, 'showTrendsFirst');
  };

  return (
    <div className={`slot-table-row ${isSelected ? 'selected-row' : ''}`} onClick={handleRowClick}>
      <IonGrid className="ion-no-padding">
        <IonRow className="ion-align-items-center">
          <IonCol size="4">
            <div className="data-bottom-alignment">
              <div className="table-data-flex-box">
                <span className="click-target" onClick={onSlotNumberClicked}>
                  {rowData.slot_number}
                </span>
                <span
                  className={`like-btn ${isFavorite ? 'like-active' : ''}`}
                  onClick={onFavoriteClicked}
                />
                {rowData?.is_active ? null : (
                  <span className="game-inactive">
                    <IonLabel title="Inactive">
                      <IonIcon icon={alertCircleOutline} className="ion-icons-style" />
                    </IonLabel>
                  </span>
                )}
              </div>
            </div>
            <div
              className={[
                !rowData.last_jackpot_age ? 'game-name game-name-noage ' : 'game-name ',
                'click-target',
              ].join(' ')}
            >
              <span className="game-title" onClick={() => onListSelect(rowData, 'showTrendsFirst')}>
                {titleCase(rowData.game_name)} <span className="game-denom">{rowData.denom}</span>
              </span>
            </div>
          </IonCol>
          {hotListRowInsights.map((insight: string) => renderCol(rowData, insight))}

          <IonCol size="2" className="hot-list__active-col right-flex-align">
            <div className="table-data-flex-box">
              <IonButton
                fill="clear"
                size="small"
                color="medium"
                onClick={() => onListSelect(rowData, 'showGraphFirst')}
              >
                <IonIcon slot="icon-only" icon={statsChart}></IonIcon>
              </IonButton>
            </div>
          </IonCol>
        </IonRow>
        <IonRow className="ion-align-items-center">
          <IonCol size="4" className="jkpt-date-lbel padding-right-15">
            {(selectedTimeFrame === 'sinceLastJackpot' || listType === 'myJackpot') && (
              <div className="data-lst">
                {selectedTimeFrame === 'sinceLastJackpot' &&
                  rowData.last_jackpot_age &&
                  rowData.last_jackpot_age}
                {listType === 'myJackpot' &&
                  rowData.jackpot_datetime &&
                  moment(rowData.jackpot_datetime).format('DD MMM, HH:mm')}
              </div>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default HotListDetailRow;
