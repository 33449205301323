import React from 'react';
import './terms-and-conditions.scss';

const TermsAndConditions: React.FC<{ onClosePopUp: () => void; urlName: string }> = (props) => {
  const { onClosePopUp, urlName } = props;
  return (
    <div className="dark-popup">
      <div className="dark-popup-content dark-popup-content-white">
        <div className="iframe-container ">
          <iframe
            src={`./${urlName}.html`}
            width="100%"
            height="100%"
            frameBorder="0"
            className="responsive-iframe"
          ></iframe>
        </div>
      </div>
      <div className="dark-popup-footer" onClick={onClosePopUp}>
        Close
      </div>
    </div>
  );
};

export default TermsAndConditions;
