import { IState } from 'appRedux/createStore';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';

interface ProtectedRouteProps {
  component: React.ComponentClass<any> | React.FunctionComponent<any>;
  path: string;
}

export const ProtectedRoute = ({ component: Comp, path, ...rest }: ProtectedRouteProps) => {
  const isSlotcheckInternal = useSelector<IState>((state) =>
    state.app.persistedState.userInfo?.groups?.includes('SlotcheckInternal')
  );

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return isSlotcheckInternal ? <Comp {...props} /> : <Redirect to="/" />;
      }}
    />
  );
};

export const ProtectedRouteCasinoAdmin = ({
  component: Comp,
  path,
  ...rest
}: ProtectedRouteProps) => {
  const isLOTCAdmin = useSelector<IState>((state) =>
    state.app.persistedState.userInfo?.groups?.includes('LOTCAdmin')
  );
  const isEurekaAdmin = useSelector<IState>((state) =>
    state.app.persistedState.userInfo?.groups?.includes('EurekaAdmin')
  );

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return isLOTCAdmin || isEurekaAdmin ? <Comp {...props} /> : <Redirect to="/" />;
      }}
    />
  );
};
