import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { playerTypeEnum } from 'common/commonEnum';
import { IState } from 'appRedux/createStore';
import { InsightFilterEnum, InsightDisplayType } from 'appRedux/models/favoritesModels';
import { FavoritesReduxCommandCreator } from 'appRedux/actions/favoritesCommandCreator';
import { COLORS } from 'common/COLORS';

import DropDown from 'components/DropdownBar/dropDown/dropDown';
import FilterDropdownSelectOption from 'components/DropdownBar/dropDown/FilterDropdownSelectOption';

export const insightOptions = [
  {
    label: 'Payout to Player (POP)',
    description: 'POP',
    value: InsightFilterEnum.PayoutToPlayer,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
    displayType: InsightDisplayType.PercentageNoFormat,
  },
  {
    label: 'Return To Player (RTP)',
    description: 'RTP',
    value: InsightFilterEnum.ReturnToPlayer,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
    displayType: InsightDisplayType.Percentage,
  },
  {
    label: 'Spins',
    description: 'Spins',
    value: InsightFilterEnum.Spins,
    playerTypeAccess: [playerTypeEnum.slotCheck, playerTypeEnum.slotCheckPro],
    displayType: InsightDisplayType.Number,
  },
  {
    label: 'Volatility (VOL)',
    description: 'VOL',
    value: InsightFilterEnum.Volatility,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
    displayType: InsightDisplayType.HeatTileLabel,
  },
  {
    label: 'Jackpot (JKPT)',
    description: 'JKPT',
    value: InsightFilterEnum.Jackpot,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
    displayType: InsightDisplayType.Money,
  },
  {
    label: 'Money Won',
    description: 'Won',
    value: InsightFilterEnum.MoneyWon,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
    displayType: InsightDisplayType.Money,
  },
  {
    label: 'Played',
    description: 'Played',
    value: InsightFilterEnum.MoneyPlayed,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
    displayType: InsightDisplayType.Money,
  },
  {
    label: 'Wins Per Spin ($ W/S)',
    description: '$ W/S',
    value: InsightFilterEnum.AverageWonPerSpin,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
    displayType: InsightDisplayType.Money,
  },
  {
    label: 'Winning Spins (% W/S)',
    description: '% W/S',
    value: InsightFilterEnum.WinRate,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
    displayType: InsightDisplayType.Percentage,
  },
];

const InsightFilter: React.FC = () => {
  const dispatch = useDispatch();

  const favoritesCommands = FavoritesReduxCommandCreator(dispatch);
  const insightFilterValue = useSelector<IState>(
    (state) => state.app.persistedState.favorites.insightFilter
  ) as InsightFilterEnum;

  const getInsightDisplayType = (value: InsightFilterEnum): InsightDisplayType | undefined => {
    const option = insightOptions.find((option) => option.value === value);

    return option?.displayType;
  };

  const handleChange = ({ value }: { value: InsightFilterEnum }) => {
    favoritesCommands.SetInsightFilter(value);

    const displayType = getInsightDisplayType(value);
    if (displayType) {
      favoritesCommands.SetInsightDisplayType(displayType);
    }
  };

  return (
    <div className="insights-selector drop-down-select-box filters-dropdown">
      <DropDown
        textAlign="left"
        displayDescription
        onChange={handleChange}
        value={insightFilterValue}
        defaultOption="pop"
        options={insightOptions}
        bgColor={COLORS.primaryColor}
        CustomSelectOption={FilterDropdownSelectOption}
      />
    </div>
  );
};

export default InsightFilter;
