import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { clearState } from 'localStorage';
import { ReduxCommandCreator } from 'appRedux/actions';
import { useDispatch } from 'react-redux';
import { useIonRouter } from '@ionic/react';

const LogoutPage = () => {
  const router = useIonRouter();
  const dispatch = useDispatch();
  const commands = ReduxCommandCreator(dispatch);

  useEffect(() => {
    let isIgnore = false;
    Auth.signOut()
      .then(() => {
        if (!isIgnore) {
          commands.ClearStore();
          clearState();
          router.push('/');
        }
      })
      .catch((error) => {
        console.error('Error signing out: ', error);
      });

    return () => {
      isIgnore = true;
    };
  }, []);

  return <></>;
};

export default LogoutPage;
export { LogoutPage };
