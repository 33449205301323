/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useEffect, useState } from 'react';
import './time-frame-selector.scss';
import Dropdown from '../dropDown/dropDown';
import FilterDropdownSelectOption from '../dropDown/FilterDropdownSelectOption';

import { Timeframe } from 'config/Timeframes';
import { TimeFrameValue, TimeFrameValues } from '../../../common/types';
import { config } from '../../../config/config';
import { COLORS } from '../../../common/COLORS';

const descriptions: { [key in TimeFrameValue]?: string } = {
  hours1: 'Last 1 Hour',
  hours4: 'Last 4 Hours',
  hours8: 'Last 8 Hours',
  hours24: 'Last 24 Hours',
  weeks1: 'Last 7 Days',
  months1: 'Last 30 Days',
};

interface TimeFrameSelectorProps {
  placeholder?: string;
  value?: string;
  onSelect: any;
  customCls?: string;
  isDisabled?: boolean;
  restrictions?: TimeFrameValues;
  additionalTimeFrame?: any;
  isShowShortName: boolean;
  timeFrames?: Timeframe[];
}

const TimeFrameSelector: React.FC<TimeFrameSelectorProps> = ({
  placeholder = 'Select a Time Frame',
  value,
  onSelect,
  isDisabled,
  restrictions,
  additionalTimeFrame,
  customCls = '',
  timeFrames = config.timeframes,
}) => {
  const [allTimeFrames, setAllTimeFrames] = useState(timeFrames);

  useEffect(() => {
    const combinedTimeFrames = [...timeFrames, ...additionalTimeFrame].sort(
      (a, b) => Number(a.display_order) - Number(b.display_order)
    );

    setAllTimeFrames(combinedTimeFrames);
  }, [additionalTimeFrame, timeFrames]);

  const onChange: any = (evt: any) => {
    onSelect(evt);
  };

  const filteredTimeFrames = allTimeFrames.filter((timeFrame: any) => {
    if (timeFrame.value === restrictions) return false;
    if ((restrictions as TimeFrameValues)?.some?.((restriction) => restriction === timeFrame.value))
      return false;
    return true;
  });

  const options = filteredTimeFrames?.map((option: Timeframe) => {
    if (option.value in descriptions) {
      option.description = descriptions[option.value] as TimeFrameValue;
    }

    switch (option.description) {
      case 'Since Last Jackpot':
        option.description = 'SLJ';
        break;
      case 'Since Last Visit':
        option.description = 'SLV';
        break;
    }

    return option;
  });

  return (
    <div
      home-page-tour-step="timeframes"
      className={`timeframe-selector drop-down-select-box filters-dropdown ${customCls}`}
    >
      <Dropdown
        textAlign="right"
        displayDescription
        options={options}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        bgColor={COLORS.primaryColor}
        styleClass={customCls}
        isDisabled={isDisabled}
        CustomSelectOption={FilterDropdownSelectOption}
      />
    </div>
  );
};

export default TimeFrameSelector;
