import './insightFieldSelector.scss';
import { ReactSelectOption } from '../../../common/types';
import { config } from '../../../config/config';
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { useCallback, useState } from 'react';

interface InsightFieldSelectorProps {
  onChange?: (options: ReactSelectOption[]) => void;
  options?: ReactSelectOption[];
  value?: ReactSelectOption[]
}

const orderedSlotInsights = config.slotInsights.sort((a, b) => {
  return a.display_order - b.display_order;
});
const insightFieldSelectorOptions = orderedSlotInsights
  .map((insight) => ({ value: insight.value, label: insight.insightFieldsSelectorLabel }))
  .filter((insight) => insight.value !== 'jackpots');
const InsightFieldSelector: React.FC<InsightFieldSelectorProps> = ({
  onChange = () => {},
  options = insightFieldSelectorOptions,
  value
}) => {
  const blockEl = 'm-multi-select';

  const selectedValue = value || [];
  const getInsightsState = useCallback(() => {
    return selectedValue.map((m) => m.value);
  }, []);

  const [displayAll, setDisplayAll] = useState<Array<any>>(getInsightsState());

  const changeHandler = (ev: { detail: { value: Array<any> } }) => {
    if (Array.isArray(ev?.detail?.value)) {
      if (ev?.detail?.value.length >= 6 || !ev?.detail?.value.length) return;
      const finalArr = insightFieldSelectorOptions.filter(({ value, label }) =>
        ev.detail.value.some((exclude) => exclude === value)
      );
      if (finalArr.length > 0) {
        setDisplayAll(ev?.detail.value);
        onChange(finalArr);
      }
    }
  };
  return (
    <div className={blockEl}>
      <IonItem lines="none" color="transparent" className="denom-class ion-no-padding">
        {/* <IonCol className="insight-fields-selector-label">
          <IonImg className="selector-icon" src="assets/images/fields-selector-icon.svg" />
        </IonCol> */}
        <IonLabel className="lbl-denom-disable">Insights (max. 5)</IonLabel>
        <IonSelect
          className={`${blockEl}__select`}
          multiple={true}
          onIonChange={(e) => changeHandler(e)}
          value={displayAll}
        >
          {options?.map((opt, index) => {
            return (
              <IonSelectOption key={`option-key-${index}`} value={opt.value}>
                {opt.label}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      </IonItem>
    </div>
  );
};

export default InsightFieldSelector;
