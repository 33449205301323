import { IonIcon, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { homeOutline, personOutline, trophyOutline } from 'ionicons/icons';
import { Redirect, Route, useLocation } from 'react-router';
import LeaderBoardTab from 'pages/LeaderBoardTab';
import HotListTab from 'pages/HotListTab';
import NewsTab from 'pages/NewsTab';
import useWhiteLabelStyles, { WHITE_LABEL_TYPE_ENUM } from 'hooks/useWhiteLabelStyles';
import { useDispatch, useSelector } from 'react-redux';
import { IOrientationInfo, IState } from 'appRedux/createStore';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Home } from './views/home';
import SlotoverviewTab from './views/slotDetail/SlotoverviewTab';
import { SlotHistoryReduxCommandCreator } from 'appRedux/actions/slotHistoryCommandCreator';
import { Favorites } from 'pages/Favorites/favorites';
import { ListReportEnum } from 'common/common';
import moment from 'moment';
import { ICasinoSchema } from 'appRedux/models/casinoModels';
import { SlotReduxCommandCreator } from 'appRedux/actions/slotCommandCreator';
import { selectAuthStateTransformed } from 'appRedux/selectors';

interface IHandleTabsDidChange {
  detail: {
    tab: string;
  };
}

const TabRootPage = () => {
  const authState = useSelector(selectAuthStateTransformed);
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<string>('home');
  const handleTabsDidChange = (event: IHandleTabsDidChange) => {
    setSelectedTab(event.detail.tab);
  };
  const casinoSchema: ICasinoSchema = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema
  ) as ICasinoSchema;
  const casinoTimezoneNew = casinoSchema?.casinoTimezoneNew;
  const styles = useWhiteLabelStyles(WHITE_LABEL_TYPE_ENUM.CANVAS);
  const isLandscape = useSelector<IState>(
    (state) => state.app.persistedState.orientationState?.isLandscape
  ) as IOrientationInfo;
  const dispatch = useDispatch();
  const historyCommands = SlotHistoryReduxCommandCreator(dispatch);
  const slotCommands = SlotReduxCommandCreator(dispatch);
  const handleClick = (event: Event) => {
    historyCommands.ClearSlotHistory();
    slotCommands.ClearSelectedSlots();
  };

  useEffect(() => {
    if (!location.pathname.includes('tabs')) {
      setSelectedTab('');
    }
    if (location.pathname.includes('/home')) {
      setSelectedTab('home');
    }
    if (
      location.pathname.includes('/tabs/favorites') ||
      location.pathname.includes('/tabs/myPlay') ||
      location.pathname.includes('/tabs/myJackpots')
    ) {
      setSelectedTab('favorites');
    }
    if (location.pathname.includes('/leaderboard')) {
      setSelectedTab('leaderboard');
    }
    if (location.pathname.includes('/detail')) {
      setSelectedTab('');
    }
  }, [location.pathname]);

  const hideTabBar = (): void => {
    const tabBar = document.getElementById('casino-tab-bar');
    if (tabBar !== null) {
      tabBar.style.display = 'none';
    }
  };

  const showTabBar = (): void => {
    const tabBar = document.getElementById('casino-tab-bar');
    if (tabBar !== null) {
      tabBar.style.display = 'flex';
    }
  };

  useEffect(() => {
    if (isLandscape) {
      hideTabBar();
    } else {
      showTabBar();
    }
  }, [isLandscape]);

  useEffect(() => {
    moment.tz.setDefault(casinoTimezoneNew);
  }, [casinoTimezoneNew]);

  return authState ? (
    <IonTabs onIonTabsDidChange={handleTabsDidChange}>
      <IonRouterOutlet>
        <Route path="/tabs/favorites" component={Favorites} />
        <Route path="/tabs/home" component={Home} />
        <Route
          path="/tabs/myPlay"
          render={() => <HotListTab selectedPage={ListReportEnum.MyPlay} />}
          exact
        />
        <Route
          path="/tabs/myJackpots"
          render={() => <HotListTab selectedPage={ListReportEnum.MyJackpot} />}
          exact
        />

        <Route path="/tabs/leaderboard" component={LeaderBoardTab} />
        <Route path="/tabs/news" component={NewsTab} />
        <Route path="/detail" component={SlotoverviewTab} />
        <Redirect exact path="/" to="/tabs/home" />
      </IonRouterOutlet>
      <IonTabBar id="casino-tab-bar" className="casino-tab-bar" slot="bottom" style={styles}>
        <IonTabButton
          tab="home"
          href="/tabs/home"
          style={styles}
          className={clsx(selectedTab === 'home' ? 'selected' : 'unselected')}
          onClick={handleClick}
        >
          <IonIcon icon={homeOutline} />
        </IonTabButton>
        <IonTabButton
          tab="favorites"
          href="/tabs/favorites"
          style={styles}
          className={clsx(selectedTab === 'favorites' ? 'selected' : 'unselected')}
        >
          <IonIcon icon={personOutline} />
        </IonTabButton>
        <IonTabButton
          tab="leaderboard"
          href="/tabs/leaderboard"
          className={clsx(selectedTab === 'leaderboard' ? 'selected' : 'unselected')}
          style={styles}
        >
          <IonIcon icon={trophyOutline} />
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  ) : (
    <Redirect to="/" />
  );
};

export default TabRootPage;
