import { IonAvatar, IonCol, IonRow } from '@ionic/react';
import { FC } from 'react';

interface MenuUserInfoProps {
  signIn: () => void;
}

const MenuNotAuthenticated: FC<MenuUserInfoProps> = ({ signIn }) => (
  <>
    <div className="sidemenu-avatar">
      <IonAvatar slot="start" className="avatar-resize">
        <img alt="" src="assets/images/user-icon.svg" className="user-img" />
      </IonAvatar>
    </div>
    <IonRow className="sidemenu-userinfo ion-no-padding">
      <IonCol
        data-cy="login-button-side-bar"
        size="12"
        className="ion-no-padding username"
        onClick={() => signIn()}
      >
        Login / Create Free Account
      </IonCol>
    </IonRow>
  </>
);

export default MenuNotAuthenticated;
