import { SubscriptionData } from '@interfaces/userSubscription';
import { IonButton } from '@ionic/react';
import clsx from 'clsx';
import { checkProUser } from 'common/common';
import React from 'react';

interface CasinoSubscriptionInfoProps {
  onNotSubscribedClick: () => void;
  isSubscribedToCasino?: SubscriptionData;
}

const CasinoSubscriptionInfo: React.FC<CasinoSubscriptionInfoProps> = ({
  onNotSubscribedClick,
  isSubscribedToCasino,
}) => {
  let isProUser = false;

  if (isSubscribedToCasino) {
    const { status = '', current_period_end = 0 } = isSubscribedToCasino;
    isProUser = checkProUser(status, current_period_end);
  }

  return (
    <IonButton
      className={clsx('my-casino-button', isSubscribedToCasino ? 'my-casinos-pro-active' : '')}
      expand="block"
      disabled={isProUser}
      onClick={() => {
        if (!isProUser) {
          onNotSubscribedClick();
        }
      }}
    >
      {isProUser ? 'PRO' : 'Upgrade to PRO'}
    </IonButton>
  );
};

export default CasinoSubscriptionInfo;
