import Storage from './storage.common';
import base64 from 'base-64';

interface IAuthCommon {
  SetAuthState(state: string): void;
  GetAuthState(): string | null;
  SetToken(token: string): void;
  GetToken(): string | null;
  GetBase64Token(): string | null;
  SetJWKToken(token: string): void;
  GetJWKToken(): string | object | null;
  GetBase64JWKToken(): string | null;
  ClearToken(): void;
  ClearJWKToken(): void;
}

const AuthCommon: IAuthCommon = {
  SetAuthState: (state) => {
    Storage.Set('loginAuthState', state);
  },
  GetAuthState: () => {
    const token = Storage.Get('loginAuthState') as string;
    return token;
  },
  SetToken: (token) => {
    Storage.Set('Token', token);
  },
  GetToken: () => {
    const token = Storage.Get('Token') as string;
    return token;
  },
  GetBase64Token: () => {
    const token = Storage.Get('Token') as string;
    if (token && token !== null) {
      return base64.decode(token);
    }
    return null;
  },
  SetJWKToken: (token) => {
    Storage.Set('JWKToken', token);
  },
  GetJWKToken: () => {
    const token = Storage.Get('JWKToken');
    return token;
  },
  GetBase64JWKToken: () => {
    const token = Storage.Get('JWKToken') as string;
    if (token && token !== null) {
      return base64.decode(token);
    }
    return null;
  },
  ClearToken: () => {
    Storage.Set('Token', null);
  },
  ClearJWKToken: () => {
    Storage.Set('JWKToken', null);
  },
};

export default AuthCommon;
