import {
  InputChangeEventDetail,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonLoading,
  IonRow,
  useIonToast,
} from '@ionic/react';
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';
import * as yup from 'yup';
import { formatPhoneNumber } from 'config/config';
import { FC, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { onPromise } from '..';
import { Link, useHistory } from 'react-router-dom';
import { IonInputCustomEvent } from '@ionic/core';
import { AuthService } from 'services/auth';
import { toastConfig } from 'config/ToastConfig';
import { useDispatch } from 'react-redux';
import { ReduxCommandCreator } from 'appRedux/actions';
import SmartLoading from 'components/SmartLoading';

interface FormData {
  user: string;
  password: string;
  passwordConfirmation: string;
}
export const create_account_schema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      '8 or more letters, 1 number, a special character (#?!@$%^&*-) and 1 capital letter'
    )
    .min(8, 'Password must include at least 8 characters')
    .max(24, 'Please use a shorter password (max: 24 characters)'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const SignupAccount: FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(create_account_schema),
  });
  const [phone, setPhone] = useState('');
  const [phoneErr, setPhoneErr] = useState('');
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [isSignupLoading, setSignUpLoading] = useState(false);
  const auth = new AuthService();
  const history = useHistory();
  const [presentToast] = useIonToast();
  const dispatch = useDispatch();
  const commands = ReduxCommandCreator(dispatch);

  const onSubmit = async (data: FormData) => {
    if (!phone) {
      setPhoneErr('Cell number is required');
      return;
    } else {
      setPhoneErr('');
    }
    const pattern = /^(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/;
    const validatePhNo = pattern.test(phone);

    if (validatePhNo) {
      setSignUpLoading(true);
      auth.CreateAccount(`1${phone?.replaceAll('-', '')}`, data.password).subscribe({
        next(data) {
          setSignUpLoading(false);
          if (data?.message === 'Success') {
            commands.SetUserInfo(phone, phone);
            history.push('/auth/verifyaccount');
          } else {
            toastConfig.color = 'danger';
            toastConfig.message = data?.errorMsg;
            void presentToast(toastConfig);
          }
        },
        error(err) {
          toastConfig.color = 'danger';
          toastConfig.message = err.message;
          void presentToast(toastConfig);
          setSignUpLoading(false);
        },
        complete() {
          setSignUpLoading(false);
        },
      });
    } else {
      setPhoneErr('Phonenumber is not valid');
    }
  };

  const inputEl = useRef<HTMLIonInputElement>(null);

  const formatPhone = (ev: IonInputCustomEvent<InputChangeEventDetail>) => {
    const value = ev.target.value;
    const filteredValue = formatPhoneNumber(value);
    setPhone(filteredValue);

    const inputCmp = inputEl.current;
    if (inputCmp !== null) {
      inputCmp.value = filteredValue;
    }
  };

  return (
    <div className="m-signup__create-account">
      <div>
        <form
          id="confirm-page"
          onSubmit={onPromise(handleSubmit((data: FormData) => void onSubmit(data)))}
          className="form-modal"
        >
          <IonGrid>
            <IonRow>
              <IonCol>
                <div className="header-bar">
                  <span className="heading4 signup-box-title primary-color-text">
                    Create Free Account
                  </span>
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <span className="button-text primary-color-text">Mobile Number</span>
                <div className="phone-group">
                  <div>
                    <div className="input-group country-code">
                      <span className="us-flag primary-color-text">+1</span>
                    </div>
                  </div>
                  <div>
                    <div className="input-group cy-username-field-group">
                      <IonInput
                        type="text"
                        autocomplete="tel"
                        maxlength={12}
                        placeholder="(###) ### - ####"
                        value={phone}
                        data-cy="username-text-field"
                        onIonChange={(ev) => formatPhone(ev)}
                        ref={inputEl}
                      />
                      {phoneErr && <div className="field-validation-message">{phoneErr}</div>}
                    </div>
                  </div>
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <span className="button-text primary-color-text">New Password</span>
                <div className="input-group">
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange } }) => (
                      <div className="input-row">
                        <IonInput
                          type={showPasswordNew ? 'text' : 'password'}
                          placeholder=""
                          onIonChange={onChange}
                          autocomplete="new-password"
                        />

                        <div
                          className="input-icon-box"
                          onClick={() => {
                            setShowPasswordNew((showPasswordNew) => !showPasswordNew);
                          }}
                        >
                          <IonIcon icon={showPasswordNew ? eyeOffOutline : eyeOutline} />
                        </div>
                      </div>
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.password && errors.password.message && (
                    <div className="field-validation-message">{errors.password.message}</div>
                  )}
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <span className="button-text primary-color-text">Confirm Password</span>
                <div className="input-group">
                  <Controller
                    name="passwordConfirmation"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange } }) => (
                      <>
                        <IonInput
                          type={showPasswordConfirm ? 'text' : 'password'}
                          placeholder=""
                          onIonChange={onChange}
                          autocomplete="new-password"
                        />

                        <div
                          className="input-icon-box"
                          onClick={() => {
                            setShowPasswordConfirm((showPasswordConfirm) => !showPasswordConfirm);
                          }}
                        >
                          <IonIcon icon={showPasswordConfirm ? eyeOffOutline : eyeOutline} />
                        </div>
                      </>
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors?.passwordConfirmation?.message && (
                    <div className="field-validation-message">
                      {errors.passwordConfirmation.message}
                    </div>
                  )}
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonButton type="submit" className="submit-btn">
                  Next
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
        <div className="create-account-link">
          <span>Already have an account? </span>
          <Link to="/">
            <span className="unregister" data-cy="login-button-mobile-reg">
              Login
            </span>
          </Link>
        </div>
      </div>

      <SmartLoading loading={isSignupLoading} />
    </div>
  );
};
