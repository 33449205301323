interface IStorageCommon {
  Set(name: string, value: any): void;
  Get(name: string): string | object | null;
  SetSession(name: string, value: any): boolean;
  GetSession(name: string): string | object | null;
}
const storageCommon: IStorageCommon = {
  Set: (name, value) => {
    let data = null;
    try {
      data = JSON.stringify(value);
    } catch (ex) {
      data = value as string;
    }
    localStorage.setItem(name, data);
    return true;
  },
  Get(name) {
    try {
      const json = localStorage.getItem(name);
      if (json != null) {
        let data = null;
        try {
          data = JSON.parse(json) as object;
        } catch (ex) {
          data = json;
        }
        return data as object;
      }
      return null;
    } catch (ex) {
      return null;
    }
  },
  SetSession: (name, value) => {
    let data = null;
    try {
      data = JSON.stringify(value);
    } catch (ex) {
      data = value as string;
    }
    localStorage.setItem(name, data);
    return true;
  },
  GetSession(name) {
    try {
      const json = localStorage.getItem(name);
      if (json != null) {
        let data = null;
        try {
          data = JSON.parse(json) as object;
        } catch (ex) {
          data = json;
        }
        return data;
      }
      return null;
    } catch (ex) {
      return null;
    }
  },
};

export default storageCommon;
