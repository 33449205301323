import './advancedViewHeader.scss';
import { IonCol, IonIcon, IonImg, IonLabel, IonRow, IonText } from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { closeCircleOutline } from 'ionicons/icons';

const AdvancedViewHeader: React.FC<any> = ({
  isAdvancedDisplayState,
  advancedViewInsights,
  selectedSlotsCount,
  handleInsightFieldsChange,
  handleClearSelectedSlots,
  handleCompareSlots,
  isComparingSlots,
  listType,
  datasets,
}) => {
  const fieldSelectorValues = advancedViewInsights.map((insight: string) => ({
    value: insight,
    label: insight,
  }));
  const isCountCompare = datasets.length === selectedSlotsCount ? false : true;
  const isShowCount = isCountCompare
    ? datasets.length + '/' + selectedSlotsCount
    : selectedSlotsCount;
  return (
    <>
      <IonRow className="advanced-view-header">
        <IonCol className="advanced-view-buttons">
          {!!selectedSlotsCount && (
            <>
              <button type="button" className="bg-compare" onClick={handleClearSelectedSlots}>
                <IonLabel className="selected-slots-btn-label">
                  {isComparingSlots && (
                    <>
                      <span>{isShowCount}</span>Selected
                    </>
                  )}
                  {!isComparingSlots && (
                    <>
                      <span>{selectedSlotsCount} </span> Selected
                    </>
                  )}
                </IonLabel>
                <IonIcon icon={closeCircleOutline} className="clear-selected-slots-btn" />
              </button>
              {selectedSlotsCount > 1 && !isComparingSlots && (
                <button
                  type="button"
                  className="click-target bg-compare"
                  onClick={handleCompareSlots}
                >
                  <FontAwesomeIcon icon={faList} className="click-target yellow fa-list" />
                  <IonLabel className="compare-btn-label"> Compare </IonLabel>
                </button>
              )}
              {!isAdvancedDisplayState && isComparingSlots && (
                <div className="advanced-view-hint">
                  <IonImg src="assets/images/rotate-device-icon.svg" />
                  <IonText>Rotate</IonText>
                </div>
              )}
            </>
          )}
        </IonCol>
        {/* {isAdvancedDisplayState && (
          <IonCol className="fields-selector-container">
            <InsightFieldSelector
              onChange={handleInsightFieldsChange}
              value={fieldSelectorValues}
            />
          </IonCol>
        )} */}
      </IonRow>
    </>
  );
};

export default AdvancedViewHeader;
