import { IonButton, IonImg } from '@ionic/react';
import React from 'react';
import './no-playerid-link.scss';
import { useSelector } from 'react-redux';
import { IState } from 'appRedux/createStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';

export enum SCREEN_TYPE {
  Leaderboard = 'Leaderboard',
  MyPlay = 'MyPlay',
  JKPTS = 'JKPTS',
}
interface NoPlayerIDProps {
  screenType?: SCREEN_TYPE;
  onClick?: () => void;
}

const NoPlayerIDLink: React.FC<NoPlayerIDProps> = ({ screenType }) => {
  const casinoId = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema?.kpCasinoPk
  ) as number;
  const routerLink = `/auth/link-player/${casinoId}`;

  return (
    <div className="no-player">
      <div className="child-no-player">
        {casinoId.toString() === '1' && (
          <IonImg src="assets/images/noPlayerIDWhite.png" className="no-PlayerID" />
        )}
        {casinoId.toString() === '2' && (
          <IonImg src="assets/images/noPlayerIDEureka.png" className="no-PlayerID" />
        )}

        <p
          className={clsx(
            'lotc-description',
            casinoId.toString() === '1' ? 'lotc-padding' : 'eureka-padding'
          )}
        >
          {screenType === SCREEN_TYPE.Leaderboard && 'Link your player card to see the Leaderboard'}
          {screenType === SCREEN_TYPE.MyPlay && 'Link your player card to track your play'}
          {screenType === SCREEN_TYPE.JKPTS && 'Link your player card to see your jackpots'}
        </p>

        <IonButton className="player-card-button-add" routerLink={routerLink}>
          <FontAwesomeIcon icon={faLink} className="fa-link" />
          Link Your Player ID
        </IonButton>

        {/* <IonButton className="enternow-button" routerLink={routerLink}>
          <span>Enter Now</span>
        </IonButton> */}
      </div>
    </div>
  );
};

export default NoPlayerIDLink;
