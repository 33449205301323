import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonPage,
  IonRow,
  IonToolbar,
} from '@ionic/react';

const Support: React.FC = () => {
  return (
    <IonPage className="app-parent">
      <div className="slotcheck-header">
        <IonHeader className="small-header">
          <IonToolbar>
            <IonButton
              fill="clear"
              onClick={() => {
                history.back();
              }}
              className="icon-back"
              slot="start"
            >
              <IonImg src="assets/images/back-icon.svg" />
            </IonButton>
            <div className="logo header-title">Support</div>
          </IonToolbar>
        </IonHeader>
      </div>
      <IonContent>
        <IonGrid className="m-contact-us">
          <IonRow>
            <IonCol>
              <div className="contact-container">
                <p className="contact-title">
                  For issues, questions, or comments, please contact us via.
                </p>
                <div className="mail-link">
                  <a className="mailto" href="mailto:info@slotcheck.com">
                    support@slotcheck.com
                  </a>
                </div>
                <p className="contact-title">
                  When reporting an issue, please include the following information:
                </p>
                <ol className="order-list">
                  <li className="list-item">
                    Clearly state your issue with as much specificity as possible.
                  </li>
                  <li className="list-item">Include screen shot(s).</li>
                  <li className="list-item">
                    What device were you using when issue occured? Make and model.
                  </li>
                </ol>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default Support;
