import variables from '../variables-styles.module.scss';

export const heatBeanRatings = {
  6: {
    color: variables.hotColor,
    light_color: `rgba(${variables.hotColor}, 0.2)`,
    label: 'Hot',
    label_volatility: 'High',
    is_show: true,
    text_lbl_volatility: 'High',
    text_sleeper_slot: 6,
  },
  5: {
    color: variables.warmColor,
    light_color: `rgba(${variables.warmColor},0.2)`,
    label: '',
    label_volatility: '',
    is_show: true,
    text_lbl_volatility: 'High',
    text_sleeper_slot: 5,
  },
  4: {
    color: variables.neutralWarmColor,
    light_color: `rgba(${variables.neutralWarmColor}, 0.2)`,
    label: '',
    label_volatility: '',
    is_show: true,
    text_lbl_volatility: 'Medium',
    text_sleeper_slot: 4,
  },
  3: {
    color: variables.neutralColdColor,
    light_color: `rgba(${variables.neutralColdColor}, 0.2)`,
    label: '',
    label_volatility: '',
    is_show: true,
    text_lbl_volatility: 'Medium',
    text_sleeper_slot: 3,
  },
  2: {
    color: variables.coldColor,
    light_color: `rgba(${variables.coldColor}, 0.2)`,
    label: '',
    label_volatility: '',
    is_show: true,
    text_lbl_volatility: 'Low',
    text_sleeper_slot: 2,
  },
  1: {
    color: variables.veryColdColor,
    light_color: `rgba(${variables.veryColdColor}, 0.2)`,
    label: 'Cold',
    label_volatility: 'Low',
    is_show: true,
    text_lbl_volatility: 'Low',
    text_sleeper_slot: 1,
  },
  0: {
    color: 'hsl(0,0%,50%)',
    light_color: 'hsla(0,0%,50%,0.2)',
    label: '',
    label_volatility: '',
    is_show: false,
    text_lbl_volatility: '',
    text_sleeper_slot: '',
  },
};

export const hotColdRatings = {
  6: heatBeanRatings[6],
  1: heatBeanRatings[1],
  0: heatBeanRatings[0],
};
