/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { IonCol } from '@ionic/react';
import './userBadge.scss';
import '../../pages/LeaderBoardTab/views/Leaderboard/leaderboard.scss';

interface UserBadgeProps {
  aliasName: string;
  aliasInitial: string;
  isProAccount: boolean;
  playerCircleCls?: string;
}

const UserBadge: React.FC<UserBadgeProps> = ({
  aliasName,
  aliasInitial,
  isProAccount,
  playerCircleCls = 'user-avatar-color5',
}) => {
  const playerTypeIconCls = isProAccount ? 'slot-pro-player-icon' : 'content-hide';
  return (
    <IonCol size="6" class="center-flex-align">
      <div className="table-data-flex-container player-name-container">
        <span className={`player-alias-box ${playerCircleCls}`}>
          {aliasInitial}
          <div className={`alias-icon ${playerTypeIconCls}`} />
        </span>
        <span className="player-alias-name">{aliasName}</span>
      </div>
    </IonCol>
  );
};

export default UserBadge;
