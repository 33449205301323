import React, { useEffect } from 'react';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';

const config: AwsRumConfig = {
  sessionSampleRate: 1,
  identityPoolId: 'us-east-2:c3ec7475-94ef-441e-9b6a-f059aa3b51e1',
  endpoint: 'https://dataplane.rum.us-east-2.amazonaws.com',
  telemetries: ['performance', 'errors', 'http'],
  allowCookies: true,
  enableXRay: false,
};

const awsRum = new AwsRum('725ff41e-b0f1-4584-a62c-d8544a9045df', '1.0.0', 'us-east-2', config);

export enum RumPageIdEnum {
  Home = 'home',
  Details = 'details',
}

interface PageAttributes {
  casinoID: number;
  [key: string]: string | number | boolean | undefined;
}

interface RumTrackerProps {
  pageId: RumPageIdEnum;
  pageAttributes: PageAttributes;
}

const RumTracker: React.FC<RumTrackerProps> = ({ pageId, pageAttributes }) => {
  let isMounted = true;
  useEffect(() => {
    if (isMounted) {
      awsRum.recordPageView({
        pageId: pageId,
        pageAttributes: pageAttributes,
      });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return null;
};

export default RumTracker;
