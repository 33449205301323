export enum AUTH_STATE {
  AUTHENTICATED = 'AUTHENTICATED',
  LOGGED_OUT = 'LOGGED_OUT',
  SIGN_IN = 'SIGN_IN',
  CREATE_USER = 'CREATE_USER',
  VERIFY_CODE = 'VERIFY_CODE',
  VERIFIED = 'VERIFIED',
  PLAYER_CARD = 'PLAYER_CARD',
}

export enum REPORT_TYPE_ENUM {
  HOT_SLOTS = 'HOT_SLOTS',
  TYPES = 'TYPES',
  ALL = 'ALL',
  GAMES = 'GAMES',
  MAPS = 'MAPS',
}


export type ReportType = keyof typeof REPORT_TYPE_ENUM;
export type AuthStateType = keyof typeof AUTH_STATE;