import { ICasinoReport } from 'appRedux/models/casinoModels';

// Split slots into 6 bins to designate their heat rating
export interface BinnedSlots {
  1: [];
  2: [];
  3: [];
  4: [];
  5: [];
  6: [];
}

interface MinMax {
  min: number;
  max: number;
}

interface Ranges {
  1: MinMax;
  2: MinMax;
  3: MinMax;
  4: MinMax;
  5: MinMax;
  6: MinMax;
}

interface Stats {
  avg: number;
  stddev: number;
  min: number;
  max: number;
}

const getRanges = (stats: any): Ranges => ({
  6: {
    min: stats.avg + 0.75 * stats.stddev,
    max: stats.max,
  },
  5: {
    min: stats.avg + 0.25 * stats.stddev,
    max: stats.avg + 0.75 * stats.stddev,
  },
  4: {
    min: stats.avg,
    max: stats.avg + 0.25 * stats.stddev,
  },
  3: {
    min: stats.avg - 0.25 * stats.stddev,
    max: stats.avg,
  },
  2: {
    min: stats.avg - 0.75 * stats.stddev > 0 ? stats.avg - 0.75 * stats.stddev : 0,
    max: stats.avg - 0.25 * stats.stddev > 0 ? stats.avg - 0.25 * stats.stddev : 1,
  },
  1: {
    min: stats.min,
    max: stats.avg - 0.75 * stats.stddev > 0 ? stats.avg - 0.75 * stats.stddev : 0,
  },
});

const inRange = (val: any, rangeNumber: any, ranges: any) => {
  if (rangeNumber === 1 && val >= ranges[rangeNumber].min && val <= ranges[rangeNumber].max)
    return true;
  if (val > ranges[rangeNumber].min && val <= ranges[rangeNumber].max) return true;
  return false;
};

const groupByKey = (array: any, key: any) => {
  return array.reduce((hash: any, obj: any) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) });
  }, {});
};

export default function (slots: ICasinoReport[], prop: string): BinnedSlots {
  let filterData: any = {};

  if (slots) {
    const gMap = groupByKey(slots, prop + '_ntile');

    filterData = gMap;

    return filterData;
  }
  return filterData;
}

export const determineHeatBin = (val: any, stats: Stats): number => {
  const ranges = getRanges(stats);

  if (val === 0) {
    return 0;
  } else if (inRange(val, 1, ranges)) {
    return 1;
  } else if (inRange(val, 2, ranges)) {
    return 2;
  } else if (inRange(val, 3, ranges)) {
    return 3;
  } else if (inRange(val, 4, ranges)) {
    return 4;
  } else if (inRange(val, 5, ranges)) {
    return 5;
  } else if (inRange(val, 6, ranges)) {
    return 6;
  } else if (val >= stats.max) {
    return 6;
  } else if (val <= stats.min) {
    return 1;
  } else {
    return 0;
  }
};
