/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IDenomination } from './../common/types.d';
import {
  copySharp,
  personOutline,
  heartOutline,
  headsetOutline,
  shieldCheckmarkOutline,
  logInOutline,
  notificationsOutline,
} from 'ionicons/icons';
import { devConfig } from './config.dev';
import { prodConfig } from './config.prod';
import { heatBeanRatings, hotColdRatings } from './HeatBeanRatings';
import { HotListTable } from './HotListTable';
import { hotSlotsLabels, Insights, myPlayInsights, snapshots } from './Insights';
import { AllTimeFrames, TimeFrames } from './Timeframes';
import { playerTypeEnum } from 'common/commonEnum';

export const routes = {
  appPages: [
    {
      title: 'Hot Slots',
      icon: copySharp,
      path: '/hot-slots',
      selected: '/hot-slots',
    },
  ],
  userPages: [
    {
      title: 'Casinos',
      icon: heartOutline,
      path: '/auth/choosecasinos',
      selected: '/auth/choosecasinos',
    },
    {
      title: 'Profile',
      icon: personOutline,
      path: '/profile',
      selected: '/profile',
    },
    {
      title: 'Notifications',
      icon: notificationsOutline,
      path: '/notifications',
      selected: '/notifications',
    },
  ],
  infoPages: [
    {
      title: 'Support',
      icon: headsetOutline,
      path: '/contact-us',
      selected: '/contact-us',
    },
    {
      title: 'Terms & Privacy',
      icon: shieldCheckmarkOutline,
      path: '/terms',
      selected: '/terms',
    },
  ],
  logoutPages: [
    {
      title: 'Logout',
      icon: logInOutline,
      path: '/logout',
      selected: '/logout',
    },
  ],
};

export default function baseEnv() {
  let env_amplifyAuth;
  switch (process.env.REACT_APP_HOST_TYPE) {
    case 'prod':
      env_amplifyAuth = prodConfig.Auth;
      break;
    case 'dev':
      env_amplifyAuth = devConfig.Auth;
      break;
    default:
      env_amplifyAuth = devConfig.Auth;
      break;
  }

  return {
    amplify: env_amplifyAuth,
  };
}

export const Denom = [
  {
    id: 1,
    label: '$ 0.01',
    value: '$.01',
    field: 0.01,
  },
  {
    id: 2,
    label: '$ 0.02',
    value: '$.02',
    field: 0.02,
  },
  {
    id: 3,
    label: '$ 0.05',
    value: '$.05',
    field: 0.05,
  },
  {
    id: 4,
    label: '$ 0.10',
    value: '$.10',
    field: 0.1,
  },
  {
    id: 5,
    label: '$ 0.25',
    value: '$.25',
    field: 0.25,
  },
  {
    id: 6,
    label: '$ 1.00',
    value: '$1.00',
    field: 1.0,
  },
  {
    id: 7,
    label: '$ 5.00',
    value: '$5.00',
    field: 5.0,
  },
] as IDenomination[];

export const selectedDenom = [...Denom];

export type HeatBeanObjectKey = keyof typeof heatBeanRatings;
export type HotColdRatingsObjectKey = keyof typeof hotColdRatings;

interface Config {
  list: any;
  mapbox: any;
  kp_alias: string;
  kp_name: string;
  casino_timezone: string;
  compareAccess: any;
  timeframes: any;
  allTimeFrames: any;
  slotInsights: any;
  snapShots: any;
  heatBeanRatings: any;
  hotColdRatings: any;
  hotSlotsLabels: any;
  myPlayInsights: any;
  hotListTableRow: any;
  denom: any;
  selectedDenom: any;
  pick_your_insights: any;
  info_updates_roughly: any;
}

export const config = {
  list: {
    limit: 50,
  },
  mapbox: {
    key: 'pk.eyJ1IjoiZ3JzdG91c2xhbmQiLCJhIjoiY2tvazQ2ZTNqMDB1bDJva3ltNnp5OHJqZyJ9.LqMQqT3A0mCcERcP9AxQtA',
    styleUrl: 'mapbox://styles/grstousland/ckokgvb0200pw17mvm74ab9fk',
  },
  kp_alias: 'SP',
  kp_name: 'Slotcheck Player',
  casino_timezone: 'America/Chicago',
  compareAccess: [playerTypeEnum.slotCheckPro],
  timeframes: TimeFrames,
  allTimeFrames: AllTimeFrames,
  slotInsights: Insights,
  snapShots: snapshots,
  heatBeanRatings,
  hotColdRatings,
  hotSlotsLabels,
  myPlayInsights: myPlayInsights,
  hotListTableRow: HotListTable,
  denom: Denom,
  selectedDenom: selectedDenom,
  pick_your_insights:
    'Everyone defines "Hot Slots" differently. Below are ten categories of slot insights that slot players have told us are valuable to consider when determining what slots to play. The information presented is historical (in the recent past) and does not predict future play as slot machines generate spin results randomly.',
  info_updates_roughly:
    'KaPloint updates roughly every 5 minutes.  If you do not immediately see the slots you played, please wait and they will appear.',
};

export const getTimeCharacter = (timeframe: any) => {
  let charAt = '';
  if (timeframe === 'hours1') {
    charAt = 'in last 1 Hour';
  }
  if (timeframe === 'hours4') {
    charAt = 'in last 4 Hours';
  }
  if (timeframe === 'hours8') {
    charAt = 'in last 8 Hours';
  }
  if (timeframe === 'hours24') {
    charAt = 'in last 24 Hours';
  }
  if (timeframe === 'weeks1') {
    charAt = 'in last 7 Days';
  }
  if (timeframe === 'months1') {
    charAt = 'in last 30 Days';
  }

  if (timeframe === 'sinceLastJackpot') {
    charAt = 'Since Last Jackpot';
  }
  if (timeframe === 'sinceLastVisit') {
    charAt = 'Since Last Visit';
  }
  return charAt;
};
export const getTimeCharacterHotCold = (timeframe: any) => {
  let charAt = '';
  if (timeframe === 'hours1') {
    charAt = 'in the last 1 hour';
  }
  if (timeframe === 'hours4') {
    charAt = 'in the last 4 hours';
  }
  if (timeframe === 'hours8') {
    charAt = 'in the last 8 hours';
  }
  if (timeframe === 'hours24') {
    charAt = 'in the last 24 hours';
  }
  if (timeframe === 'weeks1') {
    charAt = 'in the last 7 days';
  }
  if (timeframe === 'months1') {
    charAt = 'in the last 30 days';
  }

  if (timeframe === 'sinceLastJackpot') {
    charAt = 'Since Last Jackpot';
  }
  if (timeframe === 'sinceLastVisit') {
    charAt = 'Since Last Visit';
  }
  return charAt;
};

export const formatPhoneNumber = (phonenumber: any): string => {
  let phone = phonenumber.replace(/\D/g, '');
  const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    phone = `${match[1]}${match[2] ? '-' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
  }
  return phone;
};

export const getInsightDetails = (insightValue: string) =>
  config.slotInsights.find(({ value }: any) => value === insightValue);
export const getTimeFrameDetails = (timeFrameValue: string) =>
  config.allTimeFrames.find(({ value }: any) => value === timeFrameValue);
