import React, { useEffect, useState } from 'react';
import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react';
import { statsChart, alertCircleOutline } from 'ionicons/icons';
import { noop } from 'lodash';
import clsx from 'clsx';

import { InsightDisplayType } from 'appRedux/models/favoritesModels';

import { LikeButton } from './LikeButton';
import { HeatTile } from './HeatTile';
import { titleCase } from 'common/include';
import { toPercentage } from 'common/toPercentage';

export interface ListItemProps {
  slotNumber: string;
  slotName: string;
  slotPrice: string;
  heatValue: string;
  heatDisplayType: InsightDisplayType;
  iconAction: (slotNumber: string) => void;
  heatRating?: number;
  isActiveSlot?: boolean;
  onClick?: () => void;
  isSelected?: boolean;
}

export const ListItem: React.FC<ListItemProps> = ({
  slotNumber,
  slotName,
  slotPrice,
  heatValue = '',
  heatDisplayType,
  heatRating = 0,
  iconAction,
  isActiveSlot = false,
  onClick = noop,
  isSelected = false,
}) => {
  const [formattedSlotName, setFormattedSlotName] = useState('');

  useEffect(() => {
    setFormattedSlotName(titleCase(slotName));
  }, [slotName]);

  const onDetailClick = (
    event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>,
    slotNumber: string
  ) => {
    event.stopPropagation();
    iconAction(slotNumber);
  };

  const getHeatDisplay = (value: string, displayType: InsightDisplayType): React.ReactNode => {
    switch (displayType) {
      case InsightDisplayType.Percentage:
        return toPercentage(value);

      case InsightDisplayType.PercentageNoFormat:
        return `${Math.round(Number(value))}%`;

      case InsightDisplayType.Number:
        return Number(value).toLocaleString();

      case InsightDisplayType.Money:
        const numValue = Number(value);

        if (numValue) {
          return `$${numValue.toFixed(2)}`;
        } else {
          return '$0.00';
        }

      case InsightDisplayType.HeatTileLabel:
        return <HeatTile heatRating={heatRating} isVolatilityLabel />;

      case InsightDisplayType.String:
      default:
        return value;
    }
  };

  return (
    <IonRow
      className={clsx(
        'favorites-container__list__item',
        isSelected ? 'favorites-container__list__item--active' : ''
      )}
      onClick={onClick}
    >
      <IonCol size="6" className="slot-info">
        <div className="slot-info__status">
          <span className="body">{slotNumber}</span>

          <LikeButton slotNumber={slotNumber}></LikeButton>
          {!isActiveSlot && (
            <IonIcon aria-label="Slot is Inactive" icon={alertCircleOutline}></IonIcon>
          )}
        </div>

        <div className="slot-info__name body">
          {formattedSlotName} <span className="subtitle2">{slotPrice}</span>
        </div>
      </IonCol>
      <IonCol size="4" className="heat-info">
        {getHeatDisplay(heatValue, heatDisplayType)}
      </IonCol>
      <IonCol size="2" className="actions">
        <IonButton
          color="medium"
          fill="clear"
          size="small"
          onClick={(event) => onDetailClick(event, slotNumber)}
        >
          <IonIcon slot="icon-only" icon={statsChart}></IonIcon>
        </IonButton>
      </IonCol>
    </IonRow>
  );
};
