import './heat-legend.scss';
import { IonCol, IonRow } from '@ionic/react';
import { InsightValue } from '../../../../common/types';
import { config } from '../../../../config/config';
import clsx from 'clsx';
const { heatBeanRatings, hotColdRatings } = config;

export enum DirectionType {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

interface HeatLegendProps {
  onClick: (heatLevel: number) => void;
  direction: DirectionType;
  selectedInsight: InsightValue;
  heatFilter: number[];
}

function HorizontalEachItem({ eachHeat, selectedInsight, heat, color }: any) {
  return (
    <>
      {eachHeat === 6 &&
        (selectedInsight.indexOf('volatility_rating') === 0 ? (
          <td className="heat-legend__label">{heat.label_volatility}</td>
        ) : (
          <td className="heat-legend__label">{heat.label}</td>
        ))}
      <td style={{ paddingRight: 2 }}>
        <div className="heat-legend-chart__color" style={{ background: color }}></div>
      </td>
      {eachHeat === 1 &&
        (selectedInsight.indexOf('volatility_rating') === 0 ? (
          <td className="heat-legend__label">{heat.label_volatility}</td>
        ) : (
          <td className="heat-legend__label">{heat.label}</td>
        ))}
    </>
  );
}

const HeatLegend: React.FC<HeatLegendProps> = ({
  onClick,
  direction,
  selectedInsight,
  heatFilter,
}) => {
  let HeatLegendKeys: any = [];
  let configHotCold: any;

  let heatLegend: any = {};
  if (selectedInsight === 'jackpots' || selectedInsight === 'jackpot_value') {
    heatLegend['6'] = heatBeanRatings['6'];
    heatLegend['5'] = heatBeanRatings['5'];
    heatLegend['4'] = heatBeanRatings['4'];
    heatLegend['0'] = heatBeanRatings['0'];
  } else {
    heatLegend = heatBeanRatings;
  }
  configHotCold = heatLegend;
  HeatLegendKeys = Object.keys(configHotCold).sort((a: any, b: any) => {
    return b - a;
  });

  const selectedOption: any = config.slotInsights.find((option: any) => {
    return option.value === selectedInsight;
  });

  const HorizontalRow = () => {
    return (
      <tr>
        {HeatLegendKeys.map(
          (eachHeat: any) =>
            configHotCold[eachHeat].is_show === true && (
              <HorizontalEachItem
                key={eachHeat}
                eachHeat={eachHeat}
                color={configHotCold[eachHeat].color}
                heat={configHotCold[eachHeat]}
                selectedInsight={selectedInsight}
              />
            )
        )}
      </tr>
    );
  };

  const VerticalRow = () => {
    return (
      <>
        {selectedInsight.indexOf('volatility_rating') === 0 ? (
          <tr>
            <td>
              <div className="heat-legend__cold">{configHotCold['6'].label_volatility}</div>
            </td>
          </tr>
        ) : (
          <tr>
            <td>
              <div className="heat-legend__cold">{configHotCold['6'].label}</div>
            </td>
          </tr>
        )}
        {HeatLegendKeys.map(
          (eachHeat: any) =>
            configHotCold[eachHeat].is_show === true &&
            direction === DirectionType.vertical && (
              <tr key={eachHeat}>
                <td>
                  <div
                    className={[
                      heatFilter.indexOf(Number(eachHeat)) !== -1 || heatFilter.length === 0
                        ? 'heat-legend__active'
                        : 'heat-legend__inactive',
                      'heat-legend__color-square',
                      'heat-legend__label__padding__left',
                      'heat-legend__margin',
                      eachHeat === '6' && ' heat-border__top-left-right ',
                      eachHeat === '1' && ' heat-border__bottom-left-right ',
                    ].join(' ')}
                    style={{ background: configHotCold[eachHeat].color }}
                    onClick={() => onClick(parseInt(eachHeat))}
                  />
                </td>
              </tr>
            )
        )}

        {selectedInsight.indexOf('volatility_rating') === 0 ? (
          <tr>
            <td>
              <div className="heat-legend__cold">{configHotCold['1']?.label_volatility}</div>
            </td>
          </tr>
        ) : (
          <tr>
            <td>
              <div className="heat-legend__cold">{configHotCold['1']?.label}</div>
            </td>
          </tr>
        )}
      </>
    );
  };

  const RenderHeatLegend = () => {
    return (
      <>
        {direction === DirectionType.horizontal && <HorizontalRow />}
        {direction === DirectionType.vertical && <VerticalRow />}
      </>
    );
  };

  return (
    <>
      {direction === DirectionType.vertical && (
        <table className="heat-legend">
          <tbody>
            <RenderHeatLegend />
          </tbody>
        </table>
      )}

      {direction === DirectionType.horizontal && (
        <div className="heat-legend-chart">
          <IonRow className="ion-align-items-center">
            <IonCol>
              <table className="heat-legend">
                <tbody>
                  <RenderHeatLegend />
                </tbody>
              </table>
            </IonCol>
            {(selectedOption.value === 'return' || selectedOption.value === 'win_rate') && (
              <IonCol className="ion-padding-start">
                <table>
                  <tr>
                    <td>
                      <div
                        className="heat-legend__floor-avg-vertical"
                        style={{ background: selectedOption.backgroundColor }}
                      ></div>
                    </td>
                    <td className="heat-legend__floor-label">Machine Average</td>
                  </tr>
                </table>
              </IonCol>
            )}
          </IonRow>
        </div>
      )}
    </>
  );
};

export default HeatLegend;
