import { createSelector } from 'reselect';
import { IState } from '../createStore';

const selectSlotState = (state: IState) => state.app.persistedState.slotState;
const selectSlotSlotOrderAscending = (state: IState) => state.app.persistedState;
const selectFloormapState = (state: IState) => state.app.persistedState.floormapState;

const selectSelectedSlots = createSelector(
  [selectSlotState],
  (slotState) => slotState.selectedSlots
);

const selectIsSlotOrderAscending = createSelector(
  [selectSlotSlotOrderAscending],
  (slotState) => slotState?.isSlotOrderAscending ?? false
);

const selectHeatFilter = createSelector(
  [selectFloormapState],
  (floormapState) => floormapState.heatFilter
);

export { selectSelectedSlots, selectIsSlotOrderAscending, selectHeatFilter };
