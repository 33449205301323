import { IonButton, IonContent, IonPage } from '@ionic/react';
import CasinoNavBar from 'components/CasinoNavBar';

export const TestEveriLogin = () => {
  const callEveriLogin = () => {
    // `${window.location.origin}/auth/everi` not able to be used yet need everi to enable our dev env
    const redirectUri = 'https://my.slotcheck.com/auth/everi';
    const everiUrl = __EVERI_URL__;

    const everiVars = {
      client_id: __EVERI_CLIENT_ID__,
      nonce: __EVERI_NONCE__,
      force_auth: 'false',
      redirect_uri: redirectUri,
      response_type: 'id_token',
      response_mode: 'query',
      scope: 'openid email player_id profile',
    };

    const queryParams = new URLSearchParams(everiVars).toString();

    window.location.href = `${everiUrl}?${queryParams}`;
  };

  return (
    <IonPage className="app-parent">
      <div className="slotcheck-header">
        <CasinoNavBar showBack={true} hideNotify hideSearch />
      </div>
      <IonContent>
        <IonButton onClick={callEveriLogin}>Test Everi Login</IonButton>
      </IonContent>
    </IonPage>
  );
};
