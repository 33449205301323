import { useState } from 'react';
import { InputEvent } from '../../../common/types';
import { PASSWORD_SCHEMA } from '../consts';

const usePasswords = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleCurrentPasswordChange = (e: InputEvent) => setCurrentPassword(e.target.value);
  const handleNewPasswordChange = (e: InputEvent) => setNewPassword(e.target.value);
  const handleConfirmPasswordChange = (e: InputEvent) => setConfirmPassword(e.target.value);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleShowCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
  const handleShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const clearFields = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const isPasswordValid = () => PASSWORD_SCHEMA.test(newPassword);

  return {
    currentPassword,
    newPassword,
    confirmPassword,
    showCurrentPassword,
    showNewPassword,
    showConfirmPassword,
    handleShowCurrentPassword,
    handleShowNewPassword,
    handleShowConfirmPassword,
    handleCurrentPasswordChange,
    handleNewPasswordChange,
    handleConfirmPasswordChange,
    clearFields,
    isPasswordValid
  };
};

export default usePasswords;
