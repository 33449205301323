import { IHistorySlotInfo } from 'appRedux/models/casinoModels';
import { IReducer } from './moduleReducerRegistry';
import { slotHistoryActionTypes } from 'appRedux/actions/slotHistoryCommandCreator';

export const historyInitialState = {
  activeSlotNumber: 0,
  slotNumbers: [],
};

export const slotHistoryReducer: IReducer = (draft, action) => {
  const { type, state } = action;
  const newHistoryValues: IHistorySlotInfo = state as IHistorySlotInfo;
  switch (type) {
    case slotHistoryActionTypes.UPDATE_TO_ACTIVE_SLOT_NUMBER:
      if (draft.app && draft.app.persistedState && draft.app.persistedState.historyState) {
        draft.app.persistedState.historyState.activeSlotNumber = newHistoryValues.activeSlotNumber;
      }
      break;
    case slotHistoryActionTypes.ADD_TO_SLOT_HISTORY:
      if (draft.app && draft.app.persistedState) {
        const { persistedState } = draft.app;
        if (persistedState.historyState) {
          persistedState.historyState.activeSlotNumber = newHistoryValues.activeSlotNumber;
          persistedState.historyState.slotNumbers.push(...newHistoryValues.slotNumbers);
        }
      }
      break;
    case slotHistoryActionTypes.CLEAR_SLOT_HISTORY:
      if (draft.app && draft.app.persistedState && draft.app.persistedState.historyState) {
        draft.app.persistedState.historyState.activeSlotNumber = 0;
        draft.app.persistedState.historyState.slotNumbers = [];
      }
      break;
  }
};

export default slotHistoryReducer;
