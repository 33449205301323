import { useRef, useEffect, MutableRefObject } from 'react';

const useRefWithClickOutsideListenerCallback = (
  callback: () => void = () => {}
): MutableRefObject<any> => {
  const domNode:any = useRef(null);

  useEffect(() => {
    const maybeCallback = (event: Event) => {
      if (!domNode.current?.contains(event.target)) {
        callback();
      }
    };
    document.addEventListener('mousedown', maybeCallback);
    return () => {
      document.removeEventListener('mousedown', maybeCallback);
    };
  }, [callback]);

  return domNode;
};

export default useRefWithClickOutsideListenerCallback;
