import { produce } from 'immer';

import {
  IOrientationInfo,
  IPersistedState,
  IReportInfo,
  IState,
  ITabProperties,
  appInitialState,
} from 'appRedux/createStore';
import moduleReducerRegistry from './moduleReducerRegistry';
import { actionTypes } from 'appRedux/actions/commandCreator';
import { IUserInfo } from 'services/auth';
import { AUTH_STATE, REPORT_TYPE_ENUM, AuthStateType } from 'appRedux/types/reduxTypes';
import { floormapInitialState } from './floormapReducer';
import { slotInitialState } from './slotReducer';
import {
  IFavoritesState,
  InsightDisplayType,
  InsightFilterEnum,
  TimeframeFilterEnum,
} from 'appRedux/models/favoritesModels';
import { InsightValue, TimeFrameValue } from 'common/types';
import { config } from 'config/config';
import { ICasinoSchemaWithAccess } from 'appRedux/models/casinoModels';

export interface IAction {
  type: string;
  state?: any;
}

const historyResetInitialState = {
  activeSlotNumber: 0,
  slotNumbers: [],
};

const defaultFilters: ITabProperties = {
  timeframe: 'months1' as TimeFrameValue,
  insight: 'spins' as InsightValue,
  denominations: config.denom,
  advancedViewInsights: [
    'pop',
    'return',
    'spins',
    'jackpot_value',
    'money_played',
  ] as InsightValue[],
};

const resetInitailState = {
  leaderboard: { ...defaultFilters },
  jackpot: { ...defaultFilters, insight: 'jackpot_value' as InsightValue },
  myplay: { ...defaultFilters, insight: 'spins' as InsightValue },
  graphfilter: { ...defaultFilters, insight: 'spins' as InsightValue },
  mapfilter: { ...defaultFilters, insight: 'spins' as InsightValue },
  main: { ...defaultFilters, insight: 'spins' as InsightValue },
  hottestSlots: { ...defaultFilters, insight: 'spins' as InsightValue },
};

const favoritesResetInitialState: IFavoritesState = {
  favoritesList: [],
  insightFilter: InsightFilterEnum.Spins,
  displayType: InsightDisplayType.Number,
  timeframeFilter: TimeframeFilterEnum.ThirtyDays,
};

const resetInitialState: IPersistedState = {
  userInfo: {
    lastUpdateDateCollapse: false,
    hasCompletedOnboarding: false,
  },
  authState: AUTH_STATE.SIGN_IN,
  emailAlertState: false,
  reportType: {
    id: REPORT_TYPE_ENUM.MAPS,
  },
  historyState: historyResetInitialState,
  isSlotOrderAscending: true,
  linkPlayerState: {
    casinoLinkedAccounts: [],
    isPlayerLinked: false,
    linkedCasinoId: null,
  },
  favorites: favoritesResetInitialState,
  filterState: resetInitailState,
  floormapState: floormapInitialState,
  slotState: slotInitialState,
};

const app = produce((draft: IState, action: IAction) => {
  if (!draft) return appInitialState;

  const { type, state } = action;

  switch (type) {
    case actionTypes.SET_AUTH_STATE:
      draft.app.persistedState.authState = state as AuthStateType;
      break;
    case actionTypes.SET_TOUR_STATE:
      if (draft.app.persistedState.userInfo) {
        draft.app.persistedState.userInfo.hasCompletedOnboarding = state as boolean;
      }
      break;
    case actionTypes.SET_USER_PRIVACY:
      if (draft.app.persistedState.userInfo) {
        draft.app.persistedState.userInfo.isPublicVisible = state as boolean;
      }
      break;
    case actionTypes.SET_USERINFO_STATE:
      draft.app.persistedState.userInfo = {
        ...draft.app.persistedState.userInfo,
        ...(state as IUserInfo),
      };
      break;
    case actionTypes.INIT_USERINFO_STATE:
      draft.app.persistedState.userInfo = {
        ...(state as IUserInfo),
      };
      break;
    case actionTypes.SET_USER_ALIAS_STATE: {
      const { aliasInitial, aliasInitial2, aliasName } = state as IUserInfo;
      if (draft.app.persistedState.userInfo) {
        draft.app.persistedState.userInfo.aliasInitial = aliasInitial;
        draft.app.persistedState.userInfo.aliasInitial2 = aliasInitial2;
        draft.app.persistedState.userInfo.aliasName = aliasName;
      }
      break;
    }
    case actionTypes.SET_USER_INFORMATION_STATE: {
      const { firstName, lastName, emailAddress } = state as IUserInfo;
      if (draft.app.persistedState.userInfo) {
        draft.app.persistedState.userInfo.firstName = firstName ?? '';
        draft.app.persistedState.userInfo.lastName = lastName ?? '';
        draft.app.persistedState.userInfo.emailAddress = emailAddress ?? '';
      }
      break;
    }
    case actionTypes.SET_USERINFO_LEVEL_STATE:
      draft.app.persistedState.userInfo = {
        ...draft.app.persistedState.userInfo,
        ...(state as IUserInfo),
      };
      break;
    case actionTypes.SET_AWS_JWK_STATE:
      draft.app.authJWK = state as string;
      break;
    case actionTypes.CLEAR_STORE:
      draft.app.authJWK = '';
      draft.app.InactivityAlertState = false;
      draft.app.persistedState = resetInitialState;
      break;
    case actionTypes.SET_CLIENT_SECRET:
      draft.app.clientSecret = state as string;
      break;
    case actionTypes.SET_EMAIL_ALERT_STATE:
      draft.app.persistedState.emailAlertState = state as boolean;
      break;
    case actionTypes.SET_REPORT_TYPE_STATE:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      draft.app.persistedState.reportType = {
        ...draft.app.persistedState.reportType,
        ...(state as object),
      } as IReportInfo;
      break;
    case actionTypes.SET_ORIENTATION:
      draft.app.persistedState.orientationState = state as IOrientationInfo;
      break;
    case actionTypes.SET_SLOT_ORDER:
      draft.app.persistedState.isSlotOrderAscending = state as boolean;
      break;
    case actionTypes.SET_USER_PRIMARY_CASINO:
      draft.app.persistedState.userInfo = {
        ...draft.app.persistedState.userInfo,
        primaryCasino: state as ICasinoSchemaWithAccess[],
      };

      break;
    default:
      // Module reducers
      moduleReducerRegistry.forEach((reducer) => {
        reducer(draft, action);
      });
  }

  return draft;
});

export default app;
