import {
  IonImg,
} from '@ionic/react';
  
const FooterLogo = () => {
  return (
    <div className='footer'>
      <div className="logo slotcheck-logo">
        <IonImg src="assets/images/SLOTcheckTM.svg" />
      </div>
    </div>
    
  );
};
  
export default FooterLogo;
  