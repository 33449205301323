import { IonButton, IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow, useIonRouter } from '@ionic/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { IState } from 'appRedux/createStore';
import useLocalStorage from 'common/reactHooks/useLocalStorage';
import { IUserInfo } from 'services/auth';

export const UserConfirm: React.FC = () => {
  const router = useIonRouter();
  const [hasAgreedToLegalDisclaimer] = useLocalStorage<boolean>('legalDisclaimer', false);
  const { aliasInitial2, aliasName } = useSelector<IState>(
    (state) => state.app.persistedState.userInfo
  ) as IUserInfo;

  const handleGetStartedBtnClick = () => {
    if (hasAgreedToLegalDisclaimer) {
      router.push('/auth/choosecasinos');
    } else {
      router.push('/auth/legalDisclaimer');
    }
  };

  return (
    <IonPage className="app-parent">
      <IonContent className="scroll-disable">
        <div className="container">
          <div className="content">
            <div className="m-signup m-signup__login">
              <div className="modal-container">
                <div className="m-signup__user-confirm">
                  <>
                    <form id="confirm-page" className="form-modal">
                      <div className="success-check" />
                      <IonGrid>
                        <IonRow>
                          <IonCol>
                            <div className="header-bar">
                              <span className="heading6 signup-box-title primary-color-text">
                                Your player alias is
                              </span>
                            </div>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol>
                            <div className="body-container">
                              <div className="name-container">
                                <span className="heading2 primary-color-text">{aliasInitial2}</span>
                              </div>
                              <span className="body primary-color-text">{aliasName}</span>
                            </div>
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol>
                            <IonButton className="submit-btn" onClick={handleGetStartedBtnClick}>
                              Next
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </form>
                  </>
                </div>
              </div>
              <div className="footer-container">
                <div className="logo">
                  <IonImg src="assets/images/SLOTcheckTM.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
