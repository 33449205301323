/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { COLORS } from '../../../common/COLORS';
import { InsightValue, InsightValues } from '../../../common/types';
import { config } from '../../../config/config';

import DropDown from '../dropDown/dropDown';
import FilterDropdownSelectOption from '../dropDown/FilterDropdownSelectOption';
import './insight-selector.scss';
import { InsightType } from 'common/common';

interface InsightSelectorProps {
  placeholder?: string;
  selectedInsight?: InsightValue | any;
  onSelect: any;
  restrictions?: InsightValue | InsightValues;
  additionalInsights?: any;
  customCls?: string;
  isShowShortName: boolean;
  id: any;
  isDisabled?: boolean;
  insights: InsightType[];
}

const InsightSelector: React.FC<InsightSelectorProps> = ({
  placeholder = 'Select an Insight',
  selectedInsight,
  onSelect: onChange,
  restrictions,
  additionalInsights,
  isShowShortName,
  id,
  customCls = '',
  isDisabled,
  insights = config.slotInsights,
}) => {
  const [options, setOptions] = useState<any[]>([]);

  const [allInsightValue, setAllInsightValue] = useState(insights);
  // let allInsightValue = insights;
  // if (additionalInsights.length > 0) {
  //   allInsightValue = allInsightValue.concat(additionalInsights);
  // }
  // const orderedSlotInsights = allInsightValue.sort((a, b) => {
  //   return a.display_order - b.display_order;
  // });

  useEffect(() => {
    const combinedInsights = [...insights, ...additionalInsights].sort(
      (a, b) => Number(a.display_order) - Number(b.display_order)
    );

    setAllInsightValue(combinedInsights);
  }, [insights, additionalInsights]);

  useEffect(() => {
    const newOptions: any[] = [];
    allInsightValue.forEach((slotInsight) => {
      const insightData = config.hotSlotsLabels[slotInsight.value];
      const insightValue = {
        label: insightData.label,
        value: slotInsight.value,
        color: insightData.color,
        description: isShowShortName ? slotInsight.short_name : insightData.label,
        tooltipInfo: slotInsight.description,
        backgroundColor: slotInsight.backgroundColor,
        icon: insightData.icon,
        asset_type: insightData.asset_type,
        icon_text: insightData.text,
        playerTypeAccess: slotInsight.playerTypeAccess,
      };

      if (
        insightValue &&
        insightValue.value !== restrictions &&
        !(restrictions as unknown as InsightValues)?.some?.(
          (restriction) => insightValue.value === restriction
        )
      ) {
        newOptions.push(insightValue);
      }
    });

    setOptions(newOptions);
  }, [restrictions?.length, allInsightValue]);

  //

  return (
    <div
      home-page-tour-step="insights"
      className={`insights-selector drop-down-select-box filters-dropdown 
      } ${customCls}`}
    >
      <DropDown
        textAlign="left"
        id={id}
        displayDescription
        options={options}
        onChange={onChange}
        value={selectedInsight}
        defaultOption="return"
        placeholder={placeholder}
        bgColor={COLORS.primaryColor}
        CustomSelectOption={FilterDropdownSelectOption}
      />
    </div>
  );
};

export default InsightSelector;
