/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { IState } from 'appRedux/createStore';
import { ICasinoSchema } from 'appRedux/models/casinoModels';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment-timezone';
import { CasinoService } from 'services/casino';
import { lastValueFrom } from 'rxjs';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { CasinoReduxCommandCreator } from 'appRedux/actions/casinoCommandCreator';

import { AuthService, ICasinoLinkedAccount } from 'services/auth';
import { CustomerData, IUserSubscription, SubscriptionData } from '@interfaces/userSubscription';
import SmartLoading from 'components/SmartLoading';
import {
  faCalendarCheck,
  faDiamondTurnRight,
  faGlobe,
  faLink,
  faLocationPinLock,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CasinoSubscriptionInfo from './components/CasinoSubscriptionInfo';
import { checkProUser } from 'common/common';
import { ReduxCommandCreator } from 'appRedux/actions';
import { FilterReduxCommandCreator } from 'appRedux/actions/filterCommandCreator';

const MyCasinos = () => {
  const auth = new AuthService();
  const casinoService = new CasinoService();
  const dispatch = useDispatch();
  const casinoCommands = CasinoReduxCommandCreator(dispatch);
  const commands = ReduxCommandCreator(dispatch);
  const filterCommandCreator = FilterReduxCommandCreator(dispatch);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const disableCasinoChange = queryParams.get('disableCasinoChange') === 'true';

  const [casinoData, setCasinosData] = useState<ICasinoSchema[]>([]);
  const [comingSoonCasinos, setComingSoonCasinos] = useState<ICasinoSchema[]>([]);

  const [loading, setIsLoading] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState<IUserSubscription[]>([]);
  const selectedCasinoId = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema?.kpCasinoPk
  ) as number;
  const casinoLinkedAccounts = useSelector<IState>(
    (state) => state.app.persistedState.linkPlayerState?.casinoLinkedAccounts
  ) as ICasinoLinkedAccount[];

  const router = useIonRouter();

  useEffect(() => {
    const fetchCasinosData = async (): Promise<void> => {
      try {
        setIsLoading(true);
        const casinoData = await lastValueFrom(casinoService.GetAllCasinos());
        if (casinoData?.DataSet) {
          const isActiveCasinos = casinoData.DataSet.filter(
            (casinoInfo) => casinoInfo.isactive && !casinoInfo.isComingSoon
          );
          const isComingSoonCasinos = casinoData.DataSet.filter(
            (casinoInfo) => casinoInfo.isactive && casinoInfo.isComingSoon
          );

          setCasinosData(isActiveCasinos);
          setComingSoonCasinos(isComingSoonCasinos);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    auth.GetUserSubscriptions().subscribe({
      next(data) {
        if (data) {
          const filterData = data.DataSet.filter((item) => item.hasOwnProperty('casinoId'));
          setSubscriptionData(filterData);
        }
      },
      error(err) {},
      complete() {
        fetchCasinosData();
      },
    });
  }, []);

  const casinoSelect = (casinoDetail: ICasinoSchema) => {
    const { casinoTimezoneNew } = casinoDetail;
    const userSubscriptionCasino = subscriptionData.find(
      (casino: IUserSubscription) => casino.casinoId === casinoDetail.casinoId
    );
    casinoCommands.SetCasinoSchema(casinoDetail);

    moment.tz.setDefault(casinoTimezoneNew);

    const casinoItem = casinoLinkedAccounts.find(
      (item: ICasinoLinkedAccount) => item.casinoId === casinoDetail.casinoId
    );
    const isPlayerLinked = casinoItem ? true : false;
    casinoCommands.SetLinkedCasinos({
      isPlayerLinked,
      linkedCasinoId: casinoDetail.casinoId,
    });
    if (userSubscriptionCasino) {
      const { subscriptionData = {} as SubscriptionData, customerData = {} as CustomerData } =
        userSubscriptionCasino;
      const subscriptionId = subscriptionData.id || '';
      const customerId = customerData.id || '';
      const canceledAt = subscriptionData.canceled_at ?? null;
      const currentPeriodEnd = subscriptionData.current_period_end ?? 0;
      const subscriptionActive = subscriptionData.status || 'inactive';
      const planId = subscriptionData.plan?.id || '';
      const isProUser = checkProUser(subscriptionActive, currentPeriodEnd);
      filterCommandCreator.ResetAllFilters();
      commands.SetUserLevel({
        level: planId,
        customer_id: customerId,
        subscription_id: subscriptionId,
        current_period_end: currentPeriodEnd,
        canceled_at: canceledAt,
        isProUser,
      });
    } else {
      commands.SetUserLevel({
        level: '',
        customer_id: '',
        subscription_id: '',
        isProUser: false,
      });
    }
  };

  const navigatePlayerLink = (casinoDetail: ICasinoSchema): void => {
    const { casinoId } = casinoDetail || {};

    if (!disableCasinoChange) {
      casinoSelect(casinoDetail);
    }

    router.push(`/auth/link-player/${casinoId}`);

    if (!disableCasinoChange) {
      casinoSelect(casinoDetail);
    }

    router.push(`/auth/link-player/${casinoId}`);
  };

  const renderCasinoItems = (eachCasino: ICasinoSchema) => {
    const { casinoCode, casinoId, displayName, address } = eachCasino;
    const eachCasinoLinkedAccount = casinoLinkedAccounts?.find(
      (casino: ICasinoLinkedAccount) => casino.casinoId === casinoId
    );
    const userSubscriptionCasino = subscriptionData.find(
      (casino: IUserSubscription) => casino.casinoId === casinoId
    );

    return (
      <div className="my-casinos-box-top" key={casinoCode}>
        <div
          className={clsx(
            'my-casinos-info',
            'my-casinos-info-section',
            eachCasino?.isComingSoon ? 'my-casinos-info-section--light' : ''
          )}
        >
          {!disableCasinoChange && !eachCasino.isComingSoon && (
            <div
              className="my-casinos-logo-icons radio-right"
              onClick={() => {
                casinoSelect(eachCasino);
                history.push('/tabs/home');
              }}
            >
              <IonRadio value={casinoId.toString()}></IonRadio>
            </div>
          )}
          <div className="my-casinos-logo-icons">
            <div className="my-casino-logo-section">
              {eachCasino && (
                <img
                  src={eachCasino.logos.navBar}
                  className={clsx(
                    'casino-logo',
                    eachCasino?.isComingSoon ? 'casino-logo--light' : ''
                  )}
                />
              )}
            </div>
            <div className="my-casinos-link-icons">
              <FontAwesomeIcon
                icon={faPhone}
                onClick={() => {
                  if (eachCasino) {
                    window.open(eachCasino.contact, '_blank');
                  }
                }}
              />
              <FontAwesomeIcon
                icon={faGlobe}
                onClick={() => {
                  if (eachCasino) {
                    window.open(eachCasino.website, '_blank');
                  }
                }}
              />
              <FontAwesomeIcon
                icon={faDiamondTurnRight}
                onClick={() => {
                  if (eachCasino) {
                    window.open(eachCasino.address.mapsLink, '_blank');
                  }
                }}
              />
            </div>
          </div>
          <div className="my-casinos-name-number">{displayName}</div>
          <div className="my-casinos-description">{address.displayAddress}</div>
          <div className="my-casinos-player-card-box">
            <IonGrid>
              <IonRow>
                {!eachCasino.isComingSoon ? (
                  <>
                    <IonCol sizeXs="12" sizeSm="6" class="ion-justify-content-center">
                      <div className="my-casinos-player-card-player-number">
                        {eachCasinoLinkedAccount?.casinoId === casinoId ? (
                          <div className="my-casinos-name-number">
                            Player ID: {String(eachCasinoLinkedAccount?.playerId)}
                          </div>
                        ) : (
                          <IonButton
                            className="my-casinos-player-card-button-add"
                            expand="full"
                            onClick={() => {
                              navigatePlayerLink(eachCasino);
                            }}
                          >
                            <FontAwesomeIcon icon={faLink} className="fa-link" />
                            Link Your Player ID
                          </IonButton>
                        )}
                      </div>
                    </IonCol>
                    {casinoId === eachCasino?.casinoId && (
                      <IonCol sizeXs="12" sizeSm="6" class="ion-justify-content-center">
                        <CasinoSubscriptionInfo
                          isSubscribedToCasino={userSubscriptionCasino?.subscriptionData}
                          onNotSubscribedClick={() => {
                            casinoSelect(eachCasino);
                            history.push('/auth/stripe', {
                              previousPage: location.pathname,
                            });
                          }}
                        ></CasinoSubscriptionInfo>
                      </IonCol>
                    )}
                  </>
                ) : (
                  <div className="availability-date primary-color-text">
                    <FontAwesomeIcon className="icon" icon={faLocationPinLock} /> Available{' '}
                    {eachCasino?.isComingSoonDate || 'Soon'}
                  </div>
                )}
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </div>
    );
  };

  return (
    <IonPage className="app-parent">
      <SmartLoading loading={loading} />
      <div className="slotcheck-header">
        <IonHeader className="small-header">
          <IonToolbar>
            <IonButton
              fill="clear"
              onClick={() => {
                history.goBack();
              }}
              className="icon-back"
              slot="start"
            >
              <IonImg src="assets/images/back-icon.svg" />
            </IonButton>
            <div className="logo header-title heading5">
              {disableCasinoChange ? 'My Player Cards' : 'Casinos'}
            </div>
          </IonToolbar>
        </IonHeader>
      </div>
      <IonContent>
        <div className="my-casinos">
          <div className="my-casinos-box-transparent">
            {selectedCasinoId && (
              <IonRadioGroup value={selectedCasinoId.toString()} className="my-casino-group">
                {casinoData?.map((casino) => {
                  return renderCasinoItems(casino);
                })}
              </IonRadioGroup>
            )}

            {comingSoonCasinos.length >= 1 && (
              <div className="upcoming-casinos">
                <h2 className="header heading5 primary-color-text">
                  <FontAwesomeIcon icon={faCalendarCheck} /> Upcoming launches
                </h2>

                {comingSoonCasinos?.map((casino) => renderCasinoItems(casino))}
              </div>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MyCasinos;
