import { playerTypeEnum } from 'common/commonEnum';
import { TimeFrameValue } from '../common/types';

export interface Timeframe {
  id: number;
  value: TimeFrameValue;
  label: string;
  label2: string;
  description: string;
  timeframeHours?: null | number;
  timeframeWeeks?: null | number;
  timeframeMonths?: null | number;
  timeName?: string;
  timeBucket?: string;
  display_order: number;
  playerTypeAccess: playerTypeEnum[];
}

export const AllTimeFrames: any[] = [
  {
    id: 1,
    value: 'hours1',
    label: '1 hr',
    label2: '1 hr',
    description: '1 hr',
    timeframeHours: 1,
    timeframeWeeks: null,
    timeframeMonths: null,
    timeName: '1H',
    timeBucket: '5 m',
    graphtimeBucket: '1 H',
    display_order: 1,
  },
  {
    id: 2,
    value: 'hours4',
    label: '4 hrs',
    label2: '4 hrs',
    description: '4 hrs',
    timeframeHours: 4,
    timeframeWeeks: null,
    timeframeMonths: null,
    timeName: '4H',
    timeBucket: '5 m',
    graphtimeBucket: '4 H',
    display_order: 2,
  },
  {
    id: 3,
    value: 'hours8',
    label: '8 hrs',
    label2: '8 hrs',
    description: '8 hrs',
    timeframeHours: 8,
    timeframeWeeks: null,
    timeframeMonths: null,
    timeName: '8H',
    timeBucket: '5 m',
    graphtimeBucket: '8 H',
    display_order: 3,
  },
  {
    id: 4,
    value: 'hours24',
    label: '24 hrs',
    label2: '24 hrs',
    description: '24 hrs',
    timeframeHours: 24,
    timeframeWeeks: null,
    timeframeMonths: null,
    timeName: 'DAY',
    timeBucket: '5 m',
    graphtimeBucket: '1 d',
    display_order: 4,
  },
  {
    id: 5,
    value: 'weeks1',
    label: '7 days',
    label2: '7 days',
    description: '7 days',
    timeframeHours: null,
    timeframeWeeks: 1,
    timeframeMonths: null,
    timeName: 'WEEK',
    timeBucket: '1 d',
    graphtimeBucket: '7 d',
    display_order: 5,
  },
  {
    id: 6,
    value: 'months1',
    label: '30 days',
    label2: '30 days',
    description: '30 days',
    timeframeHours: null,
    timeframeWeeks: null,
    timeframeMonths: 1,
    timeName: 'MONTH',
    timeBucket: '1 d',
    graphtimeBucket: '30 d',
    display_order: 6,
  },
  {
    id: 7,
    value: 'sinceLastJackpot',
    label: 'Since Last Jackpot (SLJ)',
    label2: 'Since Last Jackpot (SLJ)',
    description: 'Since Last Jackpot',
    timeName: 'SLJ',
    timeBucket: '1 d',
    graphtimeBucket: '30 d',
    display_order: 7,
  },
  {
    id: 8,
    value: 'sinceLastVisit',
    label: 'Since Last Visit (SLV)',
    label2: 'Since Last Visit (SLV)',
    description: 'Since Last Visit',
    display_order: 8,
  },
  {
    id: 9,
    value: 'all',
    label: '1 Year',
    label2: '1 Year',
    description: '1 Year',
    display_order: 9,
  },
  {
    id: 10,
    value: 'lastThreeYears',
    label: 'Last 3 Years',
    label2: 'Last 3 Years',
    description: 'Last 3 Years',
    display_order: 10,
  },
  {
    id: 11,
    value: 'allJackpots',
    label: 'All Jackpots',
    label2: 'All Jackpots',
    description: 'All Jackpots',
    display_order: 11,
  },
];

export const TimeFrames: Timeframe[] = [
  {
    id: 1,
    value: 'hours1',
    label: '1 hr',
    label2: '1 hr',
    description: '1 hr',
    timeframeHours: 1,
    timeframeWeeks: null,
    timeframeMonths: null,
    timeName: '1H',
    timeBucket: '5 m',
    display_order: 1,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
  {
    id: 2,
    value: 'hours4',
    label: '4 hrs',
    label2: '4 hrs',
    description: '4 hrs',
    timeframeHours: 4,
    timeframeWeeks: null,
    timeframeMonths: null,
    timeName: '4H',
    timeBucket: '5 m',
    display_order: 2,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
  {
    id: 3,
    value: 'hours8',
    label: '8 hrs',
    label2: '8 hrs',
    description: '8 hrs',
    timeframeHours: 8,
    timeframeWeeks: null,
    timeframeMonths: null,
    timeName: '8H',
    timeBucket: '5 m',
    display_order: 3,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
  {
    id: 4,
    value: 'hours24',
    label: '24 hrs',
    label2: '24 hrs',
    description: '24 hrs',
    timeframeHours: 24,
    timeframeWeeks: null,
    timeframeMonths: null,
    timeName: 'DAY',
    timeBucket: '5 m',
    display_order: 4,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
  {
    id: 5,
    value: 'weeks1',
    label: '7 days',
    label2: '7 days',
    description: '7 days',
    timeframeHours: null,
    timeframeWeeks: 1,
    timeframeMonths: null,
    timeName: 'WEEK',
    timeBucket: '8 h',
    display_order: 5,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
  {
    id: 6,
    value: 'months1',
    label: '30 days',
    label2: '30 days',
    description: '30 days',
    timeframeHours: null,
    timeframeWeeks: null,
    timeframeMonths: 1,
    timeName: 'MONTH',
    timeBucket: '1 d',
    display_order: 6,
    playerTypeAccess: [playerTypeEnum.slotCheck, playerTypeEnum.slotCheckPro],
  },
  {
    id: 7,
    value: 'sinceLastJackpot',
    label: 'Since Last Jackpot (SLJ)',
    label2: 'Since Last Jackpot (SLJ)',
    description: 'Since Last Jackpot',
    timeframeHours: null,
    timeframeWeeks: null,
    timeframeMonths: null,
    timeName: 'SLJ',
    timeBucket: '1 d',
    display_order: 7,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
];

export const spinsTimeFrames = TimeFrames.map((tf) =>
  tf.value === 'weeks1'
    ? { ...tf, playerTypeAccess: [playerTypeEnum.slotCheckPro] }
    : tf.value === 'months1'
    ? { ...tf, playerTypeAccess: [playerTypeEnum.slotCheck, playerTypeEnum.slotCheckPro] }
    : tf
);

export const myJackpotTimeFrames: Timeframe[] = [
  {
    id: 9,
    value: 'all',
    label: '1 Year',
    label2: '1 Year',
    description: '1 Year',
    display_order: 9,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
  // {
  //   id: 10,
  //   value: "lastThreeYears",
  //   label: "Last 3 Years",
  //   label2: "Last 3 Years",
  //   description: "Last 3 Years",
  //   display_order: 10,
  //   playerTypeAccess: [playerTypeEnum.slotCheckPro]
  // },
  {
    id: 11,
    value: 'allJackpots',
    label: 'All Jackpots',
    label2: 'All Jackpots',
    description: 'All Jackpots',
    display_order: 11,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
];

export const myPlayTimeFrames: Timeframe[] = [
  {
    id: 8,
    value: 'sinceLastVisit',
    label: 'Since Last Visit (SLV)',
    label2: 'Since Last Visit (SLV)',
    description: 'Since Last Visit',
    display_order: 8,
    playerTypeAccess: [playerTypeEnum.slotCheck, playerTypeEnum.slotCheckPro],
  },
];
