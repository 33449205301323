import moment from 'moment';
import { useState, useEffect } from 'react';

interface CountdownTimerProps {
  minutes: string;
  setResendTimerExpired: (isExpired: boolean) => void;
}

const CountdownTimer = ({ setResendTimerExpired, minutes }: CountdownTimerProps) => {
  const [duration, setDuration] = useState(moment(0).add(minutes, 'm'));
  const [formattedTime, setFormattedTime] = useState(duration.utcOffset(0, false).format('mm:ss'));

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (!+duration.minutes() && !+duration.seconds()) {
        setResendTimerExpired(true);
        clearInterval(timerInterval);
      }

      setDuration(duration.subtract(1, 's'));
      setFormattedTime(duration.format('mm:ss'));
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [duration]);

  return <div>{formattedTime}</div>;
};

export default CountdownTimer;
