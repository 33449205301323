import { Dispatch } from 'react';
import { IAction } from 'appRedux/reducers';

import {
  InsightFilterEnum,
  TimeframeFilterEnum,
  InsightDisplayType,
} from 'appRedux/models/favoritesModels';
import { IUserFavorite } from 'appRedux/models/playerModels';

export const AppKey = 'FAVORITESLIST-';
export const favoritesActionTypes = {
  ADD_FAVORITE: `${AppKey}ADD_FAVORITE`,
  REMOVE_FAVORITE: `${AppKey}REMOVE_FAVORITE`,
  SET_FAVORITES: `${AppKey}SET_FAVORITES`,
  CLEAR_FAVORITES: `${AppKey}CLEAR_FAVORITES`,
  SET_INSIGHT_FILTER: `${AppKey}SET_INSIGHT_FILTER`,
  SET_TIMEFRAME_FILTER: `${AppKey}SET_TIMEFRAME_FILTER`,
  SET_INSIGHT_DISPLAY_TYPE: `${AppKey}SET_INSIGHT_DISPLAY_TYPE`,
};

export const FavoritesReduxCommandCreator = (dispatch: Dispatch<IAction>) => ({
  AddFavorite(favorite: IUserFavorite) {
    dispatch({
      type: favoritesActionTypes.ADD_FAVORITE,
      state: favorite,
    });
  },
  RemoveFavorite(slotNumber: number) {
    dispatch({
      type: favoritesActionTypes.REMOVE_FAVORITE,
      state: slotNumber,
    });
  },
  ClearFavorites() {
    dispatch({
      type: favoritesActionTypes.CLEAR_FAVORITES,
    });
  },
  SetFavorites(favoriteList: IUserFavorite[]) {
    dispatch({
      type: favoritesActionTypes.SET_FAVORITES,
      state: favoriteList,
    });
  },
  SetInsightFilter(insight: InsightFilterEnum) {
    dispatch({
      type: favoritesActionTypes.SET_INSIGHT_FILTER,
      state: insight,
    });
  },
  SetInsightDisplayType(displayType: InsightDisplayType) {
    dispatch({
      type: favoritesActionTypes.SET_INSIGHT_DISPLAY_TYPE,
      state: displayType,
    });
  },
  SetTimeframeFilter(timeframe: TimeframeFilterEnum) {
    dispatch({
      type: favoritesActionTypes.SET_TIMEFRAME_FILTER,
      state: timeframe,
    });
  },
});
