/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FC, useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleUp,
  faCircleInfo,
  faAngleDoubleDown,
} from '@fortawesome/free-solid-svg-icons';
import './snap-shot.scss';
import { config } from '../../config/config';
import { getHeatBean, kFormatter, parseValue } from '../../common/include';
import PinSelectors from '../PinSelectors/PinSelectors';
import {
  ColdestRTP,
  ComparePlanSLJ,
  FindInsights,
  HottestRTP,
  Jackpot,
  LOTC,
  MoneyWon,
  NoOfJackpot,
  Played,
  Pop,
  Return,
  Spins,
  TrendsSectionInfo,
  Vol,
  WinRate,
  WinSpin,
} from '../InsightsPopup/InsightsPopup';
import TrendsSlots from '../TrendsSlots/TrendsSlots';
import { useSelector } from 'react-redux';
import { IState } from 'appRedux/createStore';
import InsightModal from '../InsightsPopup/InsightsPopup';
import { SnapshotMetric, SnapshotMetricGroup } from 'common/common';
import { ICasinoReport } from 'appRedux/models/casinoModels';
import { SectionID } from 'appRedux/reducers/slotReducer';

interface ShapshotItemProps {
  description: string;
  id: string | number;
  bgColor: string;
  snapshotScore: string | number;
  label: string;
  field: string;
  onClickpopUp: (id: any) => void;
}

const ShapshotItem: FC<ShapshotItemProps> = ({
  description,
  id,
  bgColor,
  snapshotScore,
  label,
  field,
  onClickpopUp,
}) => {
  return (
    <IonRow className="sl-mbt-5">
      <IonCol size="1">
        <span className="box-indicator" style={{ background: `${bgColor}` }}></span>
      </IonCol>
      <IonCol size="3">{snapshotScore}</IonCol>
      <IonCol size="8" className="align-flex-center">
        <span>{label}</span>
        <FontAwesomeIcon
          icon={faCircleInfo}
          className="info-icon"
          onClick={() => {
            onClickpopUp(field);
          }}
        />
      </IonCol>

      {/* <ToolTip
        showBackdrop={false}
        styleName="snapshot-tooltip"
        toolInfo={description}
        triggerId={tooltipId}
        triggerActionType="click"
        sidePos="bottom"
        alignmentPos="center"
      /> */}
    </IonRow>
  );
};

interface SnapshotsProps {
  slotInfo: any;
  selectedSlotData: ICasinoReport;
  handleRearrangeComponent: (item: any) => void;
  refetchFavourite: () => {};
}

const SnapShot: FC<SnapshotsProps> = (props) => {
  const isProUser = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.isProUser
  ) as boolean;
  const [collapse, setCollapse] = useState(true);
  const toggleCollapse = () => {
    setCollapse(!collapse);
  };
  const [activeInsight, setActiveInsight] = useState('');
  const [showIonModal, setShowIonModal] = useState(false);
  const isLandscape =
    (useSelector<IState>(
      (state) => state.app.persistedState.orientationState?.isLandscape
    ) as boolean) || false;
  const isAdvancedDisplayState = isProUser && isLandscape ? true : false;

  const { handleRearrangeComponent, selectedSlotData } = props;

  const renderAdditionBox = (slotInsight: SnapshotMetric) => {
    const insightValue = selectedSlotData[slotInsight?.field as keyof ICasinoReport];
    const { description, id, field } = slotInsight;
    const parsedValue = parseValue(insightValue, slotInsight.field);
    const snapshotScore = parsedValue ? parsedValue : 'N/A';
    const stdColor = getHeatBean(selectedSlotData, slotInsight.value);
    let snapshot_label = '';

    switch (slotInsight.field) {
      case 'jackpot_value':
        const jackpotsValue = parseValue(selectedSlotData.jackpots, 'jackpots');
        snapshot_label = `${slotInsight.snapshot_label}(${jackpotsValue})`;
        break;

      case 'pop':
        const popSpins = selectedSlotData.pop_spins || 0;
        snapshot_label = popSpins ? `${slotInsight.snapshot_label}` : 'N/A';
        break;

      default:
        snapshot_label = slotInsight.snapshot_label;
    }
    return (
      <ShapshotItem
        description={description}
        key={id}
        id={id}
        bgColor={stdColor}
        snapshotScore={snapshotScore}
        label={snapshot_label}
        field={field}
        onClickpopUp={(id) => {
          setActiveInsight(id);
          setShowIonModal(true);
        }}
      />
    );
  };

  return (
    <>
      {showIonModal && (
        <InsightModal isOpen={true} onDidDismiss={() => setShowIonModal(false)}>
          {activeInsight === 'money_won' && (
            <MoneyWon
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'return' && (
            <Return
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'spins' && (
            <Spins
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'pop' && (
            <Pop
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'volatility_rating' && (
            <Vol
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'jackpot_value' && (
            <Jackpot
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'jackpots' && (
            <NoOfJackpot
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'money_played' && (
            <Played
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'avg_money_won_per_spin' && (
            <WinSpin
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'win_rate' && (
            <WinRate
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'coldest_rtp' && (
            <ColdestRTP
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'hottest_rtp' && (
            <HottestRTP
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'trends_section_info' && (
            <TrendsSectionInfo
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'compare-plan-slj' && (
            <ComparePlanSLJ
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'find-insights' && (
            <FindInsights
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'lotc' && (
            <LOTC
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
        </InsightModal>
      )}
      {!isProUser && (
        <IonGrid
          className={
            collapse ? 'snap-shot ion-no-padding' : 'snap-shot content-hide-col ion-no-padding'
          }
        >
          <IonRow className="row-100">
            <IonCol size="12" className="ion-no-padding">
              <div className="section-header">
                <div className="section-header-icon section-header-icon-collapse">
                  <FontAwesomeIcon
                    icon={collapse ? faAngleDoubleUp : faAngleDoubleDown}
                    onClick={toggleCollapse}
                  />
                </div>
                <div className="section-header-title">
                  <h2>SnapShot</h2>
                  <span className="section-sub-header">Last 30 Days</span>
                </div>
              </div>
            </IonCol>
            {config.snapShots.snapShotArr.map(
              (groupInsight: SnapshotMetricGroup, index: number) => (
                <IonCol size="12" key={index} className={groupInsight.className}>
                  <IonGrid className="ion-no-padding">
                    {groupInsight.items.map((slotInsight: SnapshotMetric, newindex: number) => {
                      return renderAdditionBox(slotInsight);
                    })}
                  </IonGrid>
                </IonCol>
              )
            )}
            <IonCol size="12" className="ion-no-padding ion-text-center pin-section">
              <PinSelectors sectionId={SectionID.Snapshot} onClickItem={handleRearrangeComponent} />
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
      {isProUser && (
        <IonGrid
          className={
            collapse ? 'snap-shot trends-padding' : 'snap-shot content-hide-col ion-no-padding'
          }
        >
          <IonRow className="row-100">
            <TrendsSlots
              slotInfo={selectedSlotData}
              isAdvancedDisplayState={isAdvancedDisplayState}
              onClickpopUp={(item: string) => {
                setShowIonModal(true);
                setActiveInsight(item);
              }}
            />

            <IonCol size="12" className="ion-no-padding ion-text-center pin-section">
              <PinSelectors sectionId={SectionID.Snapshot} onClickItem={handleRearrangeComponent} />
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </>
  );
};

export default SnapShot;
