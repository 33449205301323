import { applyMiddleware, createStore } from 'redux';
import { loadState, saveState } from 'localStorage';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { AUTH_STATE, AuthStateType, REPORT_TYPE_ENUM, ReportType } from 'appRedux/types/reduxTypes';
import { throttle } from 'lodash';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import {
  ICasinoSchema,
  ICasinoSchemaWithAccess,
  IHistorySlotInfo,
  ILinkPlayerInfo,
  ISearchInfo,
} from './models/casinoModels';
import { historyInitialState } from './reducers/slotHistoryReducer';
import { favoritesInitialState } from './reducers/favoriteReducer';
import { IFavoritesState } from './models/favoritesModels';
import { IDenomination, InsightValue, TimeFrameValue } from 'common/types';
import { filterInitialState } from './reducers/filtersReducer';
import { floormapInitialState } from './reducers/floormapReducer';
import { LatitudeLongitudeType } from './actions/floormapCommandCreator';
import { slotInitialState } from './reducers/slotReducer';
import rootReducer from './reducers';

export interface IUserInfo {
  user?: string;
  phone?: string;
  emailAddress?: string;
  level?: string;
  customer_id?: string;
  current_period_end?: number;
  canceled_at?: number;
  subscription_id?: string;
  appUserPk?: string;
  groups?: Array<string>;
  isProUser?: boolean;
  hasCompletedOnboarding?: boolean;
  lastUpdateDateCollapse?: boolean;
  aliasInitial?: string;
  aliasInitial2?: string;
  aliasName?: string;
  firstName?: string;
  lastName?: string;
  isPublicVisible?: boolean;
  primaryCasino?: ICasinoSchemaWithAccess[];
}

export interface IReportInfo {
  id: ReportType;
}

export interface GeoJSONFeature {
  type: string;
  [key: string]: string | number;
}

export interface FloorPlan {
  kp_floor_plan_pk: number;
  floor_num: number;
  floor_name: string;
  kp_casino_fk: number;
  geojson: {
    type: string;
    features: GeoJSONFeature[];
  };
}

export interface SlotMetaData {
  game_name: string;
  slot_number: number;
  area: string;
  section: string;
  location: string;
}

export interface IOrientationInfo {
  isMobile: boolean;
  device: string;
  orientation: string;
  isLandscape: boolean;
}

export interface ICasinoSearchData {
  casinoId: number;
  data: ISearchInfo[];
}

export interface IDetailState {
  sections: string[];
}

export interface ITabProperties {
  timeframe: TimeFrameValue;
  insight: InsightValue;
  denominations: IDenomination[];
  advancedViewInsights: InsightValue[];
}
export interface IFilterState {
  leaderboard: ITabProperties;
  jackpot: ITabProperties;
  myplay: ITabProperties;
  graphfilter: ITabProperties;
  mapfilter: ITabProperties;
  main: ITabProperties;
  hottestSlots: ITabProperties;
}
export interface IFloormapState {
  heatFilter: number[];
  bearing: number;
  zoom: number;
  latitudeLongitude: LatitudeLongitudeType;
}

export interface ISlotState {
  collapsed: boolean;
  sections: string[];
  selectedSlotsName: string;
  selectedSlots: string[];
}

export interface IPersistedState {
  userInfo?: IUserInfo;
  authState?: AuthStateType;
  emailAlertState?: boolean;
  reportType?: IReportInfo;
  casinoSchema?: ICasinoSchema;
  orientationState?: IOrientationInfo;
  linkPlayerState?: ILinkPlayerInfo;
  historyState?: IHistorySlotInfo;
  casinoSearchData?: ICasinoSearchData;
  isSlotOrderAscending?: boolean;
  favorites: IFavoritesState;
  filterState: IFilterState;
  floormapState: IFloormapState;
  slotState: ISlotState;
}
export interface IState {
  app: {
    authJWK?: string;
    clientSecret?: string;
    InactivityAlertState?: boolean;
    persistedState: IPersistedState;
    linkPlayerState?: ILinkPlayerInfo;
  };
}

const initialPersistedState: IPersistedState = {
  userInfo: { hasCompletedOnboarding: false, lastUpdateDateCollapse: false },
  authState: AUTH_STATE.SIGN_IN,
  emailAlertState: false,
  reportType: {
    id: REPORT_TYPE_ENUM.MAPS,
  },
  historyState: historyInitialState,
  isSlotOrderAscending: true,
  linkPlayerState: {
    casinoLinkedAccounts: [],
    isPlayerLinked: false,
    linkedCasinoId: null,
  },
  favorites: favoritesInitialState,
  filterState: filterInitialState,
  floormapState: floormapInitialState,
  slotState: slotInitialState,
};

const persistedState = loadState(initialPersistedState) || initialPersistedState;
const initialState: IState = {
  app: {
    authJWK: '',
    InactivityAlertState: false,
    persistedState,
  },
};

export default () => {
  const middlewares = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(rootReducer, initialState, composedEnhancers);
  store.subscribe(
    throttle(() => {
      saveState(store.getState().app.persistedState);
    }, 1000)
  );
  return store;
};

const appInitialState = initialState.app;
export { appInitialState };

export const useAppSelector: TypedUseSelectorHook<IState> = useSelector;
