import React from 'react';
import { IonButton, IonCard, IonCardContent, IonIcon } from '@ionic/react';
import { card, checkmarkCircle } from 'ionicons/icons';

interface ILinkPlayerModal {
  onLinkClick: () => void;
  onCancelClick: () => void;
  timeLeft: number;
}

export const ReasonToLinkPlayerCard: React.FC<ILinkPlayerModal> = ({
  onCancelClick,
  onLinkClick,
  timeLeft,
}) => {
  const formatTimeLeft = (remainingTime: number | null): string => {
    if (remainingTime === null) {
      return 'Link Player Card';
    }

    if (remainingTime <= 24) {
      return `${Math.ceil(remainingTime)} hour${remainingTime > 1 ? 's' : ''} remaining`;
    } else if (remainingTime <= 48) {
      return '1 day remaining';
    } else if (remainingTime < 72) {
      return '2 days remaining';
    } else if (remainingTime == 72) {
      return '3 days remaining';
    }

    return 'Link Player Card';
  };

  return (
    <IonCard
      id="reason-to-link-modal"
      data-testid="linkCardModal"
      className="modal-container reason-to-link-modal"
    >
      <IonCardContent>
        <div className="heading-container">
          <div role="heading" className="heading3 heading">
            Link Player ID
          </div>
          <div className="body-bold subtitle">{formatTimeLeft(timeLeft)}</div>

          <div className="subtitle1 description">
            Link Player ID to continue accessing this casino
          </div>
        </div>

        <ul className="unstyled-list benefits-list">
          <li className="benefit subtitle1">
            <IonIcon icon={checkmarkCircle} className="icon" />
            Track your play
          </li>
          <li className="benefit subtitle1">
            <IonIcon icon={checkmarkCircle} className="icon" />
            Track your jackpots
          </li>
          <li className="benefit subtitle1">
            <IonIcon icon={checkmarkCircle} className="icon" />
            Access to Leaderboard
          </li>
        </ul>

        <div className="actions">
          <IonButton
            onClick={onLinkClick}
            expand="block"
            color="primary"
            data-testid="linkButton"
            className="action"
          >
            <IonIcon icon={card} className="icon" />
            Link Player ID
          </IonButton>

          <IonButton expand="block" color="medium" onClick={onCancelClick} className="action">
            Maybe Later
          </IonButton>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default ReasonToLinkPlayerCard;
