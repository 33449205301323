import { IonContent, IonImg, IonPage } from '@ionic/react';
import { SyntheticEvent } from 'react';
import { SignupAccount } from './SignupAccount';

export function onPromise<T>(promise: (event: SyntheticEvent) => Promise<T>) {
  return (event: SyntheticEvent) => {
    if (promise) {
      promise(event).catch((error) => {
        console.log('Unexpected error', error);
      });
    }
  };
}

export const CreateAccount = () => {
  return (
    <IonPage className="app-parent">
      <IonContent className="scroll-disable">
        <div className='container'>
          <div className="content">
            <div className="m-signup m-signup__create-account">
              <div className="modal-container">
                <SignupAccount />
              </div>
              <div className="footer-container">
                <div className="logo">
                  <IonImg src="assets/images/SLOTcheckTM.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
