import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './hot-box.scss';
import useWhiteLabelStyles, { WHITE_LABEL_TYPE_ENUM } from 'hooks/useWhiteLabelStyles';
import { useDynamicCSS } from 'hooks/useDynamicCSS';
import UpArrow from 'components/UpdatedTimeInfo/UpArrow';
import { IState } from 'appRedux/createStore';
import { ICasinoSchema } from 'appRedux/models/casinoModels';
import { useDispatch, useSelector } from 'react-redux';
import { SlotReduxCommandCreator } from 'appRedux/actions/slotCommandCreator';

function HotBox() {
  const dispatch = useDispatch();
  const slotCommands = SlotReduxCommandCreator(dispatch);
  const hasCollapsed = useSelector<IState>(
    (state) => state.app.persistedState.slotState?.collapsed
  ) as boolean;
  const [collapse, setCollapse] = useState(hasCollapsed);
  const styles = useWhiteLabelStyles(WHITE_LABEL_TYPE_ENUM.CANVAS);
  const btnstyles = useWhiteLabelStyles(WHITE_LABEL_TYPE_ENUM.BUTTON);
  const casinoSchema: ICasinoSchema = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema
  ) as ICasinoSchema;
  const css = `
  .hot-avg-box .hot-avg-box-content {
    color: ${casinoSchema.styles.color}
  }
  .collapse-button {
    color #000 !important;
  }`;
  useDynamicCSS(css);

  const toggleCollapse = () => {
    setCollapse(!collapse);
    slotCommands.ToggleHotColdCollapsed({
      collapsed: !collapse,
    });
  };

  useEffect(() => {
    if (collapse === true) {
      setTimeout(() => {
        setCollapse(false);
        slotCommands.ToggleHotColdCollapsed({
          collapsed: false,
        });
      }, 5000);
    }
  }, [collapse]);

  return (
    <div
      style={styles}
      className={
        collapse ? 'hot-box sticky-bottom' : 'hot-box sticky-bottom hot-box-shrink hot-box-collapse'
      }
    >
      <div
        className={!collapse ? 'hot-collapse-icon  hot-collapse-close' : 'hot-collapse-icon'}
        onClick={toggleCollapse}
      >
        {collapse && <FontAwesomeIcon icon={faChevronDown} className="collapse-button" />}

        {!collapse && <UpArrow />}
      </div>
      <div className={collapse ? 'hot-avg-content' : 'content-hide'}>
        <div className="hot-avg-box">
          <div style={btnstyles} className="hot-avg-box-content hot-avg-box-content-label">
            <span className="">HOT</span>
            <span className="">Average</span>
            <span className="">COLD</span>
          </div>
          <div className="hot-avg-box-content">
            <span className="box-indicator hot"></span>
            <span className="box-indicator warm"></span>
            <span className="box-indicator neutral-warm"></span>
            <span className="box-indicator neutral-cold"></span>
            <span className="box-indicator cold"></span>
            <span className="box-indicator very-cold"></span>
          </div>
          <div style={btnstyles} className="hot-avg-box-content">
            <span className="">HIGH</span>
            <span className="">MED</span>
            <span className="">LOW</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HotBox;
