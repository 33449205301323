/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import InsightSelector from './InsightSelector/InsightSelector';
import TimeFrameSelector from './TimeFrameSelector/TimeFrameSelector';
import { closeCircleOutline } from 'ionicons/icons';

import './dropdown-bar.scss';
import {
  IDenomination,
  InsightValue,
  InsightValues,
  ReactSelectOption,
  TimeFrameValue,
} from '../../common/types';
import { ReactElement, useEffect, useState } from 'react';
import { config, Denom } from '../../config/config';

import { myJackpotTimeFrames, spinsTimeFrames } from '../../config/Timeframes';
import DenominationSelector from './DenominationSelector/DenominationSelector';

import InsightFieldSelector from './InsightFieldSelector/InsightFieldSelector';
import { useDispatch, useSelector } from 'react-redux';
import { IFilterState, IState, ITabProperties, useAppSelector } from 'appRedux/createStore';
import { AuthService } from 'services/auth';
import useWhiteLabelStyles, { WHITE_LABEL_TYPE_ENUM } from 'hooks/useWhiteLabelStyles';
import clsx from 'clsx';
import { FilterReduxCommandCreator } from 'appRedux/actions/filterCommandCreator';
import { FilterTypeEnum, IFilterType, InsightType, heatLegendAllInsight } from 'common/common';
import { FloormapReduxCommandCreator } from 'appRedux/actions/floormapCommandCreator';
import { myJackpotsPageInsights } from 'config/Insights';
import { playerTypeEnum } from 'common/commonEnum';
import { titleCase } from 'common/include';
import { SlotReduxCommandCreator } from 'appRedux/actions/slotCommandCreator';

interface DropdownBarProps {
  tab?: string;
  isShowShortName?: boolean;
  onClickGo?: () => void;
  id?: string;
  showDenominationSelector?: boolean;
  fieldSelectorValues?: any;
}

const DropdownBar = ({
  onClickGo,
  isShowShortName = true,
  id,
  showDenominationSelector = false,
  fieldSelectorValues,
}: DropdownBarProps): ReactElement<any, any> | null => {
  const isProUser = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.isProUser
  ) as boolean;
  const dispatch = useDispatch();
  const floormapCommand = FloormapReduxCommandCreator(dispatch);
  const filterCommandCreator = FilterReduxCommandCreator(dispatch);
  const slotCommands = SlotReduxCommandCreator(dispatch);

  const tabFilters = useSelector<IState>(
    (state) => state.app.persistedState.filterState[id! as IFilterType]
  ) as ITabProperties;

  const userId = useSelector<IState>((state) => state.app.persistedState.userInfo?.appUserPk);
  const isLandscape = useSelector<IState>(
    (state) => state.app.persistedState.orientationState?.isLandscape
  ) as boolean;

  const { selectedSlotsName, selectedSlots } = useAppSelector(
    (state) => state.app.persistedState.slotState
  );

  const [timeFrames, setTimeFrames] = useState(config.timeframes);
  const [insights, setInsights] = useState(config.slotInsights);

  useEffect(() => {
    if (tabFilters.insight === 'spins') {
      setTimeFrames(spinsTimeFrames);
    }
  }, [tabFilters]);

  useEffect(() => {
    if (id === 'jackpot') {
      setInsights(myJackpotsPageInsights);
    }
  }, [id]);

  useEffect(() => {
    if (isProUser === false) {
      const selectedInsightInfo = insights.find((insight) => insight.value === tabFilters.insight);
      const selectedTimeframeInfo = timeFrames.find(
        (timeFrame) => timeFrame.value === tabFilters.timeframe
      );

      const isFreeUserAllowed =
        selectedInsightInfo?.playerTypeAccess.includes(playerTypeEnum.slotCheck) &&
        selectedTimeframeInfo?.playerTypeAccess.includes(playerTypeEnum.slotCheck);

      if (!isFreeUserAllowed) {
        filterCommandCreator.ResetAllFilters();
      }
    }
  }, [isProUser, insights, timeFrames, tabFilters, id]);

  const isMultiSelect =
    isProUser && (id == FilterTypeEnum.hottestSlots || id == FilterTypeEnum.myplay) && isLandscape;

  const {
    timeframe: tabSelectedTimeframe,
    insight: tabSelectedInsight,
    denominations: tabSelectedDenoms,
    advancedViewInsights,
  } = tabFilters;
  const auth = new AuthService();
  let insightRestrictions: InsightValues = ['jackpots'];
  let additionalInsights: any[] = [];
  let additionalTimeFrames: any[] = [];
  let timeFrameRestrictions = [] as TimeFrameValue[];
  const shouldHideTimeframes = tabSelectedInsight === 'pop';

  if (id === 'myplay') {
    insightRestrictions = ['time_played', 'session_played', 'jackpots'];
    additionalInsights = config.myPlayInsights;
    timeFrameRestrictions = ['sinceLastJackpot'];
  }

  if (id === 'favorites') {
    timeFrameRestrictions = ['sinceLastVisit'];
  }

  if (id === 'jackpot') {
    insightRestrictions = [
      ...insightRestrictions,
      'money_won',
      'avg_money_won_per_spin',
      'spins',
      'return',
      'money_played',
      'win_rate',
      'sleeper_slot_rating',
      'volatility_rating',
      'jackpots',
      'pop',
    ];
    additionalTimeFrames = myJackpotTimeFrames;

    timeFrameRestrictions = ['sinceLastJackpot', 'hours1', 'hours4', 'hours8'];
  }
  if (id === 'leaderboard') {
    insightRestrictions = [
      ...insightRestrictions,
      'pop',
      'return',
      'money_played',
      'win_rate',
      'sleeper_slot_rating',
      'volatility_rating',
    ];
    timeFrameRestrictions = ['sinceLastJackpot'];
  }

  if (
    [
      'jackpots',
      'jackpot_value',
      'volatility_rating',
      'avg_money_won_per_spin',
      'win_rate',
    ].includes(tabSelectedInsight)
  ) {
    if (tabSelectedTimeframe === 'sinceLastJackpot') {
      filterCommandCreator.SetFilterOptions(id! as FilterTypeEnum, {
        timeframe: 'months1' as TimeFrameValue,
      });
    }
    timeFrameRestrictions = [...timeFrameRestrictions, 'sinceLastJackpot'];
  }

  if (id === FilterTypeEnum.graphfilter) {
    insightRestrictions = [...insightRestrictions, 'pop'];
    timeFrameRestrictions = ['sinceLastJackpot'];
  }

  if (id === FilterTypeEnum.mapfilter) {
    timeFrameRestrictions = ['sinceLastJackpot'];
  }

  const handleInsightSelect = (selectedValue: InsightType) => {
    const insight = selectedValue.value;

    const updateFilterOptions = (options: {
      insight: InsightValue;
      timeframe?: TimeFrameValue;
      advancedViewInsights?: InsightValue[];
    }) => {
      if (isProUser) {
        const notIncludesC = advancedViewInsights.includes(insight);
        let advancedInsights;
        if (!notIncludesC) {
          const updatedAdvancedViewInsights = [...advancedViewInsights];
          updatedAdvancedViewInsights[updatedAdvancedViewInsights.length - 1] = insight;
          advancedInsights = updatedAdvancedViewInsights;
        } else {
          advancedInsights = advancedViewInsights;
        }

        options.advancedViewInsights = advancedInsights;
      }

      filterCommandCreator.SetFilterOptions(id! as FilterTypeEnum, options);
    };

    if (!isProUser) {
      switch (insight) {
        case 'jackpot_value':
        case 'return':
          setTimeFrames(config.timeframes);
          updateFilterOptions({ insight, timeframe: 'weeks1' });
          break;
        case 'spins':
          setTimeFrames(spinsTimeFrames);
          updateFilterOptions({ insight, timeframe: 'months1' });
          break;
        default:
          setTimeFrames(config.timeframes);
          updateFilterOptions({ insight });
      }
    } else {
      updateFilterOptions({ insight });
    }

    floormapCommand.SetDefaultHeatFilterAction(heatLegendAllInsight);
  };

  const handleTimeframeSelect = ({ value }: any) => {
    filterCommandCreator.SetFilterOptions(id! as FilterTypeEnum, {
      timeframe: value as TimeFrameValue,
    });
  };

  useEffect(() => {
    let isIgnore = false;
    if (!isIgnore && isProUser !== undefined) {
      handleGoClick();
    }
    return () => {
      isIgnore = true;
    };
  }, [isProUser, tabSelectedInsight, tabSelectedTimeframe, tabSelectedDenoms]);

  const handleDenominationSelect = (denominations: IDenomination[]) => {
    if (!denominations.length) return;
    filterCommandCreator.SetFilterOptions(id! as FilterTypeEnum, {
      denominations: denominations as IDenomination[],
    });
  };

  const handleInsightFieldsChange = (values: any) => {
    if (values.length >= 6 || !values.length) return;
    const advancedViewInsights = values.map((insight: ReactSelectOption) => insight.value);
    const notIncludesC = advancedViewInsights.includes(tabSelectedInsight);
    let finalInsight;
    if (!notIncludesC) {
      finalInsight = advancedViewInsights[advancedViewInsights.length - 1];
    } else {
      finalInsight = tabSelectedInsight;
    }
    filterCommandCreator.SetFilterOptions(id! as FilterTypeEnum, {
      insight: finalInsight,
      advancedViewInsights: advancedViewInsights,
    });
  };

  const handleGoClick = () => {
    if (!advancedViewInsights || !tabSelectedInsight) return;
    const updatedAdvancedViewInsights = [...advancedViewInsights];
    const isInsightIncluded = updatedAdvancedViewInsights.includes(tabSelectedInsight);

    if (!isInsightIncluded) {
      updatedAdvancedViewInsights[updatedAdvancedViewInsights.length - 1] = tabSelectedInsight;
    }
    const filterPreferences = createFilterPreferences(
      id! as FilterTypeEnum,
      tabSelectedTimeframe,
      tabSelectedInsight,
      tabSelectedDenoms,
      isInsightIncluded,
      advancedViewInsights,
      updatedAdvancedViewInsights
    );

    Object.keys(filterPreferences).forEach((id) => {
      const newPreference = filterPreferences[id as keyof IFilterState];
      if (newPreference) {
        filterCommandCreator.SetFilterOptions(id as FilterTypeEnum, newPreference);
      }
    });
    auth.trackPreference(filterPreferences).subscribe();

    if (onClickGo) {
      onClickGo();
    }
  };

  const createFilterPreferences = (
    id: FilterTypeEnum,
    tabSelectedTimeframe: TimeFrameValue,
    tabSelectedInsight: InsightValue,
    tabSelectedDenoms: IDenomination[],
    isInsightIncluded: boolean,
    advancedViewInsights: InsightValue[],
    updatedAdvancedViewInsights: InsightValue[]
  ): Partial<IFilterState> => {
    const updatedFilterPreference = {
      timeframe: tabSelectedTimeframe,
      insight: tabSelectedInsight,
      denominations: tabSelectedDenoms,
      advancedViewInsights: isInsightIncluded ? advancedViewInsights : updatedAdvancedViewInsights,
    };

    // If the filter type is 'hottestSlots', updating this will also reflect in both graph and map filters
    if (id == FilterTypeEnum.hottestSlots) {
      return {
        hottestSlots: updatedFilterPreference,
        graphfilter: updatedFilterPreference,
        mapfilter: updatedFilterPreference,
      };
    } else {
      return {
        [id]: {
          timeframe: tabSelectedTimeframe,
          insight: tabSelectedInsight,
          denominations: tabSelectedDenoms,
          advancedViewInsights: isInsightIncluded
            ? advancedViewInsights
            : updatedAdvancedViewInsights,
        },
      };
    }
  };

  const resetAllFilters = () => {
    filterCommandCreator.ResetAllFilters();
  };

  const handleClearSearch = () => {
    slotCommands.ClearSelectedSlots();
  };

  useEffect(() => {
    if (userId === null) {
      resetAllFilters();
    }
  }, [userId]);

  const styles = useWhiteLabelStyles(WHITE_LABEL_TYPE_ENUM.CANVAS);

  const getInsightSize = () => {
    if (tabSelectedInsight === 'pop') {
      return '12';
    }

    if (isLandscape) {
      return showDenominationSelector ? '4' : '6';
    }

    return '6';
  };

  return (
    <div
      className={clsx(
        'time-filter',
        id !== FilterTypeEnum.graphfilter && id !== FilterTypeEnum.mapfilter && 'bg-primary'
      )}
      style={
        id !== FilterTypeEnum.graphfilter && id !== FilterTypeEnum.mapfilter ? styles : undefined
      }
    >
      <IonGrid className="ion-no-padding">
        {selectedSlotsName && selectedSlots.length > 1 && (
          <IonRow className="ion-align-items-center">
            <IonButton
              onClick={handleClearSearch}
              expand="block"
              size="small"
              fill="clear"
              color="light"
            >
              <IonIcon slot="start" icon={closeCircleOutline}></IonIcon>
              {titleCase(selectedSlotsName)} ({selectedSlots.length})
            </IonButton>
          </IonRow>
        )}
        <IonRow className="ion-align-items-center time-filter-row-container">
          <IonCol no-padding className="ion-no-padding">
            <IonRow className="dropdown-selector-group-list">
              {showDenominationSelector && (
                <IonCol size={isLandscape ? (showDenominationSelector ? '4' : '6') : '12'}>
                  <DenominationSelector options={Denom} onSelect={handleDenominationSelect} />
                </IonCol>
              )}
              <IonCol size={getInsightSize()} id={id + '_insight'}>
                {isMultiSelect && (
                  <InsightFieldSelector
                    onChange={handleInsightFieldsChange}
                    value={fieldSelectorValues}
                  />
                )}

                {!isMultiSelect && (
                  <InsightSelector
                    id={id}
                    isShowShortName={isShowShortName}
                    restrictions={insightRestrictions}
                    insights={insights}
                    additionalInsights={additionalInsights}
                    onSelect={handleInsightSelect}
                    customCls="insights-dropdown"
                    selectedInsight={tabSelectedInsight}
                    isDisabled={false}
                  />
                )}
              </IonCol>
              {tabSelectedInsight !== 'pop' && (
                <IonCol
                  size={isLandscape ? (showDenominationSelector ? '4' : '6') : '6'}
                  className={`${shouldHideTimeframes ? 'invisible' : ''}`}
                  id={id + '_timeframe'}
                >
                  <TimeFrameSelector
                    isShowShortName={isShowShortName}
                    restrictions={timeFrameRestrictions}
                    additionalTimeFrame={additionalTimeFrames}
                    onSelect={handleTimeframeSelect}
                    value={tabSelectedTimeframe}
                    isDisabled={shouldHideTimeframes ? true : false}
                    customCls="timeframes-dropdown"
                    timeFrames={timeFrames}
                  />
                </IonCol>
              )}
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default DropdownBar;
