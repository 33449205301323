import {
  InputChangeEventDetail,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonRow,
  useIonLoading,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import AuthHelper from 'lib/auth.common';
import { Auth } from 'aws-amplify';
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';
import * as yup from 'yup';
import { formatPhoneNumber } from 'config/config';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { onPromise } from '../../CreateAccount';
import { IonInputCustomEvent } from '@ionic/core';
import { ReduxCommandCreator } from 'appRedux/actions';
import { useDispatch } from 'react-redux';
import { AuthService, IUserInfo } from 'services/auth';
import { AUTH_STATE } from 'appRedux/types/reduxTypes';
import useLocalStorage from 'common/reactHooks/useLocalStorage';

interface FormData {
  user: string;
  password: string;
}

export const login_schema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'Password must include at least 8 characters')
    .required('Password is required'),
});

interface IAWSAuthUser {
  signInUserSession: {
    accessToken: {
      payload: {
        'cognito:groups': Array<string>;
      };
    };
  };
}

export const SignInAccount = () => {
  const dispatch = useDispatch();
  const commands = ReduxCommandCreator(dispatch);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [hasAgreedToLegalDisclaimer, setHasAgreedToLegalDisclaimer] = useLocalStorage<boolean>(
    'legalDisclaimer',
    false
  );
  const [presentToast] = useIonToast();
  const [present, dismiss] = useIonLoading();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(login_schema),
  });
  const [phone, setPhone] = useState('');
  const [phoneErr, setPhoneErr] = useState('');
  const router = useIonRouter();

  const onSubmit = async (data: FormData) => {
    if (!phone) {
      setPhoneErr('Cell number is required');
      return;
    } else {
      setPhoneErr('');
    }
    const pattern = /^(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/;
    const validatePhNo = pattern.test(phone);
    if (!validatePhNo) {
      setPhoneErr('Phonenumber is not valid');
      return;
    }

    await present({
      message: 'Loading...',
      cssClass: 'custom-loading',
    });

    try {
      const username = `+1${phone.replaceAll('-', '')}`;
      const password = data.password;
      const user = (await Auth.signIn(username, password)) as IAWSAuthUser;
      const service = new AuthService();
      service.Login().subscribe({
        next(loginResponse) {
          commands.SetAuthState(AUTH_STATE.AUTHENTICATED);
          let groups: string[] = [];
          if (user && user.signInUserSession?.accessToken?.payload['cognito:groups']) {
            groups = user.signInUserSession.accessToken.payload['cognito:groups'];
          }

          if (loginResponse?.response) {
            const {
              emailAddress,
              aliasInitial,
              aliasInitial2,
              aliasName,
              appUserPk,
              firstName,
              lastName,
            } = loginResponse.response.user;

            AuthHelper.SetToken(loginResponse.response.token as string);
            const { last_login_timestamp_counter } = loginResponse.response.lastLogin;
            const shoUserConfirm: boolean =
              !last_login_timestamp_counter || last_login_timestamp_counter < 1 ? true : false;

            const aliasInformation: Partial<IUserInfo> = {
              aliasInitial: aliasInitial,
              aliasInitial2: aliasInitial2,
              aliasName: aliasName,
            };

            commands.InitUserInfo(username, username, emailAddress as string, appUserPk, groups);
            commands.SetTourState(loginResponse.response.user?.hasCompletedOnboarding as boolean);
            commands.SetUserAlias({
              ...aliasInformation,
            });
            const userInformation: Partial<IUserInfo> = {
              firstName: firstName,
              lastName: lastName,
              emailAddress: emailAddress,
            };

            commands.SetUserInformation({
              ...userInformation,
            });
            if (shoUserConfirm) {
              router.push('/auth/userconfirm');
            } else {
              if (hasAgreedToLegalDisclaimer) {
                router.push('/auth/choosecasinos');
              } else {
                router.push('/auth/legalDisclaimer');
              }
            }
          }

          dismiss()
            .then(() => {
              return;
            })
            .catch(() => {
              return;
            });
        },
        error(err) {
          void presentToast({
            position: 'top',
            duration: 5000,
            color: 'danger',
            message: 'Login has failed, please try again if this persists please contact support.',
          });
          dismiss()
            .then(() => {
              return;
            })
            .catch(() => {
              return;
            });
        },
      });
    } catch (error) {
      let message = 'Unknown Error';
      if (error instanceof Error) message = error.message;
      const username = `+1${phone.replaceAll('-', '')}`;
      switch (message) {
        case 'User is not confirmed.':
          message = 'Sorry, User is not confirmed. Please verify your cell number and confirm.';
          await Auth.resendSignUp(username);
          commands.InitUserInfo(phone, phone);
          router.push('/auth/verifyaccount');
          break;
        case 'Incorrect username or password.':
          message = 'Sorry, Incorrect password.';
          break;
        case 'User does not exist.':
          message = 'Sorry, User does not exist.';
          break;
        default:
          if (error instanceof Error) message = error.message;
          break;
      }
      void presentToast({
        position: 'top',
        duration: 5000,
        color: 'danger',
        message: message,
      });

      dismiss()
        .then(() => {
          return;
        })
        .catch(() => {
          return;
        });
    }
  };

  const inputEl = useRef<HTMLIonInputElement>(null);

  const formatPhone = (ev: IonInputCustomEvent<InputChangeEventDetail>) => {
    const value = ev.target.value;
    const filteredValue = formatPhoneNumber(value);
    setPhone(filteredValue);

    const inputCmp = inputEl.current;
    if (inputCmp !== null) {
      inputCmp.value = filteredValue;
    }
  };

  return (
    <div className="m-signup__login">
      <div>
        <form
          id="confirm-page"
          className="form-modal"
          onSubmit={onPromise(handleSubmit((data: FormData) => void onSubmit(data)))}
        >
          <IonGrid>
            <IonRow>
              <IonCol>
                <div className="header-bar">
                  <h1 id="login-header" className="heading4 signup-box-title primary-color-text">
                    Login
                  </h1>
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <span className="button-text primary-color-text">Mobile Number</span>
                <div className="phone-group">
                  <div>
                    <div className="input-group country-code">
                      <span className="us-flag primary-color-text">+1</span>
                    </div>
                  </div>
                  <div>
                    <div className="input-group cy-username-field-group">
                      <IonInput
                        type="text"
                        autocomplete="tel"
                        maxlength={12}
                        placeholder="(###) ### - ####"
                        value={phone}
                        id="username-field"
                        onIonChange={(ev) => formatPhone(ev)}
                        ref={inputEl}
                      />
                      {phoneErr && <div className="field-validation-message">{phoneErr}</div>}
                    </div>
                  </div>
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <span className="button-text primary-color-text">Password</span>
                <div className="input-group cy-password-field-group">
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange } }) => (
                      <div className="input-row">
                        <IonInput
                          type={showPasswordNew ? 'text' : 'password'}
                          placeholder=""
                          onIonChange={onChange}
                          autocomplete="current-password"
                          id="password-field"
                        />

                        <div
                          className="input-icon-box"
                          onClick={() => {
                            setShowPasswordNew((showPasswordNew) => !showPasswordNew);
                          }}
                        >
                          <IonIcon icon={showPasswordNew ? eyeOffOutline : eyeOutline} />
                        </div>
                      </div>
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.password && errors.password.message && (
                    <div className="field-validation-message">{errors.password.message}</div>
                  )}
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonButton type="submit" id="login-submit" className="submit-btn">
                  Login
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-end">
              <IonCol>
                <div className="create-account-link ion-float-end">
                  <span></span>
                  <Link to="/auth/forgotpassword">
                    <span className="unregister" data-cy="login-button-mobile-reg">
                      Forgot Password?
                    </span>
                  </Link>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>

          <div className="create-account-link">
            <span>Dont have an account? </span>
            <Link to="/auth/createaccount">
              <span className="unregister" data-cy="login-button-mobile-reg">
                Signup
              </span>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};
