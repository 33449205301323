import { useEffect } from 'react';

export const useDynamicCSS = (css: string) => {
  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.innerText = css;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);
};
