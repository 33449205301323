import { IHotColdCollapsedState } from 'appRedux/models/slotModels';
import { IReducer } from './moduleReducerRegistry';
import { slotActionTypes } from 'appRedux/actions/slotCommandCreator';
import { ISlotState } from 'appRedux/createStore';

export enum SectionID {
  Snapshot = 'Snapshot',
  Graph = 'Graph',
  Map = 'Map',
}

export type SectionIdType = keyof typeof SectionID;
export const sectionValues = Object.keys(SectionID).map(
  (key) => SectionID[key as keyof typeof SectionID]
);

export const slotInitialState: ISlotState = {
  collapsed: false,
  sections: sectionValues,
  selectedSlotsName: '',
  selectedSlots: [],
};

export const slotReducer: IReducer = (draft, action) => {
  const { type, state } = action;
  switch (type) {
    case slotActionTypes.SET_DEFAULT_SECTION:
      const { sectionId: sectionIdParam } = action.state;
      const { sections: sectionsData } = draft.app.persistedState.slotState;
      const index = sectionsData.indexOf(sectionIdParam);
      if (index !== -1) {
        sectionsData.unshift(...sectionsData.splice(index, 1));
        draft.app.persistedState.slotState.sections = sectionsData;
      }
      break;
    case slotActionTypes.MOVE_SECTION:
      const { sectionId, newPosition, sections } = action.state;
      const currentIndex = sections.indexOf(sectionId);
      if (currentIndex !== -1 && newPosition >= 0 && newPosition < sections.length) {
        const newOrder = [...sections];
        newOrder.splice(currentIndex, 1);
        newOrder.splice(newPosition, 0, sectionId);
        draft.app.persistedState.slotState.sections = newOrder;
      }
      break;
    case slotActionTypes.TOGGLE_HOT_COLD_COLLAPSED:
      if (draft.app && draft.app.persistedState && draft.app.persistedState.slotState) {
        const currentState = state as IHotColdCollapsedState;
        draft.app.persistedState.slotState.collapsed = currentState.collapsed;
      }
      break;
    case slotActionTypes.SET_SELECTED_SLOTS:
      if (draft.app && draft.app.persistedState && draft.app.persistedState.slotState) {
        const currentState = state as string[];
        draft.app.persistedState.slotState.selectedSlots = currentState;
      }
      break;
    case slotActionTypes.SET_SELECTED_SLOTS_NAME:
      if (draft.app && draft.app.persistedState && draft.app.persistedState.slotState) {
        const currentState = state as string;
        draft.app.persistedState.slotState.selectedSlotsName = currentState;
      }
      break;
    case slotActionTypes.CLEAR_SELECTED_SLOTS:
      if (draft.app && draft.app.persistedState && draft.app.persistedState.slotState) {
        draft.app.persistedState.slotState.selectedSlotsName = '';
        draft.app.persistedState.slotState.selectedSlots = [];

      }
      break;
  }
};

export default slotReducer;
