import {
  InputChangeEventDetail,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonImg,
  IonInput,
  IonPage,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import { ReduxCommandCreator } from 'appRedux/actions';
import { formatPhoneNumber } from 'config/config';
import { IonInputCustomEvent } from '@ionic/core';
import { useRef, useState } from 'react';

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const commands = ReduxCommandCreator(dispatch);
  const history = useHistory();
  const inputEl = useRef<HTMLIonInputElement>(null);

  const [phone, setPhone] = useState('');
  const [phoneErr, setPhoneErr] = useState('');

  const formatPhone = (ev: IonInputCustomEvent<InputChangeEventDetail>) => {
    const value = ev.target.value;
    const filteredValue = formatPhoneNumber(value);
    setPhone(filteredValue);

    const inputCmp = inputEl.current;
    if (inputCmp !== null) {
      inputCmp.value = filteredValue;
    }
  };

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!phone) {
      setPhoneErr('Cell number is required');
      return;
    } else {
      setPhoneErr('');
    }
    const pattern = /^(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/;
    const validatePhNo = pattern.test(phone);
    if (!validatePhNo) {
      setPhoneErr('Phonenumber is not valid');
      return;
    }

    const transformedPhoneNumber = phone
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('-', '')
      .replaceAll(' ', '');
    void Auth.forgotPassword(`+1${transformedPhoneNumber?.replaceAll('-', '')}`);
    commands.SetUserInfo(transformedPhoneNumber, transformedPhoneNumber);
    history.push('/auth/resetpassword');
  };

  return (
    <IonPage className="app-parent">
      <IonHeader>
        <IonToolbar className="toolbar-background">
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="scroll-disable">
        <div className="container">
          <div className="content">
            <div className="m-signup m-signup__login">
              <div className="modal-container">
                <form onSubmit={onSubmit}>
                  <IonRow>
                    <IonCol>
                      <div className="header-bar">
                        <span className="heading4 signup-box-title primary-color-text">
                          Forgot Password?
                        </span>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <span className="button-text primary-color-text">Mobile Number</span>
                      <div className="phone-group">
                        <div>
                          <div className="input-group country-code">
                            <span className="us-flag primary-color-text">+1</span>
                          </div>
                        </div>
                        <div>
                          <div className="input-group cy-username-field-group">
                            <IonInput
                              type="text"
                              autocomplete="tel"
                              maxlength={12}
                              placeholder="(###) ### - ####"
                              value={phone}
                              data-cy="username-text-field"
                              onIonChange={(ev) => formatPhone(ev)}
                              ref={inputEl}
                            />
                            {phoneErr && <div className="field-validation-message">{phoneErr}</div>}
                          </div>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonButton type="submit" data-cy="login-submit" className="submit-btn">
                        Submit
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </form>
              </div>
              <div className="footer-container">
                <div className="logo">
                  <IonImg src="assets/images/SLOTcheckTM.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
