import React, { useEffect } from 'react';
import './hotListTable.scss';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { config, HeatBeanObjectKey } from '../../config/config';
const { heatBeanRatings } = config;
import HotListHeader from './HotListHeader/HotListHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';
import AdvancedViewHeader from './AdvancedViewHeader/AdvancedViewHeader';
import HotListDetailRow from './HotListDetailRow/HotListDetailRow';
import { InsightValue, ReactSelectOption } from '../../common/types';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'appRedux/createStore';
import { playerTypeEnum } from 'common/commonEnum';
import { IUserFavorite } from 'appRedux/models/playerModels';
import { ICasinoInsights, ICasinoReport } from 'appRedux/models/casinoModels';
import { FilterReduxCommandCreator } from 'appRedux/actions/filterCommandCreator';
import { FilterTypeEnum } from 'common/common';

interface HotListTableProps {
  datasets: ICasinoReport[];
  activeColColor: any;
  selectedInsight: InsightValue;
  selectedTimeFrame: string;
  isLoading: boolean;
  floorDataSet: ICasinoReport[];
  sortDirection: string;
  onClick: (rowData: any) => void;
  totalCount: string;
  isFilterDataPresent: boolean;
  advancedViewInsights: InsightValue[];
  isExpandDateTime: boolean;
  onFavorite?: (rowData: any) => void;
  favoriteData?: IUserFavorite[];
  selectSlot?: (slotNumber: number, screenType: string) => void;
  updateComparedSlots?: (slotNumber: number) => void;
  selectedSlots?: number[];
  activeInsightFilter: InsightValue | null;
  listType: string;
  isAdvancedDisplayState?: boolean;
  isComparingSlots?: boolean;
  tab?: string;
}

const HotListTable: React.FC<HotListTableProps> = ({
  activeColColor,
  datasets,
  onClick,
  selectedInsight,
  sortDirection,
  totalCount,
  advancedViewInsights,
  isLoading,
  isFilterDataPresent,
  floorDataSet,
  onFavorite,
  favoriteData,
  selectSlot,
  updateComparedSlots,
  selectedSlots,
  selectedTimeFrame,
  activeInsightFilter,
  listType,
  isAdvancedDisplayState,
  isComparingSlots,
  tab,
}) => {
  const isProUser = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.isProUser
  ) as boolean;
  const activeColStyle = {
    ['--active-col-color' as any]: activeColColor,
  };
  const isAccessForCompare = config.compareAccess?.some(
    (item) => item === String(isProUser ? playerTypeEnum.slotCheckPro : playerTypeEnum.slotCheck)
  );
  const dispatch = useDispatch();
  const filterCommandCreator = FilterReduxCommandCreator(dispatch);

  const getHeatBean: any = (dataItem: ICasinoReport, insightKey: ICasinoInsights) => {
    if (floorDataSet?.length > 0) {
      if (floorDataSet?.find((x: ICasinoReport) => x.slot_number === dataItem.slot_number)) {
        const stats = dataItem[(insightKey + '_ntile') as keyof ICasinoReport];
        return (
          <div className="box-bean">
            <div
              className="box-indicator"
              style={{ background: heatBeanRatings[stats as HeatBeanObjectKey]?.color }}
            />
          </div>
        );
      }
    }
  };

  const onListSelect = (item: any, screenType?: any) => {
    selectSlot?.(item.slot_number, screenType);
  };

  const orderInsightSorting = (orderInsights: any) => {
    const orderedSlotInsights = config.slotInsights.sort((a, b) => {
      return a.display_order - b.display_order;
    });
    const intersection = orderedSlotInsights.filter((x: any) => orderInsights.includes(x.value));
    const updateOrderAdvancedview = intersection.sort(
      (a: any, b: any) => Number(a.display_order) - Number(b.display_order)
    );
    const filterAdvanceView = updateOrderAdvancedview.map((a) => a.value);
    const notIncludesC = filterAdvanceView.includes(selectedInsight);
    let finalAdvanceView = filterAdvanceView;
    if (!notIncludesC) {
      finalAdvanceView = [];
      filterAdvanceView[filterAdvanceView.length - 1] = selectedInsight;
      const intersection = orderedSlotInsights.filter((x) => filterAdvanceView.includes(x.value));
      const updateOrderAdvancedview = intersection.sort(
        (a: any, b: any) => Number(a.display_order) - Number(b.display_order)
      );
      finalAdvanceView = updateOrderAdvancedview.map((a) => a.value);
    }
    return finalAdvanceView;
  };
  useEffect(() => {
    filterCommandCreator.SetFilterOptions(tab! as FilterTypeEnum, {
      advancedViewInsights: orderInsightSorting(advancedViewInsights) as InsightValue[],
    });
  }, []);

  const handleInsightFieldsChange = (values: any) => {
    if (values.length >= 6 || !values.length) return;
    const advancedViewInsights = values.map((insight: ReactSelectOption) => insight.value);
    const notIncludesC = advancedViewInsights.includes(selectedInsight);
    if (!notIncludesC) {
      onClick(
        config.hotListTableRow.find(
          ({ insight }) => insight === advancedViewInsights[advancedViewInsights.length - 1]
        )
      );
    }
    filterCommandCreator.SetFilterOptions(tab! as FilterTypeEnum, {
      advancedViewInsights: orderInsightSorting(advancedViewInsights) as InsightValue[],
    });
  };

  const handleSelectItem = (slotNumber: number) => {
    if (isAccessForCompare) {
      if (isComparingSlots) return;
      if (!isFilterDataPresent) {
        //updateComparedSlots(slotNumber);
      }
    }
  };

  // const handleCompareSlots = () =>
  //   slotSend({
  //     type: 'compareSlots',
  //     activeTab: tab,
  //   });
  // const handleClearSelectedSlots = () => {
  //   slotSend({
  //     type: 'hideCompareSlots',
  //     activeTab: tab,
  //   });
  // };

  const renderHotListDetailRow: any = (rowData: any, index: number) => {
    const favorite = favoriteData?.find(
      (eachFavorite: IUserFavorite) => eachFavorite.slotNumber == rowData.slot_number
    );
    // const isSelected = isComparingSlots
    //   ? false
    //   : selectedSlots.some((slotNumber) => slotNumber == rowData?.slot_number);

    return (
      <HotListDetailRow
        key={index}
        rowData={rowData}
        listType={listType}
        isFavorite={favorite}
        // isSelected={isSelected}
        isFilterDataPresent={isFilterDataPresent}
        onListSelect={onListSelect}
        onFavorite={onFavorite}
        selectedTimeFrame={selectedTimeFrame}
        selectedInsight={selectedInsight}
        handleSelectItem={handleSelectItem}
        getHeatBean={getHeatBean}
        advancedViewInsights={advancedViewInsights}
        isAdvancedDisplayState={isAdvancedDisplayState}
        isLoading={isLoading}
      />
    );
  };

  return (
    <div className="slot-table">
      <div className="slot-table-header sticky-top">
        <div className="slot-table-row">
          {datasets && datasets.length > 0 && (
            <IonGrid className="ion-no-padding" style={activeColStyle}>
              {isAccessForCompare && (
                <AdvancedViewHeader
                  isAdvancedDisplayState={isAdvancedDisplayState}
                  advancedViewInsights={advancedViewInsights}
                  handleInsightFieldsChange={handleInsightFieldsChange}
                  // handleCompareSlots={handleCompareSlots}
                  // handleClearSelectedSlots={handleClearSelectedSlots}
                  selectedSlotsCount={selectedSlots?.length}
                  isComparingSlots={isComparingSlots}
                  listType={listType}
                  datasets={datasets}
                />
              )}

              <IonRow className="sticky-padding-top">
                <IonCol size="4" className="slot-name-label" onClick={() => onClick('Slot Name')}>
                  Slot Name{' '}
                  {!activeInsightFilter && (
                    <FontAwesomeIcon
                      icon={
                        sortDirection === 'desc_nulls_last' ? faLongArrowAltDown : faLongArrowAltUp
                      }
                      className="sort-arrow-insights insight-name-sort-icon"
                    />
                  )}
                </IonCol>
                <HotListHeader
                  isAdvancedDisplayState={isAdvancedDisplayState}
                  totalCount={totalCount}
                  onSelect={onClick}
                  selectedInsight={selectedInsight}
                  sortDirection={sortDirection}
                  advancedViewInsights={advancedViewInsights}
                  activeInsightFilter={activeInsightFilter}
                  listType={listType}
                />
                <IonCol size="2"></IonCol>
              </IonRow>
            </IonGrid>
          )}
        </div>
      </div>

      <div className="slot-table-body">
        {datasets.map((rowData: any, index: number) => renderHotListDetailRow(rowData, index))}
      </div>
    </div>
  );
};

export default HotListTable;
