import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { parseValueForTrends } from '../../../../common/include';
import UserBadge from '../../../../components/UserBadge/UserBadge';
import { ILeaderBoardReport } from 'appRedux/models/casinoModels';
import { FC } from 'react';
import { LeaderBoardInsightValue } from 'common/types';
import { playerLevel } from 'common/commonEnum';

interface LeaderboardTableProps {
  leadersData: ILeaderBoardReport[];
  selectedInsight: LeaderBoardInsightValue;
}

const LeadersTable: FC<LeaderboardTableProps> = ({ leadersData, selectedInsight }) => {
  return (
    <>
      {leadersData.map((leaderData: ILeaderBoardReport, index: number) => {
        const { alias_name, alias_initial2, is_current_user, plan_type } = leaderData;
        const isAnonymous = alias_name === 'Anonymous';
        const isCurrentUser = is_current_user === true;
        const playerInitialCls = isAnonymous
          ? 'user-avatar-color1'
          : isCurrentUser
          ? 'user-avatar-color5'
          : `user-avatar-color${Math.floor(Math.random() * 5) + 2}`;
        const insightValue = parseValueForTrends(leaderData[selectedInsight], selectedInsight);
        const insightOrder = leaderData.orderNumber;
        const insightPercent = leaderData[`${selectedInsight}_percent`];
        return (
          <div
            className={`table-data-leaders-content-row ${isCurrentUser ? 'current-user-row' : ''}`}
            key={index.toString()}
          >
            <IonGrid className="ion-no-padding">
              <IonRow className="ion-align-items-center">
                <IonCol
                  size="1.5"
                  className="ion-text-center leaders-count-content font-size-align"
                >
                  {insightOrder}
                </IonCol>
                <IonCol size="1" className="ion-text-center data-percent">
                  <div className="data-percent-box">{insightPercent}%</div>
                </IonCol>

                <UserBadge
                  aliasInitial={alias_initial2}
                  aliasName={alias_name}
                  playerCircleCls={playerInitialCls}
                  isProAccount={plan_type === playerLevel.slotCheckPro ? true : false}
                />
                {selectedInsight.includes('jackpot') && (
                  <IonCol
                    size="1.5"
                    className="ion-text-center leaders-count-content font-size-align"
                  >
                    <div className="jkpt-number">{leaderData.jackpots}</div>
                  </IonCol>
                )}
                <IonCol
                  size={selectedInsight.includes('jackpot') ? '2' : '3.5'}
                  class="center-flex-align"
                >
                  <div className="table-data-flex-container dollar-align">{insightValue}</div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        );
      })}
    </>
  );
};

export default LeadersTable;
