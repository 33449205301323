import { useIonToast } from '@ionic/react';
import { toastConfig, ToastType } from '../../config/ToastConfig';

const useToast = () => {
  const [presentToast] = useIonToast();
  const showToast = ({ type = 'success', message }: { type?: ToastType; message: string }) => {
    toastConfig.color = type;
    toastConfig['message'] = message;
    presentToast(toastConfig as any);
  };
  return showToast;
};

export default useToast;
