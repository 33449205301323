import { IonButton, IonImg } from '@ionic/react';
import React from 'react';
import './upgrade-now.scss';
interface UpgradeNowProps {
  onClick?: () => void;
}

const UpgradeNow: React.FC<UpgradeNowProps> = ({ onClick }) => {
  return (
    <IonButton className="upgrade-button" onClick={onClick}>
      <span>Upgrade to</span>
      <IonImg src="assets/images/slot-pro.png" className="slot-plus-png" />
    </IonButton>
  );
};

export default UpgradeNow;
