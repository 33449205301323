import atmImage from './mapicons/atm.png';
import busEntrance from './mapicons/bus-entrance.png';
import cashierWindow from './mapicons/cashier-window.png';
import cashier from './mapicons/cashier.png';
import eagleNest from './mapicons/eagle-nest-logo.png';
import guestService from './mapicons/guest-service.png';
import theBayhighRollerRoom from './mapicons/the-bay-high-roller-room.png';
import hostLounge from './mapicons/host-lounge.png';
import kisoskPromo from './mapicons/kisok-promo.png';
import kisoskEnroll from './mapicons/kisok-enroll.png';
import mainEntrance from './mapicons/main-entrance.png';
import redeem from './mapicons/redeem.png';
import restrooms from './mapicons/restrooms.png';
import theCove from './mapicons/the-cove.png';
import waterSoda from './mapicons/water-soda.png';
import woodyBar from './mapicons/woody-bar-new.png';
import woodyGrill from './mapicons/woody-grill-new.png';
import hotelLounge from './mapicons/hotel-lounge.png';

const LblImageArr = [
  {
    name: 'atm',
    image: atmImage,
  },
  {
    name: 'bus-entrance',
    image: busEntrance,
  },
  {
    name: 'cashier-window',
    image: cashierWindow,
  },
  {
    name: 'cashier',
    image: cashier,
  },
  {
    name: 'eagle-nest',
    image: eagleNest,
  },
  {
    name: 'guest-service',
    image: guestService,
  },
  {
    name: 'the-bay-high-roller-room',
    image: theBayhighRollerRoom,
  },
  {
    name: 'host-lounge',
    image: hostLounge,
  },
  {
    name: 'hotel-lounge',
    image: hotelLounge,
  },
  {
    name: 'kisok-promo',
    image: kisoskPromo,
  },
  {
    name: 'kisok-enroll',
    image: kisoskEnroll,
  },
  {
    name: 'main-entrance',
    image: mainEntrance,
  },
  {
    name: 'redeem',
    image: redeem,
  },
  {
    name: 'restrooms',
    image: restrooms,
  },
  {
    name: 'the-cove',
    image: theCove,
  },
  {
    name: 'water-soda',
    image: waterSoda,
  },
  {
    name: 'woody-bar',
    image: woodyBar,
  },
  {
    name: 'woody-grill',
    image: woodyGrill,
  },
];

export const loadImage = (mapbox:any, imageName:any, key:any) => {
  mapbox.loadImage(imageName, (error:any, image:any) => {
    if (error) throw error;
    mapbox.addImage(key, image);
  });
};

export const addMapImage = (mapbox:any) => {
  for (let index = 0; index < LblImageArr.length; index++) {
    const eachElement = LblImageArr[index];
    loadImage(mapbox, eachElement.image, eachElement.name);
  }
};
