import { FC } from 'react';
import Select from 'react-select';
import CustomInput from './compounds/CustomInput';
import CustomMultiValue from './compounds/CustomMultiValue';
import DropDownMultiselectOption from './DropDownMultiselectOption';



interface SingleSelectDropDownProps {
  onChange?: (val: any) => void;
  options?: any[];
  styleClass?: string;
  placeholder?: string;
  value?: any;
  defaultOption?: any;
  DropDownSelectOption: any;
  DropDownSelectValue: any;
  styles?: any;
  isDisabled?: boolean;
  menuDisplayPosition?: any;
  isSearchable?: boolean;
  inputType?: string;
  isLoading?: boolean;
  isOpen?: boolean;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
}

const ReactSelectDropDown: FC<SingleSelectDropDownProps> = ({
  onChange,
  options,
  styleClass,
  placeholder,
  value,
  defaultOption,
  DropDownSelectOption,
  DropDownSelectValue,
  styles,
  isDisabled = false,
  menuDisplayPosition,
  isSearchable = false,
  inputType = 'text',
  // maxMenuHeight,
  isLoading,
  isOpen,
  isMulti,
  closeMenuOnSelect,
  hideSelectedOptions,
}) => {
  const onFocusInput = () => {
    // close all denom if open
    const element: HTMLElement = document.getElementsByClassName('css-48ayfv')[0] as HTMLElement;
    element?.click();
  };

  const filterOptions = (candidate: { label: string; value: string; data: any }, input: string) => {
    const labelText = candidate?.label?.toString().toLowerCase() || '';
    const searchText = input?.toString().toLowerCase() || '';

    if (searchText && placeholder === 'Month') {
      const monthOrder = candidate.value + 1;
      return labelText.includes(searchText) || monthOrder?.toString().includes(searchText);
    } else if (searchText) {
      return labelText.includes(searchText);
    }
    return true;
  };
  const valueSlected = value?.casino_id ? value.value : value || defaultOption;
  const selectedValue = options!.filter((option) => option.value === valueSlected)?.[0];
  styleClass += ' filter-dropdown ';

  return (
    <Select
      defaultValue={defaultOption}
      menuShouldBlockScroll
      menuShouldScrollIntoView
      menuIsOpen={isOpen}
      isLoading={isLoading}
      isDisabled={isDisabled}
      onChange={onChange}
      options={options}
      className={styleClass}
      classNamePrefix="slot"
      placeholder={placeholder}
      isSearchable={false}
      value={selectedValue}
      components={{
        Option: isMulti ? DropDownMultiselectOption : DropDownSelectOption,
        SingleValue: DropDownSelectValue,
        Input: (inputProps) => (
          <CustomInput inputProps={inputProps} isSearchable={isSearchable} inputType={inputType} />
        ),
        MultiValue: (props) => <CustomMultiValue {...props} />,
      }}
      styles={styles}
      onFocus={onFocusInput}
      isMulti={isMulti}
      closeMenuOnSelect={closeMenuOnSelect}
      hideSelectedOptions={hideSelectedOptions}
      menuPlacement={menuDisplayPosition}
      filterOption={filterOptions}
    />
  );
};

export default ReactSelectDropDown;
