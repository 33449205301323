import { components, InputProps } from 'react-select';

interface CustomInputProps {
  inputProps: InputProps<true>;
  isSearchable: boolean;
  inputType: string;
}

const CustomInput: React.FC<CustomInputProps> = ({ inputProps, isSearchable, inputType }) => {
  return (
    <components.Input
      {...inputProps}
      value={''}
      readOnly={!isSearchable}
      type={isSearchable ? inputType : 'button'}
      inputMode={isSearchable ? (inputType === 'number' ? 'numeric' : 'text') : 'none'}
    />
  );
};

export default CustomInput;
