import { Dispatch } from 'react';
import { IAction } from 'appRedux/reducers';
import { IUserInfo } from 'appRedux/createStore';
import { AuthStateType } from 'appRedux/types/reduxTypes';

import { ICasinoSchemaWithAccess } from 'appRedux/models/casinoModels';

export const AppKey = 'SLOTCHECKSPA-';

export const actionTypes = {
  CLEAR_STORE: `${AppKey}CLEAR_STORE`,
  SET_AUTH_STATE: `${AppKey}SET_AUTH_STATE`,
  SET_USERINFO_STATE: `${AppKey}SET_USERINFO_STATE`,
  INIT_USERINFO_STATE: `${AppKey}INIT_USERINFO_STATE`,
  SET_AWS_JWK_STATE: `${AppKey}SET_AWS_JWK_STATE`,
  SET_INACTIVITY_ALERT_STATE: `${AppKey}SET_INACTIVITY_ALERT_STATE`,
  SET_EMAIL_ALERT_STATE: `${AppKey}SET_EMAIL_ALERT_STATE`,
  SET_CLIENT_SECRET: `${AppKey}SET_CLIENT_SECRET`,
  SET_USER_ALIAS_STATE: `${AppKey}SET_USER_ALIAS_STATE`,
  SET_USER_INFORMATION_STATE: `${AppKey}SET_USER_INFORMATION_STATE`,
  SET_USERINFO_LEVEL_STATE: `${AppKey}SET_USERINFO_LEVEL_STATE`,
  SET_REPORT_TYPE_STATE: `${AppKey}SET_REPORT_TYPE_STATE`,
  SET_CASINO_SCHEMA_STATE: `${AppKey}SET_CASINO_SCHEMA_STATE`,
  SET_ORIENTATION: `${AppKey}SET_ORIENTATION_STATE`,
  SET_LINKED_PLAYER_STATE: `${AppKey}SET_LINKED_PLAYER_STATE`,
  SET_TOUR_STATE: `${AppKey}SET_TOUR_STATE`,
  SET_LAST_UPDATED_COLLAPSE_STATE: `${AppKey}SET_LAST_UPDATED_COLLAPSE_STATE`,
  SET_USER_PRIVACY: `${AppKey}SET_USER_PRIVACY`,
  SET_SLOT_ORDER: `${AppKey}SET_SLOT_ORDER`,
  SET_USER_PRIMARY_CASINO: `${AppKey}SET_USER_PRIMARY_CASINO`,
};

export interface IReduxCommandCreator {
  ClearStore(): void;
  SetClientSecret(state: object): void;
  SetAwsJWK(state: object): void;
  SetAuthState(state: string): void;
  SetUserInfo(
    user: string,
    phone?: string,
    email?: string,
    appUserPk?: string,
    groups?: Array<string>
  ): void;
  InitUserInfo(
    user: string,
    phone?: string,
    email?: string,
    appUserPk?: string,
    groups?: Array<string>
  ): void;
  SetInactivityAlertState(state: object): void;
  SetUserAlias(state: Partial<IUserInfo>): void;
  SetUserInformation(state: Partial<IUserInfo>): void;
  SetUserLevel(state: Partial<IUserInfo>): void;
  SetReportType(state: object): void;
  SetEmailAlertState(state: boolean): void;
  SetOrientation(state: object): void;
  SetTourState(state: boolean): void;
  SetLastUpdateCollapseState(state: boolean): void;
  SetUserPrivacy(state: boolean): void;
  SetSlotOrderState(state: boolean): void;
  SetUserPrimaryCasino(state: ICasinoSchemaWithAccess[]): void;
}

export const ReduxCommandCreator = (dispatch: Dispatch<IAction>): IReduxCommandCreator => {
  return {
    ClearStore() {
      dispatch({
        type: actionTypes.CLEAR_STORE,
      });
    },
    SetClientSecret(state: object) {
      dispatch({
        type: actionTypes.SET_CLIENT_SECRET,
        state,
      });
    },
    SetAwsJWK(state: object) {
      dispatch({
        type: actionTypes.SET_AWS_JWK_STATE,
        state,
      });
    },
    SetAuthState(state: AuthStateType) {
      dispatch({
        type: actionTypes.SET_AUTH_STATE,
        state,
      });
    },
    SetTourState(state: boolean) {
      dispatch({
        type: actionTypes.SET_TOUR_STATE,
        state,
      });
    },
    SetLastUpdateCollapseState(state: boolean) {
      dispatch({
        type: actionTypes.SET_LAST_UPDATED_COLLAPSE_STATE,
        state,
      });
    },
    SetUserPrivacy(state: boolean) {
      dispatch({
        type: actionTypes.SET_USER_PRIVACY,
        state,
      });
    },
    InitUserInfo(
      user: string,
      phone?: string,
      email?: string,
      appUserPk?: string,
      groups?: Array<string>
    ) {
      const userObject: IUserInfo = { user };
      if (phone) {
        userObject.phone = phone;
      }

      if (email) {
        userObject.emailAddress = email;
      }

      if (appUserPk) {
        userObject.appUserPk = appUserPk;
      }

      if (groups) {
        userObject.groups = groups;
      }

      dispatch({
        type: actionTypes.INIT_USERINFO_STATE,
        state: userObject,
      });
    },
    SetUserInfo(
      user: string,
      phone?: string,
      email?: string,
      appUserPk?: string,
      groups?: Array<string>
    ) {
      const userObject: IUserInfo = { user };
      if (phone) {
        userObject.phone = phone;
      }

      if (email) {
        userObject.emailAddress = email;
      }

      if (appUserPk) {
        userObject.appUserPk = appUserPk;
      }

      if (groups) {
        userObject.groups = groups;
      }

      dispatch({
        type: actionTypes.SET_USERINFO_STATE,
        state: userObject,
      });
    },
    SetUserAlias(state) {
      dispatch({
        type: actionTypes.SET_USER_ALIAS_STATE,
        state,
      });
    },
    SetUserInformation(state) {
      dispatch({
        type: actionTypes.SET_USER_INFORMATION_STATE,
        state,
      });
    },
    SetUserLevel(state) {
      dispatch({
        type: actionTypes.SET_USERINFO_LEVEL_STATE,
        state,
      });
    },
    SetInactivityAlertState(state: object) {
      dispatch({
        type: actionTypes.SET_INACTIVITY_ALERT_STATE,
        state,
      });
    },
    SetEmailAlertState(state: boolean) {
      dispatch({
        type: actionTypes.SET_EMAIL_ALERT_STATE,
        state,
      });
    },
    SetReportType(state: object) {
      dispatch({
        type: actionTypes.SET_REPORT_TYPE_STATE,
        state,
      });
    },
    SetOrientation(state: object) {
      dispatch({
        type: actionTypes.SET_ORIENTATION,
        state,
      });
    },
    SetSlotOrderState(state: boolean) {
      dispatch({
        type: actionTypes.SET_SLOT_ORDER,
        state,
      });
    },
    SetUserPrimaryCasino(state: ICasinoSchemaWithAccess[]) {
      dispatch({
        type: actionTypes.SET_USER_PRIMARY_CASINO,
        state,
      });
    },
  };
};
