import { IonToggle } from '@ionic/react';
import './toggle.scss';

interface ToggleProps {
  labelOn: string;
  labelOff: string;
  isChecked?: boolean;
  onChange?: (value: boolean) => any;
  color?: string;
  toggleCls?: string;
}

const Toggle: React.FC<ToggleProps> = ({
  labelOn,
  labelOff,
  isChecked,
  onChange,
  color = 'success',
  toggleCls = 'default-toggle',
}) => {
  const handleChange = (e: { detail: { checked: boolean } }) => {
    if (onChange) {
      onChange(e.detail.checked);
    }
  };

  return (
    <div className="toggle-wrapper">
      <label>{labelOff}</label>
      <IonToggle
        onIonChange={handleChange}
        className={toggleCls}
        color={color}
        checked={isChecked}
      />
      <label>{labelOn}</label>
    </div>
  );
};
export default Toggle;
