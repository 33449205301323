import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react';
import { OrderByEnum } from 'common/common';
import { caretDownSharp, caretUpSharp } from 'ionicons/icons';
import React from 'react';

interface IListHeader {
  onSlotNameColumnClick?: () => void;
  onHeatColumnClick: () => void;
  numberOfSlots: number;
  sortDirection: OrderByEnum;
}

export const ListHeader: React.FC<IListHeader> = ({
  onHeatColumnClick,
  onSlotNameColumnClick,
  numberOfSlots,
  sortDirection,
}: IListHeader) => {
  return (
    <IonRow class="favorites-container__list__header">
      <IonCol size="6">
        <IonButton
          className="slot-name"
          disabled
          fill="clear"
          color="dark"
          onClick={onSlotNameColumnClick}
        >
          Slot Name
        </IonButton>
      </IonCol>
      <IonCol className="heat-info" size="4">
        <IonButton fill="clear" expand="full" color="dark" onClick={onHeatColumnClick}>
          {numberOfSlots} slots
          <IonIcon icon={sortDirection === OrderByEnum.Asc ? caretUpSharp : caretDownSharp} />
        </IonButton>
      </IonCol>
      <IonCol size="2"></IonCol>
    </IonRow>
  );
};
