import { IonAvatar, IonImg } from '@ionic/react';
import { FC } from 'react';
import useFitText from 'use-fit-text';
import { useSelector } from 'react-redux';
import { IState } from 'appRedux/createStore';

interface MenuAvatarProps {
  aliasInitial?: string;
}

const MenuAvatar: FC<MenuAvatarProps> = ({ aliasInitial }) => {
  const isProUser = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.isProUser
  ) as boolean;

  const { fontSize, ref } = useFitText();
  const isFounder = useSelector<IState>((state) =>
    state.app.persistedState.userInfo?.groups?.includes('Founder')
  );
  return (
    <div className={isFounder ? 'sidemenu-avatar gold-border-wrap' : 'sidemenu-avatar'}>
      <div className="sidemenu-avater-blue">
        {isProUser && (
          <div className="logo-badge-alias">
            <IonImg src="assets/images/SlotPro.png" />{' '}
          </div>
        )}
        <IonAvatar slot="start" className="">
          {aliasInitial ? (
            <>
              <span
                ref={ref}
                style={{ fontSize, height: 40, width: 40 }}
                className="acc-user-profile-initial"
              >
                {aliasInitial}
              </span>
            </>
          ) : (
            <a style={{ textDecoration: 'none' }} href="/account">
              <img alt="" src="assets/images/user-icon-104.png" />
            </a>
          )}
        </IonAvatar>
      </div>
    </div>
  );
};

export default MenuAvatar;
