import { IonButton, IonCol, IonGrid, IonImg, IonInput, IonRow } from '@ionic/react';
import * as yup from 'yup';
import { formatPhoneNumber } from 'config/config';
import { useRef, useState } from 'react';

interface FormData {
  user: string;
  password: string;
  passwordConfirmation: string;
}

export const create_account_schema = yup.object().shape({
  user: yup.string().required('Cell number is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must include at least 8 characters')
    .max(24, 'Please use a shorter password (max: 24 characters)'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const Verified = () => {
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const ionInputEl = useRef<HTMLIonInputElement>(null);

  const onInput = (ev: Event) => {
    const value = (ev.target as HTMLIonInputElement).value as string;
    const filteredValue = value.replace(/[^0-9]+/g, '');

    const formatVal = formatPhoneNumber(filteredValue);
    setPhoneNumber(formatVal);

    const inputCmp = ionInputEl.current;
    if (inputCmp !== null) {
      inputCmp.value = formatVal;
    }
  };

  return (
    <div className="m-signup m-signup__verified">
      <div className="modal-container">
        <form id="confirm-page" className="form-modal">
          <div className="success-check" />
          <IonGrid>
            <IonRow>
              <IonCol>
                <div className="header-bar">
                  <span className="heading4 signup-box-title primary-color-text">Success</span>
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <div className="body-container">
                  <span className="body primary-color-text">
                    Your account has been created successfully!!!
                  </span>
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonButton type="submit" className="submit-btn">
                  Login
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </div>
      <div className="footer-container">
        <div className="logo">
          <IonImg src="assets/images/SLOTcheckTM.svg" />
        </div>
      </div>
    </div>
  );
};
