import { IStep } from '.';

export const OnboardingTourSteps: IStep[] = [
  {
    content:
      'This is our insights field, use this field to select the different kinds of data you would like to sort slots by to find the best for your style of play.',
    target: 'div[home-page-tour-step="insights"]',
    disableBeacon: true,
  },
  {
    content: 'Here you can change the timeframe that you would like to manage your data by.',
    target: 'div[home-page-tour-step="timeframes"]',
    disableBeacon: true,
  },
  {
    content: 'If you have a favorite theme or game, you can search for it here.',
    target: 'div[home-page-tour-step="search"]',
    disableBeacon: true,
  },
  {
    content:
      'The hottest slots on the floor for any given insight and timeframe are denoted in Pink.  Pink slots are the top 16.66% of slots on the floor.  Cold slots are in Blue and represent the bottom 16.66% of slots on the floor.',
    target: 'table.heat-legend',
    disableBeacon: true,
  },
  {
    content:
      'The highlighted button reflects a specific "heat" level for a slot, with its color indicating the intensity of activity. Clicking on this button will filter the map to display only the areas corresponding to the selected heat level.',
    target: 'div.heat-legend__color-square',
    disableBeacon: true,
  },
  {
    target: '.hot-list__active-col',
    content:
      "The element you're currently viewing allows you to sort data based on the selected insight, with a single click toggling between ascending and descending order.",
    disableBeacon: true,
  },
  {
    target: 'div.report-number',
    content: 'This is the number one hottest slot for the given timeframe and insight!',
    disableBeacon: true,
  },
  {
    target: 'div[home-page-tour-step="number-1-slot-map"]',
    content:
      "Discover the casino's number one hottest slot machine on the map. Click it for details about games and availability.",
    disableBeacon: true,
  },
];

export const homePageTourSteps: IStep[] = [
  {
    target: 'div[home-page-tour-step="insights"]',
    content: 'Choose between 9 real-time insights.',
    iconSrc: 'assets/images/lightbulb-outlined.svg',
    disableBeacon: true,
  },
  {
    target: 'div[home-page-tour-step="timeframes"]',
    content: 'Choose between any of the 7 timeframes.',
    iconSrc: 'assets/images/calendar-outlined.svg',
  },
  {
    target: 'div[home-page-tour-step="pulse-button"]',
    content: 'Refresh your view each time you change insight or timeframe.',
    iconSrc: 'assets/images/reload-outlined.svg',
  },
  {
    target: 'div[home-page-tour-step="map"]',
    content:
      'The numbered circles give you the 10 hottest and coldest for the selected insight. Click on any number or slot for more detail.',
    iconSrc: 'assets/images/warning-outlined.svg',
    offset: 0,
  },
  {
    target: 'div[home-page-tour-step="favorites"]',
    content: 'Tag your favorites, track your play, and see your jackpots.',
    iconSrc: 'assets/images/heart-outlined.svg',
  },
  {
    target: 'div[home-page-tour-step="leaderboard"]',
    content: 'See your rank on the Leaderboard.',
    iconSrc: 'assets/images/prize-outlined.svg',
  },
  {
    target: 'div[home-page-tour-step="user-profile"]',
    content:
      'Edit your profile, change and manage your player alias, manage subscription & more...',
    iconSrc: 'assets/images/person-outlined.svg',
  },
];
