import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonPage,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonSkeletonText,
  IonToolbar,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { IPlayer, LinkedAccount, PlayerService } from 'services/player';
import { downloadSharp } from 'ionicons/icons';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { getUnixTime } from 'date-fns';
import { TablePagination } from '@material-ui/core';
import { debounce } from 'lodash';
import { take, tap } from 'rxjs';

import TableView from './table';
import { CasinoService } from 'services/casino';
import useSort, { SortField, SortOrder } from './useSort';

export type ICasino = {
  casinoName: string;
  casinoId: number;
  shortName: string;
};

export const AdminDashboard = () => {
  const service = new PlayerService();
  const casinoSvc = new CasinoService();
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const [loading, setLoading] = useState(false);

  const [players, setPlayers] = useState<Array<IPlayer> | []>([]);
  const [playerCount, setPlayerCount] = useState<number>(0);
  const [casinos, setCasinos] = useState<ICasino[]>([]);

  const [pageNumber, setPageNumber] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState<string>('');
  const [disabledExport, setDisabledExport] = useState<boolean>(false);
  const [casinoFilterValue, setCasinoFilterValue] = useState<number>(0);

  const [sortField, setSortField] = useState<string | null>(null);
  const [sortOrderValue, setSortOrderValue] = useState<SortOrder>('desc');
  const { sortOrder, handleSort, getOrderValue } = useSort();

  const getListOfPlayers = (
    page: number,
    limit: number,
    search: string,
    casinoFilterValue: number,
    sortField?: string,
    sortOrder?: number
  ) => {
    console.log(sortField);
    let isIgnore = false;
    setLoading(true);

    service
      .ListPlayers(page, limit, search, casinoFilterValue, sortField ?? '', sortOrder)
      .subscribe({
        next(data) {
          if (data) {
            if (!isIgnore) {
              setLoading(false);
              setPlayers(data.users);
              setPlayerCount(data.totalCount);
            }
          }
        },
      });
    return () => {
      isIgnore = true;
    };
  };

  const debouncedGetListOfPlayer = debounce(getListOfPlayers, 500);

  useEffect(() => {
    debouncedGetListOfPlayer(
      pageNumber,
      rowsPerPage,
      searchInput,
      casinoFilterValue,
      sortField ?? '',
      getOrderValue(sortOrderValue) ?? 'desc'
    );

    return () => {
      debouncedGetListOfPlayer.cancel();
    };
  }, [pageNumber, rowsPerPage, searchInput, casinoFilterValue, sortField, sortOrderValue]);

  useEffect(() => {
    if (players.length > 0) {
      setDisabledExport(false);
    } else {
      setDisabledExport(true);
    }
  }, [players]);

  const handleSortChange = (field: SortField) => {
    const newOrder = sortOrder[field] === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    handleSort(field);
    setSortOrderValue(newOrder);
  };

  useEffect(() => {
    let isIgnore = false;
    if (!isIgnore) {
      casinoSvc.GetAllCasinos().subscribe({
        next(casinoResponse) {
          if (casinoResponse) {
            const isActiveCasinos = casinoResponse.DataSet.filter(
              (casinoInfo) => casinoInfo.isactive && !casinoInfo.isComingSoon
            );
            const filteredCasinos = isActiveCasinos.map(({ casinoId, casinoName, shortName }) => ({
              casinoId,
              casinoName,
              shortName,
            }));

            const allCasinoItem = {
              casinoId: 0,
              casinoName: 'All Casinos',
              shortName: 'All Casinos',
            };
            filteredCasinos.unshift(allCasinoItem);

            setCasinoFilterValue(allCasinoItem.casinoId);
            setCasinos(filteredCasinos);
          }
        },
        error() {},
        complete() {},
      });
    }

    return () => {
      isIgnore = true;
    };
  }, []);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };

  const handleSearch = (value: string) => {
    setSearchInput(value);
    setPageNumber(0);
  };

  const findCasinoById = (id: number): ICasino | undefined => {
    return casinos.find((casino) => casino.casinoId === id);
  };

  const reportJson: { [key: string]: string | number } = {};

  const exportToCSV = (csvData: Array<IPlayer> | [], fileName: string) => {
    const exportArr = [];

    for (let index = 0; index < csvData.length; index++) {
      const element = csvData[index];
      const casinoInfo = findCasinoById(element?.casinoId);
      const properties: typeof reportJson = {
        phone_number: element.cellNumber,
        name: element.subscriptionData?.name ? element.subscriptionData?.name : '',
        alias_name: element.aliasName,
        alias_initial: element.aliasInitial,
        alias_initial2: element.aliasInitial2,
        interval: element?.subscriptionData?.plan
          ? element.subscriptionData?.plan.interval
            ? element.subscriptionData?.plan.interval
            : 'Free'
          : 'Free',
        amount: element?.subscriptionData?.plan
          ? (parseFloat(String(element?.subscriptionData?.plan.amount)) / 100 || 0).toLocaleString(
              'en-US',
              {
                style: 'currency',
                currency: 'USD',
              }
            )
          : 'N/A',
        discount:
          element?.subscriptionData?.discount && element?.subscriptionData?.plan
            ? (
                parseFloat(
                  String(
                    (element.subscriptionData?.discount?.coupon.percent_off /
                      element.subscriptionData?.plan?.amount) *
                      100
                  )
                ) || 0
              ).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })
            : 'N/A',
        paid:
          element.subscriptionData?.discount && element.subscriptionData?.plan
            ? (
                parseFloat(
                  String(
                    element.subscriptionData?.plan?.amount / 100 -
                      (element.subscriptionData?.discount?.coupon.percent_off /
                        element.subscriptionData?.plan?.amount) *
                        100
                  )
                ) || 0
              ).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })
            : element.subscriptionData?.plan
            ? (
                parseFloat(String(element.subscriptionData?.plan?.amount / 100)) || 0
              ).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })
            : 'N/A',
        coupon_name: element.subscriptionData?.discount
          ? element.subscriptionData?.discount?.coupon.name
          : 'N/A',
        coupon_code: element.subscriptionData?.discount
          ? element.subscriptionData?.discount?.coupon.id
          : 'N/A',
        subscription_level: element.subscriptionData?.status === 'active' ? 'SlotPro' : 'SlotCheck',
        signup_date: moment(element.signUpDate).format('MMMM DD, YYYY'),
        current_period_start: element.subscriptionData?.current_period_start
          ? moment
              .unix(parseInt(String(element.subscriptionData?.current_period_start)))
              .format('MMMM DD, YYYY')
          : 'N/A',
        current_period_end: element?.subscriptionData?.current_period_end
          ? moment
              .unix(parseInt(String(element.subscriptionData?.current_period_end)))
              .format('MMMM DD, YYYY')
          : 'N/A',
        email: element?.subscriptionData?.email,
        cognito_username: element.cognitoUserName,
        customer_id: element?.customerId,
        casino_subscription: casinoInfo ? casinoInfo.shortName : 'N/A',
        status: element?.subscriptionData?.status,
      };

      element?.linkedAccounts?.forEach((account: LinkedAccount) => {
        const casinoInfo = findCasinoById(account.casinoID);
        if (casinoInfo) {
          if (casinoFilterValue === 0) {
            properties[casinoInfo.shortName] = account.playerId;
          } else {
            if (casinoFilterValue === account.casinoID) {
              properties[casinoInfo.shortName] = account.playerId;
            }
          }
        }
      });

      exportArr.push(properties);
    }

    const ws = XLSX.utils.json_to_sheet(exportArr);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const exportAllToCsv = () => {
    service
      .ListPlayers(
        0,
        playerCount,
        '',
        casinoFilterValue,
        sortField ?? '',
        getOrderValue(sortOrderValue)
      )
      .pipe(
        take(1),
        tap((data) => {
          if (data) {
            exportToCSV(data.users, String(getUnixTime(new Date())));
          }
        })
      )
      .subscribe();
  };

  return (
    <IonPage className="app-parent">
      <div className="slotcheck-header">
        <IonHeader className="small-header">
          <IonToolbar>
            <IonButton
              fill="clear"
              onClick={() => {
                history.back();
              }}
              className="icon-back"
              slot="start"
            >
              <IonImg src="assets/images/back-icon.svg" />
            </IonButton>
            <IonButtons slot="end">
              <IonButtons>
                <IonButton
                  color="dark"
                  onClick={() => {
                    exportAllToCsv();
                  }}
                  disabled={disabledExport}
                >
                  <IonIcon color="light" slot="start" icon={downloadSharp}></IonIcon>
                  Export All
                </IonButton>
                <IonButton
                  color="light"
                  onClick={() => {
                    exportToCSV(players, String(getUnixTime(new Date())));
                  }}
                  disabled={disabledExport}
                >
                  <IonIcon color="light" slot="start" icon={downloadSharp}></IonIcon>
                  Export
                </IonButton>
              </IonButtons>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      </div>
      <IonContent>
        <div className="content admin__content">
          <div className="slotcheck-admin">
            <IonSearchbar
              color="light"
              placeholder="Search by phonenumber"
              onIonChange={(e) => handleSearch(e.detail.value!)}
            ></IonSearchbar>

            <IonSelect
              value={casinoFilterValue}
              placeholder="Select Casino"
              onIonChange={(e) => setCasinoFilterValue(e.detail.value)}
            >
              {casinos.map((eachCasino: ICasino, index: number) => (
                <IonSelectOption key={index} value={eachCasino.casinoId}>
                  {eachCasino.shortName}
                </IonSelectOption>
              ))}
            </IonSelect>
            {loading &&
              playerCount == 0 &&
              Array.from({ length: rowsPerPage }, (_, index) => (
                <div key={index} style={{ margin: '10px' }}>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: '100%', height: '44px', margin: '5px' }}
                  ></IonSkeletonText>
                </div>
              ))}
            {!loading && playerCount > 0 && (
              <TableView
                rows={players}
                casinos={casinos}
                sortOrder={sortOrder}
                onSortChange={handleSortChange}
                onRefreshPlayer={() => {
                  setPlayers([]);
                  setSearchInput('');
                  setPageNumber(0);
                }}
              />
            )}
            <TablePagination
              component="div"
              count={playerCount}
              page={pageNumber}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            ></TablePagination>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
