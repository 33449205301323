import { IonContent, IonPage, useIonRouter, useIonToast } from '@ionic/react';
import CasinoNavBar from 'components/CasinoNavBar';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IState, ITabProperties } from 'appRedux/createStore';
import { CasinoService, IRequestCasinoParams, IRequestDetailParam } from 'services/casino';
import { ICasinoReport } from 'appRedux/models/casinoModels';
import { debounce } from 'lodash';
import { FilterTypeEnum, IFilterType, OrderByEnum, pinArrayType } from 'common/common';
import { config } from 'config/config';
import MachineNameHeader from 'components/MachineNameHeader/MachineNameHeader';
import HotBox from 'components/HotBox/HotBox';
import SmartLoading from 'components/SmartLoading';
import { ErrorPage } from 'components/ErrorPage';
import useSlotDetailHistory from 'globalServices/useSlotDetailHistory';
import SnapShot from 'components/SnapShot/SnapShot';
import GraphSection from 'components/GraphSection/GraphSection';
import MapSection from 'components/MapSection/MapSection';
import { SectionID, SectionIdType } from 'appRedux/reducers/slotReducer';
import { SlotReduxCommandCreator } from 'appRedux/actions/slotCommandCreator';

interface IOrderedComponent {
  id: SectionIdType;
  Component: React.FunctionComponent<any>;
}

const orderedComponents: IOrderedComponent[] = [
  { id: SectionID.Snapshot, Component: SnapShot },
  { id: SectionID.Graph, Component: GraphSection },
  { id: SectionID.Map, Component: MapSection },
];

const SlotoverviewTab = ({ snapshotData }: any) => {
  const casinoSvc = new CasinoService();
  const router = useIonRouter();
  const dispatch = useDispatch();
  const detailCommand = SlotReduxCommandCreator(dispatch);
  const [playData, setPlayData] = useState<ICasinoReport[]>();
  const [isSlotPlayDataIsLoading, setSlotPlayDataIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [presentToast] = useIonToast();
  const reportType = FilterTypeEnum.hottestSlots;
  const tabFilters = useSelector<IState>(
    (state) => state.app.persistedState.filterState[reportType as IFilterType]
  ) as ITabProperties;
  const { timeframe: selectedTimeFrame, insight: primarySelectedInsight } = tabFilters;
  const isLandscape = useSelector<IState>(
    (state) => state.app.persistedState.orientationState?.isLandscape
  ) as boolean;
  const historyRedirect = useHistory();
  const location = useLocation();
  const { addToSlotHistory, activeSlotNumber } = useSlotDetailHistory();

  const casinoId = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema?.kpCasinoPk
  ) as number;
  const sectionOrder = useSelector<IState>(
    (state) => state.app.persistedState.slotState.sections
  ) as SectionIdType[];

  const [casinoParams, setCasinoParams] = useState<IRequestDetailParam>({
    casinofk: casinoId,
    insight: 'spins',
    slot: activeSlotNumber,
    orderby: OrderByEnum.Desc,
  });

  const handleInputChange = (param: keyof IRequestCasinoParams, value: string | number) => {
    setCasinoParams((prevParams) => ({ ...prevParams, [param]: value }));
  };

  const handleChangeSlot = (selectedSlotId: string) => {
    addToSlotHistory(parseInt(selectedSlotId));
    router.push('/detail');
  };

  const rearrangeEmptyGraph = () => {
    // let orderIndex = 0;
    // let sectionId: SectionIdType = SectionID.Snapshot;
    // if (showMapFirst) {
    //   orderIndex = sectionOrder.findIndex((section) => section === SectionID.Map);
    //   sectionId = SectionID.Map;
    // } else if (showGraphFirst) {
    //   orderIndex = sectionOrder.findIndex((section) => section === SectionID.Graph);
    //   sectionId = SectionID.Graph;
    // } else if (showTrendsFirst) {
    //   orderIndex = sectionOrder.findIndex((section) => section === SectionID.Snapshot);
    //   sectionId = SectionID.Snapshot;
    // }
    // const filteredSections = sectionOrder.filter((section) => section !== sectionId);
    // if (!sectionOrder || !sectionOrder[orderIndex]) return;
    // detailCommand.MoveSection({
    //   sectionId: sectionId,
    //   newPosition: orderIndex,
    //   sections: filteredSections,
    // });
  };

  const debouncedGetSlotDataReport = debounce((casinoParams) => {
    fetchIndividualSlotReport(casinoParams);
  }, 500);

  useEffect(() => {
    debouncedGetSlotDataReport(casinoParams);
    return () => {
      debouncedGetSlotDataReport.cancel();
    };
  }, [casinoParams]);

  useEffect(() => {
    handleInputChange('insight', primarySelectedInsight);
  }, [primarySelectedInsight]);

  useEffect(() => {
    const filterTime = config.allTimeFrames.filter(
      (timeframe) => timeframe.value === selectedTimeFrame
    )[0];
    handleInputChange('timeframe', filterTime.id);
  }, [selectedTimeFrame]);

  useEffect(() => {
    handleInputChange('slot', activeSlotNumber);
  }, [activeSlotNumber]);

  const fetchIndividualSlotReport = useCallback((queryParams: IRequestCasinoParams) => {
    try {
      setSlotPlayDataIsLoading(true);
      const { ...restParam } = queryParams;
      casinoSvc.GetIndividualSlotReport(restParam).subscribe({
        next(casinoResponse) {
          if (casinoResponse) {
            setPlayData(casinoResponse.DataSet);
          }
        },
        error(_err) {
          presentToast({
            position: 'top',
            duration: 5000,
            color: 'danger',
            message: 'Failed to retrieve casino report.',
          }).catch(() => null);
          setSlotPlayDataIsLoading(false);
          setIsError(true);
        },
        complete() {
          setSlotPlayDataIsLoading(false);
        },
      });
    } catch (error) {}
  }, []);

  const findComponentById = (id: SectionIdType): IOrderedComponent | undefined => {
    return orderedComponents.find((component: { id: SectionIdType }) => component.id === id);
  };

  const renderOrderedComponent = (eachItem: SectionIdType) => {
    const componentData = findComponentById(eachItem);
    if (!componentData) {
      return;
    }
    const { id, Component } = componentData;
    const handleRearrangeComponent = (item: pinArrayType) => {
      if (item) {
        detailCommand.MoveSection({
          sectionId: eachItem,
          newPosition: item.index,
          sections: sectionOrder,
        });
      }
    };

    switch (id) {
      case SectionID.Snapshot:
        return (
          <Component
            key={id}
            selectedSlotData={playData && playData[0]}
            snapShotData={snapshotData}
            handleRearrangeComponent={handleRearrangeComponent}
          />
        );
      case SectionID.Graph:
        return (
          <Component
            slotNumber={activeSlotNumber}
            playData={playData && playData[0]}
            key={id}
            onClickUpgradeNow={() => {
              historyRedirect.push('/auth/stripe', { previousPage: location.pathname });
            }}
            rearrangeEmptyGraph={rearrangeEmptyGraph}
            handleRearrangeComponent={handleRearrangeComponent}
          />
        );
      case SectionID.Map:
        return (
          <Component
            key={id}
            selectedSlotData={playData && playData[0]}
            handleRearrangeComponent={handleRearrangeComponent}
            handleChangeSlot={handleChangeSlot}
          />
        );
      default:
        return <Component key={id} />;
    }
  };

  return (
    <IonPage className="app-parent slot-overview" id="slot-overview">
      {!isLandscape && (
        <div className="slotcheck-header">
          <CasinoNavBar showBack={true} hideNotify hideSearch />
        </div>
      )}

      <IonContent>
        {isError && <ErrorPage message="Slot data not available" />}
        {isSlotPlayDataIsLoading && <SmartLoading loading={isSlotPlayDataIsLoading} />}
        {playData && playData?.length > 0 && !isSlotPlayDataIsLoading && (
          <>
            <div className="sticky-top">
              <MachineNameHeader selectedSlotData={playData?.[0]} />
            </div>
            <div className="parent-section">
              {sectionOrder.map((eachItem) => renderOrderedComponent(eachItem))}
              <HotBox />
            </div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default SlotoverviewTab;
