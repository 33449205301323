import {
  IonButton,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';

const Notifications: React.FC = () => {
  return (
    <IonPage>
      <div className="slotcheck-header">
        <IonHeader className="small-header">
          <IonToolbar>
            <IonButton
              fill="clear"
              onClick={() => {
                history.back();
              }}
              className="icon-back"
              slot="start"
            >
              <IonImg src="assets/images/back-icon.svg" />
            </IonButton>
            <div className="logo header-title">Notifications</div>
          </IonToolbar>
        </IonHeader>
      </div>
      <IonContent className="ion-padding">Notification will comes here</IonContent>
    </IonPage>
  );
};

export default Notifications;
