import { useEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faDollarSign, faEdit } from '@fortawesome/free-solid-svg-icons';
import { formatPhoneNumber } from 'common/include';
import moment from 'moment';
import { IManageCustomerResponse, StripeService } from 'services/stripe';
import { format, parseISO } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { onPromise } from 'pages/Signup/views/CreateAccount';
import {
  IPlayer,
  IPlayerSubscriptionData,
  IUserDetails,
  IUserSubscriptionData,
  PlayerService,
} from 'services/player';
import { useSelector } from 'react-redux';
import { IState } from 'appRedux/createStore';
import clsx from 'clsx';
import { arrowDownOutline, arrowUpOutline } from 'ionicons/icons';
import { casinoIdListEnum } from 'common/commonEnum';
import { capitalize } from 'lodash';
import { ICasino } from '.';
import { SortField, SortOrder } from './useSort';

interface ITableView {
  casinos: Array<ICasino> | [];
  rows?: Array<IPlayer> | [];
  onRefreshPlayer: () => void;
  sortOrder: { [key: string]: SortOrder };
  onSortChange: (sortField: SortField) => void;
}

export const subscription_schema = yup.object().shape({
  casino_id: yup.number().nullable().required('Casino Id is required'),
  end_date: yup.string().nullable().required('End date is required'),
});

interface FormData {
  casino_id: number;
  end_date: string;
}

export default function TableView(props: ITableView) {
  const { rows, casinos, onRefreshPlayer, onSortChange, sortOrder } = props;
  const [displayRows, setDisplayRows] = useState<Array<IPlayer>>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [tabDetail, setTabDetail] = useState<IUserDetails>();
  const [subscriptionDetail, setSubscriptionDetail] = useState<IPlayerSubscriptionData>();
  const isLandscape = useSelector<IState>(
    (state) => state.app.persistedState.orientationState?.isLandscape
  ) as boolean;
  const [present] = useIonAlert();
  const playerService = new PlayerService();
  const service = new StripeService();
  const initialValues: FormData = {
    casino_id: tabDetail?.casinoId ? tabDetail?.casinoId : 1,
    end_date: moment().format('YYYY-MM-DD'),
  };

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(subscription_schema),
    defaultValues: initialValues,
  });
  const currentDate = new Date().toISOString();
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);
  const maxDateString = maxDate.toISOString();

  useEffect(() => {
    let isIgnore = false;
    if (rows) {
      if (!isIgnore) {
        setDisplayRows(rows);
      }
    }
    return () => {
      isIgnore = true;
    };
  }, [rows]);

  useEffect(() => {
    if (tabDetail?.casinoId) {
      setValue('casino_id', tabDetail?.casinoId);
    }
  }, [tabDetail?.casinoId]);

  const cancelSubscription = () => {
    console.log(tabDetail);
    if (tabDetail?.cognitoUserName) {
      service.CancelSubscriptionAdmin(tabDetail?.cognitoUserName).subscribe({
        next(data: void | IManageCustomerResponse) {
          setIsOpen(false);
          setTabDetail(undefined);
          onRefreshPlayer();
        },
        error(err) {
          console.log('ERROR', err);
        },
      });
    }
  };

  const getUserSubscription = () => {
    const cognitoUserName = tabDetail?.cognitoUserName;
    const casinoId = getValues('casino_id');

    if (cognitoUserName) {
      playerService.GetCasinoSubscription(cognitoUserName, casinoId).subscribe({
        next(data: void | IUserSubscriptionData) {
          if (data) {
            setSubscriptionDetail(data.subscriptionData);
          }
        },
        error(err) {
          console.log('ERROR', err);
        },
      });
    }
  };

  const onSubmit = (data: FormData): void => {
    const formattedDate = format(parseISO(data.end_date), 'yyyy-MM-dd').replace(/-/g, '-');
    const endDate = new Date(formattedDate).toISOString();
    if (tabDetail && tabDetail?.cognitoUserName && tabDetail?.cellNumber) {
      service
        .AddSubscriptionByAdmin(
          String(__PROPRICECODE__),
          tabDetail.cognitoUserName,
          tabDetail.cellNumber,
          endDate,
          data.casino_id
        )
        .subscribe({
          next(data: void | IManageCustomerResponse) {
            setIsOpen(false);
            setTabDetail(undefined);
            onRefreshPlayer();
          },
          error(err) {
            console.log('ERROR', err);
          },
        });
    }
  };

  const getFilterIcon = (sortOrder: SortOrder) => {
    if (sortOrder === null) return arrowUpOutline;
    return sortOrder === 'desc' ? arrowUpOutline : arrowDownOutline;
  };

  const findCasinoById = (id: number): ICasino | undefined => {
    return casinos.find((casino) => casino.casinoId === id);
  };
  const nonZeroCasinos = casinos.filter((casino) => casino.casinoId !== 0);

  return (
    <>
      <IonModal id="adminmodal" isOpen={isOpen}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Custom Plan Details</IonTitle>
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  setIsOpen(false);
                  setTabDetail(undefined);
                }}
              >
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="table-admin-board">
          <div className="content">
            <form
              id="confirm-page"
              onSubmit={onPromise(handleSubmit((data: FormData) => void onSubmit(data)))}
              className="form-modal"
            >
              <div className="slotcheck-admin__list-container">
                {tabDetail && (
                  <IonGrid>
                    <Controller
                      name="casino_id"
                      control={control}
                      render={({ field }) => (
                        <IonSelect
                          placeholder="Select Casino"
                          value={field.value}
                          onIonChange={(e) => {
                            field.onChange(e.detail.value);
                            getUserSubscription();
                          }}
                        >
                          {nonZeroCasinos?.map((eachCasino, index) => (
                            <IonSelectOption key={index} value={eachCasino.casinoId}>
                              {eachCasino.shortName}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      )}
                    />
                    {errors.casino_id && errors.casino_id.message && (
                      <div className="field-validation-message">{errors.casino_id.message}</div>
                    )}
                    {tabDetail && subscriptionDetail?.name && (
                      <IonRow class="admin-item white">
                        <IonCol>First Name : </IonCol>
                        <IonCol>{subscriptionDetail?.name}</IonCol>
                      </IonRow>
                    )}

                    {tabDetail && tabDetail.aliasInitial2 && (
                      <IonRow class="admin-item white">
                        <IonCol>Alias Intial : </IonCol>
                        <IonCol>{tabDetail.aliasInitial2}</IonCol>
                      </IonRow>
                    )}

                    {tabDetail && tabDetail.aliasName && (
                      <IonRow class="admin-item white">
                        <IonCol>Alias Name : </IonCol>
                        <IonCol>{tabDetail?.aliasName}</IonCol>
                      </IonRow>
                    )}

                    {tabDetail?.linkedAccounts?.map((account) => (
                      <IonRow class="admin-item white" key={account.casinoID}>
                        <IonCol>{capitalize(casinoIdListEnum[account.casinoID])} ID : </IonCol>
                        <IonCol>{account.playerId} </IonCol>
                      </IonRow>
                    ))}

                    <IonRow class="admin-item white">
                      <IonCol>Phone Number : </IonCol>
                      <IonCol>{tabDetail?.cellNumber} </IonCol>
                    </IonRow>
                    {tabDetail?.customerId && (
                      <IonRow class="admin-item white">
                        <IonCol>Customer ID : </IonCol>
                        <IonCol>{tabDetail?.customerId} </IonCol>
                      </IonRow>
                    )}
                    <IonRow class="admin-item white">
                      <IonCol>Subscription Status : </IonCol>
                      <IonCol>
                        {subscriptionDetail?.status ? subscriptionDetail?.status : 'N/A'}{' '}
                      </IonCol>
                    </IonRow>
                    {tabDetail.signUpDate && (
                      <IonRow class="admin-item white">
                        <IonCol>Sign Up Date : </IonCol>
                        <IonCol>
                          {moment(tabDetail.signUpDate).format('DD MMM YYYY, HH:mm a')}
                        </IonCol>
                      </IonRow>
                    )}
                    {subscriptionDetail && (
                      <>
                        {subscriptionDetail?.current_period_start &&
                          subscriptionDetail?.status === 'active' && (
                            <IonRow class="admin-item white">
                              <IonCol>Start Date : </IonCol>
                              <IonCol>
                                {moment(subscriptionDetail?.current_period_start * 1000).format(
                                  'DD MMM YYYY, HH:mm a'
                                )}
                              </IonCol>
                            </IonRow>
                          )}
                        {subscriptionDetail?.current_period_end &&
                          subscriptionDetail?.status === 'active' && (
                            <IonRow class="admin-item white">
                              <IonCol>End Date : </IonCol>
                              <IonCol>
                                {moment(subscriptionDetail?.current_period_end * 1000).format(
                                  'DD MMM YYYY, HH:mm a'
                                )}
                              </IonCol>
                            </IonRow>
                          )}
                      </>
                    )}
                    {subscriptionDetail?.status === 'active' && (
                      <IonRow>
                        <IonButton
                          onClick={() => {
                            void present({
                              header: 'Alert',
                              message: 'Do you want to cancel player subscription?',
                              buttons: [
                                'Cancel',
                                {
                                  text: 'Ok',
                                  handler: (d) => {
                                    cancelSubscription();
                                  },
                                },
                              ],
                              onDidDismiss: (e) => console.log('did dismiss'),
                            });
                          }}
                        >
                          Remove Subscription
                        </IonButton>
                      </IonRow>
                    )}
                    {subscriptionDetail?.status !== 'active' && (
                      <>
                        <IonRow class="admin-item white">
                          <IonCol>End Date : </IonCol>
                          <IonCol>
                            <IonDatetimeButton datetime="dobDate">Dob</IonDatetimeButton>
                          </IonCol>
                        </IonRow>
                        {errors.end_date && errors.end_date.message && (
                          <div className="field-validation-message">{errors.end_date.message}</div>
                        )}
                        <IonRow>
                          <IonButton type="submit" className="submit-btn">
                            Add Subscription
                          </IonButton>
                        </IonRow>
                      </>
                    )}
                  </IonGrid>
                )}
              </div>
            </form>
          </div>
        </IonContent>
      </IonModal>

      <IonModal id="dobpicker" keepContentsMounted={true}>
        <Controller
          render={({ field: { onChange } }) => (
            <IonDatetime
              value={getValues('end_date')}
              id="dobDate"
              presentation="date"
              preferWheel={true}
              showDefaultButtons={true}
              min={currentDate}
              max={maxDateString}
              onIonChange={(e) => onChange(e.detail.value)}
            ></IonDatetime>
          )}
          control={control}
          name="end_date"
          rules={{ required: true }}
        />
      </IonModal>
      <div className="slotcheck-admin__list-container">
        <div className="tbl-content">
          {displayRows.length > 0 && (
            <table cellPadding="0" cellSpacing="0">
              <thead>
                <tr>
                  <th className={clsx('phone-number', !isLandscape && 'portrait')}>Phone Number</th>
                  <th className="alias-intial">Initial</th>
                  <th className="expiry">Expiry</th>
                  {isLandscape && (
                    <>
                      <th className="alias-name">Signup Date</th>
                      <th className="alias-name">Alias Name</th>
                      <th>
                        <div className="header-sort">Casino</div>
                      </th>
                      <th className="interval">
                        {' '}
                        <div
                          className="header-sort"
                          onClick={() => {
                            onSortChange('interval');
                          }}
                        >
                          Interval
                          {sortOrder.interval && (
                            <IonIcon icon={getFilterIcon(sortOrder.interval)} />
                          )}
                        </div>
                      </th>
                      <th>
                        <div
                          className="header-sort"
                          onClick={() => {
                            onSortChange('amount');
                          }}
                        >
                          Paid
                          {sortOrder.amount && <IonIcon icon={getFilterIcon(sortOrder.amount)} />}
                        </div>
                      </th>
                    </>
                  )}
                  <th>
                    <div
                      className="header-sort"
                      onClick={() => {
                        onSortChange('hasSubscription');
                      }}
                    >
                      Pro
                      {sortOrder.hasSubscription && (
                        <IonIcon icon={getFilterIcon(sortOrder.hasSubscription)} />
                      )}
                    </div>
                  </th>
                  <th className="td-icon">$</th>
                  <th>Update</th>
                </tr>
              </thead>
              <tbody>
                {displayRows &&
                  displayRows.length > 0 &&
                  displayRows.map((row, index) => (
                    <tr key={`key-${index}`} className="item">
                      <td className={clsx('phone-number', !isLandscape ? 'portrait' : 'landscape')}>
                        {formatPhoneNumber(row.cellNumber?.replace('+1', '') as string)}
                      </td>
                      <td className="alias-intial">{row.aliasInitial2}</td>
                      <td className="expiry">
                        {row.subscriptionData?.current_period_end
                          ? moment
                              .unix(parseInt(String(row.subscriptionData?.current_period_end)))
                              .format('DD MMM-YY')
                          : 'N/A'}
                      </td>
                      {isLandscape && (
                        <>
                          <td>{moment(row.signUpDate).format('DD MMM-YY')}</td>
                          <td>{row.aliasName}</td>
                          <td>{findCasinoById(row?.casinoId)?.shortName}</td>
                          <td className="interval">
                            {row.subscriptionData?.plan?.interval &&
                              row.subscriptionData?.plan?.interval}
                          </td>
                          <td>
                            {row.subscriptionData?.discount &&
                              row.subscriptionData.plan?.amount &&
                              (
                                parseFloat(
                                  String(
                                    row.subscriptionData?.plan?.amount / 100 -
                                      (row.subscriptionData.discount?.coupon?.percent_off /
                                        row.subscriptionData.plan?.amount) *
                                        100
                                  )
                                ) || 0
                              ).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}

                            {!row.subscriptionData?.discount &&
                              row.subscriptionData?.plan?.amount &&
                              (
                                parseFloat(String(row.subscriptionData.plan?.amount)) / 100 || 0
                              ).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                          </td>
                        </>
                      )}
                      <td className="td-icon">
                        {row.subscriptionData?.status === 'active' ? (
                          <FontAwesomeIcon color="#ff68bb" icon={faCheck} />
                        ) : (
                          ''
                        )}
                      </td>
                      <td className="td-icon">
                        {row?.customerId ? (
                          <FontAwesomeIcon color="#00c020" icon={faDollarSign} />
                        ) : (
                          ''
                        )}
                      </td>
                      <td className="td-edit">
                        <IonButton
                          className="button"
                          onClick={() => {
                            setIsOpen(true);
                            const { subscriptionData, ...playerWithoutSubscriptionData } = row;
                            setTabDetail(playerWithoutSubscriptionData);
                            setSubscriptionDetail(subscriptionData);
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </IonButton>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}
