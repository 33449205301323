import React, { ReactNode } from 'react';
import './dropDown.scss';
import ReactSelectDropDown from './ReactSelectDropDown';
import DropDownSelectValue from './DropDownSelectValue';
import DropDownSelectOption from './DropDownSelectOption';

interface DropDownProps {
  options: any[];
  defaultOption?: any;
  value?: any;
  placeholder?: string;
  styleClass?: string;
  bgColor?: string;
  onChange?: any;
  isDisabled?: boolean;
  textAlign?: 'left' | 'right' | 'center';
  displayDescription?: boolean;
  displayPosition?: any;
  isSearchable?: boolean;
  inputType?: string;
  // maxMenuHeight?: number;
  isLoading?: boolean;
  id?: string;
  CustomSelectOption?: ReactNode;
  isOpen?: boolean;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
}

const DropDown: React.FC<DropDownProps> = ({
  bgColor,
  styleClass,
  onChange,
  options,
  placeholder,
  value,
  defaultOption,
  displayDescription = false,
  textAlign = 'left',
  isDisabled = false,
  displayPosition = 'auto',
  isSearchable = false,
  inputType = 'text',
  // maxMenuHeight = 200,
  isLoading,
  isMulti = false,
  closeMenuOnSelect = true,
  hideSelectedOptions = false,
  isOpen,
  id,
  CustomSelectOption,
}) => {

  const SelectOption = CustomSelectOption || DropDownSelectOption;
  return (
    <span>
      <ReactSelectDropDown
        isOpen={isOpen}
        isLoading={isLoading}
        isDisabled={isDisabled}
        value={value}
        DropDownSelectOption={SelectOption}
        DropDownSelectValue={(props: any) => {
          return <DropDownSelectValue id={id} styleClass={styleClass} displayDescription={displayDescription} {...props} />;
        }}
        onChange={onChange}
        options={options}
        defaultOption={defaultOption}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
        menuDisplayPosition={displayPosition}
        isSearchable={isSearchable}
        inputType={inputType}
      />
    </span>
  );
};

export default DropDown;
