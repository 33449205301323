/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useEffect, useState } from 'react';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import useToast from 'common/reactHooks/useToast';
import useLocalStorage from 'common/reactHooks/useLocalStorage';
import { StripeService } from 'services/stripe';
import { IState } from 'appRedux/createStore';

import { ProPlanAnnual } from './views/proPlanAnnual';
import { ProPlan } from './views/proPlan';
import { lastValueFrom } from 'rxjs';

interface IStripe {
  redirectToCheckout(post: { sessionId: string }): Promise<any>;
}

interface ILocationWithPreviousPage {
  previousPage?: string;
}
const slotCheckCasinoId = __SLOTCHECK_ID__;
const slotCheckPriceCode = __SLOTCHECK_PRICE_CODE__;
const slotcheckPriceCodeYearly = __SLOTCHECK_PRICE_CODE_YEARLY__;

export const StripeApp = () => {
  const service = new StripeService();
  const showToast = useToast();
  const isProUser = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.isProUser
  ) as boolean;
  const history = useHistory();
  const location = useLocation<ILocationWithPreviousPage>();
  const [previousPage, setPreviousPage, removePreviousPage] =
    useLocalStorage('authSlatePreviousPage');

  const [stripe, setStripe] = useState<IStripe>();
  const [checkedYearlyPeriod, setCheckedYearlyPeriod] = useState(false);

  useEffect(() => {
    setStripe(Stripe(__STRIPE_PK__));
  }, []);

  useEffect(() => {
    if (location.state?.previousPage) {
      setPreviousPage(location.state.previousPage);
    }
  }, [location.state]);

  const createSession = async (priceId: string, couponCode: string) => {
    try {
      const data = await lastValueFrom(
        service.CreateCheckoutSession(priceId, couponCode, parseInt(slotCheckCasinoId))
      );

      if (stripe) {
        await stripe.redirectToCheckout({
          sessionId: data?.id as string,
        });
      }
    } catch (err: any) {
      showToast({ type: 'danger', message: err.message });
      console.error(err);
    }
  };

  const goBack = () => {
    if (previousPage) {
      history.push(previousPage as string);
    } else {
      history.goBack();
    }

    removePreviousPage();
  };

  return (
    <IonPage className="app-parent">
      <div className="slotcheck-header">
        <IonHeader className="small-header">
          <IonToolbar>
            <IonButton fill="clear" onClick={goBack} className="icon-back" slot="start">
              <IonImg src="assets/images/back-icon.svg" />
            </IonButton>
            <div className="logo header-title">Join Pro</div>
          </IonToolbar>
        </IonHeader>
      </div>
      <IonContent className="scroll-disable">
        <div className="container">
          <div className="content">
            <div className=" m-signup m-signup__product">
              {!isProUser && (
                <div className="plan-container">
                  <span className="primary-color-text">Monthly</span>
                  <IonToggle
                    checked={checkedYearlyPeriod}
                    onIonChange={(e) => {
                      setCheckedYearlyPeriod(e.detail.checked);
                    }}
                  />
                  <span className="primary-color-text">Yearly</span>
                </div>
              )}

              {!checkedYearlyPeriod ? (
                <ProPlan
                  onClickUpgrade={(couponCode) => {
                    void createSession(String(slotCheckPriceCode), couponCode);
                  }}
                  goBack={goBack}
                />
              ) : (
                <ProPlanAnnual
                  onClickUpgrade={(couponCode) => {
                    void createSession(String(slotcheckPriceCodeYearly), couponCode);
                  }}
                  goBack={goBack}
                />
              )}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
