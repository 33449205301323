import { IonContent, IonPage } from '@ionic/react';
import CasinoNavBar from 'components/CasinoNavBar';
import React from 'react';

const NewsTab: React.FC = () => {
  return (
    <IonPage>
      <CasinoNavBar hideSearch hideNotify />

      <IonContent className="ion-padding">
        <p>News will come here...</p>
      </IonContent>
    </IonPage>
  );
};

export default NewsTab;
