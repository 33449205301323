import { Dispatch } from 'react';
import { IAction } from 'appRedux/reducers';
import { IHistorySlotInfo } from '../models/casinoModels';

export const AppKey = 'SLOTCHECKSPA-';
export const slotHistoryActionTypes = {
  UPDATE_TO_ACTIVE_SLOT_NUMBER: `${AppKey}-UPDATE_TO_ACTIVE_SLOT_NUMBER`,
  ADD_TO_SLOT_HISTORY: `${AppKey}-ADD_TO_SLOT_HISTORY`,
  CLEAR_SLOT_HISTORY: `${AppKey}-CLEAR_SLOT_HISTORY`,
};

export const SlotHistoryReduxCommandCreator = (dispatch: Dispatch<IAction>) => {
  return {
    UpdateActiveSlotNumber(state: { activeSlotNumber: number; }) {
      dispatch({
        type: slotHistoryActionTypes.UPDATE_TO_ACTIVE_SLOT_NUMBER,
        state,
      });
    },
    AddToSlotHistory(state: IHistorySlotInfo) {
      dispatch({
        type: slotHistoryActionTypes.ADD_TO_SLOT_HISTORY,
        state,
      });
    },
    ClearSlotHistory() {
      dispatch({
        type: slotHistoryActionTypes.CLEAR_SLOT_HISTORY,
        state: [],
      });
    },
  };
};
