export abstract class ApiService {
  baseRoutePath = '';
  constructor(baseRoutePath: string) {
    this.baseRoutePath = baseRoutePath;
  }
  displayBasePath(): void {
    console.log(this.baseRoutePath);
  }
}

export abstract class ApiCrudService extends ApiService {
  constructor(baseRoutePath: string) {
    super(baseRoutePath);
  }

  abstract List(): any;
  abstract Save(item: object): any;
  abstract Get(id: string | number): any;
  abstract Delete(id: string | number): any;
}
