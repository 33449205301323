import {
  faCaretDown,
  faCaretUp,
  faLongArrowAltDown,
  faLongArrowAltUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import { InsightValue } from '../../../common/types';
import { config } from '../../../config/config';
import { HotListInsight } from '../../../config/HotListTable';
import clsx from 'clsx';
import { OrderByEnum } from 'common/common';

interface HotListHeaderColumnProps {
  onSelect: (rowData: HotListInsight) => void;
  selectedInsight: InsightValue;
  totalCount: string;
  advancedViewInsights?: InsightValue[];
  sortDirection: string;
  activeInsightFilter: InsightValue | null;
  listType: string;
  isAdvancedDisplayState?: boolean;
}

const HotListHeader: FC<HotListHeaderColumnProps> = ({
  onSelect,
  selectedInsight,
  advancedViewInsights,
  totalCount,
  sortDirection,
  activeInsightFilter,
  listType,
  isAdvancedDisplayState,
}) => {
  const [activeFilter, setActiveFilter] = useState<InsightValue>(selectedInsight);
  const [activeSortingDirection, setActiveSortingDirection] = useState(OrderByEnum.Desc);

  let classes = 'hot-list__col-header';
  let classes_total = '';
  classes += ' click-target';

  const headerItems = isAdvancedDisplayState
    ? advancedViewInsights?.map((insightValue) => {
        return config.hotListTableRow.find((insight) => insight.insight === insightValue);
      })
    : [config.hotListTableRow.find(({ insight }) => insight === selectedInsight)];

  const handleHeaderItemClick = (rowData: HotListInsight) => {
    const { insight } = rowData;

    if (activeFilter === insight) {
      const newDirection =
        activeSortingDirection === OrderByEnum.Desc ? OrderByEnum.Asc : OrderByEnum.Desc;
      setActiveSortingDirection(newDirection);
    }

    onSelect(rowData);
    setActiveFilter(insight);
  };

  return (
    <>
      {headerItems?.map((rowData: HotListInsight | undefined) => {
        const hotlistDetail = config.slotInsights.find(
          (insightItem) => insightItem.value === rowData!.insight
        )!;

        if (selectedInsight === rowData?.insight) {
          if (listType !== 'myJackpot') {
            classes += ' hot-list__active-col';
            classes_total += ' hot-list__activetotal-col';
          } else {
            classes_total += ' hot-list__activetotal-col';
          }
        } else {
          classes_total += ' hot-list__total-col';
        }

        const isInsightPresent = advancedViewInsights?.some((item) => item === rowData?.insight);
        let insightData, insightSymbolTextStyle;

        if (!isAdvancedDisplayState)
          return (
            <div
              key={rowData?.insight}
              className={`hot-list-row header-align ${classes}`}
              onClick={() => onSelect(rowData!)}
            >
              <span className={classes_total}>
                {totalCount} Slots
                {activeInsightFilter && (
                  <FontAwesomeIcon
                    icon={sortDirection === OrderByEnum.Desc ? faCaretDown : faCaretUp}
                    className="sort-count-arrow-insights"
                  />
                )}
              </span>
            </div>
          );

        if (isInsightPresent) {
          insightData = config.hotSlotsLabels[rowData!.insight];
          insightSymbolTextStyle = {
            color: insightData.color,
          };
          return (
            <div
              key={rowData?.insight}
              className={clsx('hot-list-row', classes)}
              onClick={() => handleHeaderItemClick(rowData!)}
            >
              <div className="hot-list-row">
                <div className="game-data-td-report">
                  <span className="row-insight-value header-row">
                    {hotlistDetail.insightFieldsSelectorLabel}
                    {activeInsightFilter === rowData!.insight && (
                      <FontAwesomeIcon
                        icon={sortDirection === OrderByEnum.Desc ? faCaretDown : faCaretUp}
                        className="sort-count-arrow-insights"
                      />
                    )}
                  </span>
                </div>
              </div>
              <div className="box-bean">
                <div className="box-indicator" />
              </div>
            </div>
          );
        }

        return null;
      })}
    </>
  );
};

export default HotListHeader;
