import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIonToast } from '@ionic/react';
import { debounce } from 'lodash';

import { PlayerService } from 'services/player';
import { FavoritesReduxCommandCreator } from 'appRedux/actions/favoritesCommandCreator';
import { IListResponse } from '@interfaces/services';
import { IUserFavorite } from 'appRedux/models/playerModels';
import { selectUserId } from 'appRedux/selectors';

interface FavoritesHookProps {
  onOperationComplete?: () => void;
}

const useFavorites = ({ onOperationComplete }: FavoritesHookProps) => {
  const playerSvc = new PlayerService();
  const [presentToast] = useIonToast();
  const dispatch = useDispatch();

  const [isFavoritesLoading, setIsFavoritesLoading] = useState(false);
  const [slotFavoriteCount, setSlotFavoriteCount] = useState(0);

  const favoritesCommandCreator = FavoritesReduxCommandCreator(dispatch);
  const userId = useSelector(selectUserId);

  const fetchUserFavorites = useCallback(
    debounce((userId: string) => {
      setIsFavoritesLoading(true);
      const subscription = playerSvc.GetUserFavorite(userId).subscribe({
        next(favResponse) {
          if (favResponse) {
            setIsFavoritesLoading(false);
            favoritesCommandCreator.SetFavorites(favResponse.DataSet);
          }
        },
        error() {
          presentToast({
            position: 'top',
            duration: 5000,
            color: 'danger',
            message: 'Failed to get Favorites list.',
          }).catch(() => null);
        },
      });

      return () => {
        subscription.unsubscribe();
      };
    }, 500),
    [userId]
  );

  const fetchSlotFavorites = useCallback(
    debounce((slotNumber: string) => {
      setIsFavoritesLoading(true);

      const subscription = playerSvc.GetSlotUserFavorites(slotNumber).subscribe({
        next(favResponse: IListResponse<IUserFavorite> | void) {
          if (favResponse) {
            setIsFavoritesLoading(false);
            setSlotFavoriteCount(favResponse.DataSet.length);
          }
        },
        error() {
          presentToast({
            position: 'top',
            duration: 5000,
            color: 'danger',
            message: 'Failed to get Favorites list.',
          }).catch(() => null);
        },
      });

      return () => {
        subscription.unsubscribe();
      };
    }, 500),
    []
  );

  const handleDeleteFavorite = useCallback(
    debounce((slotNumber: string, userId: string) => {
      setIsFavoritesLoading(true);

      const subscription = playerSvc.DeleteFavorite(slotNumber, userId).subscribe({
        next(favResponse) {
          if (favResponse) {
            favoritesCommandCreator.RemoveFavorite(Number(slotNumber));
          }
        },
        error() {
          presentToast({
            position: 'top',
            duration: 5000,
            color: 'danger',
            message: 'Failed to remove liked machine.',
          }).catch(() => null);
        },
        complete() {
          setIsFavoritesLoading(false);

          onOperationComplete && onOperationComplete();
        },
      });

      return () => {
        subscription.unsubscribe();
      };
    }, 500),
    []
  );

  const handleInsertFavorite = useCallback(
    debounce((slotNumber: string, userId: string) => {
      setIsFavoritesLoading(true);

      const subscription = playerSvc.InsertFavorite(slotNumber, userId).subscribe({
        next(favResponse) {
          if (favResponse) {
            favoritesCommandCreator.AddFavorite({
              slotNumber: Number(slotNumber),
            });
          }
        },
        error() {
          presentToast({
            position: 'top',
            duration: 5000,
            color: 'danger',
            message: 'Failed to Add liked slot.',
          }).catch(() => null);
        },
        complete() {
          setIsFavoritesLoading(false);

          onOperationComplete && onOperationComplete();
        },
      });

      return () => {
        subscription.unsubscribe();
      };
    }, 500),
    []
  );

  return {
    slotFavoriteCount,
    isFavoritesLoading,
    onOperationComplete,
    fetchUserFavorites,
    fetchSlotFavorites,
    handleInsertFavorite,
    handleDeleteFavorite,
  };
};

export default useFavorites;
