import { kFormatter, titleCase } from '../../common/include';
import { config } from '../../../../config/config';
import { parseValue } from 'common/include';
const { heatBeanRatings } = config;
export const InsertGameNameToSlot = (selectedInsight, slot, slotData) => {
  let gameNameFormatted = `#${slot.slot_number}\n${titleCase(slot.game_name)} ${
    slot.denom ? slot?.denom : ''
  }\n`;

  let appendText = '';
  switch (selectedInsight) {
    case 'money_won':
      appendText =
        slot?.money_won !== undefined ? `Won: \n${parseValue(slot.money_won, 'money_won')}` : '';
      break;
    case 'spins':
      appendText = slot?.spins !== undefined ? `# Spins: \n${parseValue(slot.spins, 'spins')}` : '';
      break;
    case 'avg_money_won_per_spin':
      appendText =
        slot?.avg_money_won_per_spin !== undefined
          ? `$ W/S: \n${parseValue(slot.avg_money_won_per_spin, 'avg_money_won_per_spin')}`
          : '';
      break;
    case 'win_rate':
      appendText =
        slot?.win_rate !== undefined ? `% WS: \n${parseValue(slot.win_rate, 'win_rate')}` : '';
      break;
    case 'jackpot_value':
      appendText =
        slot?.jackpot_value !== undefined
          ? `$ JKPT: \n${parseValue(slot.jackpot_value, 'jackpot_value')}`
          : '';
      break;
    case 'jackpots':
      appendText =
        slot?.jackpots !== undefined ? `# JKPT: \n${parseValue(slot.jackpots, 'jackpots')}` : '';
      break;
    case 'money_played':
      appendText =
        slot?.money_played !== undefined
          ? `Played: \n${parseValue(slot.money_played, 'money_played')}`
          : '';
      break;
    case 'return':
      appendText = slot?.return !== undefined ? `RTP: \n${parseValue(slot.return, 'return')}` : '';
      break;
    case 'pop':
      appendText = slot?.pop !== undefined ? `POP: \n${parseValue(slot?.pop, 'pop')}` : '';
      break;
    case 'sleeper_slot_rating':
      appendText =
        slot?.sleeper_slot_rating !== undefined
          ? `Sleeper Slots: \n${parseValue(slot?.sleeper_slot_rating_ntile, 'sleeper_slot_rating')}`
          : '';
      break;
    case 'volatility_rating':
      appendText =
        slot?.volatility_rating !== undefined
          ? `VOL: \n${parseValue(slot?.volatility_rating_ntile, 'volatility_rating')}`
          : '';
      break;
  }
  return gameNameFormatted + appendText;
};

export const mapConfiguration = (map) => {};

export const setPreferenceHeatMapColor = (map, heatFilter) => {
  let newHeatFilter = [];
  for (let i = 6; i >= 1; i--) {
    if (heatFilter.length > 0) {
      const eachIndex = heatFilter.indexOf(i);
      if (eachIndex !== -1) {
        newHeatFilter.push(['match', ['feature-state', 'heat'], [i], true, false]);
        newHeatFilter.push(heatBeanRatings[i].color);
      } else {
        newHeatFilter.push(['match', ['feature-state', 'heat'], [i], true, false]);
        newHeatFilter.push(heatBeanRatings[i].light_color);
      }
    } else {
      newHeatFilter.push(['match', ['feature-state', 'heat'], [i], true, false]);
      newHeatFilter.push(heatBeanRatings[i].color);
    }
  }

  map.setPaintProperty('slotLayer', 'fill-color', [
    'case',
    newHeatFilter[0],
    newHeatFilter[1],
    newHeatFilter[2],
    newHeatFilter[3],
    newHeatFilter[4],
    newHeatFilter[5],
    newHeatFilter[6],
    newHeatFilter[7],
    newHeatFilter[8],
    newHeatFilter[9],
    newHeatFilter[10],
    newHeatFilter[11],
    heatBeanRatings[0].color,
  ]);
};
