import UserProfileMenu from './UserProfileMenu';
import { IonButton, IonContent, IonHeader, IonImg, IonPage, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

const UserProfile: React.FC = () => {
  const history = useHistory();
  const [isUserProfileActive, setIsUserProfileActive] = useState(false);

  useEffect(() => {
    setIsUserProfileActive(location.pathname === '/profile');
  }, [location.pathname]);

  return (
    <IonPage className="app-parent">
      <div className="slotcheck-header">
        <IonHeader className="small-header">
          <IonToolbar>
            <IonButton
              fill="clear"
              onClick={() => {
                history.push('/tabs/home');
              }}
              className="icon-back"
              slot="start"
            >
              <IonImg src="assets/images/back-icon.svg" />
            </IonButton>
            <div className="logo header-title">Profile</div>
          </IonToolbar>
        </IonHeader>
      </div>
      <IonContent>{isUserProfileActive && <UserProfileMenu />}</IonContent>
    </IonPage>
  );
};

export default UserProfile;
