import { IonButton, IonIcon, IonLabel, IonPopover } from '@ionic/react';
import { pinSharp } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { pinArrayType } from 'common/common';
import { IState } from 'appRedux/createStore';
import { useDispatch, useSelector } from 'react-redux';
import { SectionIdType } from 'appRedux/reducers/slotReducer';
import { SlotReduxCommandCreator } from 'appRedux/actions/slotCommandCreator';

interface PINProps {
  sectionId: SectionIdType;
  onClickItem: (item: pinArrayType) => void;
}

const pinArr: pinArrayType[] = [
  { label: 'Top', id: 1, index: 0 },
  { label: 'Center', id: 2, index: 1 },
  { label: 'Bottom', id: 3, index: 2 },
];

const PinSelectors: React.FC<PINProps> = (props) => {
  const { sectionId } = props;
  const [showPopover, setShowPopover] = useState(false);
  const [popoverData, setPopoverData] = useState<pinArrayType[] | undefined>(undefined);
  const popover = useRef<HTMLIonPopoverElement>(null);

  const dispatch = useDispatch();
  const detailCommand = SlotReduxCommandCreator(dispatch);
  const sectionOrder = useSelector<IState>(
    (state) => state.app.persistedState.slotState.sections
  ) as string[];

  const getFilteredPinArrays = (sections: string[]): Record<string, pinArrayType[]> => {
    const filteredArrays: Record<string, pinArrayType[]> = {};

    sections.forEach((section) => {
      const position = sections.indexOf(section);
      let filteredArray: pinArrayType[] = [];

      if (position === 0) {
        filteredArray = pinArr.filter((item) => item.index !== 0);
      } else if (position === 1) {
        filteredArray = pinArr.filter((item) => item.index !== 1);
      } else if (position === 2) {
        filteredArray = pinArr.filter((item) => item.index !== 2);
      }
      filteredArrays[section] = filteredArray;
    });

    return filteredArrays;
  };
  const orderIndex = sectionOrder.indexOf(sectionId);
  const lbl_pin = pinArr[orderIndex];
  const openPopover = () => {
    const filteredArrays = getFilteredPinArrays(sectionOrder);
    setPopoverData(filteredArrays[sectionId]);
    setShowPopover(true);
  };

  const closePopover = () => {
    setShowPopover(false);
    setPopoverData([]);
  };

  const PopoverContent = () => (
    <div className="pin-list">
      {popoverData &&
        popoverData.map((pinItem: pinArrayType, index: number) => (
          <div
            key={index}
            onClick={() => {
              onClickItem(pinItem);
            }}
          >
            {pinItem.label}
          </div>
        ))}
    </div>
  );

  const onClickItem = (pinItem: pinArrayType) => {
    props.onClickItem(pinItem);
    closePopover();
    detailCommand.MoveSection({
      sectionId: sectionId,
      newPosition: pinItem.index,
      sections: sectionOrder,
    });
  };

  const handleSectionClick = (e: any) => {
    popover.current!.event = e;
    openPopover();
  };
  return (
    <div className="pin-selector">
      <IonButton fill="clear" id={sectionId} className="pin-button" onClick={handleSectionClick}>
        <IonIcon className="body-bold" icon={pinSharp} />
        <IonLabel className="body-bold">{lbl_pin?.label}</IonLabel>
      </IonButton>
      <IonPopover
        ref={popover}
        isOpen={showPopover}
        onDidDismiss={closePopover}
        className="snapshot-tooltip pin-popover"
        mode="ios"
        arrow={false}
      >
        <div className="pop-content">
          <PopoverContent />
        </div>
      </IonPopover>
    </div>
  );
};

export default PinSelectors;
