import { parseValueForTrends, parseValueForTrendsNumber } from '../../common/include';

export const parseTrendValuesArray = ({
  monthTrendValue,
  weekTrendValue,
  dayTrendValue,
  eightHoursTrendValue,
  fourHoursTrendValue,
  oneHourTrendValue,
  insightId,
}: any) => ({
  monthTrendValueItem: parseValueForTrendsNumber(monthTrendValue, insightId),
  weekTrendValueItem: parseValueForTrendsNumber(weekTrendValue, insightId),
  dayTrendValueItem: parseValueForTrendsNumber(dayTrendValue, insightId),
  eightHoursTrendValueItem: parseValueForTrendsNumber(eightHoursTrendValue, insightId),
  fourHoursTrendValueItem: parseValueForTrendsNumber(fourHoursTrendValue, insightId),
  oneHourTrendValueItem: parseValueForTrendsNumber(oneHourTrendValue, insightId),
});

export const getTrendLine = (
  monthTrendValue: number,
  weekTrendValue: number,
  dayTrendValue: number,
  eightHoursTrendValue: number,
  fourHoursTrendValue: number,
  oneHourTrendValue: number
) => {
  let monthToWeekTrend = '',
    weekToDayTrend = '',
    dayToEightHoursTrend = '',
    eightHoursToFourHoursTrend = '',
    fourHoursTrendToOneHourTrend = '';
  // Month To Week Trend Line
  if (monthTrendValue < weekTrendValue) monthToWeekTrend = 'low-medium';
  if (monthTrendValue > weekTrendValue) {
    monthToWeekTrend = 'medium-low';
  }
  if (monthTrendValue === weekTrendValue) monthToWeekTrend = 'medium';
  if (monthTrendValue > weekTrendValue) {
    if (weekTrendValue > dayTrendValue) monthToWeekTrend = 'medium-low';
    else monthToWeekTrend = 'high-medium';
  }

  // Week To Day Trend Line
  if (weekTrendValue > dayTrendValue) {
    // Goes down
    if (['low-medium', 'medium', 'high-medium'].includes(monthToWeekTrend))
      weekToDayTrend = 'medium-low';
    if (['medium-low', 'high-low', 'low'].includes(monthToWeekTrend)) weekToDayTrend = 'low';
  } else if (weekTrendValue === dayTrendValue) {
    // Goes straight
    weekToDayTrend = 'medium';
  } else {
    // Goes up
    if (['low-medium', 'medium', 'high-medium'].includes(monthToWeekTrend))
      weekToDayTrend = 'medium-high';
    if (['medium-low', 'high-low', 'low'].includes(monthToWeekTrend)) weekToDayTrend = 'low-medium';
  }

  // Day To 8 Hours Trend Line
  if (dayTrendValue > eightHoursTrendValue) {
    // Goes down
    if (['low-medium', 'medium', 'high-medium'].includes(weekToDayTrend))
      dayToEightHoursTrend = 'medium-low';
    if (['medium-low', 'high-low', 'low'].includes(weekToDayTrend)) dayToEightHoursTrend = 'low';
  } else {
    // Goes up
    if (['low-medium', 'medium', 'high-medium'].includes(weekToDayTrend))
      dayToEightHoursTrend = 'medium-high';
    if (['medium-low', 'high-low', 'low'].includes(weekToDayTrend))
      dayToEightHoursTrend = 'low-medium';
    if (['medium-high'].includes(weekToDayTrend)) dayToEightHoursTrend = 'high';
  }

  // 8 Hours to 4 Hours Trend Line

  if (eightHoursTrendValue > fourHoursTrendValue) {
    // Goes down
    if (['low-medium', 'medium', 'high-medium'].includes(dayToEightHoursTrend))
      eightHoursToFourHoursTrend = 'medium-low';
    if (['medium-low', 'high-low', 'low'].includes(dayToEightHoursTrend))
      eightHoursToFourHoursTrend = 'low';
  } else {
    // Goes up
    if (['low-medium', 'medium', 'high-medium'].includes(dayToEightHoursTrend))
      eightHoursToFourHoursTrend = 'medium-high';
    if (['medium-low', 'high-low', 'low'].includes(dayToEightHoursTrend))
      eightHoursToFourHoursTrend = 'low-medium';
  }

  // 4 Hours to 1 Hour Trend Line
  if (fourHoursTrendValue > oneHourTrendValue) {
    // Goes down
    if (['low-medium', 'medium', 'high-medium'].includes(eightHoursToFourHoursTrend))
      fourHoursTrendToOneHourTrend = 'medium-low';
    if (['medium-low', 'high-low', 'low'].includes(eightHoursToFourHoursTrend))
      fourHoursTrendToOneHourTrend = 'low';
    if (['medium-high', 'low-high', 'high'].includes(eightHoursToFourHoursTrend))
      fourHoursTrendToOneHourTrend = 'high-medium';
  } else {
    // Goes up
    if (['low-medium', 'medium', 'high-medium'].includes(eightHoursToFourHoursTrend))
      fourHoursTrendToOneHourTrend = 'medium-high';
    if (['medium-low', 'high-low', 'low'].includes(eightHoursToFourHoursTrend))
      fourHoursTrendToOneHourTrend = 'low-medium';
  }

  return {
    monthToWeekTrend,
    weekToDayTrend,
    dayToEightHoursTrend,
    eightHoursToFourHoursTrend,
    fourHoursTrendToOneHourTrend,
  };
};

export const parseTrendValues = ({
  monthTrendValue,
  weekTrendValue,
  dayTrendValue,
  eightHoursTrendValue,
  fourHoursTrendValue,
  oneHourTrendValue,
  insightId,
}: any) => ({
  monthTrendValue: parseValueForTrends(monthTrendValue, insightId),
  weekTrendValue: parseValueForTrends(weekTrendValue, insightId),
  dayTrendValue: parseValueForTrends(dayTrendValue, insightId),
  eightHoursTrendValue: parseValueForTrends(eightHoursTrendValue, insightId),
  fourHoursTrendValue: parseValueForTrends(fourHoursTrendValue, insightId),
  oneHourTrendValue: parseValueForTrends(oneHourTrendValue, insightId),
});
