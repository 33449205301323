import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export interface InfoWarningProps {
  status: 'error' | 'info';
  heading: string;
  description?: string;
}

export const InfoWarning: React.FC<InfoWarningProps> = ({ heading, description, status }) => {
  return (
    <div className={`info-warning ${status}`} role="alert">
      <div className="body-bold card-heading">
        <FontAwesomeIcon icon={faTriangleExclamation} className="icon" aria-hidden="true" />
        {heading}
      </div>
      {description && <div className="body card-description">{description}</div>}
    </div>
  );
};

export default InfoWarning;
