/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { IonButton, IonImg } from '@ionic/react';
import { IState } from 'appRedux/createStore';
import { useSelector } from 'react-redux';
import { useState } from 'react';

interface IPlans {
  onClickUpgrade: (couponCode: string) => void;
  goBack: () => void;
}

export const ProPlan = ({ onClickUpgrade, goBack }: IPlans) => {
  const [couponCode, setCouponCode] = useState('');
  const isProUser = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.isProUser
  ) as boolean;

  const onClickBack = () => {
    goBack();
  };
  return (
    <>
      <div className="slot-plan-box">
        <div className="header">
          <div style={{ marginBottom: '20px' }}>
            <IonImg style={{ height: '75px' }} src="assets/images/PRO-logo.svg" />
          </div>
          <div className="price-container">
            <span className="primary-color-text price heading6">$100</span>{' '}
            <span className="primary-color-text time-span body-bold"> per month</span>
          </div>
        </div>
        <div className="slot-plan-box-content body-bold">
          <ul>
            <div>
              <li>All 9 Hot Slot Insights</li>
            </div>
            <div>
              <li>30-day trends across 6 timeframes</li>
            </div>
            <div>
              <li>All 9 hot slot insights</li>
            </div>
            <div>
              <li>Ranked list of hot slots for all 9 insights</li>
            </div>
            <div>
              <li>Slot comparison feature</li>
            </div>
          </ul>
        </div>
        <div className="slot-plan-box-footer">
          <div className="slot-plan-box-footer">
            {isProUser && (
              <>
                <IonButton color="light" fill="outline" onClick={onClickBack}>
                  Active Plan
                </IonButton>
              </>
            )}

            {!isProUser && (
              <>
                <IonButton onClick={() => onClickUpgrade(couponCode)}>Pro Upgrade!</IonButton>
                <IonButton color="light" fill="outline" onClick={onClickBack}>
                  Keep basic version
                </IonButton>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
