import { IonImg, IonModal } from '@ionic/react';
import React, { ComponentProps, useLayoutEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPhone, faGlobe, faDiamondTurnRight } from '@fortawesome/free-solid-svg-icons';
type IonModalProps = ComponentProps<typeof IonModal>;

type Props = {
  onDismiss: () => void;
};

type InsightPopupProps = {
  isOpen: boolean;
  activeInsight: string;
  setShowIonModal: (isOpen: boolean) => void;
};

export const TrendsSectionInfo = ({ onDismiss }: Props) => (
  <div className="dark-popup">
    <div className="dark-popup-content">
      <h2>Hot Slot Insight Trends</h2>
      <p>
        Knowing how various insights are trending helps you get a feel for how this slot has been
        paying out and how you think it may payout in the future.
      </p>
      <p>
        The PRO version of SlotCheck also includes the most recent 1,4 and 8 hours when in landscape
        mode.
      </p>
      <p>
        Slots results are random, but seeing trends give your gut and intuition a better chance to
        maximize your gaming enjoyment.
      </p>
    </div>
    <div className="dark-popup-footer" onClick={onDismiss}>
      Close
    </div>
  </div>
);

export const MoneyWon = ({ onDismiss }: Props) => {
  return (
    <div className="dark-popup">
      <div className="dark-popup-content">
        <h2>Won</h2>
        <p>
          This insight tells you the total amount of money the slot has paid out regardless of how
          much money was Played (paid in).
        </p>
        <p>
          To be clear, money Won by itself is not an expression of how profitable or “Hot” a slot
          has been. That would be RTP.
        </p>
        <h2>How do I think about money Won?</h2>
        <p>By itself, money Won tells you how much money that slot has paid out.</p>
        <p>
          Money Won is used to calculate Return to Player (RTP) as well as Win per Spin (W/S) and
          Winning Spins (WS).
        </p>
        <p>
          Money Won, along with the other Slot Check insights, helps give you a sense of the slot
          floor and a feel for which slots may be hot.
        </p>
      </div>
      <div
        className="dark-popup-footer"
        onClick={() => {
          onDismiss();
        }}
      >
        Close
      </div>
    </div>
  );
};

export const Return = ({ onDismiss }: Props) => {
  return (
    <div className="dark-popup">
      <div className="dark-popup-content">
        <h2>Return to Player (RTP) </h2>
        <p>
          This is the &quot;Hot Slot&quot; indicator. It tells you how profitable a slot has been in
          the recent past. When players say, “I want to know where the winning machines are,&quot;
          this is the number they are referring to.
        </p>
        <p>
          RTP is the amount of money won (paid out) divided by the money played (paid in) for a
          selected timeframe (e.g., 24 hour, 7 days, or 30 days). This number is expressed as a
          percentage (%) so it applies to any budget or amount played.
        </p>
        <h2>How do I think about RTP?</h2>
        <p>
          In short, it tells you how profitable a slot has been recently. If you compare RTP to POP,
          you can get an idea of how this slot might payout in the future based on if RTP is
          trending below or above POP for a given timeframe.
        </p>
        <p>
          For example, if POP is 90% over the past 500K spins and RTP is 73% (17% below POP) over
          the past 30 days, you can have a high degree of confidence that slot will start paying
          over 100% of the money paid in at some point in the future as it must get back to the 90%
          POP it is set to payout over its lifetime. If that same slot has a 30-day RTP above POP,
          the opposite is likely true.
        </p>
        <p>
          When a slot machine will payout and how much is random and cannot be predicted. With Slot
          Check, and luck, you may be able to increase your odds.
        </p>
      </div>
      <div className="dark-popup-footer" onClick={onDismiss}>
        Close
      </div>
    </div>
  );
};

export const Pop = ({ onDismiss }: Props) => {
  return (
    <div className="dark-popup">
      <div className="dark-popup-content">
        <h2>Payout to Player (POP) </h2>
        <p>
          Each slot has a set POP. POP tells you how much money a given slot has returned to the
          player over its lifetime. The higher the number, the looser the slot.
        </p>
        <p>
          POP is the total amount of money won (paid out) divided by the total money played (paid
          in) over the lifetime of the slot machine, expressed as a %.
        </p>

        <h2>How do I think about POP?</h2>
        <p>
          If POP for a given slot machine is 93% over the past 650K spins, you can have a high
          degree of confidence that that slot machine is set to pay out 93% to the player over its
          lifetime. The greater the number of spins, the higher your confidence is that that slot is
          set to that POP over its lifetime.
        </p>
        <p>
          For example, if the RTP (return to player) for the above slot is 76% over the past 30
          days, you know with a high degree of confidence that that slot is due to payout more than
          100% to player at some point in the future as RTP has been 17% below POP in past 30 days.
          If RTP is above POP, the opposite is true.
        </p>
        <p>
          If the RTP for that slot is 76% over the past 30 days and that slot has been played a lot,
          you know with a high degree of confidence that that slot is due to payout more than 100%
          to player as RTP has been below (17%) that slot&apos;s set POP. If RTP is above POP, the
          opposite is true.
        </p>
        <p>
          When a slot machine will payout and how much is random and cannot be predicted. With Slot
          Check, and luck, you may be able to increase your odds.
        </p>
      </div>
      <div
        className="dark-popup-footer"
        onClick={() => {
          onDismiss();
        }}
      >
        Close
      </div>
    </div>
  );
};

export const Vol = ({ onDismiss }: Props) => {
  return (
    <div className="dark-popup">
      <div className="dark-popup-content">
        <h2>Volatility (VOL) </h2>
        <p>
          This fun and valuable insight will help you determine what type of slot you want to play
          for the greatest entertainment value.
        </p>
        <p>
          Within the industry, slots are generally referred to as either High Volatility (HV) or Low
          Volatility (LV) slots.
        </p>

        <h2>Low Volatility Slots</h2>
        <p>
          Low Volatility slots will make your budget last longer compared to high volatility slots.
          LV slots will give you more playing time. LV slots will also give you more winning spins.
          They typically are not big wins, compared to HV slots, but they give you the enjoyment of
          more wins.
        </p>
        <p>
          Below is a visual to help you think about how a LV slot pays out. Lines above POP are
          winning spins.
        </p>
        <IonImg src="assets/images/low-volatility-slot.png" className="insights-img" />
        <p>As you can see, there are a lot of winning spins.</p>

        <h2>High Volatility Slots</h2>
        <p>
          High Volatility (LV) slots will not have many winning spins, compared to LV slots, but
          when they do hit a winning spin, the payouts are typically big. HV slots have a low
          percent of Winning Spins (WS) and a high percent Return to Player (RTP). These slots are
          riskier, but the payoff and thrill can be huge
        </p>
        <p>
          Below is a visual to help picture how a HV slot pays out. As you can see, there are a lot
          of losing spins below the POP line, but when a winning spin is hit, it is big, as
          indicated by the spikes above the POP line.
        </p>
        <IonImg src="assets/images/high-volatility-slot.png" className="insights-img" />
        <p>
          Are you feeling lucky or do you have a budget to get you thru to the next big win, are
          questions to answer when playing HV slots.
        </p>

        <h2>How do I think about Volatility?</h2>
        <p>
          Let&apos;s say you have a dinner reservation in an hour, and you want to find a slot that
          will fill that time. In this scenario, you may want a LV slot that will extend your budget
          to give you that time on machine while you wait for your table.
        </p>
        <p>
          Let&apos;s say that your table is called, and you have $20 or $40 left on your ticket and
          you are feeling lucky. Now you may want to find a HV slot that you think is ready to hit
          big. You are prepared to lose it all quickly, but it’s worth the risk to have the thrill
          of hitting a big one.
        </p>
        <p>
          Slot Check helps you find the best slot for you and your budget so you can WIN YOUR WAY!
        </p>
      </div>
      <div className="dark-popup-footer" onClick={onDismiss}>
        Close
      </div>
    </div>
  );
};

export const Jackpot = ({ onDismiss }: Props) => {
  return (
    <div className="dark-popup">
      <div className="dark-popup-content">
        <h2>Jackpots (JKPT)</h2>
        <p>
          Jackpots are defined as any payout equal to or greater than $1,200. These are hand pay
          jackpots. This Slot Check insight tells you when jackpots were hit, how many, and for how
          much.
        </p>
        <h2>How do I think about Jackpots?</h2>
        <p>
          Knowing if the slot you are about to play has just hit a jackpot, or several jackpots, is
          good and valuable information …or so say many of our customers. While slot results are
          random, many believe that if a slot just hit a jackpot, it is unlikely to hit another one
          so soon. Those players can sleep better at night knowing that they didn’t just spend their
          budget on a slot that just paid out before they sat down. It pays to SLOT CHECK BEFORE YOU
          PLAY!
        </p>
        <p>
          Slot Check will also tell you which slots have had the most <b>Spins</b>, or money{' '}
          <b>Played</b>, Since the Last Jackpot (<b>SLJ</b>). Some of our players tell us that they
          believe the slots that are most likely to hit a jackpot are those that have not paid out a
          jackpot in a long time. These players look for slots to play that have had a lot of money
          Played, or have been spun a lot, SLJ. Slot Check gives you the insights to Win YOUR WAY!
        </p>
        <p>
          To celebrate your jackpots, Slot Check keeps a recent history of all of your jackpots.
        </p>
      </div>
      <div className="dark-popup-footer" onClick={onDismiss}>
        Close
      </div>
    </div>
  );
};

export const NoOfJackpot = ({ onDismiss }: Props) => {
  return (
    <div className="dark-popup">
      <div className="dark-popup-content">
        <h2># Jackpots (# JKPT)</h2>
        <p>
          Jackpots are defined as any payout equal to or greater than $1,200. These are hand pay
          jackpots. This Slot Check insight tells you how many jackpots were hit in a selected
          timeframe.
        </p>
        <h2>How do I think about Jackpots?</h2>
        <p>
          Knowing if the slot you are about to play has just hit a jackpot, or several jackpots, is
          good and valuable information …or so say many of our customers. While slot results are
          random, many believe that if a slot just hit a jackpot, it is unlikely to hit another one
          so soon. Those players can sleep better at night knowing that they didn’t just spend their
          budget on a slot that just paid out before they sat down. It pays to SLOT CHECK BEFORE YOU
          PLAY!
        </p>
        <p>
          Slot Check will also tell you which slots have had the most <b>Spins</b>, or money{' '}
          <b>Played</b>, Since the Last Jackpot (<b>SLJ</b>). Some of our players tell us that they
          believe the slots that are most likely to hit a jackpot are those that have not paid out a
          jackpot in a long time. These players look for slots to play that have had a lot of money
          Played, or have been spun a lot, SLJ. Slot Check gives you the insights to Win YOUR WAY!
        </p>
        <p>
          To celebrate your jackpots, Slot Check keeps a recent history of all of your jackpots.
        </p>
      </div>
      <div className="dark-popup-footer" onClick={onDismiss}>
        Close
      </div>
    </div>
  );
};

export const Played = ({ onDismiss }: Props) => {
  return (
    <div className="dark-popup">
      <div className="dark-popup-content">
        <h2>Played</h2>
        <p>
          This is the total amount of money that players have put into a given slot machine
          including free play. Free Play and the player&apos;s money are the same to a slot machine.
          It is therefore included in the Played number.
        </p>

        <h2>How do I think about money Played?</h2>
        <p>Like money Won and Spins, by itself, it is a measure of how popular a machine is. </p>
        <p>
          It is also used to compare with how much money was Won to know how profitable a slot is.
        </p>
        <p>
          Like number of Spins and money Won, it is used to calculate Return to Player (RTP) as well
          as Win per Spin (W/S) and Winning Spins (WS).
        </p>
        <p>
          Money Played, along with the other Slot Check insights, helps give you a sense of the slot
          floor and a feel for which are the hot slots.
        </p>
      </div>
      <div className="dark-popup-footer" onClick={onDismiss}>
        Close
      </div>
    </div>
  );
};

export const Spins = ({ onDismiss }: Props) => {
  return (
    <div className="dark-popup">
      <div className="dark-popup-content">
        <h2>Spins</h2>
        <p>
          This tells you how many times the spin button has been pressed regardless of how much has
          been played (paid in).
        </p>
        <h2>How do I think about Spins?</h2>
        <p>Spins will tell you how popular a slot has been.</p>
        <p>
          Spins will tell you how confident you can be in the accuracy of the Payout to Player (POP)
          insight. See POP info button for more details.
        </p>
        <p>
          Spins is also the number used to calculate the Winning Spins (WS) and Wins per Spin (W/S)
          insights.
        </p>
      </div>
      <div className="dark-popup-footer" onClick={onDismiss}>
        Close
      </div>
    </div>
  );
};

export const WinSpin = ({ onDismiss }: Props) => {
  return (
    <div className="dark-popup">
      <div className="dark-popup-content">
        <h2> Win Per Spin (W/S) </h2>
        <p>
          This insight tells you how much a given slot, on average, has payout per spin over a
          selected period.
        </p>
        <p>
          W/S is calculated by dividing money Won by the number of Spins for a given timeframe. This
          number is expressed as a dollar amount ($).
        </p>

        <h2>How do I think about W/S?</h2>
        <p>
          Like any of the insights in Slot Check, they are best when considered in context with
          other insights. By themselves that are information. In context they are insights that can
          help you WIN YOUR WAY!
        </p>
        <p>
          W/S is an indication of how hot a slot is. It tells you how much money on average that
          slot has won on each spin. In SLOT PRO, you can see a sorted list of which slots have the
          highest W/S for a given period. Variables like what denomination was Played, may influence
          the W/S insight. Higher denomination games may have a higher W/S.
        </p>
        <p>
          Keep in mind that a slot may have a low W/S number but a high percentage of Winning Spins
          (WS). Stated another way, this slot may not pay out a lot each spin, but it has a lot of
          winning spins. Because there are frequent winning spins, it is a fun game to play. Such a
          slot describes the personality of a Low Volatility (LV) slot. Players have told us they
          like LV slots as they extend time on machine. Some players call these “Low and Slow”
          slots, as they make their budget last longer.
        </p>
      </div>
      <div className="dark-popup-footer" onClick={onDismiss}>
        Close
      </div>
    </div>
  );
};

export const WinRate = ({ onDismiss }: Props) => {
  return (
    <div className="dark-popup">
      <div className="dark-popup-content">
        <h2>Winning Spins (WS) </h2>
        <p>
          This insight tells you how often a spin will result in a winning spin for a selected
          timeframe. This insight is expressed as a percentage (%).
        </p>
        <h2>How do I think about WS?</h2>
        <p>By itself, you can find the slots on the floor that have had the most winning spins.</p>
        <p>
          While it can be assumed that all players love winning spins, WS by itself does not tell
          you how big those winning spins are or how profitable a slot is. For this, you must look
          to Volatility (VOL) and Return to player (RTP).
        </p>
        <p>
          Low Volatility slots are typically those that have a high percentage of winning spins but
          the winning spins are typically not high dollar and thus have lower RTP. These are slots
          that typically provide more time on device (longer slot play) and the frequency of winning
          spins make them fun to play.
        </p>
        <p>
          By contrast, high volatility games have low percent of winning spins but high RTP. These
          games are riskier and your money can go quickly, but when they do hit a winning spin it
          can be big.
        </p>
      </div>
      <div className="dark-popup-footer" onClick={onDismiss}>
        Close
      </div>
    </div>
  );
};

export const ColdestRTP = ({ onDismiss }: Props) => {
  return (
    <div className="dark-popup">
      <div className="dark-popup-content">
        <p>
          {' '}
          <span className="bold-text-popup">Return to Player (RTP)</span>is the “Hot Slot”
          indicator. It tells you how profitable a slot has been in the recent past.
        </p>
        <p>
          When players say, “I want to know where the winning machines are,” this is the number they
          are referring to.
        </p>
        <p>
          RTP is the amount of money won (paid in) divided by the money played (paid in) for a
          selected timeframe (1 hr. to 30 days). This number is expressed as a percentage (%) so it
          applies to any budget or amount played.
        </p>

        <h2>How do I think about RTP?</h2>
        <p>
          If you put $100 in the slot <span className="bold-text-popup">(Played)</span>
          and you get back $18 <span className="bold-text-popup">(Won)</span>, the Return to Player
          (RTP) for that slot during your play would be 18% ($18/$100).
        </p>
        <p>
          <span className="box-indicator very-cold mr-indiactor"></span>If 18% RTP was in the bottom
          16.3% of all the slots on the floor, it would have a royal blue hot slot square denoting
          it is a cold slot. Blue is cold. Pink is hot.
        </p>
      </div>
      <div className="dark-popup-footer" onClick={onDismiss}>
        Close
      </div>
    </div>
  );
};
export const HottestRTP = ({ onDismiss }: Props) => {
  return (
    <div className="dark-popup">
      <div className="dark-popup-content">
        <p>
          {' '}
          <span className="bold-text-popup">Return to Player (RTP)</span>is the “Hot Slot”
          indicator. It tells you how profitable a slot has been in the recent past.
        </p>
        <p>
          When players say, “I want to know where the winning machines are,” this is the number they
          are referring to.
        </p>
        <p>
          RTP is the amount of money won (paid in) divided by the money played (paid in) for a
          selected timeframe (1 hr. to 30 days). This number is expressed as a percentage (%) so it
          applies to any budget or amount played.
        </p>

        <h2>How do I think about RTP?</h2>
        <p>
          If you put $100 in the slot <span className="bold-text-popup">(Played)</span>
          and you get back $232 <span className="bold-text-popup">(Won)</span>, the Return to Player
          (RTP) for that slot during your play would be 232% ($232/$100).
        </p>
        <p>
          <span className="box-indicator pink mr-indiactor"></span>If 232% RTP was in the top 16.3%
          of all the slots on the floor, it would have a pink hot slot square denoting it is hot
          slot. Pink is hot, blue is not.
        </p>
      </div>
      <div className="dark-popup-footer" onClick={onDismiss}>
        Close
      </div>
    </div>
  );
};
export const ComparePlanSLJ = ({ onDismiss }: Props) => {
  return (
    <div className="dark-popup">
      <div className="dark-popup-content">
        <p>SLJ information goes to here.</p>
      </div>
      <div className="dark-popup-footer" onClick={onDismiss}>
        Close
      </div>
    </div>
  );
};

export const FindInsights = ({ onDismiss }: Props) => {
  return (
    <div className="dark-popup">
      <div className="dark-popup-content">
        <p>
          Everyone defines &quot;Hot Slots&quot; differently. Below are ten categories of slot
          insights that slot players have told us are valuable to consider when determining what
          slots to play. The information presented is historical (in the recent past) and does not
          predict future play as slot machines generate spin results randomly.
        </p>
      </div>
      <div className="dark-popup-footer" onClick={onDismiss}>
        Close
      </div>
    </div>
  );
};

export const LOTC = ({ onDismiss }: Props) => {
  return (
    <div className="dark-popup lotc-popup-container">
      <div className="dark-popup-content">
        <div className="lotc-popup-logo">
          <IonImg src="assets/images/LOT_property_logo.png" />
        </div>
        <div className="lotc-scroll-content">
          <div className="lotc-popup-heading">Lake of the Torches Resort Casino</div>
          <div className="lotc-popup-content">
            Please go to the Lake Club and signup for a player card and get your player ID. Linking
            your player ID in Slot Check will allow you to track your play, see your jackpots, and
            see yourself on the leaderboard
          </div>
          <div className="lotc-popup-content">
            510 Old Abe Rd, <br />
            Lac Du Flambeau, <br />
            WI 54538, <br />
            United States
          </div>
          <div className="lotc-popup-connect">
            <div
              className="lotc-popup-connect-box"
              onClick={() => {
                window.open('tel:18002586724');
              }}
            >
              <div className="lotc-popup-connect-icon">
                <FontAwesomeIcon icon={faPhone} />
              </div>
              <div className="lotc-popup-connect-text">Call</div>
            </div>

            <div
              className="lotc-popup-connect-box"
              onClick={() => {
                window.open('https://lakeofthetorches.com', '_blank');
              }}
            >
              <div className="lotc-popup-connect-icon">
                <FontAwesomeIcon icon={faGlobe} />
              </div>
              <div className="lotc-popup-connect-text">Website</div>
            </div>

            <div
              className="lotc-popup-connect-box"
              onClick={() => {
                window.open(
                  'https://www.google.com/maps/dir//Lake+of+the+Torches+Resort+Casino+510+Old+Abe+Rd+Lac+Du+Flambeau,+WI+54538+United+States/@45.9741614,-89.8917995,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x4d55f3d0e03d2df1:0x812ed45bb04baf75',
                  '_blank'
                );
              }}
            >
              <div className="lotc-popup-connect-icon">
                <FontAwesomeIcon icon={faDiamondTurnRight} />
              </div>
              <div className="lotc-popup-connect-text">Direction</div>
            </div>
          </div>
          <div className="dark-popup-footer" onClick={onDismiss}>
            <FontAwesomeIcon icon={faClose} />
          </div>
        </div>
      </div>
    </div>
  );
};

const InsightModal: React.FC<IonModalProps> = (props) => {
  const modalRef = useRef<HTMLIonModalElement>(null);

  useLayoutEffect(
    () => () => {
      // hack to fix
      modalRef.current?.dismiss();
    },
    []
  );

  return (
    <div>
      <IonModal ref={modalRef} {...props} isOpen={true} className="insight-modal">
        {props.children}
      </IonModal>
    </div>
  );
};

export const InsightPopUp = ({ isOpen, activeInsight, setShowIonModal }: InsightPopupProps) => {
  return (
    <>
      {isOpen && (
        <InsightModal isOpen={true} onDidDismiss={() => setShowIonModal(false)}>
          {activeInsight === 'money_won' && (
            <MoneyWon
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'return' && (
            <Return
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'spins' && (
            <Spins
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'pop' && (
            <Pop
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'volatility_rating' && (
            <Vol
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'jackpot_value' && (
            <Jackpot
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'jackpots' && (
            <NoOfJackpot
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'money_played' && (
            <Played
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'avg_money_won_per_spin' && (
            <WinSpin
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'win_rate' && (
            <WinRate
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'coldest_rtp' && (
            <ColdestRTP
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'hottest_rtp' && (
            <HottestRTP
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'trends_section_info' && (
            <TrendsSectionInfo
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'compare-plan-slj' && (
            <ComparePlanSLJ
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'find-insights' && (
            <FindInsights
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
          {activeInsight === 'lotc' && (
            <LOTC
              onDismiss={() => {
                setShowIonModal(false);
              }}
            />
          )}
        </InsightModal>
      )}
    </>
  );
};

export default InsightModal;
