import Bathrooms from './mapicons/Bathrooms.png';


const LblImageArr = [
  {
    name: 'Bathrooms',
    image: Bathrooms,
  }
];

export const loadImage = (mapbox:any, imageName:any, key:any) => {
  mapbox.loadImage(imageName, (error:any, image:any) => {
    if (error) throw error;
    mapbox.addImage(key, image);
  });
};

export const addMapRWCImage = (mapbox:any) => {
  for (let index = 0; index < LblImageArr.length; index++) {
    const eachElement = LblImageArr[index];
    loadImage(mapbox, eachElement.image, eachElement.name);
  }
};
