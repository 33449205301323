import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonRow } from '@ionic/react';
import { config, HeatBeanObjectKey } from '../../config/config';
import { parseTrendValues, parseTrendValuesArray } from './utils';
import { LineChart, Line } from 'recharts';
const { heatBeanRatings } = config;

const HeatBox: React.FC<{ heatLevel?: number }> = ({ heatLevel }) =>
  heatLevel ? (
    <span
      className="box-indicator"
      style={{ background: `${heatBeanRatings[heatLevel as HeatBeanObjectKey].color}` }}
    />
  ) : null;

interface TrendRowProps {
  trendLine: {
    monthToWeekTrend?: string;
    weekToDayTrend?: string;
    dayToEightHoursTrend: string;
    eightHoursToFourHoursTrend: string;
    fourHoursTrendToOneHourTrend: string;
  };
  trendValues?: {
    monthTrendValue: string | number;
    weekTrendValue: string | number;
    dayTrendValue: string | number;
    eightHoursTrendValue: string | number;
    fourHoursTrendValue: string | number;
    oneHourTrendValue: string | number;
  };
  heatLevel?: {
    monthHeat: number;
    weekHeat: number;
    dayHeat: number;
    eightHoursHeat: number;
    fourHoursHeat: number;
    oneHourHeat: number;
  };
  shouldHideHeatBox?: boolean;
  shouldHideTrendLine?: boolean;
  trendValueCls?: string;
  isAdvancedDisplayState?: boolean;
  trenLineArray?: any;
  screenWidth: any;
}

interface TrendRowContainerProps extends TrendRowProps {
  snapshotLabel?: string;
  insightId?: string;
  children?: React.ReactNode;
  trendLabel: string;
  enableDarkBg?: boolean;
  showInsightModal: () => void;
  lastJackpotAge?: string;
  noJackpotsWithinMonth?: boolean;
  trenLineArray?: any;
  screenWidth: any;
}

const TrendRowItem: React.FC<TrendRowProps> = ({
  trendLine,
  isAdvancedDisplayState,
  trendValues,
  heatLevel,
  shouldHideHeatBox,
  shouldHideTrendLine,
  trendValueCls = '',
  trenLineArray,
  screenWidth,
}) => {
  const {
    monthTrendValue,
    weekTrendValue,
    dayTrendValue,
    eightHoursTrendValue,
    fourHoursTrendValue,
    oneHourTrendValue,
  } = trendValues || {};
  const { monthHeat, weekHeat, dayHeat, eightHoursHeat, fourHoursHeat, oneHourHeat } =
    heatLevel || {};
  const {
    monthTrendValueItem,
    weekTrendValueItem,
    dayTrendValueItem,
    eightHoursTrendValueItem,
    fourHoursTrendValueItem,
    oneHourTrendValueItem,
  } = trenLineArray || {};
  let dataArrTrends = [];
  const lineDataArray: any[] = [];
  if (isAdvancedDisplayState) {
    dataArrTrends = [
      monthTrendValueItem,
      weekTrendValueItem,
      dayTrendValueItem,
      eightHoursTrendValueItem,
      fourHoursTrendValueItem,
      oneHourTrendValueItem,
    ];
    dataArrTrends = dataArrTrends.filter(Number);
    for (let index = 0; index < dataArrTrends.length; index++) {
      const element = dataArrTrends[index];
      const dataChart = {
        pv: element,
        name: 'Page ' + index,
        showarrow: false,
      };
      if (index === dataArrTrends.length - 1) {
        dataChart['showarrow'] = true;
      }
      lineDataArray.push(dataChart);
    }
  } else {
    dataArrTrends = [monthTrendValueItem, weekTrendValueItem, dayTrendValueItem];
    for (let index = 0; index < dataArrTrends.length; index++) {
      const element = dataArrTrends[index];
      const dataChart = {
        pv: element,
        name: 'Page ' + index,
        showarrow: false,
      };
      if (index === dataArrTrends.length - 1) {
        dataChart['showarrow'] = true;
      }
      lineDataArray.push(dataChart);
    }
  }
  const CustomizedDot = (props: any) => {
    const { cx, cy, payload } = props;

    if (payload.showarrow === true) {
      return (
        <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="white" viewBox="0 0 1024 1024">
          <path d="M256 102.4v819.2l512-409.6L256 102.4z" />
        </svg>
      );
    }

    return (
      <svg
        x={cx - 10}
        y={cy - 10}
        width={20}
        height={20}
        fill="green"
        viewBox="0 0 1024 1024"
      ></svg>
    );
  };
  return (
    <>
      {!shouldHideTrendLine && (
        <div className={'trends-graph-container'}>
          {lineDataArray.length > 0 && (
            <LineChart
              width={screenWidth * (lineDataArray.length - 1) + 30}
              height={30}
              data={lineDataArray}
              stackOffset="sign"
              margin={{
                top: 8,
                right: 20,
                left: 30,
                bottom: 5,
              }}
            >
              <Line
                isAnimationActive={false}
                type="monotone"
                dataKey="pv"
                stroke="#fff"
                strokeWidth={1}
                dot={<CustomizedDot />}
              />
            </LineChart>
          )}
        </div>
      )}
      <IonCol className="trend-column">
        {/* {!shouldHideTrendLine && <span className={`line-trends ${monthToWeekTrend}`} />} */}
        <div className="table-data-flex-container">
          {!shouldHideHeatBox && <HeatBox heatLevel={monthHeat} />}
          <span className={trendValueCls}>{monthTrendValue}</span>
        </div>
      </IonCol>
      <IonCol className="trend-column">
        {/* {!shouldHideTrendLine && (
          <span className={`line-trends ${weekToDayTrend} ${weekToDayTrend && !isAdvancedDisplayState ? 'arrow' : ''}`} />
        )} */}
        <div className="table-data-flex-container">
          {!shouldHideHeatBox && <HeatBox heatLevel={weekHeat} />}
          <span className={trendValueCls}>{weekTrendValue}</span>
        </div>
      </IonCol>
      <IonCol className="trend-column">
        {/* {!shouldHideTrendLine && isAdvancedDisplayState && <span className={`line-trends ${dayToEightHoursTrend}`} />} */}
        <div className="table-data-flex-container">
          {!shouldHideHeatBox && <HeatBox heatLevel={dayHeat} />}
          <span className={trendValueCls}>{dayTrendValue}</span>
        </div>
      </IonCol>
      {isAdvancedDisplayState && (
        <>
          <IonCol className="trend-column">
            {/* {!shouldHideTrendLine && <span className={`line-trends ${eightHoursToFourHoursTrend}`} />} */}
            <div className="table-data-flex-container">
              {!shouldHideHeatBox && <HeatBox heatLevel={eightHoursHeat} />}
              <span className={trendValueCls}>{eightHoursTrendValue}</span>
            </div>
          </IonCol>
          <IonCol className="trend-column">
            {/* {!shouldHideTrendLine && <span className={`line-trends ${fourHoursTrendToOneHourTrend} ${isAdvancedDisplayState ? 'arrow' : ''}`} />} */}
            <div className="table-data-flex-container">
              {!shouldHideHeatBox && <HeatBox heatLevel={fourHoursHeat} />}
              <span className={trendValueCls}>{fourHoursTrendValue}</span>
            </div>
          </IonCol>
          <IonCol className="trend-column">
            <div className="table-data-flex-container">
              {!shouldHideHeatBox && <HeatBox heatLevel={oneHourHeat} />}
              <span className={trendValueCls}>{oneHourTrendValue}</span>
            </div>
          </IonCol>
        </>
      )}
    </>
  );
};

const TrendRowContainer: React.FC<TrendRowContainerProps> = ({
  trendLabel,
  enableDarkBg,
  showInsightModal,
  children,
}) => {
  return (
    <div className={`trends-table-row ${enableDarkBg ? '' : 'gray-bg'}`}>
      <IonRow>
        {children}
        <IonCol className="ion-text-end trend-column">
          <span>{trendLabel}</span>
          <FontAwesomeIcon icon={faCircleInfo} className="info-icon" onClick={showInsightModal} />
        </IonCol>
      </IonRow>
    </div>
  );
};

const TrendRow: React.FC<TrendRowContainerProps> = (props) => {
  const {
    trendLabel,
    enableDarkBg,
    showInsightModal,
    lastJackpotAge,
    noJackpotsWithinMonth,
    isAdvancedDisplayState,
    trendLine,
    screenWidth,
    ...rest
  } = props;
  const { heatLevel, trendValues, snapshotLabel, insightId = '' } = rest;
  const parsedTrendValues = parseTrendValues({ ...trendValues, insightId }) || {};
  const trenLineArray = parseTrendValuesArray({ ...trendValues, insightId }) || {};
  const { monthTrendValue } = parsedTrendValues;

  const { monthHeat } = heatLevel || {};
  const shouldHideHeatBox = ['jackpot_value', 'jackpots'].includes(insightId);
  const shouldHideTrendLine = [
    'spins',
    'jackpot_value',
    'jackpots',
    'money_won',
    'money_played',
    'pop',
  ].includes(insightId);
  const trendValueCls = insightId === 'jackpots' ? 'jackpots-number-value' : '';

  const trendContainerProps = {
    trendLine,
    trendLabel,
    enableDarkBg,
    showInsightModal,
    trenLineArray,
    screenWidth,
  };

  if (['jackpot_value'].includes(insightId) && noJackpotsWithinMonth)
    return (
      <TrendRowContainer {...trendContainerProps}>
        <IonCol size="9">
          <div className="table-data-flex-container">
            <span>{lastJackpotAge}</span>
          </div>
        </IonCol>
      </TrendRowContainer>
    );

  if (insightId === 'jackpots' && noJackpotsWithinMonth) return null;

  if (insightId === 'pop')
    return (
      <TrendRowContainer {...trendContainerProps}>
        <IonCol size="9">
          <div className="table-data-flex-container">
            <span
              className="box-indicator"
              style={{
                background: `${heatBeanRatings[monthHeat as HeatBeanObjectKey].color}`,
              }}
            ></span>
            <span>{monthTrendValue}</span>
            <span className="pop-description">{snapshotLabel}</span>
          </div>
        </IonCol>
      </TrendRowContainer>
    );

  return (
    <TrendRowContainer {...trendContainerProps}>
      <TrendRowItem
        {...rest}
        trendLine={trendLine}
        shouldHideHeatBox={shouldHideHeatBox}
        trendValueCls={trendValueCls}
        shouldHideTrendLine={shouldHideTrendLine}
        trendValues={parsedTrendValues}
        trenLineArray={trenLineArray}
        isAdvancedDisplayState={isAdvancedDisplayState}
        screenWidth={screenWidth}
      />
    </TrendRowContainer>
  );
};

export default TrendRow;
