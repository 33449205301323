import { useIonModal } from '@ionic/react';
import { useEffect, useState, useCallback } from 'react';
import ReactJoyride, { EVENTS, Callback, ACTIONS } from 'react-joyride';
import { noop } from 'lodash';
import { IStep } from 'components/AppTour/tours';
import TourModal from './TourModal/TourModal';

interface AppTourProps {
  steps: IStep[];
  onTourClose?: () => void;
  tourTitle?: string;
  tourDescription?: string;
  continuous?: boolean;
  disableScrolling?: boolean;
  showProgress?: boolean;
  disableOverlay?: boolean;
  disableOverlayClose?: boolean;
  disableCloseOnEsc?: boolean;
  showSkipButton?: boolean;
  hasCompletedOnboarding?: boolean;
}

const AppTour: React.FC<AppTourProps> = ({
  steps = [],
  onTourClose = noop,
  tourTitle = 'Quick Click Tutorial',
  tourDescription = 'Let us help speed up your learning curve so you can get to the hot slots.',
  continuous = true,
  showProgress = true,
  disableOverlayClose = false,
  disableCloseOnEsc = false,
  disableScrolling = false,
  disableOverlay = false,
  showSkipButton = false,
  hasCompletedOnboarding = false,
}: AppTourProps) => {
  const [tourIsActive, setTourIsActive] = useState(false);

  const finishTour = () => {
    setTourIsActive(false);
    hideTourModal();
    onTourClose();
  };
  const startTour = () => {
    setTourIsActive(true);
    hideTourModal();
  };

  const [showTourModal, hideTourModal] = useIonModal(TourModal, {
    closeTour: () => finishTour(),
    startTour: () => startTour(),
    title: tourTitle,
    description: tourDescription,
  });

  const openTourModal = useCallback(() => {
    showTourModal({
      backdropDismiss: false,
      cssClass: 'tour-modal',
    });
  }, [showTourModal]);

  useEffect(() => {
    let isIgnore = false;
    if (steps && steps.length > 1 && !hasCompletedOnboarding) {
      if (!isIgnore) {
        openTourModal();
      }
    }
    return () => {
      isIgnore = true;
    };
  }, [steps, openTourModal]);

  const handleJoyrideCallback: Callback = ({ action, type }) => {
    if (type === EVENTS.TOUR_END || action === ACTIONS.CLOSE) {
      finishTour();
    }
  };

  return (
    <ReactJoyride
      steps={steps}
      run={tourIsActive}
      showSkipButton={showSkipButton}
      callback={handleJoyrideCallback}
      continuous={continuous}
      disableScrolling={disableScrolling}
      showProgress={showProgress}
      disableOverlay={disableOverlay}
      disableOverlayClose={disableOverlayClose}
      disableCloseOnEsc={disableCloseOnEsc}
    />
  );
};

export default AppTour;
