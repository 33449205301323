import { IonButton, IonCard, IonCardContent, IonCol, IonRow } from '@ionic/react';

interface TourModalProps {
  startTour: () => void;
  closeTour: () => void;
  title: React.ReactNode;
  description: React.ReactNode;
}

const TourModal: React.FC<TourModalProps> = ({
  closeTour,
  startTour,
  title,
  description,
}: TourModalProps) => {
  return (
    <IonCard id="tour-modal" className="modal-container app-tour-tooltip">
      <h5>{title}</h5>
      <IonCardContent>{description}</IonCardContent>
      <IonRow className="tour-modal-buttons">
        <IonCol>
          <IonButton
            expand="block"
            className="close-tour-btn"
            color="secondary"
            onClick={closeTour}
          >
            Maybe Later
          </IonButton>
        </IonCol>
        <IonCol>
          <IonButton expand="block" className="start-tour-btn" onClick={startTour}>
            Start
          </IonButton>
        </IonCol>
      </IonRow>
    </IonCard>
  );
};

export default TourModal;
