import { IonButton, IonCol, IonGrid, IonImg, IonRow, useIonModal } from '@ionic/react';
import TermsAndConditions from 'components/SignupModal/termsAndConditions/TermsAndConditions';
import { useHistory } from 'react-router-dom';

export const NewUser = () => {
  const history = useHistory();
  const [presentPrivacy, dismissPrivacy] = useIonModal(TermsAndConditions, {
    urlName: 'privacy',
    onClosePopUp: () => dismissPrivacy(),
    onDismiss: (data: string, role: string) => dismissPrivacy(data, role),
  });

  const [presentTerms, dismissTerms] = useIonModal(TermsAndConditions, {
    urlName: 'terms',
    onClosePopUp: () => dismissTerms(),
    onDismiss: (data: string, role: string) => dismissTerms(data, role),
  });
  const goToPrivacyPolicy = () => {
    presentPrivacy();
  };
  const goToTermsAndConditions = () => {
    presentTerms();
  };

  return (
    <div className="m-signup m-signup__main">
      <div>
        <IonGrid>
          <IonRow>
            <IonCol>
              <span className="heading5 primary-color-text">Welcome to</span>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonImg className="logo" src="assets/images/SLOTcheckTM.svg" />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <span className="heading5 primary-color-text action-bar-caption">
                Would you like to
              </span>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                className="create-btn btn"
                onClick={() => {
                  history.push('/auth/createaccount');
                }}
              >
                Create Free Account
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                className="login-btn btn"
                onClick={() => {
                  history.push('/auth/login');
                }}
              >
                Login
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <div className="footer-container slot-plan-terms primary-color-text">
        <div>
          <span className="primary-color-text">By selecting the plan above you accept our </span>
          <span className="body-bold underline" onClick={goToTermsAndConditions}>
            Terms & conditions
          </span>
          <span className="primary-color-text"> and </span>
          <span className="body-bold underline" onClick={goToPrivacyPolicy}>
            Privacy policy
          </span>
        </div>
      </div>
    </div>
  );
};
