import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IState } from 'appRedux/createStore';
import { FavoritesReduxCommandCreator } from 'appRedux/actions/favoritesCommandCreator';
import { playerTypeEnum } from 'common/commonEnum';
import { TimeframeFilterEnum } from 'appRedux/models/favoritesModels';
import { COLORS } from 'common/COLORS';

import DropDown from 'components/DropdownBar/dropDown/dropDown';
import FilterDropdownSelectOption from 'components/DropdownBar/dropDown/FilterDropdownSelectOption';

const timeframeOptions = [
  {
    label: '1 Hr',
    description: '1 Hr',
    value: TimeframeFilterEnum.OneHour,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
  {
    label: '4 Hrs',
    description: '4 Hrs',
    value: TimeframeFilterEnum.FourHours,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
  {
    label: '8 Hrs',
    description: '8 Hrs',
    value: TimeframeFilterEnum.EightHours,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
  {
    label: '24 Hrs',
    description: '24 Hrs',
    value: TimeframeFilterEnum.TwentyFourHours,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
  {
    label: 'Last 7 Days',
    description: 'Last 7 days',
    value: TimeframeFilterEnum.SevenDays,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
  {
    label: 'Last 30 Days',
    description: '30 Days',
    value: TimeframeFilterEnum.ThirtyDays,
    playerTypeAccess: [playerTypeEnum.slotCheck, playerTypeEnum.slotCheckPro],
  },
  {
    label: 'Since Last Jackpot (SLJ)',
    description: 'SLJ',
    value: TimeframeFilterEnum.SinceLastJackpot,
    playerTypeAccess: [playerTypeEnum.slotCheckPro],
  },
];

interface TimeframeFilterProps {
  removeSinceLastJackpot?: boolean;
}

const TimeframeFilter: React.FC<TimeframeFilterProps> = ({ removeSinceLastJackpot = false }) => {
  const dispatch = useDispatch();

  const favoritesCommands = FavoritesReduxCommandCreator(dispatch);
  const timeframeFilter = useSelector<IState>(
    (state) => state.app.persistedState.favorites.timeframeFilter
  );

  const handleChange = ({ value }: { value: TimeframeFilterEnum }) => {
    favoritesCommands.SetTimeframeFilter(value);
  };

  const options = removeSinceLastJackpot
    ? timeframeOptions.filter((options) => options.value !== TimeframeFilterEnum.SinceLastJackpot)
    : timeframeOptions;

  return (
    <div className="timeframe-selector drop-down-select-box filters-dropdown">
      <DropDown
        textAlign="right"
        displayDescription
        onChange={handleChange}
        value={timeframeFilter}
        defaultOption="pop"
        options={options}
        bgColor={COLORS.primaryColor}
        CustomSelectOption={FilterDropdownSelectOption}
      />
    </div>
  );
};

export default TimeframeFilter;
