export enum casinoListEnum {
  LOTC = 'lotc',
  EUREKA = 'eureka',
  RWC = 'rwc',
  SLOTCHECK = 'slotcheck',
}

export enum casinoIdListEnum {
  LOTC = 1,
  EUREKA = 2,
  RWC = 3
}

export enum playerTypeEnum {
  slotCheckPlus = 'SlotCheckPlus',
  slotCheckPro = 'SlotPro',
  slotCheck = 'SlotCheck',
}

export enum playerLevel {
  slotCheckPro = 2,
  slotCheck = 1,
}

export type playerType = keyof typeof playerTypeEnum;
export type casinoList = keyof typeof casinoListEnum;
export type casinoIdList = keyof typeof casinoIdListEnum;
