import { useIonToast } from '@ionic/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IRequestUpdateParam, PlayerService } from 'services/player';
import { ReduxCommandCreator } from 'appRedux/actions';
import { IState, IUserInfo } from 'appRedux/createStore';

import { ToastType } from '../../../components/ToastContainer/ToastContainer';
import { toastConfig } from '../../../config/ToastConfig';

export const useGenerateAlias = (): {
  generateNewAlias: () => void;
  updateUserDataPublicVisible: (isPublicAccount: boolean) => void;
  userAlias: string | undefined;
  userAliasInitial: string | undefined;
  isAliasLoading: boolean;
} => {
  const playerSvc = new PlayerService();
  const [isAliasLoading, setAliasLoading] = useState(false);
  const { aliasName, aliasInitial2, phone } = useSelector<IState>(
    (state) => state.app.persistedState.userInfo
  ) as IUserInfo;
  const casinoId = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema?.kpCasinoPk
  ) as number;
  const cellNumber = phone && phone.replace(/\+/g, '');
  const [presentToast] = useIonToast();
  const dispatch = useDispatch();
  const commands = ReduxCommandCreator(dispatch);

  const showToast = async ({
    type = 'success',
    message,
  }: {
    type?: ToastType;
    message: string;
  }) => {
    toastConfig.color = type;
    toastConfig['message'] = message;
    try {
      await presentToast(toastConfig);
    } catch (error) {
      // handle the error
    }
  };

  const updateUserData = (
    userAlias: string,
    userAliasInitial: string,
    userAliasInitial2: string
  ) => {
    if (cellNumber) {
      const requestBody: IRequestUpdateParam = {
        cellNumber: cellNumber.toString(),
        ...(userAlias && { aliasName: userAlias }),
        ...(userAliasInitial && { aliasInitial: userAliasInitial }),
        ...(userAliasInitial2 && { aliasInitial2: userAliasInitial2 }),
      };
      const playerSubscription = playerSvc.UpdateProfile(requestBody).subscribe({
        next() {
          void showToast({
            type: 'success',
            message: 'Successfully updated the profile.',
          });
        },
        error() {
          void showToast({ type: 'danger', message: 'Failed to update the data.' });
        },
        complete() {
          setAliasLoading(false);
        },
      });
      return () => {
        playerSubscription.unsubscribe();
      };
    }
  };

  const updateUserDataPublicVisible = (isPublicAccount: boolean) => {
    if (cellNumber) {
      setAliasLoading(true);
      const playerSubscription = playerSvc
        .UpdatePrivacy(cellNumber.toString(), isPublicAccount)
        .subscribe({
          next() {
            commands.SetUserPrivacy(isPublicAccount);
            getUserAlias(isPublicAccount);
            void showToast({ type: 'success', message: 'Privacy updated successfully!' });
          },
          error() {
            void showToast({ type: 'danger', message: 'Failed to update the privacy.' });
          },
          complete() {
            setAliasLoading(false);
          },
        });
      return () => {
        playerSubscription.unsubscribe();
      };
    }
  };

  const getUserAlias = (isPublicAccount: boolean) => {
    const playerSubscription = playerSvc.GetUserAlias(casinoId).subscribe({
      next(aliasResponse) {
        if (aliasResponse) {
          const { aliasInitial2, aliasInitial, aliasName } = aliasResponse.DataSet[0];

          const userAlias: Partial<IUserInfo> = {
            aliasInitial,
            aliasInitial2,
            aliasName,
          };
          commands.SetUserAlias(userAlias);
          if (isPublicAccount) {
            updateUserData(aliasName, aliasInitial, aliasInitial2);
          }
          void showToast({ type: 'success', message: 'Changes has been updated successfully!' });
        }
      },
      error() {
        void showToast({ type: 'danger', message: 'Failed to retrieve refetch alias name.' });
      },
      complete() {
        setAliasLoading(false);
      },
    });
    return () => {
      playerSubscription.unsubscribe();
    };
  };

  const generateNewAlias = () => {
    setAliasLoading(true);
    const playerSubscription = playerSvc.GenerateAlias().subscribe({
      next(aliasResponse) {
        if (aliasResponse) {
          const {
            alias_initial2: newAliasInitial2,
            alias_initial: newAliasInitial,
            alias_name: newAlias,
          } = aliasResponse.DataSet[0] || {};

          const newUserAlias: Partial<IUserInfo> = {
            aliasInitial: newAliasInitial,
            aliasInitial2: newAliasInitial2,
            aliasName: newAlias,
          };
          commands.SetUserAlias({
            ...newUserAlias,
          });
          updateUserData(newAlias, newAliasInitial, newAliasInitial2);
          void showToast({ message: 'New alias has been saved' });
        }
      },
      error() {
        void showToast({ type: 'danger', message: 'Failed to retrieve new alias name.' });
      },
      complete() {
        setAliasLoading(false);
      },
    });
    return () => {
      playerSubscription.unsubscribe();
    };
  };

  return {
    generateNewAlias,
    updateUserDataPublicVisible,
    userAlias : aliasName,
    userAliasInitial: aliasInitial2,
    isAliasLoading: isAliasLoading,
  };
};

export default useGenerateAlias;
