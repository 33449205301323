import { useIonToast } from '@ionic/react';
import { ReduxCommandCreator } from 'appRedux/actions';
import { checkProUser } from 'common/common';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { AuthService } from 'services/auth';

export const SubscriptionCancel = () => {
  const history = useHistory();
  const [presentToast] = useIonToast();
  const authService = new AuthService();
  const dispatch = useDispatch();
  const commands = ReduxCommandCreator(dispatch);

  useEffect(() => {
    authService.GetUserSubscriptionByCasinoId().subscribe({
      next(data) {
        if (data) {
          const { subscriptionData, customerData } = data;
          if (subscriptionData) {
            const subscriptionId = subscriptionData.id || '';
            const canceledAt = subscriptionData.cancel_at;
            const currentPeriodEnd = subscriptionData.current_period_end ?? 0;
            const subscriptionActive = subscriptionData.status || 'inactive';
            const planId = subscriptionData.plan?.id || '';
            const isProUser = checkProUser(subscriptionActive, currentPeriodEnd);
            const customerId = customerData?.id || '';
            commands.SetUserLevel({
              level: planId,
              customer_id: customerId,
              subscription_id: subscriptionId,
              current_period_end: currentPeriodEnd,
              canceled_at: canceledAt,
              isProUser,
            });
          }
        }
      },
      error(err) {}
    });

    void presentToast({
      position: 'top',
      duration: 3000,
      color: 'success',
      message: 'Subscription Cancelled Successfully!!',
    });
    history.push('/');
  }, []);
  return <div>NOW LOADING</div>;
};
