import { InsightValue } from '../common/types';

export interface HotListInsight {
  id: number;
  insight: InsightValue;
  value: string;
}

export const HotListTable = [
  {
    id: 0,
    value: 'Game Name',
    insight: 'game_name',
  },
  {
    id: 8,
    insight: 'return',
    value: '% Return',
  },
  {
    id: 3,
    insight: 'avg_money_won_per_spin',
    value: '$ Win per Spin',
  },
  
  {
    id: 7,
    insight: 'money_played',
    value: '$ Played',
  },
  {
    id: 1,
    insight: 'money_won',
    value: '$ Won',
  },
  {
    id: 2,
    insight: 'spins',
    value: '# Spins',
  },
  {
    id: 4,
    insight: 'win_rate',
    value: '% Winning Spins',
  },
  {
    id: 6,
    insight: 'jackpots',
    value: '# Jackpots',
  },
  {
    id: 5,
    insight: 'jackpot_value',
    value: '$ Jackpots',
  },
  {
    id: 9,
    insight: 'sleeper_slot_rating',
    value: 'Sleeper Slot Rating',
  },
  {
    id: 10,
    insight: 'volatility_rating',
    value: 'Volatility Rating',
  },
  {
    id: 11,
    insight: 'time_played',
    value: 'Time Played',
  },
  {
    id: 12,
    insight: 'session_played',
    value: 'Sessions',
  },
  {
    id: 13,
    insight: 'pop',
    value: 'Payout To Player (POP)',
  },
] as HotListInsight[];
  