import { useCallback, useEffect, useState } from 'react';
import { IonGrid, IonRow, IonCol, useIonToast } from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleUp, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import './graph-section.scss';
import SlotDataChart from '../SlotDataChart/SlotDataChart';
import PinSelectors from '../PinSelectors/PinSelectors';
import DropdownBar from '../DropdownBar/DropdownBar';
import { Insights } from 'config/Insights';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { IState, ITabProperties } from 'appRedux/createStore';
import {
  ICasinoReport,
  IGraphGameData,
  ISlotBucketData,
  ISlotGraphData,
} from 'appRedux/models/casinoModels';
import { CasinoService, IRequestCasinoParams, IRequestDetailParam } from 'services/casino';
import { FilterTypeEnum, IFilterType, OrderByEnum, getTimeFrameInfo } from 'common/common';
import { config } from 'config/config';
import { debounce } from 'lodash';
import { SectionID } from 'appRedux/reducers/slotReducer';

interface GraphSectionProps {
  slotNumber: number;
  playData: ICasinoReport;
  handleRearrangeComponent: () => void;
  rearrangeEmptyGraph: (slotPlayData: ICasinoReport[]) => void;
  onClickUpgradeNow: () => void;
}

const GraphSection: React.FC<GraphSectionProps> = ({
  slotNumber,
  playData,
  handleRearrangeComponent,
  rearrangeEmptyGraph,
  onClickUpgradeNow,
}) => {
  const casinoSvc = new CasinoService();
  const [presentToast] = useIonToast();
  const [collapse, setCollapse] = useState(true);
  const toggleCollapse = () => {
    setCollapse(!collapse);
  };
  const [finalJKPTData, setJKPTData] = useState<IGraphGameData[]>([]);
  const graphFilters = useSelector<IState>(
    (state) => state.app.persistedState.filterState[FilterTypeEnum.graphfilter as IFilterType]
  ) as ITabProperties;
  const { timeframe, insight } = graphFilters;
  const filterTime: any = getTimeFrameInfo(timeframe);
  const insightId = Insights.find((eachInsight) => eachInsight.value === insight)?.insightId;
  const casinoId = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema?.kpCasinoPk
  ) as number;
  const [graphData, setGraphData] = useState<ISlotGraphData[]>();
  const [bucketData, setBucketData] = useState<ISlotBucketData>();
  const [isSlotDataLoading, setIsSlotDataLoading] = useState(false);
  const [isSlotGraphDataLoading, setSlotGraphDataLoading] = useState(false);
  const [slotSplitData, setslotSplitData] = useState<IGraphGameData[]>([]);
  const [casinoParams, setCasinoParams] = useState<IRequestDetailParam>({
    casinofk: casinoId,
    timeframe: 6,
    insight: 'spins',
    slot: slotNumber,
    orderby: OrderByEnum.Desc,
  });

  const handleInputChange = (param: keyof IRequestCasinoParams, value: string | number) => {
    setCasinoParams((prevParams) => ({ ...prevParams, [param]: value }));
  };

  const fetchGraphBucketSlotReport = useCallback(
    (queryParams) => {
      setIsSlotDataLoading(true);
      casinoSvc.GetGraphBucketSlotReport(queryParams).subscribe({
        next(casinoResponse) {
          if (casinoResponse) {
            setBucketData(casinoResponse.totalJackpots?.[0] || null);
            setGraphData(casinoResponse.slotPlayData);
            rearrangeEmptyGraph(casinoResponse.slotPlayData);
          }
        },
        error() {
          presentToast({
            position: 'top',
            duration: 5000,
            color: 'danger',
            message: 'Failed to retrieve casino report.',
          }).catch(() => null);
        },
        complete() {
          setIsSlotDataLoading(false);
        },
      });
    },
    [casinoSvc]
  );

  const fetchGetGraphData = useCallback(
    (queryParams) => {
      setSlotGraphDataLoading(true);
      casinoSvc.GetGraphData(queryParams).subscribe({
        next(casinoResponse) {
          if (casinoResponse) {
            setslotSplitData(casinoResponse.DataSet);
          }
        },
        error() {
          presentToast({
            position: 'top',
            duration: 5000,
            color: 'danger',
            message: 'Failed to retrieve casino report.',
          }).catch(() => null);
        },
        complete() {
          setSlotGraphDataLoading(false);
        },
      });
    },
    [casinoSvc]
  );

  const requestGraphData = useCallback(
    (requestParams) => {
      fetchGetGraphData(requestParams);
    },
    [fetchGetGraphData]
  );

  useEffect(() => {
    if (bucketData && playData) {
      if (bucketData.bucket && insightId) {
        const requestParams = {
          slot_number: slotNumber,
          insight_filter: insightId,
          timeframe_filter: filterTime?.id,
          timebucket_id: filterTime?.graphtimeBucket,
          bucket_time: bucketData.bucket,
          casinofk: casinoId,
        };
        requestGraphData(requestParams);
      }
    }
  }, [bucketData]);

  useEffect(() => {
    if (graphData) {
      const finalData = slotSplitData.map((element) => {
        const gameBucketTime = element.game_time;

        const matchedData = graphData.find((data) => {
          const sourceBucketTime = data?.bucket;
          const dateBucket = moment(sourceBucketTime)
            .add(1, 'd')
            .subtract(1, 'milliseconds')
            .format();
          return moment(gameBucketTime).isBetween(sourceBucketTime, dateBucket);
        });

        return matchedData ? { ...element, ...matchedData } : element;
      });

      setJKPTData(finalData);
    }
  }, [graphData, slotSplitData]);

  const debouncedGetSlotDataReport = useCallback(
    debounce((casinoParams) => {
      fetchGraphBucketSlotReport(casinoParams);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedGetSlotDataReport(casinoParams);
    return () => {
      debouncedGetSlotDataReport.cancel();
    };
  }, [casinoParams, debouncedGetSlotDataReport]);

  useEffect(() => {
    handleInputChange('insight', insight);
  }, [insight]);

  useEffect(() => {
    const filterTime = config.allTimeFrames.find((item) => item.value === timeframe);
    if (filterTime) {
      handleInputChange('timeframe', filterTime.id);
    }
  }, [timeframe]);

  return (
    <IonGrid className="graph-section">
      <IonRow className="row-100">
        <IonCol size="12" className="graph-section-col">
          <div className="section-header">
            <div className="section-header-icon section-header-icon-collapse">
              <FontAwesomeIcon
                icon={collapse ? faAngleDoubleUp : faAngleDoubleDown}
                onClick={toggleCollapse}
              />
            </div>
            <div className="section-header-title">
              <DropdownBar id={FilterTypeEnum.graphfilter} isShowShortName={true} />
            </div>
          </div>
        </IonCol>
        <IonCol size="12" className={collapse ? '' : 'content-hide'}>
          <SlotDataChart
            timeframe={timeframe}
            insight={insight}
            slotPlayData={graphData}
            slotSplitData={finalJKPTData}
            onClickUpgradeNow={onClickUpgradeNow}
            isLoading={isSlotGraphDataLoading || isSlotDataLoading}
            bucketData={bucketData}
          />
        </IonCol>
        <IonCol size="12" className="ion-no-padding ion-text-center pin-section">
          <PinSelectors sectionId={SectionID.Graph} onClickItem={handleRearrangeComponent} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default GraphSection;
