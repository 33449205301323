import { IonButton, IonContent, IonHeader, IonImg, IonPage, IonToolbar } from '@ionic/react';
// import AWS from 'aws-sdk';
import { useEffect, useState } from 'react';
const S3_BUCKET = 'slotcheck-casinos';
const REGION = 'us-east-1';

// AWS.config.update({
//   accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
//   secretAccessKey: process.env.REACT_APP_SECRET_KEY,
// });

// const myBucket = new AWS.S3({
//   params: { Bucket: S3_BUCKET },
//   region: REGION,
// });

export const CasinoAdminDashboard = () => {
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileDataURL, setFileDataURL] = useState(null);
  const [selectedColor, setSelectedColor] = useState('#ddd');

  // const handleFileInput = (e: { target: { files: any } }) => {
  //   if (e.target && e.target.files.length > 0) {
  //     const fileData = e.target.files[0];
  //     console.log(fileData);
  //     setSelectedFile(fileData);
  //   }
  // };

  // useEffect(() => {
  //   let fileReader: FileReader,
  //     isCancel = false;
  //   if (selectedFile) {
  //     fileReader = new FileReader();
  //     fileReader.onload = (e: any) => {
  //       const { result } = e.target || null;
  //       if (result && !isCancel) {
  //         setFileDataURL(result);
  //       }
  //     };
  //     fileReader.readAsDataURL(selectedFile);
  //   }
  //   return () => {
  //     isCancel = true;
  //     if (fileReader && fileReader.readyState === 1) {
  //       fileReader.abort();
  //     }
  //   };
  // }, [selectedFile]);

  // const uploadFile = (file: { name: string }) => {
  //   const params = {
  //     ACL: 'public-read',
  //     Body: file,
  //     Bucket: S3_BUCKET,
  //     Key: file.name,
  //   };

  //   myBucket
  //     .putObject(params)
  //     .on('httpUploadProgress', (evt) => {
  //       setProgress(Math.round((evt.loaded / evt.total) * 100));
  //     })
  //     .send((err) => {
  //       if (err) console.log(err);
  //     });
  // };
  const removeUpload = () => {
    setFileDataURL(null);
    setSelectedFile(null);
  };

  return (
    <IonPage className="app-parent">
      <div className="slotcheck-header">
        <IonHeader className="small-header">
          <IonToolbar>
            <IonButton
              fill="clear"
              onClick={() => {
                history.back();
              }}
              className="icon-back"
              slot="start"
            >
              <IonImg src="assets/images/back-icon.svg" />
            </IonButton>
            <div className="logo header-title">Casino Admin</div>
          </IonToolbar>
        </IonHeader>
      </div>
      <IonContent>
        <div className="content admin__content">
          <div className="casino-admin">
            <h6>Casino Logo</h6>
            <div className="file-upload">
              <div className="image-upload-wrap">
                {!fileDataURL && (
                  <>
                    <input
                      className="file-upload-input"
                      type="file"
                      // onChange={handleFileInput}
                      accept="image/*"
                    />
                    <div className="drag-text">Add Casino Image Here</div>
                  </>
                )}
                {fileDataURL ? (
                  <p className="img-preview-wrapper">{<img src={fileDataURL} alt="preview" />}</p>
                ) : null}
                {fileDataURL && (
                  <button
                    type="button"
                    onClick={() => {
                      removeUpload();
                    }}
                    className="remove-image"
                  >
                    Remove <span className="image-title">Image</span>
                  </button>
                )}
              </div>
            </div>

            <h6>Casino Theme</h6>
            <input
              type="color"
              id="head"
              name="head"
              value={selectedColor}
              onChange={(e) => setSelectedColor(e.target.value)}
              className="casino-theme"
            />

            <div className="app-form-button">
              {/* <IonButton onClick={() => selectedFile && uploadFile(selectedFile)}>
                Save Changes
              </IonButton> */}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
