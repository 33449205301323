import { OrderByEnum, OrderValueEnum } from 'common/common';
import { arrowDownOutline, arrowUpOutline } from 'ionicons/icons';
import { useState } from 'react';

const useLeaderboardFilters = () => {
  const [sortBy, setSortBy] = useState<OrderByEnum>(OrderByEnum.Asc);
  const [activeFilter, setActiveFilter] = useState('rank');

  const isAliasFilterActive = activeFilter === 'alias';
  const isRankFilterActive = activeFilter === 'rank';

  const getNewAliasFilterValue = () =>
    sortBy === OrderByEnum.Desc ? OrderByEnum.Asc : OrderByEnum.Desc;

  const getNewRankFilterValue = () =>
    sortBy === OrderByEnum.Desc ? OrderByEnum.Asc : OrderByEnum.Desc;

  const getRankFilterIcon = () => {
    if (sortBy === null) return arrowUpOutline;
    return sortBy === OrderByEnum.Desc ? arrowUpOutline : arrowDownOutline;
  };

  const getAliasFilterIcon = () => {
    if (sortBy === null) return arrowUpOutline;
    return sortBy === OrderByEnum.Asc ? arrowUpOutline : arrowDownOutline;
  };

  const toggleRankFilter = () => {
    setActiveFilter('rank');
    setSortBy(getNewRankFilterValue());
  };

  const toggleAliasFilter = () => {
    setActiveFilter('alias');
    setSortBy(getNewAliasFilterValue());
  };

  const handleResetFilters = () => {
    setActiveFilter('rank');
    setSortBy(OrderByEnum.Asc);
  };

  return {
    sortBy,
    activeFilter,
    toggleRankFilter,
    toggleAliasFilter,
    handleResetFilters,
    getAliasFilterIcon,
    getRankFilterIcon,
    isRankFilterActive,
    isAliasFilterActive,
  };
};

export default useLeaderboardFilters;
