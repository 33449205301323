import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import clsx from 'clsx';
import { HotListInsight } from 'config/HotListTable';
import { config } from 'config/config';
import { InsightValue } from 'common/types';
import { OrderByEnum } from 'common/common';
import { useSelector } from 'react-redux';
import { IState } from 'appRedux/createStore';

interface HotListHeaderColumnProps {
  onSelect: (rowData: HotListInsight) => void;
  selectedInsight: InsightValue;
  totalCount: string;
  advancedViewInsights?: InsightValue[];
  activeInsightFilter: InsightValue | null;
  listType: string;
  isAdvancedDisplayState?: boolean;
  sortBy: OrderByEnum;
  sortOrder: string;
}

const HotListHeader: FC<HotListHeaderColumnProps> = ({
  onSelect,
  selectedInsight,
  advancedViewInsights,
  activeInsightFilter,
  listType,
  isAdvancedDisplayState,
  sortBy,
  sortOrder,
}) => {
  const [activeFilter, setActiveFilter] = useState<InsightValue>(selectedInsight);
  const isTopTenSlot = useSelector<IState>(
    (state) => state.app.persistedState.isSlotOrderAscending
  ) as boolean;

  const [activeSortingDirection, setActiveSortingDirection] = useState(
    isTopTenSlot ? OrderByEnum.Desc : OrderByEnum.Asc
  );

  let classes = 'hot-list__col-header';
  let classes_total = '';
  classes += ' click-target';

  const headerItems = isAdvancedDisplayState
    ? advancedViewInsights?.map((insightValue) => {
        return config.hotListTableRow.find((insight) => insight.insight === insightValue);
      })
    : [config.hotListTableRow.find(({ insight }) => insight === selectedInsight)];

  const handleHeaderItemClick = (rowData: HotListInsight) => {
    const { insight } = rowData;

    if (activeFilter === insight) {
      const newDirection =
        activeSortingDirection === OrderByEnum.Desc ? OrderByEnum.Asc : OrderByEnum.Desc;
      setActiveSortingDirection(newDirection);
    }

    onSelect(rowData);
    setActiveFilter(insight);
  };

  const getSortIcon = (column: string): JSX.Element | null => {
    if (column === sortOrder) {
      return activeSortingDirection === OrderByEnum.Asc ? (
        <FontAwesomeIcon icon={faCaretUp} className="sort-arrow-insights insight-name-sort-icon" />
      ) : (
        <FontAwesomeIcon
          icon={faCaretDown}
          className="sort-arrow-insights insight-name-sort-icon"
        />
      );
    }
    return null;
  };

  return (
    <>
      {headerItems?.map((rowData: HotListInsight | undefined) => {
        const hotlistDetail = config.slotInsights.find(
          (insightItem) => insightItem.value === rowData!.insight
        )!;

        if (selectedInsight === rowData?.insight) {
          if (listType !== 'myJackpot') {
            classes += ' hot-list__active-col';
            classes_total += ' hot-list__activetotal-col';
          } else {
            classes_total += ' hot-list__activetotal-col';
          }
        } else {
          classes_total += ' hot-list__total-col';
        }

        if (!isAdvancedDisplayState) {
          return (
            <div
              key={`${rowData?.insight || ''}-${
                isAdvancedDisplayState ? 'is-advanced-view' : 'is-standard-view'
              }`}
              className={clsx('hot-list-row', classes)}
              onClick={() => onSelect(rowData!)}
            >
              <span className={classes_total}>
                {hotlistDetail?.insightFieldsSelectorLabel}
                {getSortIcon(hotlistDetail.field)}
              </span>
            </div>
          );
        } else {
          return (
            <div
              key={`${rowData?.insight || ''}-${
                isAdvancedDisplayState ? 'is-advanced-view' : 'is-standard-view'
              }`}
              className={clsx('hot-list-row', classes)}
              onClick={() => handleHeaderItemClick(rowData!)}
            >
              <div className="hot-list-row">
                <div className="game-data-td-report">
                  <span className="row-insight-value">
                    {hotlistDetail.insightFieldsSelectorLabel}
                    {activeInsightFilter === rowData!.insight && getSortIcon(activeInsightFilter)}
                  </span>
                </div>
              </div>
            </div>
          );
        }
      })}
    </>
  );
};

export default HotListHeader;
