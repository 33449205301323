import { INVALID_EMAIL_MESSAGE } from '../consts';
import { InputChangeEventDetail, useIonToast } from '@ionic/react';
import { SetStateAction, useState } from 'react';
import { validateEmail } from '../../../common/include';
import { toastConfig } from '../../../config/ToastConfig';
import { ToastType } from '../../../components/ToastContainer/ToastContainer';
import {
  ALL_REQUIRED_FIELDS_MESSAGE,
  EMAIL_CANNOT_BE_EMPTY_MESSAGE,
  FIRST_NAME_CANNOT_BE_EMPTY_MESSAGE,
  LAST_NAME_CANNOT_BE_EMPTY_MESSAGE,
} from '../consts';
import { IRequestUpdateParam, PlayerService } from 'services/player';
import { IState } from 'appRedux/createStore';
import { useDispatch, useSelector } from 'react-redux';
import { IUserInfo } from 'services/auth';
import { ReduxCommandCreator } from 'appRedux/actions';

const defaultErrorsState = {
  firstNameError: '',
  lastNameError: '',
  emailAddressError: '',
};

const useEditProfileForm = () => {
  const userData = useSelector<IState>((state) => state.app.persistedState.userInfo) as IUserInfo;
  const playerSvc = new PlayerService();
  const dispatch = useDispatch();
  const commands = ReduxCommandCreator(dispatch);
  const [firstName, setFirstName] = useState(userData?.firstName);
  const [lastName, setLastName] = useState(userData?.lastName);
  const [cellNumber] = useState(userData?.phone);
  const [email, setEmail] = useState(userData?.emailAddress);
  const [birthday, setBirthday] = useState(userData?.birthday);
  const [errors, setErrors] = useState(defaultErrorsState);
  const [isUpdatingUserData, setUpdatingUserData] = useState(false);

  const [presentToast] = useIonToast();

  // TODO: make useToast hook
  const showToast = ({ type = 'success', message }: { type?: ToastType; message: string }) => {
    toastConfig.color = type;
    toastConfig['message'] = message;
    void presentToast(toastConfig);
  };

  const handleFirstNameChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setFirstName(e.detail.value || '');
    if (!e.detail.value)
      setErrors({ ...errors, firstNameError: FIRST_NAME_CANNOT_BE_EMPTY_MESSAGE });
    else setErrors({ ...errors, firstNameError: '' });
  };

  const handleLastNameChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setLastName(e.detail.value || '');
    if (!e.detail.value) setErrors({ ...errors, lastNameError: LAST_NAME_CANNOT_BE_EMPTY_MESSAGE });
    else setErrors({ ...errors, lastNameError: '' });
  };

  const handleEmailChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setEmail(e.detail.value || '');
    if (!e.detail.value) setErrors({ ...errors, emailAddressError: EMAIL_CANNOT_BE_EMPTY_MESSAGE });
    else setErrors({ ...errors, emailAddressError: '' });
  };

  const handleBirthdayChange = (e: { detail: { value: SetStateAction<string | undefined> } }) =>
    setBirthday(e.detail.value);

  const updateUserData = () => {
    if (cellNumber) {
      setUpdatingUserData(true);
      const requestBody: IRequestUpdateParam = {
        cellNumber: cellNumber.toString(),
        ...(firstName && { firstName: firstName }),
        ...(lastName && { lastName: lastName }),
        ...(email && { emailAddress: email }),
      };
      playerSvc.UpdateProfile(requestBody).subscribe({
        next() {
          const userInformation: Partial<IUserInfo> = {
            ...requestBody,
          };
          commands.SetUserInformation({
            ...userInformation,
          });
          showToast({ message: 'Profile information has been updated' });
        },
        error(err) {
          void showToast({ type: 'danger', message: 'Something went wrong' });
        },
        complete() {
          setUpdatingUserData(false);
        },
      });
    }
  };

  const handleEditProfile = () => {
    if (!firstName || !lastName) {
      showToast({ type: 'danger', message: ALL_REQUIRED_FIELDS_MESSAGE });
      setErrors({
        firstNameError: firstName ? '' : FIRST_NAME_CANNOT_BE_EMPTY_MESSAGE,
        lastNameError: lastName ? '' : LAST_NAME_CANNOT_BE_EMPTY_MESSAGE,
        emailAddressError: email ? '' : EMAIL_CANNOT_BE_EMPTY_MESSAGE,
      });
    } else {
      if (email) {
        const isEmailValid = validateEmail(email);
        if (isEmailValid) {
          void updateUserData();
        } else {
          showToast({ type: 'danger', message: INVALID_EMAIL_MESSAGE });
        }
      } else {
        void updateUserData();
      }
    }
  };

  const formHasChanges =
    firstName !== userData?.firstName ||
    lastName !== userData?.lastName ||
    email !== userData?.emailAddress;
  const isSubmitDisabled = !formHasChanges;

  return {
    firstName,
    lastName,
    cellNumber: `${cellNumber}`,
    email,
    formErrors: errors,
    birthday,
    isSubmitDisabled,
    isUpdatingUserData,
    handleFirstNameChange,
    handleLastNameChange,
    handleEmailChange,
    handleBirthdayChange,
    handleEditProfile,
  };
};

export default useEditProfileForm;
