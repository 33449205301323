import { IFloormapState } from 'appRedux/createStore';
import { IReducer } from './moduleReducerRegistry';
import {
  LatitudeLongitudeType,
  floormapActionTypes,
} from 'appRedux/actions/floormapCommandCreator';
import { heatLegendAllInsight } from 'common/common';

export const floormapInitialState: IFloormapState = {
  heatFilter: heatLegendAllInsight,
  bearing: 0,
  zoom: 14,
  latitudeLongitude: undefined,
};

export const floormapReducer: IReducer = (draft, action) => {
  const { type } = action;
  switch (type) {
    case floormapActionTypes.SET_MAP_VALUES:
      const {
        bearing: bearingValue,
        zoom: zoomValue,
        latitudeLongitude: latitudeLongitudeValue,
      } = action.state;
      if (draft.app.persistedState.floormapState) {
        draft.app.persistedState.floormapState.bearing = bearingValue;
        draft.app.persistedState.floormapState.zoom = zoomValue;
        draft.app.persistedState.floormapState.latitudeLongitude = latitudeLongitudeValue;
      }
      break;
    case floormapActionTypes.SET_DEFAULT_HEAT_FILTER:
      const heatFilter = action.state as number[];
      draft.app.persistedState.floormapState.heatFilter = heatFilter;
      break;
    case floormapActionTypes.SET_HEAT_FILTER:
      if (draft.app.persistedState.floormapState.heatFilter) {
        const selectedHeatFilter = action.state as number;
        const existingFilter = draft.app.persistedState.floormapState.heatFilter;
        const doesExistInFavorites = existingFilter.some(
          (favorite) => favorite === selectedHeatFilter
        );
        if (!doesExistInFavorites) {
          draft.app.persistedState.floormapState.heatFilter.push(selectedHeatFilter);
        } else {
          draft.app.persistedState.floormapState.heatFilter =
            draft.app.persistedState.floormapState.heatFilter.filter(
              (favorite) => favorite !== selectedHeatFilter
            );
        }
      }
      break;
    case floormapActionTypes.SET_BEARING:
      const bearing = action.state as number;
      draft.app.persistedState.floormapState.bearing = bearing;
      break;
    case floormapActionTypes.SET_ZOOM:
      const zoom = action.state as number;
      draft.app.persistedState.floormapState.zoom = zoom;
      break;
    case floormapActionTypes.SET_LATITUDE_LONGITUDE:
      const latitudeLongitude  = action.state as LatitudeLongitudeType;
      draft.app.persistedState.floormapState.latitudeLongitude =
        latitudeLongitude as LatitudeLongitudeType;
      break;
  }
};
