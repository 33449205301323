import { IonContent, IonPage, useIonModal, useIonToast } from '@ionic/react';
import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { lastValueFrom } from 'rxjs';

import { ReduxCommandCreator } from 'appRedux/actions';
import { useAppSelector } from 'appRedux/createStore';
import { AUTH_STATE } from 'appRedux/types/reduxTypes';
import { CasinoReduxCommandCreator } from 'appRedux/actions/casinoCommandCreator';
import { AuthService } from 'services/auth';

import LinkPlayerCardModal from 'pages/MyPlayerCards/LinkPlayerCardModal';
import SmartLoading from 'components/SmartLoading';
import ReasonToLinkPlayerCard from './LinkPlayerModal';

interface ICasinoParams {
  casinoIDParam: string;
}

export const LinkPlayer: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = new AuthService();

  const commands = ReduxCommandCreator(dispatch);
  const casinoCommands = CasinoReduxCommandCreator(dispatch);

  const { casinoIDParam } = useParams<ICasinoParams>();
  const casinoId = parseInt(casinoIDParam) || 1;

  const [presentToast] = useIonToast();
  const [isLoading, setIsLoading] = useState(false);

  const [hasCasinoAccess, setHasCasinoAccess] = useState(false);
  const [timeLeft, setTimeLeft] = useState<number | null>(null);

  const { userId, casinoSchema } = useAppSelector((state) => ({
    userId: state.app.persistedState.userInfo?.appUserPk,
    casinoSchema: state.app.persistedState.casinoSchema,
  }));

  const [showReasonToLinkModal, closeReasonToLinkModal] = useIonModal(ReasonToLinkPlayerCard, {
    onLinkClick: () => void openLinkCardModal(),
    onCancelClick: () => {
      closeReasonToLinkModal();
      onCancelLinkPlayerCard();
    },
    timeLeft,
  });

  const onLinkPlayerCardSubmitSuccess = async () => {
    await handleSuccessResponse();
  };

  const onCancelLinkPlayerCard = () => {
    if (hasCasinoAccess) {
      history.replace('/tabs/home');
    } else {
      presentToast({
        position: 'top',
        duration: 5000,
        color: 'danger',
        message: 'Player ID must be linked for access to each casino.',
      }).catch(() => null);
      history.replace('/auth/choosecasinos');
    }

    closeLinkModal();
  };

  const [showLinkModal, closeLinkModal] = useIonModal(LinkPlayerCardModal, {
    onSubmitSuccess: () => void onLinkPlayerCardSubmitSuccess(),
    onCancel: () => onCancelLinkPlayerCard(),
    casinoInfo: casinoSchema,
  });

  const openLinkCardModal = () => {
    showLinkModal({
      backdropDismiss: true,
      cssClass: 'tour-modal',
    });
  };

  const openLinkModals = (casinoAccess: boolean) => {
    if (casinoAccess) {
      showReasonToLinkModal({
        backdropDismiss: true,
        cssClass: 'tour-modal',
      });
    } else {
      openLinkCardModal();
    }
  };

  const handleSuccessResponse = async () => {
    commands.SetAuthState(AUTH_STATE.AUTHENTICATED);

    try {
      if (userId) {
        const data = await lastValueFrom(auth.GetLinkedAccounts(userId));

        if (data) {
          const linkedAccount = data.user.linkedAccounts;
          const casinoItem = linkedAccount?.find((item) => item.casinoId === casinoId);
          const isPlayerLinked = Boolean(casinoItem);
          const linkedCasinoId = casinoItem ? casinoItem.casinoId : null;

          casinoCommands.SetLinkedCasinos({
            casinoLinkedAccounts: linkedAccount,
            isPlayerLinked,
            linkedCasinoId,
          });

          history.replace('/tabs/home');
        }
      } else {
        throw new Error('no user id');
      }
    } catch (err) {
      presentToast({
        position: 'top',
        duration: 5000,
        color: 'danger',
        message: 'Failed to retrieve linked accounts.',
      }).catch(() => null);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        setIsLoading(true);

        const userInfo = await lastValueFrom(auth.GetUserInfo([casinoId]));

        if (userInfo && isMounted) {
          const { casinoAccess } = userInfo.user;

          // eslint-disable-next-line security/detect-object-injection
          const casinoAccessInfo = casinoAccess[casinoId];

          setHasCasinoAccess(casinoAccessInfo.hasAccess);
          setTimeLeft(casinoAccessInfo.timeLeft);

          openLinkModals(casinoAccessInfo.hasAccess);
        }
      } catch {
        if (isMounted) {
          presentToast({
            position: 'top',
            duration: 5000,
            color: 'danger',
            message: 'Failed to retrieve casino information.',
          }).catch(() => null);
        }
      }
    };

    if (isMounted) {
      void fetchData();
    }

    return () => {
      isMounted = false;
      closeLinkModal();
    };
  }, []);

  return (
    <IonPage>
      <IonContent>
        <SmartLoading loading={isLoading} />
        <div className="content light-theme"></div>
      </IonContent>
    </IonPage>
  );
};
