import { IPersistedState } from './reduxStore/createStore';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
/**
 * Load state from local storage and merge it with the initial state or return
 * initial state
 * @param {IPersistedState} initialState - The initial state of the application
 * @returns {IPersistedState} - The merged state
 */

const customMerge = (initialState: IPersistedState, persistedState: IPersistedState) => {
  return mergeWith(initialState, persistedState, (objValue, srcValue, key) => {
    if (isArray(objValue) && key === 'advancedViewInsights') {
      if (isEqual(objValue, srcValue)) {
        return objValue;
      }
      return srcValue;
    }
    return undefined;
  });
};

export const loadState = (initialState: IPersistedState) => {
  try {
    const serialState = window.localStorage.getItem('appState');
    if (!serialState) {
      return initialState;
    }
    const persistedState = JSON.parse(serialState) as IPersistedState;
    return customMerge(initialState, persistedState);
  } catch (err) {
    console.error('Error loading state from local storage:', err);
    return initialState;
  }
};

/**
 * Save state to local storage
 * @param {IPersistedState} persistedState - The state to be saved
 */
export const saveState = (persistedState: IPersistedState): void => {
  try {
    const serialState = JSON.stringify(persistedState);

    window.localStorage.setItem('appState', serialState);
  } catch (err) {
    console.error('Error saving state to local storage:', err);
  }
};

/**
 * Delete state from local storage
 */
export const deleteState = (): void => {
  try {
    window.localStorage.removeItem('appState');
  } catch (err) {
    console.error('Error deleting state from local storage:', err);
  }
};

export const clearState = (): void => {
  try {
    window.localStorage.clear();
  } catch (err) {
    console.error('Error deleting state from local storage:', err);
  }
};
