import { IonList, IonLabel, IonIcon, IonItem } from '@ionic/react';
import { FC } from 'react';

interface MenuPage {
  title: string;
  icon: string;
  path: string;
  badge?: number;
  selected: string;
  id?: string;
}

interface MenuListSectionProps {
  className?: string;
  pages: MenuPage[];
  signOut: () => void;
  onRouteChange: () => void;
  id?: string;
}

const MenuListSection: FC<MenuListSectionProps> = ({
  className,
  pages,
  signOut,
  onRouteChange,
  id = '',
}) => {
  const isFreePlayCredit = true;
  const isRedeemed = false;

  return (
    <IonList lines="none" className={`menu-list${className ? ` ${className}` : ''}`}>
      {pages
        .filter((route) => route.path != null)
        .map((page) => {
          if (page.path === '/logout') {
            return (
              <IonItem key={page.title} id={id} onClick={signOut}>
                <IonLabel className="lbl-white">
                  <IonIcon icon={page.icon} className="sidemenu-icon" />
                  <span className="sidemenu-text">{page.title}</span>
                </IonLabel>
              </IonItem>
            );
          } else {
            return (
              <IonItem key={page.title} id={id} routerLink={page.path} onClick={onRouteChange}>
                <IonLabel className="lbl-white">
                  <IonIcon icon={page.icon} className="sidemenu-icon" />
                  <span className="sidemenu-text">{page.title}</span>

                  {page?.title === 'Free Play' &&
                    isFreePlayCredit === true &&
                    isRedeemed === false && <span className="notification-bubble">1</span>}
                </IonLabel>
              </IonItem>
            );
          }
        })}
    </IonList>
  );
};

export default MenuListSection;
