import { addMapImage } from './lotc/mapboxImageLoad';
import { addMapEurekaImage } from './eureka/mapboxImageLoad';
import { addMapRWCImage } from './rwc/mapboxImageLoad';
import { useEffect, useState } from 'react';

const fetchGeoJSON = async (casinoName: string, fileName: string) => {
  const url = `https://slotcheck-floorplan.s3.us-east-2.amazonaws.com/${casinoName}/${fileName}`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch ${fileName}: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error loading GeoJSON data:', error);
    return null;
  }
};

const useMapData = (casinoId: number) => {
  const [wallMapData, setWallMapData] = useState(null);
  const [labelMapData, setLabelMapData] = useState(null);
  const [bankIdMapData, setBankIdMapData] = useState(null);
  const [slotsMapData, setSlotsMapData] = useState(null);

  useEffect(() => {
    let isCancelled = false; 
    const loadData = async () => {
      try {
        let wallMapData, labelMapData, bankIdMapData, slotsMapData;

        switch (casinoId) {
          case 1:
            wallMapData = await fetchGeoJSON('lotc', 'lott-walls.json');
            labelMapData = await fetchGeoJSON('lotc', 'lott-labels.json');
            bankIdMapData = await fetchGeoJSON('lotc', 'lott-bank-ids.json');
            break;
          case 2:
            wallMapData = await fetchGeoJSON('eureka', 'eureka-walls.json');
            labelMapData = await fetchGeoJSON('eureka', 'eureka-labels.json');
            bankIdMapData = await fetchGeoJSON('eureka', 'eureka-bank-ids.json');
            break;
          case 3:
            wallMapData = await fetchGeoJSON('rwc', 'rwc-walls.json');
            labelMapData = await fetchGeoJSON('rwc', 'rwc-labels.json');
            bankIdMapData = await fetchGeoJSON('rwc', 'rwc-bank-ids.json');
            break;
          default:
            wallMapData = await fetchGeoJSON('lotc', 'lott-walls.json');
            labelMapData = await fetchGeoJSON('lotc', 'lott-labels.json');
            bankIdMapData = await fetchGeoJSON('lotc', 'lott-bank-ids.json');
            break;
        }

        if (!isCancelled) {
          setWallMapData(wallMapData);
          setLabelMapData(labelMapData);
          setBankIdMapData(bankIdMapData);
          if (slotsMapData) {
            setSlotsMapData(slotsMapData);
          }
        }
      } catch (error) {
        if (!isCancelled) {
          console.error('Error fetching data:', error);
        }
      }
    };

    void loadData();
    return () => {
      isCancelled = true; 
    };
  }, [casinoId]);

  return {
    wallMapData,
    labelMapData,
    bankIdMapData,
    addMapImage,
    addMapRWCImage,
    addMapEurekaImage,
    slotsMapData,
  };
};
export default useMapData;
