import { casinoActionTypes } from 'appRedux/actions/casinoCommandCreator';
import { COLORS } from 'common/COLORS';
import { IReducer } from './moduleReducerRegistry';

import { ICasinoSchema, ILinkPlayerInfo } from 'appRedux/models/casinoModels';
import { ICasinoSearchData } from 'appRedux/createStore';

export const casinoInitialState = {
  casinoName: 'lotc',
  casinoId: 1,
  casinoCode: '01',
  displayName: 'Lake of the Torches Resort Casino',
  kpCasinoPk: 1,
  casinoTimezoneNew: '',
  contact: 'tel:18002586724',
  website: 'https://lakeofthetorches.com',
  logos: {
    navBar: 'assets/images/LOTC.svg',
  },
  styles: { backgroundColor: COLORS.primaryColor, color: COLORS.white },
  floorMaps: {
    center: {
      x: 0.025422708161187302,
      y: 10.011562325911456,
    },
  },
  priceCodes: {
    monthly: __PROPRICECODE__,
    yearly: __PROPRICECODEYEARLY__,
  },
  address: {
    city: 'LAC DU FLAMBEAU',
    country: 'UNITED STATES',
    displayAddress: 'Lac Du Flambeau, Wisconsin',
    state: 'WI',
    zip: 54538,
    mapsLink:
      'https://www.google.com/maps/dir//Lake+of+the+Torches+Resort+Casino+510+Old+Abe+Rd+Lac+Du+Flambeau,+WI+54538+United+States/@45.9741614,-89.8917995,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x4d55f3d0e03d2df1:0x812ed45bb04baf75',
  },
};

export const casinoReducer: IReducer = (draft, action) => {
  const { type, state } = action;

  switch (type) {
    case casinoActionTypes.SET_CASINO_SEARCH_DATA:
      draft.app.persistedState.casinoSearchData = state as ICasinoSearchData;

      break;
    case casinoActionTypes.SET_CASINO_SCHEMA_STATE:
      draft.app.persistedState.casinoSchema = state as ICasinoSchema;

      break;

    case casinoActionTypes.SET_LINKED_PLAYER_STATE:
      draft.app.persistedState.linkPlayerState = {
        ...draft.app.persistedState.linkPlayerState,
        ...(state as ILinkPlayerInfo),
      };
      break;
  }
};
