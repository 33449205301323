import { Dispatch } from 'react';
import { IAction } from 'appRedux/reducers';
const floormapReduxKey = 'FLOORMAP';

export const floormapActionTypes = {
  SET_MAP_VALUES: `${floormapReduxKey}-SET_MAP_VALUES`,
  SET_HEAT_FILTER: `${floormapReduxKey}-SET_HEAT_FILTER`,
  SET_DEFAULT_HEAT_FILTER: `${floormapReduxKey}-SET_DEFAULT_HEAT_FILTER`,
  SET_BEARING: `${floormapReduxKey}-SET_BEARING`,
  SET_ZOOM: `${floormapReduxKey}-SET_ZOOM`,
  SET_LATITUDE_LONGITUDE: `${floormapReduxKey}-SET_LATITUDE_LONGITUDE`,
};

interface LatitudeLongitude {
  latitude: number;
  longitude: number;
}

export type LatitudeLongitudeType = LatitudeLongitude | undefined;

export const FloormapReduxCommandCreator = (dispatch: Dispatch<IAction>) => {
  return {
    setMapValues(state: {
      heatFilter?: number;
      bearing: number;
      zoom: number;
      latitudeLongitude: LatitudeLongitudeType;
    }) {
      dispatch({
        type: floormapActionTypes.SET_MAP_VALUES,
        state,
      });
    },
    SetDefaultHeatFilterAction(heatFilter: number[]) {
      dispatch({
        type: floormapActionTypes.SET_DEFAULT_HEAT_FILTER,
        state: heatFilter,
      });
    },
    SetHeatFilterAction(selectedHeatFilter: number) {
      dispatch({
        type: floormapActionTypes.SET_HEAT_FILTER,
        state: selectedHeatFilter,
      });
    },
    SetBearingAction(bearing: number) {
      dispatch({
        type: floormapActionTypes.SET_BEARING,
        state: bearing,
      });
    },
    SetZoomAction(zoom: number) {
      dispatch({
        type: floormapActionTypes.SET_ZOOM,
        state: zoom,
      });
    },
    SetLatitudeLongitudeAction(latitudeLongitude: LatitudeLongitudeType) {
      dispatch({
        type: floormapActionTypes.SET_ZOOM,
        state: latitudeLongitude,
      });
    },
  };
};
