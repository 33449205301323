import { checkmark, warning } from 'ionicons/icons';
import { AnimationBuilder, IonicSafeString } from '@ionic/react';

export declare type ToastType = 'info' | 'danger' | 'success';

export let color!: ToastType; // = 'danger';

type Color =
  | 'danger'
  | 'dark'
  | 'light'
  | 'medium'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'tertiary'
  | 'warning'
  | (string & Record<never, never>)
  | undefined;
type Mode = 'ios' | 'md';

interface ToastButton {
  text?: string;
  icon?: string;
  side?: 'start' | 'end';
  role?: 'cancel' | string;
  cssClass?: string | string[];
  handler?: () => boolean | void | Promise<boolean | void>;
}
interface ToastOptions {
  header?: string;
  message?: string | IonicSafeString;
  cssClass?: string | string[];
  duration?: number;
  buttons?: (ToastButton | string)[];
  position?: 'top' | 'bottom' | 'middle';
  translucent?: boolean;
  animated?: boolean;
  icon?: string;
  htmlAttributes?: { [key: string]: any };

  color?: Color;
  mode?: Mode;
  keyboardClose?: boolean;
  id?: string;

  enterAnimation?: AnimationBuilder;
  leaveAnimation?: AnimationBuilder;
}

export const toastConfig: ToastOptions = {
  position: 'top',
  message: '',
  duration: 5000,
  color: color ? color : 'danger',
  buttons: [
    {
      side: 'start',
      icon: color === 'danger' ? (warning as any) : (checkmark as any),
    },
  ],
};
